<template>
  <dialog-form :show-dialog="show" max-width="500px" :persistent="true">
    <div class="d-flex flex-column px-0 pt-16">
      <div class="text-center mb-2 pt-10">
        <div class="warning-error">
          <warning-error class="error-icon"></warning-error>
        </div>

        <h1
          class="text-h6 black--text mb-2 text-center pt-2 px-4"
          :class="`${subtitleMessage ? '' : 'pb-16'}`"
        >
          {{ confirmMessage }}
        </h1>

        <h2
          v-if="subtitleMessage"
          class="text-subtitle-1 black--text mb-2 text-center pt-4 px-4 pb-16"
        >
          {{ subtitleMessage }}
        </h2>
      </div>
      <v-card-actions class="d-flex footer-section justify-end pa-4">
        <v-btn
          tile
          elevation="0"
          bg-color="white"
          class="text-none px-4 mb-4 mb-md-0 align-self-stretch"
          @click="hideModal()"
          >{{ $t("common.actions.cancel") }}</v-btn
        >
        <v-btn
          tile
          elevation="0"
          color="orange"
          class="text-none ml-0 mb-4 mb-md-0 ml-md-4 align-self-stretch white--text"
          type="submit"
          @click="confirm()"
          >{{ $t("common.actions.confirm") }}</v-btn
        >
      </v-card-actions>
    </div>
  </dialog-form>
</template>

<script>
import DialogForm from "./Dialog/DialogForm.vue";
import WarningError from "../assets/svg/warning-error.svg";
export default {
  components: { DialogForm, WarningError },
  props: {
    value: Boolean,
    confirmMessage: String,
    subtitleMessage: String,
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    confirm() {
      this.$emit("confirmed", true);
      this.hideModal();
    },
    hideModal() {
      this.show = false;
    },
  },
};
</script>

<style scoped>
.warning-error {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: rgba(242, 93, 59, 0.12);
  position: relative;
  z-index: 9998;
  margin: 0 auto;
}
.error-icon {
  position: absolute;
  top: 47%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 1;
  z-index: 9999;
}
</style>
