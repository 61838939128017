var render = function render(){var _vm=this,_c=_vm._self._c;return _c('dialog-form',{attrs:{"show-dialog":_vm.show},on:{"showStateChanged":(val) => (_vm.show = val)}},[_c('dialog-form-header',{attrs:{"title":_vm.modalTitle}}),_c('dialog-form-section-one-col',[_c('v-row',{staticClass:"black--text"},[_c('v-col',{attrs:{"cols":"12","sm":"5"}},[_c('v-row',[_c('v-col',[_c('dialog-form-text-input',{attrs:{"title":_vm.$t('pages.attributeClasses.form.className'),"name":"name","solo":"","dense":"","type":"text","hide-details":false,"disabled":!_vm.attributeClass || _vm.attributeClass.fixed,"error-messages":_vm.form.errors.name},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1)],1)],1)],1),_c('v-row',{staticClass:"black--text"},[_c('v-col',{attrs:{"cols":"12","sm":"5"}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('dialog-form-text-input',{attrs:{"title":_vm.$t('pages.attributeClasses.form.availableAttributes.label'),"placeholder":_vm.$t(
                  'pages.attributeClasses.form.availableAttributes.placeholder'
                ),"solo":"","dense":"","type":"text"},model:{value:(_vm.availableAttributesSearch),callback:function ($$v) {_vm.availableAttributesSearch=$$v},expression:"availableAttributesSearch"}})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('div',{staticClass:"solo-item-list-area py-2"},_vm._l((_vm.availableAttributes),function(attribute){return _c('span',{key:attribute.id,class:{
                  selected: _vm.isAttributeSelected(attribute),
                  disabled: _vm.isUnassignedAttributeDisabled(attribute),
                },on:{"click":function($event){_vm.selectedAttribute = attribute}}},[_vm._v(" "+_vm._s(attribute.name)+" ")])}),0)])],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"2","align-self":"center"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6","sm":"12"}},[_c('v-row',{attrs:{"dense":"","justify":"center"}},[_c('v-btn',{attrs:{"outlined":"","icon":"","color":"blue darken-1","text":""},on:{"click":function($event){return _vm.removeAttributeClass(_vm.selectedAttribute)}}},[_c('v-icon',{staticClass:"black--text"},[_vm._v("mdi-chevron-left")])],1)],1)],1),_c('v-col',{attrs:{"cols":"6","sm":"12"}},[_c('v-row',{attrs:{"dense":"","justify":"center"}},[_c('v-btn',{attrs:{"outlined":"","icon":"","color":"blue darken-1","text":""},on:{"click":function($event){return _vm.addAttributeClass(_vm.selectedAttribute)}}},[_c('v-icon',{staticClass:"black--text"},[_vm._v("mdi-chevron-right")])],1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"5"}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('dialog-form-text-input',{attrs:{"title":_vm.$t('pages.attributeClasses.form.availableAttributes.label'),"placeholder":_vm.$t(
                  'pages.attributeClasses.form.availableAttributes.placeholder'
                ),"solo":"","dense":"","type":"text"},model:{value:(_vm.selectedAttributesSearch),callback:function ($$v) {_vm.selectedAttributesSearch=$$v},expression:"selectedAttributesSearch"}})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('div',{staticClass:"solo-item-list-area py-2"},_vm._l((_vm.selectedAttributes),function(attribute){return _c('span',{key:attribute.id,class:{
                  selected: _vm.isAttributeSelected(attribute),
                  disabled: _vm.isAssignedAttributeDisabled(attribute),
                },on:{"click":function($event){_vm.selectedAttribute = attribute}}},[_vm._v(" "+_vm._s(attribute.name)+" ")])}),0)])],1)],1)],1)],1),_c('v-card-actions',{staticClass:"py-6 px-6 d-flex flex-column-reverse flex-md-row"},[(_vm.form.errors.alert)?_c('v-alert',{staticClass:"mb-0",attrs:{"dense":"","type":"error"}},[_vm._v(" "+_vm._s(_vm.form.errors.alert)+" ")]):_vm._e(),_c('v-btn',{staticClass:"text-none px-4 mb-4 mb-md-0 align-self-stretch",attrs:{"color":"#686868","large":"","elevation":"0","text":"","tile":"","x-large":"","loading":_vm.loading},on:{"click":function($event){_vm.show = false}}},[_c('v-icon',{staticClass:"mr-3",attrs:{"dark":""}},[_vm._v("mdi-close")]),_vm._v(_vm._s(_vm.$t("common.actions.close"))+" ")],1),_c('v-spacer'),_c('v-btn',{staticClass:"text-none ml-0 mb-4 mb-md-0 ml-md-4 px-6 align-self-stretch",attrs:{"color":"#266663","disabled":_vm.loading,"large":"","dark":"","elevation":"0","tile":"","loading":_vm.loading},on:{"click":function($event){return _vm.saveAttributeClass()}}},[_c('v-icon',{staticClass:"white--text"},[_vm._v("mdi-content-save-outline")]),_vm._v(" "+_vm._s(_vm.$t("common.actions.save"))+" ")],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }