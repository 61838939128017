<template>
  <div class="page-container fill-height grow d-flex flex-column flex-nowrap">
    <v-container fluid class="py-0" style="margin-top: -66px">
      <v-row class="px-2">
        <v-col cols="12" md="6" lg="3" class="px-2 py-2 pt-4 d-flex">
          <amount-change-card
            :title="$t('components.cards.titles.entries')"
            iconBackColor="#6493BE1f"
            :value="totalEvents"
            :hideBenchmark="true"
          >
            <template v-slot:icon>
              <Package style="width: 20px; height: 20px"></Package>
            </template>
          </amount-change-card>
        </v-col>
        <v-col cols="12" md="6" lg="3" class="px-2 py-2 pt-4 d-flex">
          <amount-change-card
            :title="$t('components.cards.titles.ignored')"
            iconBackColor="#6493BE1f"
            :value="ignoreEvents"
            :hideBenchmark="true"
          >
            <template v-slot:icon>
              <Package style="width: 20px; height: 20px"></Package>
            </template>
          </amount-change-card>
        </v-col>
        <v-col cols="12" md="6" lg="3" class="px-2 py-2 pt-4 d-flex">
          <amount-change-card
            :title="$t('components.cards.titles.failed')"
            iconBackColor="#6493BE1f"
            :value="failEvents"
            :hideBenchmark="true"
          >
            <template v-slot:icon>
              <Package style="width: 20px; height: 20px"></Package>
            </template>
          </amount-change-card>
        </v-col>
        <v-col cols="12" md="6" lg="3" class="px-2 py-2 pt-4 d-flex">
          <amount-change-card
            :title="$t('components.cards.titles.lastUpdated')"
            iconBackColor="#6493BE1f"
            :value="formatDate(this.integration.lastSynchronizedAt, false)"
            :hideBenchmark="true"
          >
            <template v-slot:icon>
              <Package style="width: 20px; height: 20px"></Package>
            </template>
          </amount-change-card>
        </v-col>
      </v-row>
      <v-row class="px-4 pb-2">
        <v-btn
          tile
          outlined
          elevation="0"
          bg-color="white"
          class="border-sm text-capitalize black--text black--border white"
          @click="backToIntegrations"
        >
          {{
            $t("pages.settings.index.integrations.events.backToIntegrations")
          }}
        </v-btn>
      </v-row>
      <v-row dense no-gutters>
        <v-col cols="12" sm="6" xl="3" class="pl-1 pb-0">
          <div class="flex-grow-1 white pa-4 pr-2 d-flex align-center">
            <v-text-field
              :label="$t('pages.settings.index.search')"
              color="#686868"
              class="search-field rounded-0 pt-0"
              height="44"
              light
              hide-details="auto"
              background-color="#F7F7F7"
              filled
              solo
              dense
              prepend-inner-icon="mdi-magnify"
              flat
              clearable
              v-model="filters.search"
            ></v-text-field>
          </div>
        </v-col>
        <v-col class="bg-white"></v-col>
      </v-row>
      <v-row class="px-2">
        <v-col cols="12" class="px-2 pt-0">
          <v-data-table
            :headers="headers"
            :items="integrationEntryEvents.data"
            :server-items-length="integrationEntryEvents.totalCount"
            :options.sync="pagination"
            :item-class="entryRowClass"
            :footer-props="tableFooterProps"
            @update:page="changePage(pagination)"
            @update:items-per-page="changePage(pagination)"
            @update:sort-by="changePage(pagination)"
            @update:sort-desc="changePage(pagination)"
          >
            <template #item.timestamp="{ item }">
              {{ formatDate(item.timestamp) }}
            </template>
            <template #item.eventInfo="{ item }">
              {{ formatEventInfo(item) }}
            </template>
            <template #item.edit="{ item }">
              <v-menu
                offset-y
                min-width="120px"
                class="px-4"
                left
                v-if="item.attachmentUrl !== null || item.attachmentId !== null"
              >
                <template v-slot:activator="{ on, attrs }">
                  <div class="d-flex justify-end">
                    <v-btn v-bind="attrs" v-on="on" icon
                      ><v-icon>mdi-dots-vertical</v-icon></v-btn
                    >
                  </div>
                </template>
                <v-list>
                  <v-list-item
                    v-if="
                      item.attachmentUrl !== null || item.attachmentId !== null
                    "
                    @click="openAttachment(item)"
                  >
                    <v-list-item-title>
                      {{
                        $t(
                          "pages.integrationEntryEvents.index.actions.viewAttachment"
                        )
                      }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { appLayout } from "@/util/layout";
import AmountChangeCard from "../../Components/Cards/AmountChangeCard.vue";
import Package from "../../assets/svg/package.svg";
import debounce from "lodash/debounce";
import pickBy from "lodash/pickBy";
import { DateTime } from "luxon";
import qs from "qs";
import { normalizeISOString } from "../../util/dateTime";
import GlobalDateRange from "../../mixins/GlobalDateRange.vue";
import { footerProps } from "@/util/dataTable";

export default {
  mixins: [GlobalDateRange],
  layout: appLayout({
    title: "pages.integrationEntryEvents.index.title",
    pushContent: true,
  }),
  components: { AmountChangeCard, Package },
  props: {
    integrationEntryEvents: Object,
    totalEvents: Number,
    ignoreEvents: Number,
    failEvents: Number,
    integration: Object,
    settings: Object,
  },
  data() {
    const searchParams = qs.parse(window.location.search.substring(1));

    return {
      pagination: {
        page: this.integrationEntryEvents?.currentPage,
        itemsPerPage: this.integrationEntryEvents?.pageSize,
        sortBy: [searchParams.sortBy ?? "entryId"],
        sortDesc: [searchParams.sortDesc === "true"],
        dateRange: {
          from: null,
          to: null,
        },
      },
      filters: {
        search: searchParams.filters?.search ?? null,
      },
      tableFooterProps: footerProps,
    };
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t("pages.integrationEntryEvents.index.fields.entryId"),
          value: "entryId",
        },
        {
          text: this.$t("pages.integrationEntryEvents.index.fields.dateTime"),
          value: "timestamp",
        },
        {
          text: this.$t("pages.integrationEntryEvents.index.fields.entryText"),
          value: "entryText",
        },
        {
          text: this.$t("pages.integrationEntryEvents.index.fields.event"),
          value: "event",
        },
        {
          text: this.$t("pages.integrationEntryEvents.index.fields.eventInfo"),
          value: "eventInfo",
        },
        {
          text: "",
          value: "edit",
        },
      ];
    },
  },
  methods: {
    changePage(options) {
      const existingQuery = qs.parse(window.location.search.substring(1));

      const query = {
        ...existingQuery,
        integrationid: this.integration.id,
        page: options.page,
        pageSize: options.itemsPerPage,
        filters: pickBy(this.filters),
        sortBy: options.sortBy[0],
        sortDesc: options.sortDesc[0],
        dateRangeFrom: options.dateRange?.from ?? null,
        dateRangeTo: options.dateRange?.to ?? null,
      };

      this.$inertia.get(
        this.route("integration-entry-events.index", query),
        {},
        {
          preserveState: true,
          preserveScroll: true,
        }
      );
    },
    formatDate(dateString, seconds = true) {
      if (!dateString) return "";

      const normalizedDate = normalizeISOString(dateString);

      let dateFormat = "";

      if (this.settings.defaultDateFormat === "YYYY-MM-DD") {
        dateFormat = "yyyy-LL-dd HH:mm";
      } else if (this.settings.defaultDateFormat === "DD-MM-YYYY") {
        dateFormat = "dd-LL-yyyy HH:mm";
      }

      if (seconds) dateFormat += ":ss";

      return DateTime.fromISO(normalizedDate).toUTC().toFormat(dateFormat);
    },
    formatEventInfo(entryEvent) {
      const eventInfo = entryEvent.eventInfo;

      const eventArgs = entryEvent.eventInfoArgs ?? {};

      if (!this.$te(`common.integrations.entries.info.${eventInfo}`))
        return eventInfo;

      return this.$t(
        `common.integrations.entries.info.${eventInfo}`,
        eventArgs
      );
    },
    getAttachmentUrl(entry) {
      if (entry.attachmentId) {
        return this.route("api.files.content", {
          id: entry.attachmentId,
          filename: entry.attachmentName,
        });
      }

      return entry.attachmentUrl;
    },
    openAttachment(entry) {
      const anchor = document.createElement("a");
      anchor.target = "_blank";
      anchor.href = this.getAttachmentUrl(entry);

      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
    },
    entryRowClass(item) {
      if (item.event === "FAIL") return "red--text";
      if (item.event === "IGNORE" || item.event === "SKIP")
        return "amber--text";

      return "";
    },
    backToIntegrations() {
      this.$inertia.get(this.route("integrations.index"));
    },
  },
  mounted() {
    this.syncDateRange((dateRange) => {
      this.pagination.dateRange = dateRange;
      this.pagination.page = 1;

      this.changePage(this.pagination);
    });
  },
  watch: {
    filters: {
      handler: debounce(function () {
        this.pagination = {
          page: 1,
          itemsPerPage: 50,
          sortBy: [],
          sortDesc: [],
        };

        this.changePage(this.pagination);
      }, 250),
      deep: true,
    },
  },
};
</script>
