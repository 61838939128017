<template>
  <DialogFormInputWrapper :title="title">
    <div class="d-flex flex-row" :class="dateFormatClass">
      <DialogFormInputWrapper :title="$t('components.inputs.dateRangePicker.year')" class="year">
        <v-text-field
          class="rounded-0 mb-1 mr-2"
          background-color="#F7F7F7"
          solo
          flat
          label="yyyy"
          single-line
          filled
          :hide-details="hideDetails"
          :disabled="disabled"
          :readonly="readonly"
          :value="year"
          @change="(val) => yearChanged(val)"
          dense
          height="20px"
          :tabindex="dateFormatIndexes[0]"
        >
        </v-text-field>
      </DialogFormInputWrapper>
      <DialogFormInputWrapper :title="$t('components.inputs.dateRangePicker.month')" class="month">
        <v-text-field
          class="rounded-0 mb-1 mr-2"
          background-color="#F7F7F7"
          solo
          flat
          label="MM"
          single-line
          filled
          :hide-details="hideDetails"
          :disabled="disabled"
          :value="month"
          @change="(val) => monthChanged(val)"
          :readonly="readonly"
          dense
          height="20px"
          :tabindex="dateFormatIndexes[1]"
        >
        </v-text-field>
      </DialogFormInputWrapper>
      <DialogFormInputWrapper :title="$t('components.inputs.dateRangePicker.day')" class="day">
        <v-text-field
          class="rounded-0 mb-1 mr-2"
          background-color="#F7F7F7"
          solo
          flat
          label="dd"
          single-line
          filled
          :hide-details="hideDetails"
          :disabled="disabled"
          :value="day"
          @change="(val) => dayChanged(val)"
          :readonly="readonly"
          dense
          height="20px"
          :tabindex="dateFormatIndexes[2]"
        >
        </v-text-field>
      </DialogFormInputWrapper>
    </div>
  </DialogFormInputWrapper>
</template>

<script>
import DialogFormInputWrapper from "../Dialog/inputs/DialogFormInputWrapper.vue";
export default {
  components: {
    DialogFormInputWrapper,
  },
  props: {
    title: String,
    placeholder: String,
    year: String,
    month: String,
    day: String,
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      defailt: false,
    },
    hideDetails: { type: [Boolean, String], default: true },
    tabKey: Number,
    defaultDateFormat: {
      type: String,
      default: "YYYY-MM-DD",
    },
  },
  data() {
    return {};
  },
  emits: ["input", "change"],

  computed: {
    dateFormatClass() {
      if (this.defaultDateFormat === "DD-MM-YYYY") {
        return "date-format-dd-mm-yyyy";
      } else {
        return "date-format-yyyy-mm-dd";
      }
    },
    dateFormatIndexes() {
      if (this.defaultDateFormat === "DD-MM-YYYY") {
        return [3, 2, 1].map((n) => n + this.tabKey * 3);
      } else {
        return [1, 2, 3].map((n) => n + this.tabKey * 3);
      }
    },
  },
  methods: {
    yearChanged(value) {
      const strippedValue = value.replace(/[^0-9]/g, "").slice(0, 4);

      // Pad the value to 4 digits
      const paddedValue = strippedValue.padEnd(4, "0");

      // Emit the event with the formatted date
      const date = paddedValue + "-" + this.month + "-" + this.day;
      if (this.dateIsValid(date)) {
        this.$emit("change", date);
      } else {
        console.warn("invalid date", date);
        this.$emit("change", "2020-" + this.month + "-" + this.day);
      }
    },
    monthChanged(value) {
      const strippedValue = value.replace(/[^0-9]/g, "").slice(0, 2);
      // Pad the value to 4 digits
      const paddedValue = strippedValue.padStart(2, "0");
      // Emit the event with the formatted date

      const date = this.year + "-" + paddedValue + "-" + this.day;
      if (this.dateIsValid(date)) {
        this.$emit("change", date);
      } else {
        console.warn("invalid date", date);
        this.$emit("change", this.year + "-01-" + this.day);
      }
    },
    dayChanged(value) {
      const strippedValue = value.replace(/[^0-9]/g, "").slice(0, 2);
      // Pad the value to 4 digits
      const paddedValue = strippedValue.padStart(2, "0");
      // Emit the event with the formatted date

      const date = this.year + "-" + this.month + "-" + paddedValue;
      if (this.dateIsValid(date)) {
        this.$emit("change", date);
      } else {
        console.warn("invalid date", date);
        this.$emit("change", this.year + "-" + this.month + "-01");
      }
    },
    dateIsValid(dateString) {
      // Regular expression to check if the date is in the correct format
      const regex = /^\d{4}-\d{2}-\d{2}$/;

      // If the date string doesn't match the format, return false
      if (!regex.test(dateString)) {
        return false;
      }

      // Parse the date components
      const [year, month, day] = dateString.split("-").map(Number);

      // Create a new Date object with the parsed components
      const date = new Date(year, month - 1, day);

      // Check if the year, month, and day match the input
      if (
        date.getFullYear() !== year ||
        date.getMonth() !== month - 1 ||
        date.getDate() !== day
      ) {
        return false;
      }

      // If all checks pass, return true
      return true;
    }, 
  },
};
</script>

<style lang="scss" scoped>
.date-format-dd-mm-yyyy {
  .day {
    order: 1;
  }
  .month {
    order: 2;
  }
  .year {
    order: 3;
  }
}
</style>
