<template>
  <div class="flex-grow-1 d-flex justify-center align-center">
    <div class="d-flex justify-center align-center">
      <div class="px-0" fluid style="width: 1313px">
        <v-card
          light
          color="#fff"
          elevation="0"
          rounded="0"
          max-width="100%"
          class="flex-grow-1"
        >
          <div class="px-10 py-10">
            <p class="page-title">
              {{ $t("components.reportTemplates.reportAppendix3.pageTitle") }}
            </p>
            <div class="appendix-content">
              <p>
                {{
                  $t(
                    "components.reportTemplates.reportAppendix3.pageDescription"
                  )
                }}
              </p>
              <p class="heading">
                {{
                  $t(
                    "components.reportTemplates.reportAppendix3.content1.heading"
                  )
                }}
              </p>
              <p>
                {{
                  $t("components.reportTemplates.reportAppendix3.content1.text")
                }}
              </p>
              <p class="heading">
                {{
                  $t(
                    "components.reportTemplates.reportAppendix3.content2.heading"
                  )
                }}
              </p>
              <p>
                {{
                  $t("components.reportTemplates.reportAppendix3.content2.text")
                }}
              </p>
              <p class="heading">
                {{
                  $t(
                    "components.reportTemplates.reportAppendix3.content3.heading"
                  )
                }}
              </p>
              <p>
                {{
                  $t("components.reportTemplates.reportAppendix3.content3.text")
                }}
              </p>
              <p class="heading">
                {{
                  $t(
                    "components.reportTemplates.reportAppendix3.content4.heading"
                  )
                }}
              </p>
              <p>
                {{
                  $t(
                    "components.reportTemplates.reportAppendix3.content4.text"
                  )
                }}:
              </p>
              <div class="d-flex flex-row usps">
                <div class="flex-grow-1">
                  <p>
                    {{
                      $t(
                        "components.reportTemplates.reportAppendix3.summerization1.number"
                      )
                    }}
                  </p>
                  <p>
                    {{
                      $t(
                        "components.reportTemplates.reportAppendix3.summerization1.text"
                      )
                    }}
                  </p>
                </div>
                <div class="flex-grow-1">
                  <p>
                    {{
                      $t(
                        "components.reportTemplates.reportAppendix3.summerization2.number"
                      )
                    }}
                  </p>
                  <p>
                    {{
                      $t(
                        "components.reportTemplates.reportAppendix3.summerization2.text"
                      )
                    }}
                  </p>
                </div>
                <div class="flex-grow-1">
                  <p>
                    {{
                      $t(
                        "components.reportTemplates.reportAppendix3.summerization3.number"
                      )
                    }}
                  </p>
                  <p>
                    {{
                      $t(
                        "components.reportTemplates.reportAppendix3.summerization3.text"
                      )
                    }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.page-title {
  font-size: 42px;
}

.appendix-content {
  p {
    font-size: 20px;
    color: #666666;
    &.heading {
      color: #000;
      margin-bottom: 0;
    }
  }

  .usps {
    margin-top: 80px;
    div {
      p {
        font-size: 26px;
      }
      > p:first-child {
        margin-bottom: 0;
        font-size: 38px;
        line-height: 1;
        color: #000000;
      }
    }
  }
}
</style>
