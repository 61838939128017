<template>
  <dialog-form
    :show-dialog="show"
    :loading="loading"
    @showStateChanged="show = $event"
    max-width="500px"
  >
    <dialog-form-header-stepper
      :on-dismiss="() => (show = false)"
    ></dialog-form-header-stepper>
    <dialog-form-section-one-col class="py-6 px-2">
      <v-row>
        <v-col cols="2" offset="5">
          <lottie-vue-player v-bind="oneshotAnimation" :src="LottieCheckmark" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <p class="text-body-1 text-center">
            {{ $t("pages.settings.index.integrations.created") }}
          </p>
        </v-col>
      </v-row>
    </dialog-form-section-one-col>
    <dialog-form-section-one-col>
      <v-row dense>
        <v-col cols="6" offset="3">
          <v-btn
            large
            class="align-self-stretch align-self-sm-center mt-5 mt-sm-0 fill-width white--text"
            elevation="0"
            tile
            color="primary"
            @click="() => (show = false)"
          >
            {{ $t("common.actions.close") }}
          </v-btn>
        </v-col>
      </v-row>
    </dialog-form-section-one-col>
  </dialog-form>
</template>
<script>
import DialogForm from "../../Components/Dialog/DialogForm.vue";
import DialogFormHeaderStepper from "../../Components/Dialog/DialogFormHeaderStepper.vue";
import DialogFormSectionOneCol from "../../Components/Dialog/DialogFormSectionOneCol.vue";
import { oneshotAnimation } from "../../util/lottieConfigs";
import LottieCheckmark from "@/assets/animations/lottie-checkmark.json";

export default {
  components: {
    DialogForm,
    DialogFormHeaderStepper,
    DialogFormSectionOneCol,
  },
  props: {
    value: Boolean,
  },
  data() {
    return {
      loading: false,
      oneshotAnimation,
      LottieCheckmark: JSON.stringify(LottieCheckmark),
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>
