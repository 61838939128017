<template>
  <dialog-form-input-wrapper :title="title" :ai-status="aiStatus">
    <v-textarea
      class="rounded-0 mb-1"
      background-color="#F7F7F7"
      solo
      flat
      :label="placeholder"
      rows="3"
      filled
      :hide-details="true"
      :disabled="disabled"
      :value="value"
      :append-icon="appendIcon"
      :error-messages="errorMessages"
      @input="$emit('input', $event)"
      @change="$emit('change', $event)"
      no-resize
    ></v-textarea>
  </dialog-form-input-wrapper>
</template>

<script>
import DialogFormInputWrapper from "./DialogFormInputWrapper.vue";
export default {
  components: { DialogFormInputWrapper },
  props: {
    title: String,
    placeholder: String,
    aiStatus: String,
    value: String,
    errorMessages: Array,
    disabled: {
      type: Boolean,
      default: false,
    },
    appendIcon: null,
  },
  emits: ["update:value"],
};
</script>

<style lang="scss" scoped></style>
