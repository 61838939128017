<template>
  <div class="page-container fill-height grow d-flex flex-column flex-nowrap">
    <div>
      <v-card elevation="0" class="ma-4">
        <v-card-text class="pa-0 flex-grow-1">
          <v-tabs
            v-model="tab"
            class="mb-4 pl-sm-4"
            style="border-bottom: 1px solid #e8e8e8"
            show-arrows
          >
            <v-tab
              v-for="contentTab in tabs"
              :key="contentTab.id"
              :href="`#${getTabHref(contentTab.id)}`"
              @change="setTabInUrl(contentTab.id)"
            >
              {{ contentTab.name }}
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab" class="fill-height fill-width px-0 pb-4">
            <tab-general
              v-bind="generalBindings"
              ref="generalTab"
              :auth="auth"
              :locales="locales"
            />
          </v-tabs-items>
        </v-card-text>
      </v-card>
      <v-card elevation="0" class="mt-4 ml-4">
        <v-card-text>
          <v-row>
            <v-col cols="10">
              <v-subheader>{{ $t("pages.profile.logout") }}</v-subheader>
            </v-col>

            <v-col cols="2" class="d-flex justify-end">
              <v-btn
                tile
                block
                elevation="0"
                color="orange"
                class="font-weight-regular text-capitalize mb-2 mr-5 white--text"
                href="/logout"
                v-inertia:post
                >{{ $t("pages.profile.logout") }}</v-btn
              >
            </v-col>
          </v-row>
          <v-row class="d-none">
            <v-col cols="4">
              <v-subheader>{{ $t("pages.profile.resetPassword") }}</v-subheader>
            </v-col>
            <v-col cols="6" offset="2" class="d-flex justify-end">
              <v-btn outlined block color="primary">{{
                $t("pages.profile.resetPassword")
              }}</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import { appLayout } from "@/util/layout";
import TabGeneral from "./Tabs/TabGeneral.vue";

export default {
  layout: appLayout({ title: "pages.profile.title" }),
  components: {
    TabGeneral,
  },
  props: {
    auth: Object,
    locales: Array,
  },
  data() {
    return {
      tab: window.location.hash?.substring(1) ?? null,
    };
  },
  computed: {
    formattedLocales() {
      return this.locales.map((x) => ({
        text: this.$t(`pages.profile.languages.${x}`),
        value: x,
      }));
    },
    generalBindings() {
      return {
        tabHref: this.getTabHref(1),
      };
    },
    tabs() {
      return [
        {
          id: 1,
          name: this.$t("pages.settings.index.tabs.general"),
        },
      ];
    },
  },
  methods: {
    getTabHref(tabId) {
      switch (tabId) {
        case 1:
          return "general";
      }
    },
    setTabInUrl(tabId) {
      const tabHref = this.getTabHref(tabId);
      if (!tabHref) return;
    },
  },
};
</script>
