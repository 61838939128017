<template>
  <div
    tabindex="0"
    class="presentation-container mx-4 mb-4"
    :class="connectedToSystem === false ? 'connect-blur' : null"
    style="margin-top: -47px"
  >
    <amount-change-card
      class="card-asset-num"
      :class="!connectedToSystem ? 'connect-color-overlay' : null"
      :title="$t('components.cards.titles.numberOfAssets')"
      iconBackColor="#6493BE1f"
      :value="numberOfAssets"
      :hideBenchmark="true"
    >
      <template v-slot:icon>
        <Package style="width: 20px; height: 20px"></Package>
      </template>
    </amount-change-card>
    <amount-change-card
      class="card-total-co2"
      :title="$t('components.cards.titles.totalSpentAmount')"
      iconBackColor="#EDDDE6"
      :value="totalSpentAmount"
      :unit="totalSpentAmountUnit"
      :hideBenchmark="true"
      :class="!connectedToSystem ? 'connect-color-overlay' : null"
    >
      <template v-slot:icon>
        <DonateCoin style="width: 20px; height: 20px"></DonateCoin>
      </template>
    </amount-change-card>
    <!---<amount-change-card
      class="card-total-co2"
      :title="$t('components.cards.titles.co2Ytd')"
      iconBackColor="#EDDDE6"
      :value="co2Ytd"
      :unit="co2YtdUnit"
      :hideBenchmark="true"
      :class="!connectedToSystem ? 'connect-color-overlay' : null"
    >
      <template v-slot:icon>
        <DonateCoin style="width: 20px; height: 20px"></DonateCoin>
      </template>
    </amount-change-card>-->

    <amount-change-card
      class="card-co2-year"
      :title="$t('components.cards.titles.totalCo2')"
      iconBackColor="#E5EDEC"
      :value="totalCo2Used"
      :unit="totalCo2UsedUnit"
      :hideBenchmark="true"
      :class="!connectedToSystem ? 'connect-color-overlay' : null"
    >
      <template v-slot:icon>
        <Leaf style="width: 20px; height: 20px"></Leaf>
      </template>
    </amount-change-card>
    <amount-change-card
      :title="$t('components.cards.titles.trees')"
      iconBackColor="#E5EDEC"
      :value="treesToOffset"
      :unit="$t('common.units.trees')"
      :hideBenchmark="true"
      class="card-trees"
      :class="!connectedToSystem ? 'connect-color-overlay' : null"
    >
      <template v-slot:icon>
        <Tree style="width: 20px; height: 20px"></Tree>
      </template>
    </amount-change-card>

    <PieChartCard
      :labels="co2CategoryLabels"
      :colors="['#266663', '#6493BE', '#F25D3B', '#B7B7B7']"
      :dataSeries="getScopes"
      :title="$t('components.cards.co2Categories.title')"
      class="card-co2-category"
      :class="!connectedToSystem ? 'connect-color-overlay' : null"
    ></PieChartCard>
    <LineChartCard
      v-if="
        emissionsPerMonthCurrentYear.length > 0 &&
        emissionsPerMonthLastYear.length > 0
      "
      class="card-co2-emissions-overview"
      :title="$t('components.cards.co2Emissions.title')"
      :series="adjustedEmissionsPerMonthCurrentYear"
      :compare-series="adjustedEmissionsPerMonthLastYear"
      :unit="
        emissionsPerMonthOver1000kg
          ? this.$t('common.units.tCO2e')
          : this.$t('common.units.kgCO2e')
      "
      :x-axis-categories="months1YearToCurrent"
      :seriesLabel="$t('components.cards.co2Emissions.labels.thisYear')"
      :compareSeriesLabel="$t('components.cards.co2Emissions.labels.lastYear')"
      :maxVal="
        Math.ceil(
          Math.max(
            ...[
              ...adjustedEmissionsPerMonthCurrentYear,
              ...adjustedEmissionsPerMonthLastYear,
            ]
          )
        )
      "
      :class="!connectedToSystem ? 'connect-color-overlay' : null"
    ></LineChartCard>
    <v-card
      v-else
      light
      color="#fff"
      elevation="0"
      rounded="0"
      class="card-co2-emissions-overview"
      :class="!connectedToSystem ? 'connect-color-overlay' : null"
    >
      <div
        class="mb-4 px-5 py-5 fill-height d-flex flex-column"
        style="height: 550px"
      >
        <h3 class="mb-8 font-weight-regular text-h5">
          {{ $t("components.cards.co2Emissions.title") }}
        </h3>
        <div class="flex-grow-1 h-10 custom-skeleton"></div>
      </div>
    </v-card>
    <bar-chart-card
      v-if="co2MethodsByMonths.length > 0"
      :title="$t('components.cards.emissionsByMethod.title')"
      class="card-co2-emissions-overview-calc"
      :series="co2MethodsByMonths"
      :x-axis-categories="months1YearToCurrent"
      :unit="$t('common.units.kgCO2e')"
      :maxVal="
        Math.ceil(
          Math.max(
            ...[...co2MethodsByMonths[0]?.data, ...co2MethodsByMonths[1]?.data]
          )
        )
      "
      :class="!connectedToSystem ? 'connect-color-overlay' : null"
    ></bar-chart-card>
    <v-card
      v-else
      light
      color="#fff"
      elevation="0"
      rounded="0"
      class="card-co2-emissions-overview-calc"
      :class="!connectedToSystem ? 'connect-color-overlay' : null"
    >
      <div
        class="mb-4 px-5 py-5 fill-height d-flex flex-column"
        style="height: 550px"
      >
        <h3 class="mb-8 font-weight-regular text-h5">
          Emissions overview by calculation method
        </h3>
        <div class="flex-grow-1 h-10 custom-skeleton"></div>
      </div>
    </v-card>
    <v-card elevation="0" class="simple-table-card card-top-20-table">
      <div
        class="px-5 py-5 fill-height d-flex flex-column"
        :class="!connectedToSystem ? 'connect-color-overlay' : null"
      >
        <div class="d-flex flex-column">
          <div class="d-flex flex-row align-center pb-8">
            <h3
              v-if="top20ListMode == 'purchase'"
              class="font-weight-regular text-h5 flex-grow-1 pr-4"
            >
              {{ $t("components.cards.top20Purchases.title") }}
            </h3>
            <h3 v-else class="font-weight-regular text-h5 flex-grow-1 pr-4">
              {{ $t("components.cards.top20Suppliers.title") }}
            </h3>
            <v-menu offset-y left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  light
                  plain
                  small
                  elevation="0"
                  v-bind="attrs"
                  v-on="on"
                  class="table-select-btn align-self-start px-0"
                  ><v-icon>mdi-dots-vertical</v-icon></v-btn
                >
              </template>
              <v-list>
                <v-list-item @click="top20ListMode = 'purchase'">
                  <v-list-item-title>{{
                    $t("components.cards.top20Purchases.buttonText")
                  }}</v-list-item-title>
                </v-list-item>
                <v-list-item @click="top20ListMode = 'supplier'">
                  <v-list-item-title>{{
                    $t("components.cards.top20Suppliers.buttonText")
                  }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
          <v-data-table
            v-if="top20ListMode == 'supplier'"
            :headers="emissionsBySupplierHeaders"
            :items="mappedSuppliers"
            :items-per-page="20"
            hide-default-footer
            disable-sort
            class="card-top-20-table"
          >
            <template #item.name="{ item }">
              {{
                item.name === "no_supplier"
                  ? $t("components.cards.top20Suppliers.noSupplier")
                  : item.name
              }}
            </template>

            <template #item.emissions="{ item }">
              {{ item.emissions }}
              {{ $t("common.units.kgCO2e") }}
            </template>
            emissions
          </v-data-table>
          <v-data-table
            v-else
            :headers="emissionsBypurchaseHeader"
            :items="mappedAssets"
            :items-per-page="20"
            hide-default-footer
            disable-sort
            class="data-table"
          >
            <template
              #item.041a251a-2138-4e14-bdfd-876194f4e04f.decimalValue="{ item }"
            >
              {{ item["041a251a-2138-4e14-bdfd-876194f4e04f"].decimalValue }}
              {{ $t("common.units.kgCO2e") }}
            </template>

            <template
              #item.8192dfef-ef4b-4ed1-9c9e-26ed84ef8de2.searchString="{ item }"
            >
              {{ defaultCurrencyCode }}
              {{
                formatCurrency(
                  item["8192dfef-ef4b-4ed1-9c9e-26ed84ef8de2"]?.decimalValue
                )
              }}
            </template>
          </v-data-table>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import { presentationLayout } from "@/util/presentationLayout";
import AmountChangeCard from "../../Components/Cards/AmountChangeCard.vue";
import PieChartCard from "../../Components/Cards/PieChartCard.vue";
import LineChartCard from "../../Components/Cards/LineChartCard.vue";

import Leaf from "../../../src/assets/svg/leaf.svg";
import Tree from "../../../src/assets/svg/tree.svg";
import DonateCoin from "../../../src/assets/svg/donate-coin.svg";
import Package from "../../../src/assets/svg/package.svg";
import BarChartCard from "../../Components/Cards/BarChartCard.vue";
import {
  currencyFormatter,
  integerNumberFormatter,
} from "../../util/formatters";

import GlobalDateRange from "../../mixins/GlobalDateRange";
import dashboardDummyData from "../../util/dashboardDummyData";
import Formatters from "../../mixins/Formatters.vue";

export default {
  mixins: [GlobalDateRange, Formatters],
  components: {
    AmountChangeCard,
    PieChartCard,
    LineChartCard,
    Leaf,
    Tree,
    DonateCoin,
    BarChartCard,
    Package,
  },
  layout: presentationLayout({
    title: "pages.dashboard.title",
    pushContent: true,
  }),
  props: {
    fiscalYears: Array,
    depreciationMethods: Array,
    depreciationStarts: Array,
    integrationInstalled: Boolean,
    currencies: Array,
    hasAssets: Boolean,
  },
  data() {
    return {
      emissionsPerMonthCurrentYear: [],
      emissionsPerMonthLastYear: [],
      assetCardData: {},
      co2MethodsByMonths: [],
      top20ListMode: "purchase",
      dateRange: {
        from: null,
        to: null,
      },
      scopes: null,
      highestCarbonAssets: [],
      highestCarbonSuppliers: [],
      connectedToSystem: this.hasAssets,
    };
  },
  methods: {
    exitPresentationMode() {
      // To exit with esc button
      //this.$inertia.get(this.route("dashboard.index"));
    },
    refreshData() {
      this.syncDateRange((dateRange) => {
        // data needs to be cleared to rerender the charts
        // Not when we want to dybamically update charts
        //      this.scopes = null;
        //      this.emissionsPerMonthCurrentYear = [];
        //      this.emissionsPerMonthLastYear = [];
        //      this.co2MethodsByMonths = [];

        // Fetch data in date range
        this.dateRange = dateRange;
        if (this.connectedToSystem) {
          this.getInfoGraphData(dateRange);
        } else {
          // populate dummy data
          this.assetCardData = dashboardDummyData.assetCardData;
          this.emissionsPerMonthCurrentYear =
            dashboardDummyData.emissionsPerMonthCurrentYear;
          this.emissionsPerMonthLastYear =
            dashboardDummyData.emissionsPerMonthLastYear;
          this.co2MethodsByMonths = dashboardDummyData.co2MethodsByMonths;
          this.scopes = dashboardDummyData.scopes;
          this.highestCarbonSuppliers =
            dashboardDummyData.highestCarbonSuppliers;
          this.highestCarbonAssets = dashboardDummyData.highestCarbonAssets;
        }
      });
    },
    async getInfoGraphData(dateRange) {
      // Amount change card data
      fetch(
        this.route("api.dashboard.infographics.assets.card-data", {
          fromDate: dateRange.from,
          toDate: dateRange.to,
          dashboard: "true",
        })
      )
        .then((response) => {
          if (!response.ok) {
            return Promise.reject();
          }
          return response.json();
        })
        .then((data) => {
          this.assetCardData = data;
        })
        .catch((error) => {
          console.warn("Could not load data", error);
        });

      // Co2 emissions overview
      fetch(
        this.route("api.dashboard.infographics.emissions.monthly", {
          fromDate: dateRange.from,
          toDate: dateRange.to,
        })
      )
        .then((response) => {
          if (!response.ok) {
            return Promise.reject();
          }
          return response.json();
        })
        .then((data) => {
          this.emissionsPerMonthCurrentYear = data;
        })
        .catch((error) => {
          console.warn("Could not load data", error);
        });

      // Create compare seriest with same dates but within last year
      fetch(
        this.route("api.dashboard.infographics.emissions.monthly", {
          fromDate: dateRange.from,
          toDate: dateRange.to,
          yearOffset: -1,
        })
      )
        .then((response) => {
          if (!response.ok) {
            return Promise.reject();
          }
          return response.json();
        })
        .then((data) => {
          this.emissionsPerMonthLastYear = data;
        })
        .catch((error) => {
          console.warn("Could not load data", error);
        });

      fetch(
        this.route("api.dashboard.infographics.co2Methods.monthly", {
          fromDate: dateRange.from,
          toDate: dateRange.to,
          yearOffset: -1,
        })
      )
        .then((response) => {
          if (!response.ok) {
            return Promise.reject();
          }
          return response.json();
        })
        .then((data) => {
          this.co2MethodsByMonths = data;
        })
        .catch((error) => {
          console.warn("Could not load data", error);
        });

      fetch(
        this.route("api.dashboard.infographics.scopes.grouped", {
          fromDate: dateRange.from,
          toDate: dateRange.to,
        })
      )
        .then((response) => {
          if (!response.ok) {
            return Promise.reject();
          }
          return response.json();
        })
        .then((data) => {
          this.scopes = data;
        })
        .catch((error) => {
          console.warn("Could not load data", error);
        });

      fetch(
        this.route(
          "api.dashboard.infographics.lists.highestEmissionsSuppliers",
          {
            fromDate: dateRange.from,
            toDate: dateRange.to,
          }
        )
      )
        .then((response) => {
          if (!response.ok) {
            return Promise.reject();
          }
          return response.json();
        })
        .then((data) => {
          this.highestCarbonSuppliers = data;
        })
        .catch((error) => {
          console.warn("Could not load data", error);
        });

      fetch(
        this.route(
          "api.dashboard.infographics.lists.highestEmissionsPurchases",
          {
            fromDate: dateRange.from,
            toDate: dateRange.to,
          }
        )
      )
        .then((response) => {
          if (!response.ok) {
            return Promise.reject();
          }
          return response.json();
        })
        .then((data) => {
          this.highestCarbonAssets = data;
        })
        .catch((error) => {
          console.warn("Could not load data", error);
        });
    },
    monthDiff(d1, d2) {
      var months;
      months = (d2.getFullYear() - d1.getFullYear()) * 12;
      months -= d1.getMonth();
      months += d2.getMonth();
      return months <= 0 ? 0 : months;
    },
  },
  mounted() {
    this.refreshData();

    setInterval(() => {
      this.refreshData();
    }, 10000);
  },
  computed: {
    defaultCurrencyId() {
      return this.$inertia.page.props.auth.settings.defaultCurrencyId;
    },
    defaultCurrencyCode() {
      const currency = this.currencies.find(
        (x) => x.id === this.defaultCurrencyId
      );

      return currency?.currencyCode ?? null;
    },
    getScopes() {
      if (this.scopes === null) {
        return [];
      }

      let scopes = [];

      let scopeKeys = [
        "cb483bd8-d20e-42a1-a252-c44ee12230ef",
        "6045ba53-b1c4-4ac0-a4be-7d4e4acc9f2b",
        "6d9ea628-4dcf-4d77-ad8b-7e8ea4d72dde",
        "other",
      ];

      for (let key of scopeKeys) {
        let scope = this.scopes[key];

        if (scope === undefined || scope === null) scope = 0;

        scopes.push(scope);
      }

      return scopes;
    },
    mappedAssets() {
      let mappedAssets = [];
      this.highestCarbonAssets.forEach((asset) => {
        let mappedAsset = {
          ...asset,
        };

        mappedAsset["041a251a-2138-4e14-bdfd-876194f4e04f"] = {
          decimalValue: 0,
        };

        asset.assetAttributeLinks.forEach((assetAttributeLink) => {
          mappedAsset[assetAttributeLink.attributeId] = assetAttributeLink;
        });
        mappedAssets.push(mappedAsset);
      });

      return [...mappedAssets];
    },
    mappedSuppliers() {
      let mappedSuppliers = [];

      mappedSuppliers = this.highestCarbonSuppliers;

      mappedSuppliers = mappedSuppliers.map((supplier) => {
        return {
          rawValue: supplier.emissions,
          emissions:
            this.getFormattedEmissionAmountForValue(supplier.emissions) +
            " " +
            this.getEmissionUnitLabelForValue(supplier.emissions),
          name: supplier.name,
        };
      });
      console.log(mappedSuppliers);
      mappedSuppliers.sort((a, b) => (a.rawValue < b.rawValue ? 1 : -1));

      return [...mappedSuppliers];
    },
    months1YearToCurrent() {
      const monthCountInSelection = this.monthDiff(
        new Date(this.dateRange.from),
        new Date(this.dateRange.to)
      );

      const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];

      const currentMonthIndex = new Date(this.dateRange.from).getMonth();

      var rMonths = [];
      for (var i = 0; i <= monthCountInSelection; i++) {
        var month = months[(currentMonthIndex + i) % 12];
        rMonths.push(month);
      }

      return rMonths;
    },
    co2CategoryLabels() {
      return [
        this.$t("components.cards.co2Categories.scope1"),
        this.$t("components.cards.co2Categories.scope2"),
        this.$t("components.cards.co2Categories.scope3"),
        this.$t("components.cards.co2Categories.unassigned"),
      ];
    },
    emissionsBySupplierHeaders() {
      return [
        {
          text: this.$t("components.cards.top20Suppliers.fields.supplierName"),
          align: "start",
          sortable: false,
          value: "name",
        },
        {
          text: this.$t("components.cards.top20Suppliers.fields.emissions"),
          value: "emissions",
        },
      ];
    },
    emissionsBypurchaseHeader() {
      return [
        {
          text: this.$t("components.cards.top20Purchases.fields.productName"),
          align: "start",
          sortable: false,
          value: "649f345f-bb47-47b5-af15-6ef294e72afd.stringValue",
        },
        {
          text: this.$t(
            "components.cards.top20Purchases.fields.carbonFootprint"
          ),
          value: "041a251a-2138-4e14-bdfd-876194f4e04f.decimalValue",
        },
        {
          text: this.$t("components.cards.top20Purchases.fields.category"),
          value: "5ac7671d-fb9d-4441-8203-a09763fbcff8.searchString",
        },
        {
          text: this.$t("components.cards.top20Purchases.fields.price"),
          value: "8192dfef-ef4b-4ed1-9c9e-26ed84ef8de2.searchString",
        },
      ];
    },
    formattedCo2MethodsByMonths() {
      return this.co2MethodsByMonths.map((data) => ({
        ...data,
        name: this.$te(`components.cards.emissionsByMethod.labels.${data.name}`)
          ? this.$t(`components.cards.emissionsByMethod.labels.${data.name}`)
          : data.name,
      }));
    },
    numberOfAssets() {
      let number = this.assetCardData?.numberOfAssetsCard?.value;

      if (!number) return "-";

      return integerNumberFormatter.format(number);
    },
    treesToOffset() {
      let totalCo2 = this.assetCardData?.totalCo2UsedCard?.value;

      if (!totalCo2) return "-";

      const trees = Math.ceil(totalCo2 / 25)?.toString();

      return integerNumberFormatter.format(trees);
    },
    totalCo2Used() {
      let totalCo2 = this.assetCardData?.totalCo2UsedCard?.value ?? 0;

      if (totalCo2 >= 1000) totalCo2 = totalCo2 / 1000;

      return currencyFormatter.format(totalCo2);
    },
    totalCo2UsedUnit() {
      let totalCo2 = this.assetCardData?.totalCo2UsedCard?.value ?? 0;

      return totalCo2 >= 1000
        ? this.$t("common.units.tCO2e")
        : this.$t("common.units.kgCO2e");
    },
    co2Ytd() {
      let co2Ytd = this.assetCardData?.co2CurrentYearCard?.value ?? 0;

      if (co2Ytd >= 1000) co2Ytd = co2Ytd / 1000;

      return integerNumberFormatter.format(co2Ytd);
    },
    co2YtdUnit() {
      let co2Ytd = this.assetCardData?.co2CurrentYearCard?.value ?? 0;

      return co2Ytd >= 1000
        ? this.$t("common.units.tCO2e")
        : this.$t("common.units.kgCO2e");
    },
    emissionsPerMonthOver1000kg() {
      const currentYearLength = this.emissionsPerMonthCurrentYear?.length ?? 0;
      const lastYearLength = this.emissionsPerMonthLastYear?.length ?? 0;

      if (!currentYearLength || !lastYearLength) return false;

      const arrayMax = (previous, current) =>
        previous > current ? previous : current;

      const currentYearMax =
        this.emissionsPerMonthCurrentYear?.reduce(arrayMax) ?? 0;
      const lastYearMax = this.emissionsPerMonthLastYear?.reduce(arrayMax) ?? 0;

      return currentYearMax >= 1000 || lastYearMax >= 1000;
    },
    adjustedEmissionsPerMonthCurrentYear() {
      if (!this.emissionsPerMonthOver1000kg)
        return this.emissionsPerMonthCurrentYear;

      return this.emissionsPerMonthCurrentYear?.map((x) => x / 1000) ?? [];
    },
    adjustedEmissionsPerMonthLastYear() {
      if (!this.emissionsPerMonthOver1000kg)
        return this.emissionsPerMonthLastYear;

      return this.emissionsPerMonthLastYear?.map((x) => x / 1000) ?? [];
    },
    totalSpentAmount() {
      // get amount from assetCardData
      let total = this.assetCardData?.totalAmountSpent?.value ?? 0;

      return currencyFormatter.format(total);
    },
    totalSpentAmountUnit() {
      return this.assetCardData?.totalAmountSpent?.unit;
    },
  },
};
</script>

<style lang="scss" scoped>
.simple-table-card:deep() {
  tr td,
  tr th {
    padding-left: 0 !important;
  }
}

.custom-skeleton {
  background: rgba(0, 0, 0, 0.12);
  overflow: hidden;
  position: relative;
}
.custom-skeleton::after {
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0)
  );
  animation: skeleton-loading 1.5s infinite;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transform: translateX(-100%);
  z-index: 1;
}

@keyframes skeleton-loading {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(100%);
  }
}

.data-table:deep(th) {
  color: #b4b4b4 !important;
}

.table-select-btn {
  min-width: unset !important;
}

.connect-blur {
  filter: blur(1.8px);
}

//
.connect-color-overlay {
  > :deep(*) {
    background: rgba(255, 255, 255, 0.3882352941);
    filter: opacity(0.4) !important;
  }
}

.connect-overlay {
  position: absolute;
  inset: 0;
  top: 108px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .connect-content {
    position: sticky;
    top: 0;
    bottom: 0;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 250px;
  }
}

.download-icon {
  height: 62px;
  width: 62px;
  background-color: #fdece7;
  border-radius: 100%;
}
.presentation-container {
  position: fixed;
  inset: 0;
  top: 160px;
  justify-items: stretch;
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-template-areas:
    "card-asset-num card-total-co2 card-co2-category card-co2-category"
    "card-co2-year card-trees card-co2-category card-co2-category"
    "card-co2-emissions-overview card-co2-emissions-overview card-top-20-table card-top-20-table"
    "card-co2-emissions-overview-calc card-co2-emissions-overview-calc card-top-20-table card-top-20-table";
  gap: 18px;

  .card-asset-num {
    grid-area: card-asset-num;
  }

  .card-total-co2 {
    grid-area: card-total-co2;
  }

  .card-co2-year {
    grid-area: card-co2-year;
  }
  .card-trees {
    grid-area: card-trees;
  }

  .card-co2-category {
    grid-area: card-co2-category;
  }

  .card-co2-emissions-overview {
    grid-area: card-co2-emissions-overview;
    overflow-x: hidden;
  }

  .card-co2-emissions-overview-calc {
    grid-area: card-co2-emissions-overview-calc;
    overflow-x: hidden;
  }

  .card-top-20-table {
    grid-area: card-top-20-table;
    overflow: hidden;
  }
}
</style>
