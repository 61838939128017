<template>
  <div class="flex d-flex justify-center align-center relative">
    <inertia-link id="back-arrow" :href="route('login')">
      <v-icon x-large>mdi-arrow-left</v-icon>
    </inertia-link>

    <v-form class="col-10 col-md-7" @submit.prevent="submit">
      <h1 class="text-h4 black--text mb-2 text-center">
        {{ $t("pages.forgotPassword.title") }}
      </h1>
      <h1 class="text-subtitle-1 grey--text mb-6 font-weight-light text-center">
        {{ $t("pages.forgotPassword.enterEmail") }}
      </h1>
      <input
        v-model="form.email"
        :label="$t('pages.forgotPassword.email')"
        name="email"
        type="email"
        :error-messages="form.errors.email"
        autocomplete="email"
        autofocus
        :placeholder="$t('pages.login.email')"
        class="lightGrey black--text pl-4 pt-2 pb-2 mb-4 text-caption input"
        style="width: 100%"
      />

      <v-btn
        tile
        flat
        block
        elevation="0"
        color="orange"
        class="font-weight-regular text-capitalize mb-2 white--text"
        type="submit"
        :disabled="form.processing"
      >
        {{ $t("pages.forgotPassword.sendInstructions") }}
      </v-btn>
    </v-form>
  </div>
</template>

<script>
import Layout from "@/Shared/AuthLayout";

export default {
  metaInfo: { title: "pages.forgotPassword.pageTitle" },
  layout: Layout,
  props: {
    errors: Object,
  },
  data() {
    return {
      form: this.$inertia.form({
        email: null,
      }),
    };
  },
  methods: {
    submit() {
      this.form.post(this.route("password.email"));
    },
  },
};
</script>
<style scoped>
.relative {
  position: relative;
}

#back-arrow {
  position: absolute;
  top: 0;
  left: 0;
  text-decoration: none;
}

input:focus {
  outline: none !important;
  box-shadow: 0 0 3px black;
}
</style>
