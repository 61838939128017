var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex-grow-1 d-flex justify-center align-center"},[_c('div',{staticClass:"d-flex justify-center align-center"},[_c('div',{staticClass:"px-0",staticStyle:{"width":"1313px"},attrs:{"fluid":""}},[_c('v-card',{staticClass:"flex-grow-1",attrs:{"light":"","color":"#fff","elevation":"0","rounded":"0","max-width":"100%"}},[_c('div',{staticClass:"px-10 py-10"},[_c('p',{staticClass:"page-title"},[_vm._v(" "+_vm._s(_vm.$t("pages.reports.climateReport.totalGhgAndScopes.title"))+" ")]),_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left",attrs:{"width":"100%"}},[_vm._v(" "+_vm._s(_vm.$t( "pages.reports.climateReport.totalGhgAndScopes.ghgEmissions" ))+" ")]),_c('th',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.$t("pages.reports.climateReport.totalGhgAndScopes.unit"))+" ")]),_c('th',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.$t( "pages.reports.climateReport.totalGhgAndScopes.previousPeriod" ))+" ")]),_c('th',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.$t( "pages.reports.climateReport.totalGhgAndScopes.currentPeriod" ))+" ")])])]),_c('tbody',[_c('tr',{staticClass:"table-section-head"},[_c('td',[_c('p',[_vm._v(" "+_vm._s(_vm.$t( "pages.reports.climateReport.totalGhgAndScopes.scope1TableHeading" ))+" ")])]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.$t( "pages.reports.climateReport.totalGhgAndScopes.tonCO2e" ))+" ")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.renderEmissions( _vm.sumEmissionsForScope( "cb483bd8-d20e-42a1-a252-c44ee12230ef" )["prev"] ))+" ")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.renderEmissions( _vm.sumEmissionsForScope( "cb483bd8-d20e-42a1-a252-c44ee12230ef" )["current"] ))+" ")])]),_vm._l((_vm.getGhgCategorySummariesForScope(
                    'cb483bd8-d20e-42a1-a252-c44ee12230ef'
                  )),function(ghgCategory){return _c('tr',{key:ghgCategory['current'].id},[_c('td',[_vm._v(_vm._s(ghgCategory["current"].name))]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.$t( "pages.reports.climateReport.totalGhgAndScopes.tonCO2e" ))+" ")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.getEmissionsLabel(ghgCategory, "prev"))+" ")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.getEmissionsLabel(ghgCategory, "current"))+" ")])])}),_c('tr',{staticClass:"table-section-head"},[_c('td',[_c('p',[_vm._v(" "+_vm._s(_vm.$t( "pages.reports.climateReport.totalGhgAndScopes.scope2TableHeading" ))+" ")])]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.$t( "pages.reports.climateReport.totalGhgAndScopes.tonCO2e" ))+" ")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.renderEmissions( _vm.sumEmissionsForScope( "6045ba53-b1c4-4ac0-a4be-7d4e4acc9f2b" )["prev"] ))+" ")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.renderEmissions( _vm.sumEmissionsForScope( "6045ba53-b1c4-4ac0-a4be-7d4e4acc9f2b" )["current"] ))+" ")])]),_vm._l((_vm.getGhgCategorySummariesForScope(
                    '6045ba53-b1c4-4ac0-a4be-7d4e4acc9f2b'
                  )),function(ghgCategory){return _c('tr',{key:ghgCategory['current'].id},[_c('td',[_vm._v(_vm._s(ghgCategory["current"].name))]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.$t( "pages.reports.climateReport.totalGhgAndScopes.tonCO2e" ))+" ")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.getEmissionsLabel(ghgCategory, "prev"))+" ")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.getEmissionsLabel(ghgCategory, "current"))+" ")])])}),_c('tr',{staticClass:"table-section-head"},[_c('td',[_c('p',[_vm._v(" "+_vm._s(_vm.$t( "pages.reports.climateReport.totalGhgAndScopes.scope3TableHeading" ))+" ")])]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.$t( "pages.reports.climateReport.totalGhgAndScopes.tonCO2e" ))+" ")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.renderEmissions( _vm.sumEmissionsForScope( "6d9ea628-4dcf-4d77-ad8b-7e8ea4d72dde" )["prev"] ))+" ")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.renderEmissions( _vm.sumEmissionsForScope( "6d9ea628-4dcf-4d77-ad8b-7e8ea4d72dde" )["current"] ))+" ")])]),_vm._l((_vm.getGhgCategorySummariesForScope(
                    '6d9ea628-4dcf-4d77-ad8b-7e8ea4d72dde'
                  )),function(ghgCategory){return _c('tr',{key:ghgCategory['current'].id},[_c('td',[_vm._v(_vm._s(ghgCategory["current"].name))]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.$t( "pages.reports.climateReport.totalGhgAndScopes.tonCO2e" ))+" ")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.getEmissionsLabel(ghgCategory, "prev"))+" ")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.getEmissionsLabel(ghgCategory, "current"))+" ")])])})],2)]},proxy:true}])}),_c('div',{staticClass:"d-flex justify-end mt-2 grey--text"},[_c('div',{staticClass:"mr-4"},[_vm._v(" "+_vm._s(_vm.$t( "pages.reports.climateReport.totalGhgAndScopes.nrDescription" ))+" ")]),_c('div',[_vm._v(" "+_vm._s(_vm.$t( "pages.reports.climateReport.totalGhgAndScopes.ncDescription" ))+" ")])])],1)])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }