<template>
  <div class="page-container fill-height grow d-flex flex-column flex-nowrap">
    <form @submit.prevent="submit">
      <v-card elevation="0" class="d-flex flex-column ma-4">
        <v-card-text class="pa-0 flex-grow-1">
          <v-col cols="12" md="4" class="pr-xl-16">
            <dialog-form-text-input
              v-model="form.name"
              :title="$t('pages.roles.form.fields.name')"
              :error-messages="form.errors.name"
            ></dialog-form-text-input>
            <dialog-form-text-input
              v-model="form.description"
              :title="$t('pages.roles.form.fields.description')"
              :error-messages="form.errors.description"
            ></dialog-form-text-input>
            <dialog-form-input-wrapper
              :title="$t('pages.roles.form.fields.color')"
            >
              <label
                for="color"
                :style="{ backgroundColor: form.color }"
                class="color-label"
              >
                <input
                  class="hidden"
                  type="color"
                  v-model="form.color"
                  id="color"
                />
              </label>
            </dialog-form-input-wrapper>
            <v-alert v-if="errors.alert" type="error" class="mt-2 mb-0">
              {{ errors.alert }}
            </v-alert>
          </v-col>
        </v-card-text>
        <v-card-actions>
          <flash-messages />
          <v-spacer></v-spacer>
          <v-btn
            v-if="role.id"
            class="text-none ml-0 mb-4 mb-md-0 ml-md-4 px-6 align-self-stretch"
            large
            color="#F25D3B"
            dark
            elevation="0"
            tile
            x-large
            text
            :loading="sending || form.processing"
            @click="deleteRole"
          >
            {{ $t("common.actions.delete") }}
          </v-btn>
          <v-btn
            class="text-none ml-0 mb-4 mb-md-0 ml-md-4 px-6 align-self-stretch"
            large
            color="#F25D3B"
            dark
            elevation="0"
            tile
            x-large
            :loading="form.processing"
            @click="submit"
          >
            {{ $t("common.actions.saveChanges") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </form>
  </div>
</template>

<script>
import { appLayout } from "@/util/layout";
import FlashMessages from "@/Shared/FlashMessages";
import DialogFormTextInput from "../../Components/Dialog/inputs/DialogFormTextInput.vue";
import DialogFormInputWrapper from "../../Components/Dialog/inputs/DialogFormInputWrapper.vue";

export default {
  layout: appLayout({ title: "pages.roles.form.pageTitle" }),
  props: {
    role: Object,
    errors: Object,
  },
  components: {
    FlashMessages,
    DialogFormTextInput,
    DialogFormInputWrapper,
  },
  data() {
    return {
      sending: false,
      form: this.$inertia.form({
        id: this.role.id,
        name: this.role.name,
        description: this.role.description,
        color: this.role.color ? this.role.color : "#646568",
      }),
    };
  },
  methods: {
    submit() {
      if (this.role.id) {
        this.form.put(this.route("roles.update", this.role.id), {
          preserveScroll: true,
          preserveState: true,
        });
      } else {
        this.form.post(this.route("roles.store"));
      }
    },
    deleteRole() {
      this.$inertia.delete(this.route("roles.destroy", this.role.id), {
        onStart: () => (this.sending = true),
        onFinish: () => (this.sending = false),
      });
    },
  },
};
</script>
<style>
.hidden {
  visibility: hidden;
}

.color-label {
  width: 50px;
  height: 50px;
  display: block;
  border-radius: 100%;
  margin: 0 2px;
}
</style>
