<template>
  <dialog-form :persistent="true" :show-dialog="show" max-width="450px">
    <div class="d-flex flex-column px-6 py-8">
      <div class="pb-8 d-flex justify-space-between">
        <div style="width: 30px"></div>
        <LogoBlack />
        <remove
          @click="close"
          class="mt-n4"
          style="cursor: pointer; width: 30px"
        />
      </div>
      <div>
        <h1 class="text-h4 black--text mb-4 text-center">
          {{ this.$t("components.changePassword.title") }}
        </h1>
      </div>
      <input
        autofocus
        type="password"
        autocomplete="password"
        v-model="form.oldPassword"
        :placeholder="$t('components.changePassword.oldPassword')"
        name="oldPassword"
        class="lightGrey black--text pl-5 pt-2 pb-2 mb-2 text-caption input"
        style="width: 100%; min-height: 48px"
      />
      <input
        autofocus
        type="password"
        v-model="form.newPassword"
        :placeholder="$t('components.changePassword.newPassword')"
        name="newPassword"
        class="lightGrey black--text pl-5 pt-2 pb-2 mb-2 text-caption input"
        style="width: 100%; min-height: 48px"
      />
      <input
        autofocus
        type="password"
        v-model="form.confirmNewPassword"
        :placeholder="$t('components.changePassword.confirmNewPassword')"
        name="confirmNewPassword"
        class="lightGrey black--text pl-5 pt-2 pb-2 mb-2 text-caption input"
        style="width: 100%; min-height: 48px"
      />
      <div class="pt-4 d-flex justify-end">
        <v-btn
          tile
          elevation="0"
          color="orange"
          class="font-weight-regular text-capitalize white--text"
          type="submit"
          @click="submitChange"
          :disabled="!areFieldsFilled"
        >
          {{ $t("components.changePassword.submit") }}
        </v-btn>
      </div>
    </div>
  </dialog-form>
</template>

<script>
import DialogForm from "./Dialog/DialogForm.vue";
import LogoBlack from "../assets/svg/verarca-logo-black.svg";
import Remove from "../assets/svg/remove.svg";
export default {
  components: {
    DialogForm,
    LogoBlack,
    Remove,
  },
  props: {
    value: Boolean,
    auth: Object,
  },
  data() {
    return {
      form: this.$inertia.form({
        userId: this.auth.user.id,
        oldPassword: null,
        newPassword: null,
        confirmNewPassword: null,
      }),
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    areFieldsFilled() {
      return (
        this.form.oldPassword &&
        this.form.newPassword &&
        this.form.confirmNewPassword
      );
    },
  },
  methods: {
    submitChange() {
      this.form.post(this.route("password.change"));

      this.close();
    },
    close() {
      this.resetForm();
      this.show = false;
    },
    resetForm() {
      this.form.oldPassword = null;
      this.form.newPassword = null;
      this.form.confirmNewPassword = null;
    },
  },
};
</script>

<style scoped></style>
