<template>
  <div>
    <h4 class="text-h4">{{ $t("pages.files.edit.title") }} {{ file.name }}</h4>
    <v-spacer class="mb-6"></v-spacer>
    <div>
      <v-row no-gutters>
        <v-col md="8" lg="6">
          <form
            @submit.prevent="
              form.put(route('files.update', file.id), {
                preserveScroll: true,
                preserveState: false,
              })
            "
          >
            <v-card elevation="1">
              <v-card-text>
                <v-row no-gutters>
                  <v-col cols="4">
                    <v-subheader>{{
                      $t("common.fieldNames.name")
                    }}</v-subheader>
                  </v-col>
                  <v-col cols="8">
                    <v-text-field
                      v-model="form.name"
                      name="name"
                      type="text"
                      :error-messages="form.errors.name"
                    />
                  </v-col>
                </v-row>
                <v-row no-gutters class="align-center">
                  <v-col cols="4">
                    <v-subheader>{{ $t("pages.files.edit.file") }}</v-subheader>
                  </v-col>
                  <v-col cols="8">
                    <a
                      v-if="file"
                      class="text-decoration-none"
                      :href="
                        route('api.files.content', {
                          id: file.id,
                          filename: file.originalName,
                        })
                      "
                      target="_blank"
                    >
                      <v-icon> mdi-attachment</v-icon>
                      {{ file.name }}{{ file.extension }}
                    </a>
                  </v-col>
                </v-row>
                <v-row no-gutters class="align-center">
                  <v-col cols="4">
                    <v-subheader>{{
                      $t("pages.files.edit.originalName")
                    }}</v-subheader>
                  </v-col>
                  <v-col cols="8">
                    {{ file.originalName }}
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col cols="4">
                    <v-subheader>{{
                      $t("pages.files.edit.lastUpdated")
                    }}</v-subheader>
                  </v-col>
                  <v-col cols="8">
                    <v-text-field
                      v-model="updatedAt"
                      name="updatedAt"
                      disabled
                      type="text"
                    />
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col cols="4">
                    <v-subheader>{{
                      $t("common.fieldNames.createdAt")
                    }}</v-subheader>
                  </v-col>
                  <v-col cols="8">
                    <v-text-field
                      v-model="createdAt"
                      name="createdAt"
                      disabled
                      type="text"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <flash-messages />
                <v-btn
                  text
                  color="error"
                  :disabled="sending || form.processing"
                  @click="destroy"
                  >{{ $t("common.actions.delete") }}</v-btn
                >
                <v-spacer></v-spacer>
                <v-btn
                  type="submit"
                  outlined
                  color="primary"
                  :disabled="sending || form.sending"
                  >{{ $t("pages.files.edit.save") }}</v-btn
                >
              </v-card-actions>
            </v-card>
          </form>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { appLayout } from "@/util/layout";
import FlashMessages from "@/Shared/FlashMessages";

export default {
  layout: appLayout({ title: "pages.files.edit.pageTitle" }),
  props: {
    file: Object,
  },
  components: {
    FlashMessages,
  },
  data() {
    return {
      sending: false,
      form: this.$inertia.form({
        name: this.file.name,
      }),
    };
  },
  computed: {
    createdAt() {
      return new Date(this.file.createdAt).toLocaleString();
    },
    updatedAt() {
      return new Date(this.file.createdAt).toLocaleString();
    },
  },
  methods: {
    destroy() {
      if (window.confirm(this.$t("pages.files.edit.confirmDelete"))) {
        this.$inertia.delete(this.route("files.destroy", this.file.id), {
          onStart: () => (this.sending = true),
          onFinish: () => (this.sending = false),
        });
      }
    },
  },
};
</script>
