<template>
  <div class="head-section pt-4 px-md-6">
    <v-btn
      v-if="onDismiss"
      @click="onDismiss()"
      color="#68686"
      icon
      absolute
      right
      top
    >
      <v-icon>mdi-close</v-icon>
    </v-btn>
    <v-row>
      <v-col cols="4" offset="4">
        <VerarcaLogo class="" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4" offset="4">
        <step-visualizer
          :selected-step="selectedStep"
          :steps="steps"
          @input="updateSelectedStep"
        />
      </v-col>
    </v-row>
    <v-card-title
      v-if="title"
      class="px-6 px-md-0 text--black text-center pt-0"
    >
      <span class="text-h5 ma-auto">{{ title }}</span>
    </v-card-title>
    <v-card-subtitle v-if="subtitle" class="px-6 text-center">
      <span class="text-subtitle-1">{{ subtitle }}</span>
    </v-card-subtitle>
  </div>
</template>
<script>
import VerarcaLogo from "../../assets/svg/verarca-logo-dark-blue.svg";

import StepVisualizer from "../StepVisualizer.vue";

export default {
  components: { VerarcaLogo, StepVisualizer },
  props: {
    title: String,
    subtitle: String,
    onDismiss: Function,
    steps: Number,
    selectedStep: [Number, String],
  },
  methods: {
    updateSelectedStep(value) {
      this.$emit("selectedStepUpdated", value);
    },
  },
};
</script>
<style lang="scss" scoped>
.head-section {
  border-bottom: 1px solid #e8e8e8;
  position: sticky;
  top: 0;
  background: white;
  z-index: 1;
}

.header-stepper:deep(.v-stepper__label) {
  display: none;
}

.header-stepper:deep(.v-stepper__step__step) {
  margin-right: 0;
}
</style>
