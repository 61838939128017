<template>
  <dialog-form
    :show-dialog="show"
    max-width="538px"
    :persistent="false"
    @showStateChanged="(val) => (show = val)"
  >
    <div class="d-flex flex-column px-0 pt-16">
      <div class="pb-8 d-flex justify-center">
        <LogoBlack />
      </div>
      <step-visualizer
        class="pb-8"
        :steps="3"
        :selected-step="selectedOnboardingStep"
        ref="stepVisualizer"
        @input="(step) => (selectedOnboardingStep = step)"
      />
      <div>
        <h1 class="text-h4 black--text mb-2 text-center">
          {{ stepTitle }}
        </h1>
        <h1
          class="text-subtitle-1 grey--text mb-6 font-weight-light text-center"
        >
          {{ stepSubtitle }}
        </h1>
      </div>
      <div
        class="d-flex align-center relative"
        style="overflow-y: scroll; overflow-x: hidden; min-height: 300px"
        :style="
          selectedOnboardingStep === 3
            ? 'height: calc(100vh - 527px);max-height: 527px;'
            : null
        "
      >
        <Transition name="fade">
          <onboarding-step-three
            v-if="selectedOnboardingStep === 1"
            class="transition-item"
            :selected-integration.sync="selectedIntegration"
            :show-continue="false"
            @otherIntegration="handleOtherIntegration"
            @updateForm="updateForm"
          />
          <onboarding-step-four
            v-if="selectedOnboardingStep === 2"
            class="transition-item"
            :selected-integration="selectedIntegration"
          />
          <onboarding-step-five
            v-else-if="
              selectedOnboardingStep === 3 &&
              selectedIntegration === businessCentralOnPremise
            "
            class="transition-item"
            :api-token="apiToken"
          />
          <onboarding-step-six
            v-if="
              selectedOnboardingStep === 3 &&
              selectedIntegration !== businessCentralOnPremise
            "
            class="transition-item"
            :integrationId="integrationId"
            ref="account-mapper"
          />
        </Transition>
      </div>
      <v-card-actions
        v-if="selectedOnboardingStep == 1"
        class="d-flex flex-row footer-section pa-8"
      >
        <v-btn
          tile
          elevation="0"
          color="orange"
          class="font-weight-regular text-capitalize white--text"
          type="submit"
          :disabled="isContinueDisabled"
          @click="moveToNextStep()"
          style="flex: 1"
        >
          {{ $t("components.onboarding.next") }}
        </v-btn>
      </v-card-actions>
      <v-card-actions v-else class="d-flex flex-column footer-section pa-8">
        <v-btn
          tile
          block
          elevation="0"
          color="orange"
          class="font-weight-regular text-capitalize white--text pa-0 mb-4"
          type="submit"
          :disabled="isContinueDisabled"
          @click="moveToNextStep()"
        >
          {{ connectSystemButtonText }}
        </v-btn>
        <v-btn
          v-if="
            selectedOnboardingStep !== 2 &&
            selectedIntegration !== businessCentralOnPremise
          "
          tile
          outlined
          block
          elevation="0"
          bg-color="white"
          class="border-sm text-capitalize black--text black--border white pa-0 ml-0"
          @click="hideModal()"
        >
          {{ nextStepOtherLabel() }}
        </v-btn>
        <!-- Hiding the api fields popup currently because they are not used -->
      </v-card-actions>
      <integration-dynamics-365-environment-modal
        v-model="showDynamicsIntegration"
      ></integration-dynamics-365-environment-modal>
      <integration-uniconta-credentials-modal
        v-if="showUnicontaIntegration && unicontaCompanies === null"
        v-model="showUnicontaIntegration"
        @update:unicontaCompanies="unicontaCompanies = $event"
        @update:unicontaUsername="unicontaUsername = $event"
        @update:unicontaPassword="unicontaPassword = $event"
      ></integration-uniconta-credentials-modal>
      <integration-uniconta-company-modal
        v-else-if="showUnicontaIntegration && unicontaCompanies !== null"
        v-model="showUnicontaIntegration"
        :uniconta-companies="unicontaCompanies"
        :uniconta-username="unicontaUsername"
        :uniconta-password="unicontaPassword"
      ></integration-uniconta-company-modal>
    </div>
  </dialog-form>
</template>
<script>
import OnboardingStepThree from "./OnboardingSteps/OnboardingStepThree.vue";
import OnboardingStepFour from "./OnboardingSteps/OnboardingStepFour.vue";
import OnboardingStepFive from "./OnboardingSteps/OnboardingStepFive.vue";
import OnboardingStepSix from "./OnboardingSteps/OnboardingStepSix.vue";
import DialogForm from "../Dialog/DialogForm.vue";
import LogoBlack from "../../assets/svg/verarca-logo-black.svg";
import StepVisualizer from "../StepVisualizer.vue";
import { serialize } from "object-to-formdata";
import IntegrationDynamics365EnvironmentModal from "../../Pages/Setting/IntegrationDynamics365EnvironmentModal.vue";
import IntegrationUnicontaCredentialsModal from "../../Pages/Setting/IntegrationUnicontaCredentialsModal.vue";
import IntegrationUnicontaCompanyModal from "../../Pages/Setting/IntegrationUnicontaCompanyModal.vue";

import {
  economic,
  businessCentral,
  businessCentralOnPremise,
  uniconta,
  other,
} from "@/util/integrations";

export default {
  components: {
    OnboardingStepThree,
    OnboardingStepFour,
    OnboardingStepFive,
    OnboardingStepSix,
    DialogForm,
    LogoBlack,
    StepVisualizer,
    IntegrationDynamics365EnvironmentModal,
    IntegrationUnicontaCredentialsModal,
    IntegrationUnicontaCompanyModal,
  },
  props: {
    selectedStep: {
      type: Number,
      default: 1,
    },
    value: Boolean,
    settings: Object,
    fiscalYears: Array,
    depreciationMethods: Array,
    depreciationStarts: Array,
    currencies: Array,
    countries: Array,
    integrationId: String,
  },
  data() {
    return {
      form: this.$inertia.form(this.generateInitialForm()),
      financialSystemForm: this.$inertia.form(
        this.generateInitialFinancialSystemForm()
      ),

      companyRegNo: "",
      companyName: "",
      companyEmail: "",
      fiscalYear: [],

      showDynamicsIntegration: false,

      showUnicontaIntegration: false,
      unicontaCompanies: null,
      unicontaUsername: null,
      unicontaPassword: null,
      apiToken: null,

      businessCentralOnPremise: businessCentralOnPremise,

      selectedOnboardingStep: this.selectedStep,
      selectedIntegration: null,
      onboardingSteps: [
        {
          id: 1,
          title: this.$t("components.onboarding.titles.chooseIntegration"),
          subTitle: this.$t(
            "components.onboarding.subtitles.integrateWithVerarca"
          ),
        },
        {
          id: 2,
          title: this.$t("components.onboarding.titles.startIntegration"),
          subTitle: this.$t(
            "components.onboarding.subtitles.howToIntegrateWithVerarca"
          ),
        },
        {
          id: 3,
          title: this.$t("components.onboarding.titles.assignAccountsToSync"),
          subTitle: this.$t(
            "components.onboarding.subtitles.chooseAccountCategory"
          ),
        },
      ],
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    currentStep() {
      return this.onboardingSteps.find(
        (x) => x.id === this.selectedOnboardingStep
      );
    },
    stepTitle() {
      if (
        this.currentStep.id === 3 &&
        this.selectedIntegration == businessCentralOnPremise
      ) {
        return this.$t("components.onboarding.titles.apiToken");
      }

      return this.currentStep.title;
    },
    stepSubtitle() {
      if (
        this.currentStep.id === 3 &&
        this.selectedIntegration == businessCentralOnPremise
      ) {
        return this.$t("components.onboarding.subtitles.apiTokenAddToApp");
      }

      return this.currentStep.title;
    },
    isContinueDisabled() {
      if (this.selectedOnboardingStep === 1) {
        return this.selectedIntegration === null;
      }

      return false;
    },
    connectSystemButtonText() {
      if (this.selectedIntegration == other) {
        return this.$t(
          "components.onboarding.stepFour.continueWithoutIntegration"
        );
      }

      if (this.selectedOnboardingStep === 2) {
        return `${this.$t(
          "components.onboarding.stepFour.clickHereToConnect"
        )} ${this.selectedIntegration}`;
      } else if (
        this.selectedOnboardingStep === 3 &&
        this.selectedIntegration === businessCentralOnPremise
      ) {
        return this.$t("components.onboarding.close");
      } else {
        return this.$t("components.onboarding.next");
      }
    },
  },
  methods: {
    moveToNextStep() {
      if (this.selectedOnboardingStep === 2) {
        switch (this.selectedIntegration) {
          case economic:
            this.redirectToEconomic();
            break;
          case uniconta:
            this.showUnicontaIntegration = true;
            break;
          case businessCentral:
            this.showDynamicsIntegration = true;
            break;
          case businessCentralOnPremise:
            this.createDataCollectIntegration();
            break;
        }

        return;
      }

      if (
        this.selectedOnboardingStep === 3 &&
        this.selectedIntegration === businessCentralOnPremise
      ) {
        this.close();
        this.selectedIntegration = null;
        this.selectedOnboardingStep = 1;
        return;
      }

      if (this.selectedOnboardingStep === 1) {
        if (this.financialSystemForm["financialSystem"] !== null) {
          this.submitFinancialSystemForm();
        }

        if (
          this.selectedIntegration === null ||
          this.selectedIntegration === other
        ) {
          this.close();
        }
      }

      if (this.selectedOnboardingStep === 2) {
        this.submitForm();
      }

      if (
        this.selectedOnboardingStep === 3 &&
        this.selectedIntegration !== businessCentralOnPremise
      ) {
        this.$refs["account-mapper"].setLinkedAccounts(() =>
          this.$emit("onAccountMappingSuccess")
        );
        return;
      }
      this.selectedOnboardingStep = (this.currentStep?.id ?? 0) + 1;
      this.$emit("update:selected-step", this.selectedOnboardingStep);
    },
    nextStepOtherLabel() {
      return this.selectedOnboardingStep === 2
        ? this.$t("components.onboarding.stepFour.apiFields")
        : this.$t("components.onboarding.skip");
    },
    hideModal() {
      if (this.selectedOnboardingStep === 2) {
        this.$emit("show-api-fields");
      } else {
        this.$emit("show-warning");
      }
    },
    async close() {
      this.$emit("close-all");
      this.show = false;
    },
    finishOnboarding() {
      this.submitForm();
      this.selectedOnboardingStep = 2;
      this.close();
    },
    redirectToEconomic() {
      const url = this.route("api.settings.economic.install");
      window.location.href = url;
    },
    async createDataCollectIntegration() {
      const response = await fetch(
        this.route("api.settings.datacollect.create"),
        {
          method: "POST",
        }
      );

      if (response.ok) {
        this.apiToken = await response.text();
        this.selectedOnboardingStep = 3;
      } else {
        console.error("datacollect integration failed to configure.");
      }
    },
    updateForm(key, value) {
      this.form[key] = value;
    },
    handleOtherIntegration(value) {
      this.financialSystemForm["financialSystem"] = value;
    },
    submitForm() {
      this.form
        .transform((data) =>
          serialize(data, { noFilesWithArrayNotation: true })
        )
        .put(this.route("onboarding.settings"), {
          onSuccess: () => {
            this.form = this.$inertia.form(this.generateInitialForm());
          },
        });
    },
    submitFinancialSystemForm() {
      this.financialSystemForm
        .transform((data) =>
          serialize(data, { noFilesWithArrayNotation: true })
        )
        .post(this.route("onboarding.integration"), {
          onSuccess: () => {
            this.financialSystemForm = this.$inertia.form(
              this.generateInitialFinancialSystemForm()
            );
          },
        });
    },
    generateInitialForm() {
      return {
        enableAssetRegister: false,
        enableEmissionReporting: false,
        financialSystem: null,
        apiUser: null,
        authId: null,
        apiKey: null,
        fiscalYear: this.fiscalYear,
      };
    },
    generateInitialFinancialSystemForm() {
      return {
        financialSystem: null,
      };
    },

    getDefaultCurrency() {
      return this.currencies[
        this.currencies.findIndex((currency) => currency.currencyCode === "DKK")
      ];
    },
  },
  watch: {
    showUnicontaIntegration(value) {
      if (value) return;

      this.unicontaUsername = null;
      this.unicontaPassword = null;
      this.unicontaCompanies = null;
    },
  },
};
</script>
<style>
.fade-enter-active {
  transition: all 0.4s ease;
}
.fade-leave-active {
  transition: all 0.2s ease;
}
.fade-enter {
  transform: translateX(20px);
  opacity: 1;
}

.fade-leave-to {
  transform: translateX(-20px);
  opacity: 0;
}
.transition-item {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.footer-section {
  position: sticky;
  bottom: 0;
  background: #fff;
}
</style>
