<template>
  <div class="organization-menu" ref="organizationMenu">
    <v-text-field
      class="search-field"
      v-model="searchString"
      outlined
      hide-details
      @focus="
        () => {
          showDropdown = true;
          this.searchString = '';
        }
      "
      color="rgba(255,255,255,0.5)"
    >
      <template v-slot:prepend-inner>
        <v-avatar :size="28" class="ml-4" style="margin: 0px 12px 0 0px">
          <img
            :src="orgImage(getOrganization(organizationId))"
            :alt="getOrganization(organizationId).name"
          />
        </v-avatar>
      </template>
      <template v-slot:append>
        <v-icon v-if="!mini">mdi-unfold-more-horizontal</v-icon>
      </template>
    </v-text-field>
    <div class="drop-down" v-if="showDropdown">
      <PopOverTail
        width="100%"
        height="10px"
        class="popover-tail"
      ></PopOverTail>
      <div class="menu-items">
        <div
          class="menu-item"
          v-for="(item, index) in combinedOrganizations"
          :key="index"
        >
          <template v-if="item.children">
            <div
              class="organization-group"
              v-show="
                hasHighlistedText(item.name, searchString) ||
                childrenHaveHighlightedText(item.children, searchString)
              "
              @click="organizationId = item.id"
            >
              <v-avatar color="red" :size="28" class="mr-4">
                <img :src="orgImage(item)" :alt="item.name" />
              </v-avatar>
              <div v-html="highlightText(item.name, searchString)"></div>
              <v-btn
                class="toggle-organization-children"
                :class="showGroup === item.name ? 'mini' : ''"
                icon
                @click.stop="
                  showGroup === item.name
                    ? (showGroup = '')
                    : (showGroup = item.name)
                "
              >
                <!--<v-icon color="#000">mdi-chevron-left</v-icon>-->
                <DrawerDismissBtnIcon></DrawerDismissBtnIcon>
              </v-btn>
            </div>
            <div class="organization-children" v-if="showGroup === item.name">
              <div
                v-for="(child, index) in item.children"
                :key="index"
                class="organization-child"
                v-show="hasHighlistedText(child.name, searchString)"
                @click="organizationId = child.id"
              >
                <SubdirArrow
                  class="subdir-arrow mr-2 ml-1"
                  width="20px"
                  height="20px"
                />
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <div
                      v-bind="attrs"
                      v-on="child.name.length > 19 && on"
                      ref="organizationName"
                      class="organization-name"
                      v-html="highlightText(child.name, searchString)"
                    ></div>
                  </template>
                  <div class="tooltip">
                    {{ child.name }}
                  </div>
                </v-tooltip>
              </div>
            </div>
          </template>
          <template v-else>
            <div
              class="organization"
              v-show="hasHighlistedText(item.name, searchString)"
              @click="organizationId = item.id"
            >
              <v-avatar color="red" :size="28" class="mr-4 mt-0">
                <img :src="orgImage(item)" :alt="item.name" />
              </v-avatar>
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <div
                    v-bind="attrs"
                    v-on="item.name.length > 17 && on"
                    ref="organizationName"
                    class="organization-name"
                    v-html="highlightText(item.name, searchString)"
                  ></div>
                </template>
                <div class="tooltip">
                  {{ item.name }}
                </div>
              </v-tooltip>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { serialize } from "object-to-formdata";
import PopOverTail from "../assets/svg/popover-tail.svg";
import SubdirArrow from "../assets/svg/subdir-arrow.svg";
import DrawerDismissBtnIcon from "../../src/assets/svg/drawer-dismiss-btn-icon.svg";

export default {
  components: { DrawerDismissBtnIcon, PopOverTail, SubdirArrow },
  props: { mini: Boolean },
  data() {
    return {
      searchString: "",
      showDropdown: false,
      showGroup: "",
    };
  },
  mounted() {
    this.searchString = [
      ...this.auth.organizations,
      ...this.auth.organizationGroups,
    ].find((organization) => organization.id === this.organizationId)?.name;

    document.addEventListener("click", this.handleClickOutside);
  },
  methods: {
    orgImage(item) {
      if (item.logoFile) {
        return this.route("api.files.content", {
          id: item.logoFile.id,
          filename: item.logoFile.originalName,
        });
      }

      const firstChar = item.name.charAt(0);

      let secondChar = "";
      if (item.name.split(" ").length > 1) {
        secondChar = item.name.split(" ")[1].charAt(0);
      }

      return (
        "https://ui-avatars.com/api/?name=" +
        firstChar +
        secondChar +
        "&color=fff&background=F25D3B"
      );
    },
    changeCurrentOrganization(organizationId) {
      fetch(this.route("users.current.organizations.current.update"), {
        method: "PUT",
        body: serialize({ organizationId }),
      }).then(() => {
        localStorage.removeItem("data-table-filters");
        localStorage.removeItem("search");
        localStorage.removeItem("pagination-sort-by");
        localStorage.removeItem("pagination-sort-desc");
        window.location.href = this.route("dashboard.index").toString();
      });
    },
    highlightText(text, term) {
      if (!term) return text;
      const regex = new RegExp(term, "gi");
      return text.replace(
        regex,
        (match) => `<span class="highlight">${match}</span>`
      );
    },
    hasHighlistedText(text, term) {
      if (!term) return true;
      const regex = new RegExp(term, "gi");

      return regex.test(text);
    },
    childrenHaveHighlightedText(children, term) {
      if (!term) return true;

      let hasHighlistedText = false;

      children.forEach((child) => {
        if (this.hasHighlistedText(child.name, term)) {
          hasHighlistedText = true;
        }
      });
      return hasHighlistedText;
    },
    getOrganizationName(organizationId) {
      return [...this.auth.organizations, ...this.auth.organizationGroups].find(
        (organization) => organization.id === organizationId
      )?.name;
    },
    getOrganization(organizationId) {
      return [...this.auth.organizations, ...this.auth.organizationGroups].find(
        (organization) => organization.id === organizationId
      );
    },
    handleClickOutside(event) {
      const el = this.$refs.organizationMenu;
      if (!(el === event.target || el.contains(event.target))) {
        // Handle the click outside event
        this.showDropdown = false;
        this.searchString = this.getOrganizationName(this.organizationId);
      }
    },
  },
  computed: {
    auth() {
      return this.$inertia.page.props.auth;
    },
    organizationId: {
      get() {
        return this.auth.organizationId;
      },
      set(organizationId) {
        if (!organizationId) return;
        this.searchString = this.getOrganizationName(organizationId);
        this.changeCurrentOrganization(organizationId);
        this.showDropdown = false;
        this.auth.organizationId = organizationId;
      },
    },
    combinedOrganizations() {
      // Structure:
      /*
        |-> organization
        |-> organization 
        |-> organizationGroup
            |-> organization
            |-> organization
        |-> organization
      */

      let organizations = [];
      let organizationGroups = [];

      this.auth.organizations.forEach((org) => {
        // Add to list if not in group
        if (org.organizationGroupId == null) {
          organizations.push(org);
        } else {
          // find group
          const id = this.auth.organizationGroups.find(
            (group) => group.id === org.organizationGroupId
          )?.id;

          // Organization group is not always accessible by current user
          if (!id) {
            organizations.push(org);
            return; // Works like a continue statement in a traditional loop
          }

          if (organizationGroups[id]) {
            organizationGroups[id].push(org);
          } else {
            organizationGroups[id] = [org];
          }
        }
      });

      // add grouped orgs to list
      Object.keys(organizationGroups).forEach((groupId) => {
        organizations.push({
          name: this.auth.organizationGroups.find(
            (group) => group.id === groupId
          )?.name,
          id: groupId,
          children: organizationGroups[groupId],
        });
      });

      return organizations;
    },
  },
  beforeDestroy() {
    document.removeEventListener("click", this.handleClickOutside);
  },
};
</script>
<style lang="scss" scoped>
.organization-menu {
  position: relative;

  &::before {
    content: " ";
    background: green;
    width: 10px;
    height: 10px;
  }

  .search-field {
    font-size: 14px;

    :deep(.v-input__prepend-inner) {
      margin-top: 14px;
      padding-right: 0;
    }

    :deep(input) {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    :deep(fieldset) {
      border-radius: 0 !important;
      border-left: none !important;
      border-right: none !important;
    }
  }
  .toggle-organization-children {
    position: absolute;
    background: #fff;
    right: 8px;
    z-index: 9999;
    width: 32px !important;
    height: 32px !important;
    transform: rotate(90deg);
  }

  .toggle-organization-children svg {
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1), visibility 0s;
  }

  .toggle-organization-children.mini svg {
    transform: rotateZ(180deg);
  }

  .drop-down {
    position: absolute;
    top: 100%;
    background: white;
    margin: 5px;
    border-radius: 8px;
    z-index: 100;
    box-shadow: rgba(0, 0, 0, 0.04) 0px 1px 4px,
      rgba(0, 0, 0, 0.04) 0px 2px 12px;
    .menu-items {
      max-height: calc(90vh - 140px);
      overflow-y: scroll;
      border-radius: 8px;
      width: 230px;

      .menu-item {
        padding: 0px 0px;

        .organization-group {
          position: sticky;
          top: 0;
          padding: 0 20px;
          height: 45px;
          display: flex;
          align-items: center;
          background: white;
          border-bottom: thin solid rgba(0, 0, 0, 0.12);
          white-space: nowrap;
          overflow: hidden;
          cursor: pointer;
          margin-bottom: 8px;
          &:hover {
            background: #e8e8e8;
          }
        }

        .organization-child {
          padding: 0 20px;
          height: 33px;
          display: flex;
          align-items: center;
          font-size: 12px;
          color: #5d5d5d;
          font-weight: 500;
          white-space: nowrap;
          overflow: hidden;
          cursor: pointer;
          &:hover {
            background: rgba(0, 0, 0, 0.1);
          }
          .subdir-arrow path {
            fill: #b3b3b3;
          }
        }

        .organization-name {
          margin-left: 8px;
          text-overflow: ellipsis;
          max-width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .organization {
          cursor: pointer;
          padding: 0 20px;
          height: 45px;
          display: flex;
          align-items: center;
          white-space: nowrap;
          overflow: hidden;
          &:hover {
            background: rgba(0, 0, 0, 0.1);
          }
        }
      }
    }

    .popover-tail {
      position: absolute;
      /*left: 50%;*/
      left: 120px;
      top: -10px;
      transform: translateX(-50%);
    }
  }
  :deep(.highlight) {
    background-color: #f25d3b42;
  }
}
</style>
