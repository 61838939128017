<template>
  <div>
    <v-btn
      text
      light
      tile
      class="text-none font-weight-medium px-2 px-xl-4"
      color="#686868"
      @click="() => (show = !show)"
      ><slot name="trigger-button"></slot>
    </v-btn>
    <dialog-form
      :showDialog="show"
      @showStateChanged="(state) => (show = state)"
    >
      <dialog-form-header
        :title="$t('components.accountingEntries.layoutOptions.title')"
      ></dialog-form-header>
      <dialog-form-section-one-col>
        <v-list flat>
          <v-list-item-group multiple>
            <v-list-item
              v-for="fieldOption in fieldOptions"
              :key="fieldOption"
              @click="toggleSelection(fieldOption)"
              :active="selection.includes(fieldOption)"
            >
              <template v-slot:default>
                <v-list-item-action>
                  <v-checkbox
                    :input-value="selection.includes(fieldOption)"
                    color="primary"
                    @click.stop="toggleSelection(fieldOption)"
                  ></v-checkbox>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>{{
                    $t("components.accountingEntries.fields." + fieldOption)
                  }}</v-list-item-title>
                </v-list-item-content>
              </template>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </dialog-form-section-one-col>
      <v-card-actions class="py-6 px-6 d-flex flex-column-reverse flex-md-row">
        <v-btn
          class="text-none ml-0 mb-4 mb-md-0 align-self-stretch"
          color="#686868"
          elevation="0"
          tile
          dark
          x-large
          text
          @click="show = false"
        >
          <v-icon color="#686868">mdi-close</v-icon>
          <p class="mb-0">
            {{ $t("common.actions.close") }}
          </p>
        </v-btn>
        <v-btn
          class="text-none ml-0 mb-4 mb-md-0 align-self-stretch"
          color="#686868"
          elevation="0"
          tile
          dark
          x-large
          text
          @click="
            () => {
              $emit('reset-layout');
              show = false;
            }
          "
        >
          <v-icon color="#686868">mdi-arrow-u-left-top</v-icon>
          <p class="mb-0">
            {{ $t("common.filters.reset") }}
          </p>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          class="text-none ml-0 mb-4 mb-md-0 ml-md-4 px-6 align-self-stretch"
          color="#266663"
          dark
          elevation="0"
          tile
          x-large
          @click="applySelection"
        >
          <v-icon class="white--text">mdi-content-save-outline</v-icon>
          <p class="white--text mb-0">
            {{ $t("common.actions.save") }}
          </p>
        </v-btn>
      </v-card-actions>
    </dialog-form>
  </div>
</template>

<script>
import DialogForm from "../Dialog/DialogForm.vue";
import DialogFormHeader from "../Dialog/DialogFormHeader.vue";
import DialogFormSectionOneCol from "../Dialog/DialogFormSectionOneCol.vue";

export default {
  components: { DialogFormHeader, DialogForm, DialogFormSectionOneCol },
  props: {
    fieldOptions: Array,
    fieldSelections: Array,
  },
  data() {
    return {
      show: false,
      selection: this.formatFieldSelection(this.fieldSelections),
    };
  },
  methods: {
    applySelection() {
      this.$emit("apply-fields", this.selection);
      this.show = false;
    },
    formatFieldSelection(fields) {
      return fields.map((field) => field.propertyName);
    },
    toggleSelection(fieldOption) {
      const index = this.selection.indexOf(fieldOption);
      if (index > -1) {
        this.selection.splice(index, 1);
      } else {
        this.selection.push(fieldOption);
      }
    },
  },
  watch: {
    fieldSelections(to) {
      this.selection = this.formatFieldSelection(to);
    },
  },
};
</script>

<style scoped lang="scss"></style>
