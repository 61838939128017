<template>
  <v-app>
    <v-main class="base-background-color">
      <v-container fluid class="py-0 content-header">
        <v-row>
          <v-col class="px-2">
            <div
              class="page-top-bar pl-md-2 d-flex flex-column flex-lg-row align-lg-center"
              :style="
                pushContent ? 'height: 148px; padding-bottom: 60px;' : null
              "
            >
              <v-avatar
                color="#ffffff80"
                :size="mini ? 28 : 48"
                class="mr-4 my-3"
              >
                <img :src="orgImage(this.getActiveOrganization)" />
              </v-avatar>
              <h4
                class="text-h4 text-md-h4 flex-grow-1 d-none d-lg-block font-weight-medium white--text"
              >
                {{ this.getActiveOrganization.name }}
              </h4>
              <div class="flex-grow-1 d-flex flex-row">
                <div
                  class="flex-grow-1 d-flex flex-column-reverse flex-sm-row align-end align-sm-center"
                >
                  <date-range-picker
                    class="d-flex flex-grow-1 justify-end justify-md-sm align-end"
                    :fromDate="globalDateRange.from"
                    :toDate="globalDateRange.to"
                    @change="(val) => dateRangeChanged(val)"
                  ></date-range-picker>
                  <span
                    class="d-none d-sm-block mx-4 mx-md-8"
                    style="height: 40px"
                  ></span>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
      <v-container fluid class="align-start px-0 pt-0">
        <slot />
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
import { Inertia } from "@inertiajs/inertia";
import DateRangePicker from "../Components/Inputs/DateRangePicker.vue";
import { initializeRemoteLanguages } from "../i18n";
import { EventBus } from "../eventBus.js";

export default {
  components: {
    DateRangePicker,
  },
  computed: {
    getPageTitleKey() {
      if (this.title === "pages.dashboard.title") {
        return this.$t("components.layout.welcome", {
          name: this.auth.user.name,
        });
      }

      if (this.title === "pages.integrationEntryEvents.index.title") {
        const integration = this.$inertia.page.props.integration;

        return this.$t(this.title, { integration: integration.name });
      }

      return this.$t(this.title, this.titleValues);
    },
    getActiveOrganization() {
      let organization = this.auth.organizations.find(
        (item) => item.id === this.auth.organizationId
      );

      if (!organization) {
        organization = this.auth.organizationGroups.find(
          (item) => item.id === this.auth.organizationId
        );
      }

      return organization;
    },
    successMessage() {
      const success = this.$page.props.flash.success;

      const languageKey = `common.flashMessages.success.${success}`;

      if (success && this.$te(languageKey)) return this.$t(languageKey);

      return success;
    },
    errorMessage() {
      const error = this.$page.props.flash.error;

      const languageKey = `common.flashMessages.error.${error}`;

      if (error && this.$te(languageKey)) return this.$t(languageKey);

      return error;
    },
    auth() {
      return this.$page.props.auth;
    },
    language() {
      return this.$page.props.language;
    },
  },
  data: () => ({
    drawer: true,
    mini: false,
    items: [
      {
        title: "Dashboard",
        route: "dashboard.index",
        icon: "mdi-view-dashboard",
      },
      {
        title: "Organizations",
        route: "organizations.index",
        icon: "mdi-file-tree",
      },
    ],
    showSuccess: false,
    showError: false,
    windowWidth: window.innerWidth,
    globalDateRange: {
      from: null,
      to: null,
    },
  }),
  methods: {
    orgImage(item) {
      if (item.logoFile) {
        return this.route("api.files.content", {
          id: item.logoFile.id,
          filename: item.logoFile.originalName,
        });
      }
      return (
        "https://ui-avatars.com/api/?name=" +
        item.name.charAt(0) +
        "&color=fff&background=F25D3B"
      );
    },
    dateRangeChanged(dateRange) {
      // Save new date range in localstorage
      localStorage.setItem("globalDateRange", JSON.stringify(dateRange));
      // Emit update event to trigger rerender
      EventBus.$emit("globalDateRangeChanged", dateRange);
    },
    url() {
      return location.pathname.substring(1);
    },
    enableSuccess() {
      this.showSuccess = true;

      setTimeout(() => (this.showSuccess = false), 2000);
    },
    enableError() {
      this.showError = true;

      setTimeout(() => (this.showError = false), 2000);
    },
    onResize() {
      this.windowWidth = window.innerWidth;
    },
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    titleValues: {
      type: Object,
      default: () => ({}),
    },
    hideMenu: {
      type: Boolean,
      default: false,
    },
    pushContent: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    successMessage() {
      this.enableSuccess();
    },
    errorMessage() {
      this.enableError();
    },
  },
  mounted() {
    const preferredLocale = this.auth.user.preferredLocale ?? "en";

    this.$i18n.locale = preferredLocale;

    initializeRemoteLanguages(this.language?.files);

    Inertia.on("success", () => {
      this.drawer = false;
    });

    Inertia.on("finish", () => {
      this.enableSuccess();
      this.enableError();
    });

    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });

    // Get date range from storage if set
    const dateRangeFromStorage = JSON.parse(
      localStorage.getItem("globalDateRange")
    );
    if (dateRangeFromStorage) {
      this.globalDateRange = dateRangeFromStorage;
      EventBus.$emit("globalDateRangeChanged", dateRangeFromStorage);
    } else {
      // Else create a date with current year selected
      const dateRange = {
        from: new Date(new Date().getFullYear(), 0, 1)
          .toISOString()
          .split("T")[0],
        to: new Date(new Date().getFullYear(), 11, 31)
          .toISOString()
          .split("T")[0],
      };
      // update global date range for rendering
      this.globalDateRange = dateRange;
      // update storage date range
      localStorage.setItem("globalDateRange", JSON.stringify(dateRange));
      // emit update event to trigger data fetching
      EventBus.$emit("globalDateRangeChanged", dateRange);
    }
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  updated() {
    const title = this.$te(this.title) ? this.$t(this.title) : this.title;

    document.title = title ? `${title} - Verarca` : "Verarca";
  },
};
</script>
<style>
img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
  display: block;
  vertical-align: middle;
}
img,
video {
  max-width: 100%;
  height: auto;
}

.max-w-1\/2 {
  max-width: 50%;
}

.max-w-4\/5 {
  max-width: 80%;
}
.fill-width {
  width: 100%;
}
.navigation-divider {
  border-color: rgba(255, 255, 255, 0.24) !important;
}
.cursor-pointer {
  cursor: pointer;
}
.cursor-default {
  cursor: default !important;
}
.relative {
  position: relative;
}
.v-application {
  caret-color: red !important;
}
.base-background-color {
  background-color: #f7f7f7 !important;
}
.content-header {
  background-color: #2a2a2a;
}
.font-size-4 {
  font-size: 1rem !important;
}
.transition-none {
  transition-property: none !important;
}
.min-w-1\/2 {
  min-width: 50% !important;
}
.min-w-6\/10 {
  min-width: 60% !important;
}
.h-fit-content {
  height: fit-content !important;
}

.fade-enter-active {
  transition: all 0.2s ease;
}
.fade-leave-active {
  transition: all 0.2s ease;
}
.fade-enter,
.fade-leave-to {
  transform: translateX(-20px);
  opacity: 0;
}

@media screen and (min-width: 960px) {
  .toggle-mobile-nav {
    display: none;
  }
}

@media screen and (min-width: 1264px) {
  .page-top-bar {
    padding-bottom: 0px;
    height: 87px;
  }
}

/* vuetify seems to have a bug that allows the navigation to be swiped hidden on larger screens, this could potentially become an issue on larger tablets. */
/* So we force the drawer to stay put. */
@media screen and (min-width: 960px) {
  .v-navigation-drawer--close {
    transform: translateX(0%) !important;
    visibility: visible !important;
  }
}
</style>
