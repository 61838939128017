import Decimal from "decimal.js";
import { isEmpty } from "lodash";
import { DateTime } from "luxon";
import { diffMonths } from "./dateTime";
import {
    depreciationStartModes,
    emissionConvertibleCurrencies,
    emissionCurrencies,
} from "./fixedAssetData";
import { integerNumberFormatter, roundedNumberFormatter } from "./formatters";

export function calculateEmissionsSpendBased(
  purchasePrice,
  purchasePriceCurrency,
  emissionFactor
) {
  const factorCurrency = emissionFactor?.activityUnit ?? null;

  const missingValues = !emissionFactor || !purchasePrice;

  const invalidCurrencies =
    !emissionCurrencies.includes(factorCurrency) ||
    !emissionCurrencies.includes(purchasePriceCurrency);

  const mismatch = factorCurrency !== purchasePriceCurrency;

  const unconvertable =
    !emissionConvertibleCurrencies.includes(factorCurrency) ||
    !emissionConvertibleCurrencies.includes(purchasePriceCurrency);

  if (missingValues || invalidCurrencies || (mismatch && unconvertable)) return;

  const dkkToUsdRate = new Decimal(0.15);
  const dkkToEurRate = new Decimal(0.13);

  const purchaseCurrency = purchasePriceCurrency;

  purchasePrice = new Decimal(purchasePrice);

  if (purchaseCurrency === "DKK" && factorCurrency === "USD") {
    purchasePrice = purchasePrice.times(dkkToUsdRate);
  } else if (purchaseCurrency === "DKK" && factorCurrency === "EUR") {
    purchasePrice = purchasePrice.times(dkkToEurRate);
  } else if (purchaseCurrency === "USD" && factorCurrency === "EUR") {
    purchasePrice = purchasePrice.dividedBy(dkkToUsdRate).times(dkkToEurRate);
  } else if (purchaseCurrency === "EUR" && factorCurrency === "USD") {
    purchasePrice = purchasePrice.dividedBy(dkkToEurRate).times(dkkToUsdRate);
  }
  let factor;
  if (emissionFactor.kgCo2eAr6) {
    factor = new Decimal(emissionFactor.kgCo2eAr6);
  } else if (emissionFactor.kgCo2eAr5) {
    factor = new Decimal(emissionFactor.kgCo2eAr5);
  } else {
    factor = new Decimal(emissionFactor.kgCo2eAr4);
  }

  const emissions = purchasePrice.times(factor);

  return roundedNumberFormatter.format(emissions.toFixed());
}

export function calculateEmissionsAverageData(
  unitValue,
  unitType,
  emissionFactor
) {
  const factorUnit = emissionFactor?.activityUnit ?? null;

  if (!emissionFactor || !unitValue || factorUnit !== unitType) return;

  let factor;

  if (emissionFactor.kgCo2eAr6) {
    factor = new Decimal(emissionFactor.kgCo2eAr6);
  } else if (emissionFactor.kgCo2eAr5) {
    factor = new Decimal(emissionFactor.kgCo2eAr5);
  } else {
    factor = new Decimal(emissionFactor.kgCo2eAr4);
  }

  const multiplier = new Decimal(unitValue);

  const emissions = multiplier.times(factor);

  return roundedNumberFormatter.format(emissions.toFixed());
}

function getDepreciationFactor(purchasePrice, scrapValue) {
  if (!purchasePrice || (!scrapValue && scrapValue !== 0)) return null;

  purchasePrice = new Decimal(purchasePrice);
  scrapValue = new Decimal(scrapValue);

  return purchasePrice.sub(scrapValue);
}

export function getDepreciationInYears(depreciationPeriod) {
  if (!depreciationPeriod) return null;

  return depreciationPeriod / 12;
}

function getDepreciationStartDate(purchaseDate, startOfDepreciation) {
  if (!purchaseDate) return null;

  purchaseDate = DateTime.fromJSDate(purchaseDate);

  switch (startOfDepreciation) {
    case depreciationStartModes.currentMonth:
      return purchaseDate.startOf("month");
    case depreciationStartModes.nextMonth:
      return purchaseDate.plus({ months: 1 }).startOf("month");
    case depreciationStartModes.currentDate:
      return purchaseDate;
  }
}

function getYearBoundary(startDate) {
  let yearBoundary = DateTime.fromFormat(`${startDate.year}-1-1`, "yyyy-M-d");

  if (yearBoundary.startOf("month") <= startDate.startOf("month")) {
    yearBoundary = yearBoundary.plus({ years: 1 });
  }

  return yearBoundary;
}

function getMonthsPassed(date, maxMonths, selectedTime) {
  let monthDiff = selectedTime.diff(date, ["months"]).months;

  if (monthDiff > maxMonths) {
    monthDiff = maxMonths;
  }

  return Math.ceil(monthDiff);
}

function validateDepreciationData(
  factor,
  totalMonths,
  startDate,
  purchasePriceCurrency,
  scrapValueCurrency,
  purchasePrice,
  scrapValue
) {
  return (
    factor &&
    startDate &&
    totalMonths &&
    !isNaN(totalMonths) &&
    purchasePriceCurrency &&
    purchasePriceCurrency === scrapValueCurrency &&
    purchasePrice >= scrapValue
  );
}

export function calculateDepreciations(
  purchaseDate,
  purchasePrice,
  purchasePriceCurrency,
  scrapValue,
  scrapValueCurrency,
  depreciationPeriod,
  startOfDepreciation,
  monthly = false,
  selectedTime = null
) {
  const factor = getDepreciationFactor(purchasePrice, scrapValue);
  const startDate = getDepreciationStartDate(purchaseDate, startOfDepreciation);
  let totalMonths = depreciationPeriod;

  if (selectedTime === null) {
    selectedTime = DateTime.utc();
  }

  if (
    !validateDepreciationData(
      factor,
      totalMonths,
      startDate,
      purchasePriceCurrency,
      scrapValueCurrency,
      purchasePrice,
      scrapValue
    )
  )
    return {};

  const depreciationPerMonth = factor.dividedBy(totalMonths);

  const yearBoundary = getYearBoundary(startDate);

  const { startMonthPercentage, endMonthPercentage } = getMonthPercentages(
    startDate,
    startOfDepreciation
  );

  const one = new Decimal(1);

  const startSubtraction = depreciationPerMonth.times(
    one.sub(startMonthPercentage)
  );
  const endSubtraction = depreciationPerMonth.times(
    one.sub(endMonthPercentage)
  );

  let amountOfMonthsPassed =
    getMonthsPassed(startDate, totalMonths, selectedTime) + 1;

  if (amountOfMonthsPassed <= 0) amountOfMonthsPassed = 0;
  if (amountOfMonthsPassed > totalMonths) amountOfMonthsPassed = totalMonths;

  const endDate = startDate.plus({ months: totalMonths }).startOf("month");

  const startBoundary = maxValue(
    startDate,
    selectedTime.startOf("year")
  ).startOf("day");

  const endBoundary = minValue(endDate, selectedTime).startOf("day");

  let currentYearDiff = endBoundary.diff(startBoundary, ["months"]).months;

  let monthsForCurrentYear = new Decimal(
    startBoundary > endBoundary ? 0 : Math.floor(currentYearDiff) + 1
  );

  if (monthsForCurrentYear < 0) monthsForCurrentYear = new Decimal(0);

  let accumulated;

  if (amountOfMonthsPassed >= totalMonths)
    accumulated = depreciationPerMonth
      .times(amountOfMonthsPassed)
      .sub(startSubtraction)
      .sub(endSubtraction);
  else if (amountOfMonthsPassed >= 1)
    accumulated = depreciationPerMonth
      .times(amountOfMonthsPassed)
      .sub(startSubtraction);
  else accumulated = depreciationPerMonth.times(amountOfMonthsPassed);

  let currentYear;

  if (monthsForCurrentYear >= totalMonths)
    currentYear = depreciationPerMonth
      .times(monthsForCurrentYear)
      .sub(startSubtraction)
      .sub(endSubtraction);
  else if (monthsForCurrentYear >= 1)
    currentYear = depreciationPerMonth
      .times(monthsForCurrentYear)
      .sub(startSubtraction);
  else currentYear = depreciationPerMonth.times(monthsForCurrentYear);

  let result = {
    monthly: depreciationPerMonth,
    yearly: depreciationPerMonth * 12,
    accumulated: accumulated,
    currentYearDepreciation: currentYear.toNumber(),
  };

  if (monthly)
    result = performDepreciationMonthlySummary(
      startDate,
      totalMonths,
      depreciationPerMonth,
      startOfDepreciation,
      result
    );
  else
    result = performDepreciationYearlySummary(
      startDate,
      totalMonths,
      yearBoundary,
      depreciationPerMonth,
      startOfDepreciation,
      result
    );

  return result;
}

// Math.max only supports numbers
function maxValue(value1, value2) {
  if (value1 > value2) return value1;
  else if (value2 > value1) return value2;
  else return value1;
}

function minValue(value1, value2) {
  if (value1 < value2) return value1;
  else if (value2 < value1) return value2;
  else return value1;
}

function performDepreciationYearlySummary(
  startDate,
  totalMonths,
  yearBoundary,
  depreciationPerMonth,
  startOfDepreciation,
  result
) {
  let currentSum = new Decimal(0);

  let currentYear = startDate.year;

  if (!result) result = {};

  const monthPercentages = getMonthPercentages(startDate, startOfDepreciation);

  for (
    let iterator = startDate;
    diffMonths(iterator, startDate) <= totalMonths;
    iterator = iterator.plus({ months: 1 })
  ) {
    if (yearBoundary <= iterator) {
      result[currentYear] = currentSum;

      yearBoundary = yearBoundary.plus({ years: 1 });
      currentSum = new Decimal(0);
      currentYear++;
    }

    const currentMonth = diffMonths(iterator, startDate);

    if (
      currentMonth === totalMonths &&
      !result[currentYear] &&
      currentSum.greaterThan(new Decimal(0))
    ) {
      result[currentYear] = currentSum;
      break;
    }

    let monthDepreciation = getMonthDepreciation(
      currentMonth,
      totalMonths,
      depreciationPerMonth,
      monthPercentages
    );

    currentSum = currentSum.add(monthDepreciation);
  }

  return result;
}

function performDepreciationMonthlySummary(
  startDate,
  totalMonths,
  depreciationPerMonth,
  startOfDepreciation,
  result
) {
  if (!result) result = {};

  const monthPercentages = getMonthPercentages(startDate, startOfDepreciation);

  for (
    let iterator = startDate;
    diffMonths(iterator, startDate) < totalMonths;
    iterator = iterator.plus({ months: 1 })
  ) {
    const year = iterator.year;
    const month = iterator.month;

    const currentMonth = diffMonths(iterator, startDate);

    let monthDepreciation = getMonthDepreciation(
      currentMonth,
      totalMonths,
      depreciationPerMonth,
      monthPercentages
    );

    result[`${year}|${month}`] = monthDepreciation;
  }

  return result;
}

function getMonthPercentages(startDate, startOfDepreciation) {
  let startMonthPercentage = new Decimal(1);
  let endMonthPercentage = new Decimal(1);

  if (startOfDepreciation === depreciationStartModes.currentDate) {
    const percentage = new Decimal(startDate.day - 1).dividedBy(
      startDate.endOf("month").day
    );

    endMonthPercentage = percentage;
    startMonthPercentage = startMonthPercentage.sub(percentage);
  }

  return { startMonthPercentage, endMonthPercentage };
}

function getMonthDepreciation(
  currentMonth,
  totalMonths,
  depreciationPerMonth,
  monthPercentages
) {
  const { startMonthPercentage, endMonthPercentage } = monthPercentages;

  switch (currentMonth) {
    case 0:
      return depreciationPerMonth.times(startMonthPercentage);
    case totalMonths - 1:
      return depreciationPerMonth.times(endMonthPercentage);
    default:
      return depreciationPerMonth;
  }
}

export function calculateBookValue(
  purchasePrice,
  purchasePriceCurrency,
  purchaseDate,
  calculatedDepreciations,
  startOfDepreciation,
  selectedTime = null,
  formatted = true
) {
  let currency = "";
  if (purchasePriceCurrency) {
    currency = purchasePriceCurrency + " ";
  }

  selectedTime =
    selectedTime?.startOf("month") ?? DateTime.utc().startOf("month");

  const selectedYear = selectedTime.year;
  const selectedMonth = selectedTime.month;

  if (
    !purchasePrice ||
    !purchaseDate ||
    !startOfDepreciation ||
    isEmpty(calculatedDepreciations) ||
    !calculatedDepreciations[`${selectedYear}|${selectedMonth}`]
  ) {
    if (formatted) return currency + "0";
    else return [currency, new Decimal(0)];
  }

  const bookValue = new Decimal(purchasePrice).sub(
    calculatedDepreciations.accumulated
  );

  Decimal.set({ rounding: Decimal.ROUND_HALF_UP });

  const roundedNumber = bookValue.round().toNumber();

  if (formatted) return currency + integerNumberFormatter.format(roundedNumber);
  else return [currency, bookValue];
}

export function calculateGainLoss(
  purchasePrice,
  purchasePriceCurrency,
  salePrice,
  salePriceCurrency,
  saleDate,
  calculatedDepreciations,
  timeOfComissioningData,
  startOfDepreciationData
) {
  let currency = "";
  if (salePriceCurrency) {
    currency = salePriceCurrency.trim() + " ";
  }

  if (purchasePriceCurrency !== salePriceCurrency || !saleDate || !salePrice)
    return currency + "0";

  const saleDateTime = DateTime.fromJSDate(saleDate);

  const saleBookValues = calculateBookValue(
    purchasePrice,
    purchasePriceCurrency,
    timeOfComissioningData,
    calculatedDepreciations,
    startOfDepreciationData,
    saleDateTime,
    false
  );

  const bookValue = saleBookValues[1];

  const salePriceDecimal = new Decimal(salePrice);

  const gainLoss = salePriceDecimal.sub(bookValue);

  Decimal.set({ rounding: Decimal.ROUND_HALF_UP });

  const roundedNumber = gainLoss.round().toNumber();

  return currency + integerNumberFormatter.format(roundedNumber);
}
