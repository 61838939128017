<template>
  <v-tab-item :transition="false" :value="tabHref">
    <div
      class="fill-height fill-width"
      style="padding-left: 15rem; padding-right: 15rem"
    >
      <div>
        <h1 class="text-subtitle-1 black--text ma-2">
          {{ $t("pages.settings.documents.title") }}
        </h1>
      </div>
      <div class="d-flex flex-column ma-2 px-2 pt-2" style="border: 1px solid #ccc">
        <div class="d-flex justify-space-between">
          <div
            class="d-flex justify-space-between mx-2 pb-2"
            style="width: 100%; border-bottom: 1px solid #ccc"
          >
            <div class="d-flex justify-space-between" style="width: 500px">
              {{ $t("pages.settings.documents.table.name") }}
            </div>
            <div class="d-flex justify-space-between" style="width: 300px">
              {{ $t("pages.settings.documents.table.updatedAt") }}
            </div>
            <div class="d-flex justify-space-between" style="width: 300px">
              {{ $t("pages.settings.documents.table.type") }}
            </div>
            <div
              class="d-flex justify-space-between"
              style="width: 100px"
            ></div>
          </div>
        </div>
        <div
          class="d-flex justify-space-between font-weight-bold py-2"
          style="width: 100%; border-bottom: 1px solid #ccc"
        >
          <div class="d-flex justify-space-between ma-2" style="width: 100%">
            <div
              class="d-flex justify-start align-self-end"
              style="width: 500px; color: #f25d3b; cursor: pointer"
            >
              <div
                class="d-flex justify-start"
                @click="openLink('https://verarca.dk/abonnementsvilkaar/')"
              >
                {{ $t("pages.settings.documents.termsAndConditions") }}
                <open-in-new-icon
                  class="align-self-end mb-1 ml-1"
                  style="cursor: pointer"
                >
                </open-in-new-icon>
              </div>
            </div>
            <div
              class="d-flex justify-space-between align-self-end"
              style="width: 300px"
            >
              2024-03-12
            </div>
            <div class="d-flex justify-start" style="width: 300px">
              {{ $t("pages.settings.documents.internalDocument") }}
            </div>
            <div
              class="d-flex justify-end pr-4 align-self-end"
              style="width: 100px"
            ></div>
          </div>
        </div>
        <div
          class="d-flex justify-space-between font-weight-bold py-2"
          style="width: 100%; border-bottom: 1px solid #ccc"
        >
          <div class="d-flex justify-space-between ma-2" style="width: 100%">
            <div
              class="d-flex justify-start align-self-end"
              style="width: 500px; color: #f25d3b; cursor: pointer"
            >
              <div
                class="d-flex justify-start"
                @click="
                  openLink(
                    'https://verarca.dk/cookie-og-privatlivspolitik-hos-vep-a-s/'
                  )
                "
              >
                {{ $t("pages.settings.documents.cookiesAndPrivacyPolicy") }}
                <open-in-new-icon
                  class="align-self-end mb-1 ml-1"
                  style="cursor: pointer"
                >
                </open-in-new-icon>
              </div>
            </div>
            <div
              class="d-flex justify-space-between align-self-end"
              style="width: 300px"
            >
              2024-03-12
            </div>
            <div class="d-flex justify-start" style="width: 300px">
              {{ $t("pages.settings.documents.internalDocument") }}
            </div>
            <div
              class="d-flex justify-end pr-4 align-self-end"
              style="width: 100px"
            ></div>
          </div>
        </div>
        <div
          class="d-flex justify-space-between font-weight-bold py-2"
          style="width: 100%"
        >
          <div class="d-flex justify-space-between ma-2" style="width: 100%">
            <div
              class="d-flex justify-start align-self-end"
              style="width: 500px; color: #f25d3b; cursor: pointer"
            >
              <div
                class="d-flex justify-start"
                @click="
                  openLink('https://verarca.dk/ofte-stillede-spoergsmaal/')
                "
              >
                {{ $t("pages.settings.documents.frequentlyAskedQuestions") }}
                <open-in-new-icon
                  class="align-self-end mb-1 ml-1"
                  style="cursor: pointer"
                >
                </open-in-new-icon>
              </div>
            </div>
            <div
              class="d-flex justify-space-between align-self-end"
              style="width: 300px"
            >
              2024-03-12
            </div>
            <div class="d-flex justify-start" style="width: 300px">
              {{ $t("pages.settings.documents.internalDocument") }}
            </div>
            <div
              class="d-flex justify-end pr-4 align-self-end"
              style="width: 100px"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </v-tab-item>
</template>

<script>
import OpenInNewIcon from "../../../assets/svg/open-in-new.svg";

export default {
  components: {
    OpenInNewIcon,
  },
  props: {
    tabHref: String,
  },
  methods: {
    openLink(url) {
      window.open(url, "_blank");
    },
  },
};
</script>

<style scoped></style>
