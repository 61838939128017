<template>
  <v-container fluid class="py-0" style="margin-top: -66px">
    <v-row class="px-2">
      <v-col
        v-if="integrationSystem === 'dynamics365'"
        cols="12"
        md="6"
        lg="3"
        class="px-2 py-2 pt-4 d-flex"
      >
        <amount-change-card
          :title="$t('pages.audits.accountingSystemBalanceTotal')"
          iconBackColor="#6493BE1f"
          :value="formatCurrency(accountingSystemBalanceTotal)"
          :hideBenchmark="true"
          :unit="auditCurrencyCode"
        >
          <template v-slot:icon>
            <PieChartIcon style="width: 20px; height: 20px"></PieChartIcon>
          </template>
        </amount-change-card>
      </v-col>
      <v-col cols="12" md="6" lg="3" class="px-2 py-2 pt-4 d-flex">
        <amount-change-card
          :title="$t('pages.audits.verarcaBalanceUnprocessedTotal')"
          iconBackColor="#F25D3B1f"
          :value="formatCurrency(verarcaBalanceUnprocessedTotal)"
          :hideBenchmark="true"
          :unit="auditCurrencyCode"
        >
          <template v-slot:icon>
            <BarChartIcon style="width: 20px; height: 20px"></BarChartIcon>
          </template>
        </amount-change-card>
      </v-col>
      <v-col cols="12" md="6" lg="3" class="px-2 py-2 pt-4 d-flex">
        <amount-change-card
          :title="$t('pages.audits.verarcaBalanceProcessedTotal')"
          iconBackColor="#F25D3B1f"
          :value="formatCurrency(verarcaBalanceProcessedTotal)"
          :hideBenchmark="true"
          :unit="auditCurrencyCode"
        >
          <template v-slot:icon>
            <BarChartIcon style="width: 20px; height: 20px"></BarChartIcon>
          </template>
        </amount-change-card>
      </v-col>
      <v-col cols="12" md="6" lg="3" class="px-2 py-2 pt-4 d-flex">
        <amount-change-card
          :title="$t('pages.audits.accountingProcessedDeviationTotal')"
          iconBackColor="#2666631f"
          :value="formatCurrency(accountingProcessedDeviationTotal)"
          :hideBenchmark="true"
          :unit="auditCurrencyCode"
        >
          <template v-slot:icon>
            <TransferIcon style="width: 20px; height: 20px"></TransferIcon>
          </template>
        </amount-change-card>
      </v-col>
    </v-row>
    <v-row class="px-2">
      <v-col class="px-2">
        <dialog-form-select-input
          v-if="integrations?.length > 0"
          :items="formattedIntegrations"
          itemText="name"
          itemValue="id"
          v-model="selectedIntegration"
          style="max-width: 270px"
          hide-details
          class="mb-4"
        ></dialog-form-select-input>
        <v-data-table
          class="elevation-0 rounded-0 account-table"
          :headers="headers"
          :items="mappedAccounts"
          disable-sort
          hide-default-footer
          :items-per-page="-1"
          :loading="loadingData"
        >
          <template v-slot:item="{ item }">
            <tr
              :style="
                !item.heading ? 'background: #FAFAFA' : 'background: #FFF'
              "
            >
              <td>{{ item.accountName }}</td>
              <td
                class="align-center justify-space-between text-right"
                v-if="integrationSystem === 'dynamics365'"
              >
                {{
                  !item.heading
                    ? formatCurrency(item.accountingSystemBalance)
                    : null
                }}
              </td>
              <td class="align-center text-right">
                {{
                  !item.heading
                    ? formatCurrency(item.verarcaBalanceUnprocessed)
                    : null
                }}
              </td>
              <td v-if="integrationSystem === 'dynamics365'" class="py-2">
                <div v-if="!item.heading" class="d-flex align-center">
                  <div class="mr-2">
                    <CheckCircleIcon
                      v-if="item.accountingUnprocessedDeviation === 0"
                      width="20px"
                      height="20px"
                    ></CheckCircleIcon>
                    <WarningIcon
                      class="warning-icon"
                      v-else
                      width="20px"
                      height="20px"
                    ></WarningIcon>
                  </div>
                  <div
                    class="flex-grow-1 text-right"
                    :class="
                      item.accountingUnprocessedDeviation !== 0
                        ? 'warning-text'
                        : null
                    "
                  >
                    {{ formatCurrency(item.accountingUnprocessedDeviation) }}
                  </div>
                </div>
              </td>
              <td class="text-right">
                {{
                  !item.heading
                    ? formatCurrency(item.verarcaBalanceProcessed)
                    : null
                }}
              </td>
              <td v-if="integrationSystem === 'dynamics365'" class="py-2">
                <div v-if="!item.heading" class="d-flex align-center">
                  <div class="mr-2">
                    <CheckCircleIcon
                      v-if="item.accountingProcessedDeviation === 0"
                      width="20px"
                      height="20px"
                    ></CheckCircleIcon>
                    <WarningIcon
                      class="warning-icon"
                      v-else
                      width="20px"
                      height="20px"
                    ></WarningIcon>
                  </div>
                  <div
                    class="flex-grow-1 text-right"
                    :class="
                      item.accountingProcessedDeviation !== 0
                        ? 'warning-text'
                        : null
                    "
                  >
                    {{ formatCurrency(item.accountingProcessedDeviation) }}
                  </div>
                </div>
              </td>
              <td v-else class="py-2">
                <div v-if="!item.heading" class="d-flex align-center">
                  <div class="mr-2">
                    <CheckCircleIcon
                      v-if="item.verarcaDeviation === 0"
                      width="20px"
                      height="20px"
                    ></CheckCircleIcon>
                    <WarningIcon
                      class="warning-icon"
                      v-else
                      width="20px"
                      height="20px"
                    ></WarningIcon>
                  </div>
                  <div
                    class="flex-grow-1 text-right"
                    :class="item.verarcaDeviation !== 0 ? 'warning-text' : null"
                  >
                    {{ formatCurrency(item.verarcaDeviation) }}
                  </div>
                </div>
              </td>
            </tr>
          </template>
          <template v-slot:footer>
            <div class="d-flex flex-grow-1 justify-end mx-4 py-4">
              <v-btn
                large
                class="align-self-stretch align-self-sm-center mt-5 mt-sm-0 text-none"
                elevation="0"
                tile
                color="#F25D3B"
                dark
                :href="excelExportLink"
                :disabled="!excelExportLink"
                >Export to Excel</v-btn
              >
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { appLayout } from "@/util/layout";
import AmountChangeCard from "../../Components/Cards/AmountChangeCard.vue";
import PieChartIcon from "../../assets/svg/pie-chart.svg";
import BarChartIcon from "../../assets/svg/bar-chart.svg";
import TransferIcon from "../../assets/svg/transfer.svg";
import CheckCircleIcon from "../../assets/svg/check-circle.svg";
import WarningIcon from "../../assets/svg/warning-error.svg";
import Vue from "vue";
import Formatters from "../../mixins/Formatters.vue";
import GlobalDateRange from "../../mixins/GlobalDateRange.vue";
import qs from "qs";
import DialogFormSelectInput from "../../Components/Dialog/inputs/DialogFormSelectInput.vue";

export default {
  mixins: [Formatters, GlobalDateRange],
  components: {
    AmountChangeCard,
    PieChartIcon,
    CheckCircleIcon,
    WarningIcon,
    BarChartIcon,
    TransferIcon,
    DialogFormSelectInput,
  },
  layout: appLayout({ title: "pages.audits.title", pushContent: true }),
  props: {
    audit: Array,
    integrationId: String,
    auditSummary: Object,
    integrations: Array,
  },
  mounted() {
    this.getAccountsAndCategories();

    this.syncDateRange((dateRange) => {
      this.globalDateRange = dateRange;
      this.handleDateRangeChanged(dateRange);
    });
  },
  data() {
    return {
      flattenedAccounts: [],
      mappedAccounts: [],
      loadingData: false,
      currentAudit: this.audit,
      currentAuditSummary: this.auditSummary,
      selectedIntegration: this.integrationId,
      globalDateRange: null,
    };
  },
  watch: {
    selectedIntegration() {
      this.flattenedAccounts = [];
      this.mappedAccounts = [];

      this.getAccountsAndCategories();
      this.handleDateRangeChanged(this.globalDateRange, true);
    },
  },
  computed: {
    accountingSystemBalanceTotal() {
      return this.currentAuditSummary?.accountingSystemBalance;
    },
    verarcaBalanceProcessedTotal() {
      return this.currentAuditSummary?.verarcaBalanceProcessed;
    },
    verarcaBalanceUnprocessedTotal() {
      return this.currentAuditSummary?.verarcaBalanceUnprocessed;
    },
    accountingProcessedDeviationTotal() {
      return this.currentAuditSummary?.accountingProcessedDeviation;
    },
    verarcaDeviationTotal() {
      return this.currentAuditSummary?.verarcaDeviation;
    },
    auditCurrencyCode() {
      return this.currentAuditSummary?.currencyCode;
    },
    integrationSystem() {
      if (!this.selectedIntegration) return null;

      return (
        this.integrations?.find((x) => x.id === this.selectedIntegration)
          ?.system ?? null
      );
    },
    headers() {
      let headers = [
        {
          text: this.$t("pages.audits.mappedAccounts"),
          align: "start",
          sortable: false,
          value: "accountName",
          width: "fill",
        },
        {
          text: this.$t("pages.audits.accountingSystemBalance"),
          value: "accountingSystemBalance",
        },
        {
          text: this.$t("pages.audits.verarcaBalanceUnprocessed"),
          value: "verarcaBalanceUnprocessed",
        },
        {
          text: this.$t("pages.audits.accountingUnprocessedDeviation"),
          value: "accountingUnprocessedDeviation",
          width: 320,
        },
        {
          text: this.$t("pages.audits.verarcaBalanceProcessed"),
          value: "verarcaBalanceProcessed",
        },
        {
          text: this.$t("pages.audits.accountingProcessedDeviation"),
          value: "accountingProcessedDeviation",
          width: 320,
        },
        {
          text: this.$t("pages.audits.deviation"),
          value: "verarcaDeviation",
          width: 320,
        },
      ];

      // Currently only Dynamics 365 BC has working totals endpoints
      if (this.integrationSystem !== "dynamics365") {
        headers = headers.filter(
          (x) =>
            x.value !== "accountingSystemBalance" &&
            x.value !== "accountingUnprocessedDeviation" &&
            x.value !== "accountingProcessedDeviation"
        );
      } else {
        headers = headers.filter((x) => x.value !== "verarcaDeviation");
      }

      return headers;
    },
    formattedIntegrations() {
      if (!this.integrations) return [];

      return this.integrations.map((x) => {
        const integrationSystemKey = `common.integrations.systems.${x.system}`;

        let integrationSystem;

        if (this.$te(integrationSystemKey)) {
          integrationSystem = this.$t(integrationSystemKey);
        } else {
          integrationSystem = this.$t("common.integrations.systems.unknown");
        }

        return {
          id: x.id,
          name: `#${x.referenceKey} - ${integrationSystem}`,
        };
      });
    },
    excelExportLink() {
      if (!this.globalDateRange || !this.selectedIntegration) return null;

      return this.route("api.audits.export.excel", {
        fromDate: this.globalDateRange.from,
        toDate: this.globalDateRange.to,
        integrationId: this.selectedIntegration,
      });
    },
  },
  methods: {
    async getAccountsAndCategories() {
      this.loadingData = true;
      fetch(
        this.route("onboarding.accountNumberMapper", {
          integrationId: this.selectedIntegration,
        })
      )
        .then((res) => res.json())
        .then((data) => {
          this.flattenedAccounts = this.formatFlattenedAccounts(
            data.economicAccounts
          );
          this.mappedAccounts = this.formatMappedAccounts(
            this.flattenedAccounts
          );
        })
        .finally(() => {
          this.loadingData = false;
        });
    },
    formatFlattenedAccounts(data) {
      const _accounts = [];
      const currentParent = {};

      data.forEach((account) => {
        if (
          account.accountType === "heading" ||
          account.accountType === "Heading" ||
          account.accountType === "Header" ||
          account.accountType.includes("Begin")
        ) {
          // Add last parentobject when encountering a new heading (Ignore first time since no parent object data has been added yet)
          if (currentParent.name && currentParent.accountNumbers.length > 0) {
            _accounts.push({ ...currentParent });
          }
          currentParent.name = account.name;
          currentParent.accountNumber = account.accountNumber;
          currentParent.accountNumbers = [];
        } else if (currentParent.accountNumbers) {
          const accountNumber = {
            name: account.name,
            number: account.accountNumber,
            id: account.id,
            balance: account.balance,
          };
          currentParent.accountNumbers.push(accountNumber);
        }
      });

      // flatten
      const flattenedAccounts = [];

      _accounts.forEach((accountGroup) => {
        flattenedAccounts.push({
          accountName: accountGroup.accountNumber + " - " + accountGroup.name,
          accountNumber: accountGroup.accountNumber,
          heading: true,
        });

        accountGroup.accountNumbers.forEach((account) => {
          flattenedAccounts.push({
            accountName: account.number + " - " + account.name,
            accountNumber: account.number,
          });
        });
      });

      return flattenedAccounts;
    },
    formatMappedAccounts(accounts) {
      accounts = [...accounts];

      // merge
      this.currentAudit.forEach((auditAccount) => {
        let index = accounts.findIndex(
          (account) => account.accountNumber === auditAccount.accountNumber
        );

        if (index !== -1) {
          Vue.set(accounts, index, {
            ...auditAccount,
            ...accounts[index],
          });
        }
      });

      // filter
      accounts = accounts.filter(
        (account) =>
          account.heading ||
          this.currentAudit.some(
            (x) => x.accountNumber === account.accountNumber
          )
      );

      // remove empty headings
      const filteredAccounts = accounts.filter(
        (account, index) =>
          !account?.heading || // include if not heading
          (!(account?.heading && accounts[index + 1]?.heading) && // exclude if this and next item are a headings
            !(account?.heading && index + 1 === accounts.length)) // exclude heading and last item in list
      );

      return filteredAccounts;
    },
    handleDateRangeChanged(dateRange, forceUpdate = false) {
      const dateRangeChanged = this.applyDateRangeToParams(dateRange);

      if (!dateRangeChanged && !forceUpdate) return;

      this.loadingData = true;

      this.fetchAuditData(dateRange)
        .then(
          () =>
            (this.mappedAccounts = this.formatMappedAccounts(
              this.flattenedAccounts
            ))
        )
        .finally(() => (this.loadingData = false));
    },
    applyDateRangeToParams(dateRange) {
      const searchParams = qs.parse(window.location.search.substring(1));

      const fromChanged = searchParams.fromDate !== dateRange.from;
      const toChanged = searchParams.toDate !== dateRange.to;

      if (!fromChanged && !toChanged) return false;

      searchParams.fromDate = dateRange.from;
      searchParams.toDate = dateRange.to;

      const url = `${window.location.pathname}?${qs.stringify(searchParams)}`;

      window.history.replaceState(null, "", url);

      return true;
    },
    fetchAuditData(dateRange) {
      const totalPromise = fetch(
        this.route("api.audits.summaries.total", {
          fromDate: dateRange.from,
          toDate: dateRange.to,
          integrationId: this.selectedIntegration,
        })
      )
        .then((res) => res.json())
        .then((data) => (this.currentAuditSummary = data));

      const accountsPromise = fetch(
        this.route("api.audits.summaries.accounts", {
          fromDate: dateRange.from,
          toDate: dateRange.to,
          integrationId: this.selectedIntegration,
        })
      )
        .then((res) => res.json())
        .then((data) => (this.currentAudit = data));

      return Promise.all([totalPromise, accountsPromise]);
    },
  },
};
</script>

<style lang="scss" scoped>
.account-table:deep(th span) {
  color: #b4b4b4;
  font-weight: 500;
  font-size: 12px;
}

.warning-text {
  color: #d32c2c;
}

.warning-icon {
  path {
    fill: #d32c2c;
  }
}
</style>
