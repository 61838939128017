<template>
  <v-tab-item :value="tabValue">
    <v-card flat>
      <dialog-form-section-two-cols class="dialog-form-cols" no-bottom-border>
        <template slot="left">
          <v-simple-table class="logs-table">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    {{ $t("pages.assets.form.logs.logsTable.datetime") }}
                  </th>
                  <th class="text-left">
                    {{ $t("pages.assets.form.logs.logsTable.event") }}
                  </th>
                  <th class="text-left">
                    {{ $t("pages.assets.form.logs.logsTable.source") }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in logs"
                  :key="item.logId"
                  @click="selectLogEntry(item.logId)"
                  :class="selectedLog?.logId === item.logId ? 'selected' : null"
                >
                  <td>{{ formatDate(item.timestamp) }}</td>
                  <td>{{ item.event }}</td>
                  <td>{{ item.source }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </template>
        <template slot="right">
          <v-simple-table v-if="selectedLog" class="log-entries-table">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left from-field">
                    {{ $t("pages.assets.form.logs.singleLogTable.from") }}
                  </th>
                  <th class="arrow"></th>
                  <th class="text-left">
                    {{ $t("pages.assets.form.logs.singleLogTable.to") }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="logEntry in selectedLog?.entries"
                  :key="logEntry.logId"
                >
                  <td
                    class="from-field"
                    :class="logEntry.from ? null : 'field-empty'"
                  >
                    <div class="field-name">{{ logEntry.field }}:</div>
                    {{
                      logEntry.from ??
                      $t("pages.assets.form.logs.singleLogTable.fieldEmpty")
                    }}
                  </td>
                  <td class="arrow">
                    <arrow-right-icon class="arrow-icon"></arrow-right-icon>
                  </td>
                  <td
                    class="to-field"
                    :class="logEntry.to ? null : 'field-empty'"
                  >
                    {{
                      logEntry.to ??
                      $t("pages.assets.form.logs.singleLogTable.fieldEmpty")
                    }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <div class="log-id" v-if="selectedLog?.logId">
            {{ $t("pages.assets.form.logs.logId") }}:
            {{ selectedLog?.logId }}
          </div>
        </template>
      </dialog-form-section-two-cols>
    </v-card>
  </v-tab-item>
</template>

<script>
import DialogFormSectionTwoCols from "../../../Components/Dialog/DialogFormSectionTwoCols.vue";
import ArrowRightIcon from "../../../assets/svg/arrow-right.svg";

export default {
  components: { DialogFormSectionTwoCols, ArrowRightIcon },
  props: {
    logs: Array,
    tabValue: [String, Number],
    selectedLog: Object,
  },
  data() {
    return {};
  },
  methods: {
    formatDate(dateString) {
      const date = new Date(dateString);
      const options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: false,
      };
      const localDateString = date
        .toLocaleString("en-US", options)
        .replace(/\//g, "-");
      return localDateString;
    },
    selectLogEntry(id) {
      this.$emit("selectLogEntry", id);
    },
  },
  computed: {},
  mounted() {},
  watch: {},
};
</script>

<style lang="scss" scoped>
.dialog-form-cols {
  :deep(.column-left) {
    height: calc(81vh - 197px);
    overflow-y: scroll;
    padding-top: 10px !important;
  }

  :deep(.column-right) {
    height: calc(81vh - 197px);
    overflow-y: scroll;
    padding-top: 10px !important;
  }
}

.logs-table {
  div table {
    thead {
      tr {
        th {
          height: 0;
          padding-bottom: 5px;
          font-weight: 400;
          font-size: 14px;
          color: #818181 !important;
        }
      }
    }
    tbody {
      tr {
        &.selected {
          background-color: #fdece9;
        }
        &:hover {
          cursor: pointer;
        }
        td {
          font-size: 12px;
          height: 38px;
          border-bottom: none !important;
        }
      }
    }
  }
}

.log-entries-table {
  div table {
    thead {
      tr {
        th {
          height: 0;
          padding-bottom: 5px;
          font-weight: 400;
          font-size: 14px;
          color: #818181 !important;
          width: 50%;
          &.arrow {
            width: 50px;
            padding: 0;
          }
        }
      }
    }
    tbody {
      tr {
        &.selected {
          background-color: #fdece9;
        }

        td {
          height: 38px;
          font-size: 12px;
          border-bottom: none !important;
          padding-top: 26px;
          padding-bottom: 10px;
          width: 50%;
          position: relative;

          &.from:before {
            position: absolute;
            top: 0;
            left: 0px;
          }

          &.from-field {
            .field-name {
              position: absolute;
              top: 6px;
              left: 15px;
              color: rgb(242, 93, 59);
              font-size: 14px;
            }
          }

          &.arrow {
            width: 50px;
            padding-left: 0;
            padding-right: 0;
          }

          &.field-empty {
            color: #b4b4b4;
          }
        }
      }
    }
  }
}

.arrow-icon {
  path {
    fill: #b4b4b4;
  }
}

.log-id {
  padding-top: 40px;
  color: #b4b4b4;
  text-align: right !important;
  font-size: 10px;
}
</style>
