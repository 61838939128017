<template>
  <div>
    <v-data-table
      :key="rerenderCount"
      ref="entry-table"
      class="entry-table"
      v-model="selectedEntries"
      :headers="accountingEntryHeaders"
      :footer-props="tableFooterProps"
      :items="entries"
      checkbox-color="#F25D3B"
      v-sortable-table="{ onEnd: sortTableHeaders }"
      :server-items-length="entryItemsTotalCount ?? null"
      :items-per-page="entryItemsPerPage"
      :options.sync="accountingEntryTableOptions"
      @update:page="changePage()"
      @update:sort-desc="changeSorting()"
      @toggle-select-all="
        ({ value }) => {
          setSelectionForAllEntries(value);
          setSelectionForAllAssets(value);
        }
      "
      multi-sort
      show-select
      :no-data-text="$t('common.dataTable.noData')"
      @update:items-per-page="($event) => updatedItemsPerPage($event)"
    >
      <template v-for="header in headers" v-slot:[`header.${header.value}`]>
        <div v-if="header.value !== 'action'" v-bind:key="header.key">
          <div class="sticky-header-item">
            <span
              class="sticky-header"
              :style="'top:' + headerTopOffset + 'px;'"
            >
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <em v-on="on">
                    <span>{{ header.text }} </span>
                  </em>
                </template>
                <span>{{ header.text }} </span>
              </v-tooltip>
            </span>
          </div>
        </div>
      </template>
      <template #item="{ isSelected, select, item }">
        <tr>
          <td class="text-start fixed">
            <v-simple-checkbox
              color="#F25D3B"
              :ripple="false"
              :value="isSelected"
              :indeterminate="indeterminateEntries[item.id]"
              @input="
                () => {
                  setEntrySelection(item.id, !isSelected);
                  select(!isSelected);
                }
              "
            ></v-simple-checkbox>
          </td>
          <td>
            <v-btn
              v-if="expandedItems[item.id]"
              @click="setExpanded(item.id, false)"
              icon
            >
              <v-icon>mdi-minus</v-icon>
            </v-btn>
            <v-btn
              v-else
              @click="
                () => {
                  setExpanded(item.id, true, isSelected);
                }
              "
              icon
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </td>
          <td v-for="header in headers" :key="header.value" class="text-start">
            <div class="fill-height d-flex align-center">
              <span v-if="header.value === 'Emissions'">{{
                emissionFormatter(item[header.value])
              }}</span>
              <span v-else-if="header.value === 'EntryDate'">{{
                formatDate(item[header.value])
              }}</span>
              <span
                v-else-if="header.value === 'EntryAmount'"
                style="text-align: right; width: 100%"
                >{{
                  item["EntryCurrency"] +
                  " " +
                  formatCurrency(item[header.value])
                }}</span
              >
              <a
                v-else-if="header.value === 'File'"
                :href="
                  item?.File
                    ? route('api.files.content', {
                        id: item.File.id,
                        filename: item.File.originalName,
                      })
                    : undefined
                "
                target="_blank"
                ><v-icon v-if="item?.File">mdi-file-document-outline</v-icon></a
              >
              <span v-else>{{ item[header.value] }}</span>
            </div>
          </td>
        </tr>
        <tr
          class="assets-table"
          :class="expandedItems[item.id] ? 'expanded' : null"
        >
          <td :colspan="headers.length + 2">
            <v-data-table
              :headers="assetHeaders"
              :items="assetsForEntries[item.id]"
              hide-default-footer
              :loading="loadingAssetsForEntries[item.id]"
              :items-per-page="-1"
              @dblclick:row="
                (_, { item }) => {
                  editAsset(item.id);
                }
              "
              class="inner-table"
            >
              <template #item.checkbox="checkBoxProps">
                <v-simple-checkbox
                  color="#F25D3B"
                  :ripple="false"
                  :value="
                    selectedAssetsIds.includes(checkBoxProps.item.id) ||
                    isSelected
                  "
                  @click="
                    () => {
                      setAssetSelection(
                        checkBoxProps.item.id,
                        !!!selectedAssetsIds.includes(checkBoxProps.item.id)
                      );

                      // Set entry selected when all assets inside are selected
                      let entrySelected = entryAssetSelectionStatus[item.id]
                        ?.hasUnSelectedAssets
                        ? false
                        : true;
                      select(entrySelected);
                      setEntrySelection(item.id, entrySelected, true);
                    }
                  "
                ></v-simple-checkbox>
              </template>
              <template #item.scope="{ item }">
                <ScopeBadge
                  :scope="getScopeNumberById(item.scopeId)"
                ></ScopeBadge>
              </template>
              <template #item.purchase_price="{ item }">
                <span style="display: flex; justify-content: right">
                  {{ item.purchasePriceCurrency }}
                  {{ formatCurrency(item.purchasePriceAmount) }}
                </span>
              </template>
              <template #item.calculation_method="{ item }">
                {{ getCalculationMethodIdentifier(item.calculationMethodId) }}
              </template>
              <template #item.ghg_category="{ item }">
                {{ item.ghgCategory?.name }}
              </template>
              <template #item.emissions="{ item }">
                {{ emissionFormatter(item.co2Emissions) }}
              </template>
              <template #item.category="{ item }">
                <div class="fill-height d-flex align-center">
                  <span>{{ item?.emissionFactor?.category?.name }}</span>
                </div>
              </template>
            </v-data-table>
          </td>
        </tr>
      </template>
      <template #footer>
        <v-expand-transition>
          <v-card
            transition="scroll-y-transition"
            style="position: sticky; bottom: 0; z-index: 200"
            class=""
            tile
            elevation="0"
            dark
            v-show="selectedAssetsIds.length + selectedEntryIds.length > 0"
          >
            <div class="px-4 d-flex flex-row py-4">
              <div class="flex-grow-1 align-center d-flex">
                <v-simple-checkbox
                  on-icon="mdi-minus-box"
                  color="#F25D3B"
                  :ripple="false"
                  :value="true"
                  @input="
                    () => {
                      setSelectionForAllAssets(false);
                      setSelectionForAllEntries(false);
                    }
                  "
                ></v-simple-checkbox>
                <v-avatar class="ml-5 mr-3" color="#545454" size="24">
                  <span class="white--text text-body-2">{{
                    selectionCount
                  }}</span></v-avatar
                ><span>{{ $t("common.dataTable.itemsSelected") }}</span>
              </div>
              <div class="">
                <v-btn
                  class="text-none"
                  large
                  elevation="0"
                  outlined
                  tile
                  @click="() => showBulkEdit()"
                >
                  <v-icon class="mr-3" dark>mdi-pencil</v-icon>
                  {{ $t("common.dataTable.editAll") }}
                </v-btn>
                <v-btn
                  class="text-none ml-4"
                  color="red"
                  large
                  elevation="0"
                  tile
                  @click="() => removeRange()"
                >
                  <v-progress-circular
                    v-if="false"
                    :size="24"
                    :width="3"
                    indeterminate
                    class="mr-3"
                  ></v-progress-circular>
                  <v-icon v-else class="mr-3" dark
                    >mdi-trash-can-outline</v-icon
                  >
                  {{ $t("common.dataTable.deleteAll") }}
                </v-btn>
                <v-btn
                  class="text-none ml-4"
                  large
                  color="#F25D3B"
                  elevation="0"
                  tile
                  @click="unVerifyRange"
                  ><AIRobot
                    width="20px"
                    height="20px"
                    class="ai-robot-icon mr-3"
                  ></AIRobot>
                  {{ $t("common.dataTable.unVerifyAll") }}
                </v-btn>
                <v-btn
                  class="text-none ml-4"
                  large
                  color="#266663"
                  elevation="0"
                  tile
                  @click="verifyRange"
                  ><v-icon class="mr-3" dark>mdi-check-circle</v-icon>
                  {{ $t("common.dataTable.verifyAll") }}
                </v-btn>
              </div>
            </div>
          </v-card>
        </v-expand-transition>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { sortableTableDirective } from "@/util/dataTable";
import { serialize } from "object-to-formdata";
import Vue from "vue";
import AIRobot from "../../assets/svg/ai-robot.svg";
import Formatters from "../../mixins/Formatters.vue";
import { co2MethodOptions } from "../../util/fixedAssetData";
import ScopeBadge from "../Attribute/ScopeBadge.vue";
import { footerProps } from "@/util/dataTable";

export default {
  mixins: [Formatters],
  components: { ScopeBadge, AIRobot },
  directives: {
    "sortable-table": sortableTableDirective,
  },
  props: {
    props: footerProps,
    entries: Array,
    headers: Array,
    entryItemsTotalCount: Number,
    entryItemsPerPage: Number,
    defaultDateFormat: String,
    filters: Array,
  },
  data() {
    return {
      expandedItems: {},
      tableFooterProps: footerProps,

      // {entryId: [{assets},...],...}
      assetsForEntries: {},

      loadingAssetsForEntries: {},

      selectedEntryIds: [],
      selectedAssetsIds: [],

      rerenderCount: 0,

      selectedEntries: [],

      headerTopOffset: 0,

      accountingEntryTableOptions: {},
    };
  },
  created() {
    // Get current page
    const pageParam = new URLSearchParams(window.location.search).get("page");
    const pageNum = pageParam ? Number(pageParam) : 1;

    this.accountingEntryTableOptions.page = pageNum;
    let sortBy = [];
    let sortDesc = [];

    this.filters
      .filter((filter) => filter.filterType === null)
      .forEach((filter) => {
        sortBy.push(filter.propertyName);
        sortDesc.push(filter.sortDesc);
      });

    // default sort
    if (sortBy.length === 0) {
      sortBy = [];
      sortDesc = [];
    }
    this.accountingEntryTableOptions.sortBy = sortBy;
    this.accountingEntryTableOptions.sortDesc = sortDesc;
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    updatedItemsPerPage(val) {
      this.$emit("updated-items-per-page", val);
    },
    editAsset(id) {
      this.$emit("edit-asset", id);
    },
    setExpanded(id, value, selected) {
      if (value === true && !this.assetsForEntries[id]) {
        Vue.set(this.loadingAssetsForEntries, id, true);
        this.getAssetsForEntry(id, selected);
      }
      Vue.set(this.expandedItems, id, value);
    },
    getAssetsForEntry(id, selected = false) {
      let assets = [];

      fetch(
        this.route("api.accounting-entries.assets", {
          id: id,
        })
      )
        .then((res) => res.json())
        .then((data) => {
          assets = data;
        })
        .finally(() => {
          // add to assetsForEntries on entiesid key
          Vue.set(this.assetsForEntries, id, assets);
          Vue.set(this.loadingAssetsForEntries, id, false);

          this.setEntrySelection(id, selected);
        });
    },
    reloadAssets() {
      const entryIds = Object.keys(this.assetsForEntries);

      entryIds.forEach((entryId) => {
        this.getAssetsForEntry(entryId);
      });
    },
    setEntrySelection(id, value, ignoreAssets) {
      // add entryid if not already added
      if (value && !this.selectedEntryIds.includes(id)) {
        this.selectedEntryIds = [...this.selectedEntryIds, id];
      }
      // remove entryid if already included
      else if (!value && this.selectedEntryIds.includes(id)) {
        this.selectedEntryIds = this.selectedEntryIds.filter(
          (entryId) => entryId !== id
        );
      }

      // Check if assets for the entry has been loaded
      if (!this.assetsForEntries[id] || ignoreAssets) {
        return false;
      }
      // Set selection state for each of the assets
      this.assetsForEntries[id].forEach((asset) => {
        this.setAssetSelection(asset.id, value);
      });
    },
    setAssetSelection(id, value) {
      if (value) {
        //Vue.set(this.selectedAssetsIds, id, value);

        if (!this.selectedAssetsIds.includes(id)) {
          this.selectedAssetsIds.push(id);
        }
      } else {
        //Vue.delete(this.selectedAssetsIds, id);
        this.selectedAssetsIds = this.selectedAssetsIds.filter(
          (assetId) => assetId !== id
        );
      }
    },
    sortTableHeaders(event) {
      // Create a shallow copy of the headers array
      let headersTmp = [...this.headers];

      // Adjust indices to account for an offset due to the checkbox column
      const oldIndex = event.oldIndex - 2;
      const newIndex = event.newIndex - 2;

      // Move the header from oldIndex to newIndex
      headersTmp.splice(newIndex, 0, headersTmp.splice(oldIndex, 1)[0]);

      this.$emit("update-header", headersTmp);

      // deselect all assets to avaid assets still being selected while entries are not selected
      this.setSelectionForAllAssets(false);
    },
    setSelectionForAllAssets(value) {
      Object.keys(this.assetsForEntries).forEach((id) => {
        this.setEntrySelection(id, value);
      });
    },
    setSelectionForAllEntries(value) {
      if (value) {
        this.selectedEntryIds = [];
        this.entries.forEach((entry) => {
          this.setEntrySelection(entry.id, true);
        });
      } else {
        this.entries.forEach((entry) => {
          this.setEntrySelection(entry.id, false);
        });

        this.selectedEntryIds = [];

        // We need this to ensure the selection is cleared when using checkbox in the selection actions
        this.selectedEntries = [];
      }
    },
    getCalculationMethodIdentifier(calculationMethod) {
      if (calculationMethod === co2MethodOptions.spendBasedMethodId) {
        return "spend";
      }

      if (calculationMethod === co2MethodOptions.supplierSpecificMethodId) {
        return "supplier";
      }

      return "average";
    },
    verifyRange() {
      // Selected entries
      const selectedEntries = this.selectedEntryIds;

      if (selectedEntries.length > 0) {
        const form = serialize({
          entryIds: selectedEntries,
        });

        this.$inertia.post(
          this.route("accounting-entries.emission-factor.bulk-approve"),
          form,
          {
            preserveScroll: true,
            preserveState: true,
            onSuccess: () => this.$emit("reload"),
          }
        );
      }

      // Selected assets
      // @todo ignore selected asset ids for entry ids where all assets are selected
      // Or reverse and ignore entry ids where all assets are selected if the assets have been loaded
      // console.log("sending selected assets", this.selectedAssetsIds);

      const selectedAssets = this.selectedAssetsIds;

      if (selectedAssets.length > 0) {
        const assetsForm = serialize({
          assetIds: selectedAssets,
        });

        this.$inertia.post(
          this.route("assets.emission-factor.bulk-approve"),
          assetsForm,
          {
            preserveScroll: true,
            preserveState: true,
          }
        );
      }
    },
    unVerifyRange() {
      // Selected entries
      const selectedEntries = this.selectedEntryIds;

      const form = serialize({
        entryIds: selectedEntries,
      });

      this.$inertia.post(
        this.route("accounting-entries.emission-factor.bulk-invalidate"),
        form,
        {
          preserveScroll: true,
          preserveState: true,
          onSuccess: () => {
            // Fetch assets again and clear loading
            selectedAssets.forEach((assetId) => {
              const entryId = this.getEntryIdForAssetId(assetId);
              this.getAssetsForEntry(entryId);
            });

            // Clear selection
            this.setSelectionForAllEntries(false);
            this.setSelectionForAllAssets(false);
          },
          onFinish: () => {
            selectedAssets.forEach((assetId) => {
              const entryId = this.getEntryIdForAssetId(assetId);
              Vue.set(this.loadingAssetsForEntries, entryId, false);
            });
          },
        }
      );

      // Selected assets
      // @todo ignore selected asset ids for entry ids where all assets are selected
      // Or reverse and ignore entry ids where all assets are selected if the assets have been loaded

      const selectedAssets = this.selectedAssetsIds;

      if (selectedAssets.length > 0) {
        const assetsForm = serialize({
          assetIds: selectedAssets,
        });

        this.$inertia.post(
          this.route("assets.emission-factor.bulk-invalidate"),
          assetsForm,
          {
            preserveScroll: true,
            preserveState: true,
          }
        );
      }
    },
    async removeRange() {
      // Selected assets
      // @todo ignore selected asset ids for entry ids where all assets are selected
      // Or reverse and ignore entry ids where all assets are selected if the assets have been loaded

      const selectedAssets = this.selectedAssetsIds;

      // Set assets loading
      selectedAssets.forEach((assetId) => {
        const entryId = this.getEntryIdForAssetId(assetId);
        Vue.set(this.loadingAssetsForEntries, entryId, true);
      });

      if (selectedAssets.length > 0) {
        const assetsForm = serialize({
          assetIds: selectedAssets,
        });

        await this.$inertia.post(
          this.route("api.assets.destroy.range"),
          assetsForm,
          {
            preserveScroll: true,
            preserveState: true,
            onSuccess: () => {
              // Fetch assets again and clear loading
              selectedAssets.forEach((assetId) => {
                const entryId = this.getEntryIdForAssetId(assetId);
                this.getAssetsForEntry(entryId);
              });

              // Clear selection
              this.setSelectionForAllEntries(false);
              this.setSelectionForAllAssets(false);
            },
            onFinish: () => {
              selectedAssets.forEach((assetId) => {
                const entryId = this.getEntryIdForAssetId(assetId);
                Vue.set(this.loadingAssetsForEntries, entryId, false);
              });
            },
          }
        );

        // reload assets after deletion
      }
    },
    getEntryIdForAssetId(id) {
      for (const entryId of Object.keys(this.assetsForEntries)) {
        const assets = this.assetsForEntries[entryId];

        if (assets.some((asset) => asset.id === id)) {
          return entryId;
        }
      }

      return null;
    },
    getScopeNumberById(id) {
      let scopeNumber;
      switch (id) {
        case "cb483bd8-d20e-42a1-a252-c44ee12230ef":
          scopeNumber = "1";
          break;
        case "6045ba53-b1c4-4ac0-a4be-7d4e4acc9f2b":
          scopeNumber = "2";
          break;
        case "6d9ea628-4dcf-4d77-ad8b-7e8ea4d72dde":
          scopeNumber = "3";
          break;

        default:
          scopeNumber = null;
          break;
      }

      return scopeNumber;
    },
    handleScroll() {
      const scrollY = window.scrollY;

      const tableHeadTopOffset =
        document
          .querySelector(".entry-table .v-data-table-header")
          .getBoundingClientRect().y +
        scrollY +
        10;

      const offset = scrollY - tableHeadTopOffset;

      this.headerTopOffset = offset > 0 ? offset : 0;
    },
    emissionFormatter(val) {
      const { value, unit } = this.formatEmissions(val);
      return value + " " + unit;
    },
    formatDate(dateString) {
      const date = new Date(dateString);

      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");

      if (this.defaultDateFormat === "YYYY-MM-DD") {
        return `${year}-${month}-${day}`;
      }
      if (this.defaultDateFormat === "DD-MM-YYYY") {
        return `${day}-${month}-${year}`;
      }
    },
    changePage() {
      const pageNumber = this.accountingEntryTableOptions.page;
      const url =
        `${this.route("accounting-entries.index")}?page=` + pageNumber;
      this.$inertia.get(
        url,
        {},
        {
          preserveState: true,
        }
      );
    },
    changeSorting() {
      let filterData = [];
      this.accountingEntryTableOptions.sortBy.forEach((field, index) => {
        filterData.push({
          propertyName: field === "Account" ? "AccountNumber" : field,
          sortDesc: this.accountingEntryTableOptions.sortDesc[index],
          filterType: null,
        });
      });
      this.$emit("add-sort-filters", filterData);
    },
  },
  computed: {
    assetHeaders() {
      return [
        { value: "checkbox", sortable: false },
        { text: this.$t("common.attributeNames.Asset name"), value: "name" },
        {
          text: this.$t("common.attributeNames.Purchase price"),
          value: "purchase_price",
        },
        { text: this.$t("common.attributeNames.Quantity"), value: "quantity" },
        {
          text: this.$t("common.attributeNames.Calculation method"),
          value: "calculation_method",
        },
        { text: this.$t("common.attributeNames.Category"), value: "category" },
        {
          text: this.$t("common.attributeNames.GHG category"),
          value: "ghg_category",
        },
        {
          text: this.$t("common.attributeNames.Emission (kgCO2e)"),
          value: "emissions",
        },
        { text: this.$t("common.attributeNames.Scope"), value: "scope" },
      ];
    },
    accountingEntryHeaders() {
      return [
        { value: "expand", sortable: false, class: "not-draggable" },
        ...this.headers,
      ];
    },
    indeterminateEntries() {
      let entryIndeterminedStates = {};

      Object.keys(this.assetsForEntries).forEach((key) => {
        const assets = this.assetsForEntries[key];

        let hasSelectedAssets = false;
        let hasUnSelectedAssets = false;

        assets.forEach((asset) => {
          if (this.selectedAssetsIds.includes(asset.id)) {
            hasSelectedAssets = true;
          } else {
            hasUnSelectedAssets = true;
          }
        });

        if (hasSelectedAssets && hasUnSelectedAssets) {
          entryIndeterminedStates[key] = true;
        }
      });

      return entryIndeterminedStates;
    },
    entryAssetSelectionStatus() {
      let entryIndeterminedStates = {};

      Object.keys(this.assetsForEntries).forEach((key) => {
        const assets = this.assetsForEntries[key];

        let hasSelectedAssets = false;
        let hasUnSelectedAssets = false;

        assets.forEach((asset) => {
          if (this.selectedAssetsIds.includes(asset.id)) {
            hasSelectedAssets = true;
          } else {
            hasUnSelectedAssets = true;
          }
        });

        entryIndeterminedStates[key] = {
          hasSelectedAssets,
          hasUnSelectedAssets,
        };
      });

      return entryIndeterminedStates;
    },
    selectionCount() {
      let acc = 0;
      // Add selection count for entry that have not been expanded
      this.selectedEntryIds.forEach((id) => {
        if (!this.expandedItems[id]) {
          const entry = this.entries.find(
            (entry) => entry.id === id
          ).NumberOfAssets;

          acc += entry;
        }
      });

      // add single assets that have been selected for entry that was expanded
      acc += this.selectedAssetsIds.length;

      return acc;
    },
  },
  watch: {
    headers() {
      // Rerender when headers are updated otherwise header order wont rerender correctly on e.g. drag n drop
      this.rerenderCount++;
    },
  },
};
</script>
<style lang="scss">
.inner-table {
  .v-data-table__wrapper {
    overflow: hidden !important;
  }
}
</style>
<style lang="scss" scoped>
.assets-table {
  display: none;
  &.expanded {
    display: table-row;
  }
}

.ai-robot-icon {
  path {
    fill: #fff;
  }
}
.entry-table {
  .sticky-header-item {
    position: relative;
    display: inline-block !important;
    height: 14px;
    width: 100%;

    .sticky-header {
      position: absolute;
      left: 0;
      right: 0;
      background: #fff;
      padding: 0 16px;
      border-bottom: thin solid rgba(0, 0, 0, 0.12);
      z-index: 10;
      em {
        display: inline-block;
        width: 90%;
        font-style: normal;
        vertical-align: middle;

        i {
          display: inline-block;
          width: 10%;
          vertical-align: middle;
        }

        span {
          padding: 10px 0;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          display: inline-block;
          vertical-align: middle;
          max-width: calc(100% - 24px);
        }
      }
    }
  }

  :deep(thead th.sortable) {
    padding: 0 !important;
    i {
      z-index: 10;
      float: right;
      height: 10px;
    }
  }

  &:deep(td > div > span) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 300px;
  }
}
</style>
