<template>
  <div
    class="dnd-container"
    :class="[isFileHovered ? 'file-hover' : '', fullPage ? 'fullPage' : '']"
    @drop="onFileContainerDrop"
    @dragover="onFileContainerDragOver"
    @dragleave="onFileContainerDragLeave"
  >
    <div class="dnd-file-area d-flex align-center" :class="dndAreaClasses">
      <template v-if="fullPage">
        <div
          class="no-pointer download-icon d-flex align-center justify-center mb-6"
        >
          <DownloadFolder></DownloadFolder>
        </div>
        <p class="no-pointer title text-h6 black--text font-weight-regular">
          {{ $t("pages.assets.import.stepOne.dropFileToUpload") }}
        </p>
      </template>
      <template v-if="!fullPage">
        <v-btn
          plain
          class="download-icon d-flex align-center justify-center"
          :class="small ? '' : 'mb-6'"
          @click="triggerFileDialog"
        >
          <DownloadFolder></DownloadFolder>
        </v-btn>
        <p
          class="no-pointer title text-h6 black--text font-weight-regular"
          :class="small ? 'mb-0 ml-4 text-body-1' : ''"
        >
          {{
            overrideUploadFileText
              ? overrideUploadFileText
              : $t("pages.assets.import.stepOne.uploadFile")
          }}
        </p>
        <div v-if="!small">
          <div
            v-if="selectedImportFiles.length > 0"
            class="no-pointer description grey--text text-center text-caption mb-6"
          >
            <p class="mb-1">
              {{ $t("pages.assets.import.stepOne.selectedFiles") }}
            </p>
            <p
              class="mb-0"
              v-for="(file, index) in getFileNamesForMultipleFiles"
              :key="index"
            >
              {{ file }}
            </p>
          </div>

          <div
            v-else-if="selectedImportFile"
            class="description grey--text text-center text-caption mb-6"
          >
            <p class="mb-1">
              {{ $t("pages.assets.import.stepOne.selectedFile") }}
            </p>
            <p class="mb-0">{{ selectedImportFile.name }}</p>
          </div>
          <p
            v-else
            class="description grey--text text-center text-caption mb-6"
          >
            {{ $t("pages.assets.import.stepOne.dragOrClick") }}
          </p>
        </div>
        <v-btn
          v-if="!small"
          class="text-none ml-0 mb-4 mb-md-0 px-6"
          large
          color="#F25D3B"
          dark
          elevation="0"
          tile
          x-large
          @click="triggerFileDialog"
        >
          <Computer class="mr-3"></Computer>
          {{ $t("pages.assets.import.stepOne.uploadFromPc") }}
        </v-btn>
        <input
          type="file"
          :accept="allowedFileExpensions"
          ref="fileUpload"
          class="d-none"
          @change="onFileUploadChanged"
          :multiple="multiple"
        />
      </template>
    </div>
  </div>
</template>

<script>
import DownloadFolder from "../../../assets/svg/folder-download.svg";
import Computer from "../../../assets/svg/computer.svg";

export default {
  components: { DownloadFolder, Computer },
  props: {
    selectedImportFile: File,
    selectedImportFiles: Array,
    selectedImportFilesFilter: Function,
    importOptions: {
      type: Array,
      default: () => [{ id: 1, text: "File (.xls, .xlsx, .csv)" }],
    },
    allowedImportMimeTypes: {
      type: Array,
      default: () => [
        "text/csv",
        "application/pdf",
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      ],
    },
    allowedFileExpensions: { type: String, default: ".xlsx,.xls,.csv,.pdf" },
    multiple: { type: Boolean, default: false },
    fullPage: { type: Boolean, default: false },
    small: { type: Boolean, default: false },
    overrideUploadFileText: { type: String, default: null },
  },
  data() {
    return {
      selectedImportOption: 1,
      isFileHovered: false,
    };
  },
  methods: {
    triggerFileDialog() {
      this.$refs.fileUpload.value = "";
      this.$refs.fileUpload.click();
    },
    // On change event (input)
    onFileUploadChanged(event) {
      this.$emit("onFileUploadChanged", event);
    },
    onFileContainerDrop(event) {
      event.preventDefault();
      this.isFileHovered = false;

      this.$emit("errorChanged", null);
      this.$emit("invalidFormatChanged", false);

      const files = [...event.dataTransfer.files];

      if (!files.length) return;

      const firstFile = files[0];

      if (this.multiple) {
        this.setSelectedImportFiles(files);
      } else {
        this.setSelectedImportFile(firstFile);
      }
    },
    // Only used when drag n drop?
    setSelectedImportFile(file) {
      if (!!file && this.allowedImportMimeTypes.includes(file.type)) {
        this.$emit("fileChanged", file);
      } else {
        this.$emit("fileChanged", null);
        this.$emit(
          "errorChanged",
          this.$t("pages.assets.import.stepOne.filetypeNotSupported")
        );
        this.$emit("invalidFormatChanged", true);
      }
    },
    onFileContainerDragOver(event) {
      event.preventDefault();
      this.isFileHovered = true;
    },
    onFileContainerDragEnter(event) {
      event.preventDefault();
      this.isFileHovered = true;
    },
    onFileContainerDragLeave(event) {
      event.preventDefault();
      this.isFileHovered = false;
    },
    setSelectedImportFiles(files) {
      if (
        !!files &&
        files.every(
          (file) => !!file && this.allowedImportMimeTypes.includes(file.type)
        )
      ) {
        if (this.selectedImportFilesFilter)
          files = this.selectedImportFilesFilter(files);

        this.$emit("filesChanged", files);
      } else {
        this.$emit("filesChanged", []);
        this.$emit(
          "errorChanged",
          this.$t("pages.assets.import.stepOne.filetypeNotSupported")
        );
        this.$emit("invalidFormatChanged", true);
      }
    },
  },
  computed: {
    dndAreaClasses() {
      let classes = "";
      classes += this.fullPage ? "ma-4" : "";
      classes += this.small
        ? " flex-row justify-start pa-5 gray"
        : " my-4 flex-column justify-center pa-8";

      return classes;
    },
    selectedFileNames() {
      const fileCount = this.selectedImportFiles.length;
      if (fileCount > 3)
        return [
          this.$t("pages.assets.import.stepOne.files", { count: fileCount }),
        ];
      return this.selectedImportFiles.map((x) => x.name);
    },
    getFileNamesForMultipleFiles() {
      let files = [];

      for (var i = 0; i < this.selectedImportFiles.length; i++) {
        files.push(this.selectedImportFiles[i].name);
      }

      return files;
    },
  },
  watch: {
    isFileHovered(to) {
      this.$emit("dragStateChanged", to);
    },
  },
};
</script>

<style lang="scss" scoped>
.dnd-container {
  z-index: 900;
  &.file-hover .dnd-file-area {
    background-color: #fdece7;
  }

  &.fullPage {
    position: fixed;
    inset: 0;
    margin: 0 !important;
    padding: 0 !important;

    .dnd-file-area {
      position: absolute;
      inset: 0;
    }
  }
  .dnd-file-area {
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23F25D3BFF' stroke-width='2' stroke-dasharray='8' stroke-dashoffset='2px' stroke-linecap='square'/%3e%3c/svg%3e");

    .description {
      max-width: 312px;
    }

    .download-icon {
      height: 62px;
      width: 62px;
      background-color: #fdece7;
      border-radius: 100%;
    }

    .no-pointer {
      pointer-events: none;
    }

    &.gray {
      background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23D6D0D0' stroke-width='2' stroke-dasharray='8' stroke-dashoffset='2px' stroke-linecap='square'/%3e%3c/svg%3e");
      .title {
        color: #b4b4b4 !important;
      }

      .download-icon {
        width: 48px;
        height: 48px;
        min-width: 48px;
      }
    }
  }
}
</style>
