<template>
  <div class="page-container fill-height grow d-flex flex-column flex-nowrap">
    <v-card
      elevation="0"
      min-height="17.625rem"
      class="d-flex flex-column ma-4"
    >
      <v-card-text class="pa-0 flex-grow-1">
        <v-tabs
          v-model="tab"
          class="mb-4"
          style="border-bottom: 1px solid #e8e8e8"
          show-arrows
        >
          <v-tab
            v-for="contentTab in tabs"
            :key="contentTab.id"
            :href="`#${getTabHref(contentTab.id)}`"
            @change="setTabInUrl(contentTab.id)"
          >
            {{ contentTab.name }}
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab" class="fill-height fill-width px-0 pb-4">
          <tab-users
            :tabHref="getTabHref(1)"
            :users="users"
            :roles="roles"
          ></tab-users>
          <tab-roles :tabHref="getTabHref(2)" :roles="rolesPage"></tab-roles>
          <tab-permissions
            :tabHref="getTabHref(3)"
            :permissions="permissions"
            :roles="roles"
            :organizations="organizations"
          ></tab-permissions>
        </v-tabs-items>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import qs from "qs";
import { appLayout } from "@/util/layout";
import Formatters from "../../mixins/Formatters.vue";
import TabUsers from "./Tabs/TabUsers.vue";
import TabRoles from "./Tabs/TabRoles.vue";
import TabPermissions from "./Tabs/TabPermissions.vue";

export default {
  layout: appLayout({ title: "pages.userManagement.title" }),
  mixins: [Formatters],
  components: {
    TabUsers,
    TabRoles,
    TabPermissions,
  },
  props: {
    users: Object,
    roles: Array,
    rolesPage: Object,
    permissions: Array,
    organizations: Array,
  },
  data() {
    return {
      tab: window.location.hash?.substring(1) ?? null,
    };
  },
  mounted() {},
  computed: {
    tabs() {
      let tabs = [
        {
          id: 1,
          name: this.$t("pages.userManagement.tabs.users"),
        },
        {
          id: 2,
          name: this.$t("pages.userManagement.tabs.roles"),
        },
        {
          id: 3,
          name: this.$t("pages.userManagement.tabs.permissions"),
        },
      ];

      return tabs;
    },
  },
  methods: {
    getTabHref(tabId) {
      switch (tabId) {
        case 1:
          return "users";
        case 2:
          return "roles";
        case 3:
          return "permissions";
      }
    },
    setTabInUrl(tabId) {
      const tabHref = this.getTabHref(tabId);

      if (!tabHref) return;

      const existingQuery = qs.parse(window.location.search.substring(1));

      const newUrl =
        this.route("user-management.index", existingQuery) + `#${tabHref}`;

      window.history.replaceState(null, "", newUrl);
    },
  },
  watch: {},
};
</script>
