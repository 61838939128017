import common from "./common";
import components from "./components";
import pages from "./pages";

import vuetifyCommon from "./vuetify/common.json";
import vuetifyDataFooter from "./vuetify/dataFooter.json";

export default {
  common,
  components,
  pages,
  vuetify: {
    noDataText: vuetifyCommon.noData,
    dataFooter: vuetifyDataFooter,
  },
};
