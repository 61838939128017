<template>
  <v-row class="px-2">
    <v-col cols="12" md="6" lg="3" class="px-2 py-2 pt-4 d-flex">
      <amount-change-card
        :title="$t('components.cards.titles.numberOfAssets')"
        iconBackColor="#6493BE1f"
        :value="numberOfAssets"
        :hideBenchmark="true"
        :bars="getAssetCountBars"
      >
        <template v-slot:icon>
          <Package style="width: 20px; height: 20px"></Package>
        </template>
      </amount-change-card>
    </v-col>
    <v-col cols="12" md="6" lg="3" class="px-2 py-2 pt-4 d-flex">
      <amount-change-card
        :title="$t('components.cards.titles.totalCo2')"
        iconBackColor="#E5EDEC"
        :value="totalCo2Used.value"
        :unit="totalCo2Used.unit"
        :hideBenchmark="true"
        :bars="getScopeCountBars"
      >
        <template v-slot:icon>
          <Leaf style="width: 20px; height: 20px"></Leaf>
        </template>
      </amount-change-card>
    </v-col>
    <v-col cols="12" md="6" lg="3" class="px-2 py-2 pt-4 d-flex">
      <amount-change-card
        :title="$t('components.cards.titles.totalSpentAmount')"
        iconBackColor="#EDDDE6"
        :value="totalSpentAmount"
        :unit="totalSpentAmountUnit"
        :hideBenchmark="true"
      >
        <template v-slot:icon>
          <DonateCoin style="width: 20px; height: 20px"></DonateCoin>
        </template>
      </amount-change-card>
    </v-col>
    <v-col cols="12" md="6" lg="3" class="px-2 py-2 pt-4 d-flex">
      <amount-change-card
        :title="$t('components.cards.titles.trees')"
        iconBackColor="#E5EDEC"
        :value="treesToOffset"
        :unit="$t('common.units.trees')"
        :hideBenchmark="true"
      >
        <template v-slot:icon>
          <Tree style="width: 20px; height: 20px"></Tree>
        </template>
      </amount-change-card>
    </v-col>
  </v-row>
</template>

<script>
import AmountChangeCard from "../Cards/AmountChangeCard.vue";

import Tree from "../../../src/assets/svg/tree.svg";
import DonateCoin from "../../../src/assets/svg/donate-coin.svg";
import Package from "../../../src/assets/svg/package.svg";
import Leaf from "../../../src/assets/svg/leaf.svg";

import {
  integerNumberFormatter,
  currencyFormatter,
} from "../../util/formatters";

import Formatters from "../../mixins/Formatters.vue";

export default {
  components: { AmountChangeCard, Tree, Package, DonateCoin, Leaf },
  mixins: [Formatters],
  props: {
    assetCardData: Object,
  },
  methods: {
    barChartCalculateScale(value) {
      // Set height to 0 if value is 0
      if (value === 0 || value === null) {
        return 0;
      }

      // If value is really small but still above 0 return 0.1
      //just to make sure there's something there for the cursor to hover.
      else if (value <= 0.1 && value !== 0) {
        return 0.1;
      }

      // Else return value
      return value;
    },
  },
  computed: {
    numberOfAssets() {
      let number = this.assetCardData?.numberOfAssetsCard?.value;

      if (!number) return "0";

      return integerNumberFormatter.format(number);
    },
    getAssetCountBars() {
      const approvedCount = this.assetCardData?.numberOfAssetsApproved?.value;
      const definedCount = this.assetCardData?.numberOfAssetsDefined?.value;
      const undefinedCount = this.assetCardData?.numberOfAssetsUndefined?.value;

      const maxCount = Math.max(
        approvedCount,
        definedCount,
        undefinedCount,
        1 // Avoid dividing by zero
      );

      return [
        {
          label: this.$t("components.cards.titles.defined"),
          renderedValue: definedCount,
          fractionValue: this.barChartCalculateScale(definedCount / maxCount),
          color: "#898989",
          rawValue: definedCount,
        },
        {
          label: this.$t("components.cards.titles.undefined"),
          renderedValue: undefinedCount,
          fractionValue: this.barChartCalculateScale(undefinedCount / maxCount),
          color: "#F25D3B",
          rawValue: undefinedCount,
        },
        {
          label: this.$t("components.cards.titles.verified"),
          renderedValue: approvedCount,
          fractionValue: this.barChartCalculateScale(approvedCount / maxCount),
          color: "#266663",
          rawValue: approvedCount,
        },
      ];
    },
    getScopeCountBars() {
      if (this.scopes !== null) {
        const scope1 = this.assetCardData?.scope1Emissions;
        const scope2 = this.assetCardData?.scope2Emissions;
        const scope3 = this.assetCardData?.scope3Emissions;

        let maxCount = Math.max(
          scope1?.value ?? 0,
          scope2?.value ?? 0,
          scope3?.value ?? 0,
          1 // Avoid dividing by zero
        );

        return [
          {
            label: "Scope 1",
            renderedValue:
              this.formatCurrency(scope1?.value ?? 0) +
              " " +
              this.$t("common.units.kgCO2e"),
            fractionValue: this.barChartCalculateScale(
              scope1?.value / maxCount
            ),
            color: "#266663",
            rawValue: scope1?.value,
          },
          {
            label: "Scope 2",
            renderedValue:
              this.formatCurrency(scope2?.value ?? 0) +
              " " +
              this.$t("common.units.kgCO2e"),
            fractionValue: this.barChartCalculateScale(
              scope2?.value / maxCount
            ),
            color: "#6493BE",
            rawValue: scope2?.value,
          },
          {
            label: "Scope 3",
            renderedValue:
              this.formatCurrency(scope3?.value) +
              " " +
              this.$t("common.units.kgCO2e"),
            fractionValue: this.barChartCalculateScale(
              scope3?.value / maxCount
            ),
            color: "#E35E3E",
            rawValue: scope3?.value,
          },
        ];
      }

      return [];
    },
    totalSpentAmount() {
      // get amount from assetCardData
      let total = this.assetCardData?.totalAmountSpent?.value ?? 0;

      return currencyFormatter.format(total);
    },
    totalSpentAmountUnit() {
      return this.assetCardData?.totalAmountSpent?.unit;
    },
    treesToOffset() {
      let totalCo2 = this.assetCardData?.totalCo2UsedCard?.value;

      if (!totalCo2) return "0";

      const trees = Math.ceil(totalCo2 / 25)?.toString();

      return integerNumberFormatter.format(trees);
    },
    totalCo2Used() {
      let totalCo2 = this.assetCardData?.totalCo2UsedCard?.value ?? 0;
      return this.formatEmissions(totalCo2);
    },
  },
};
</script>

<style lang="scss" scoped></style>
