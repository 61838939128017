<template>
  <dialog-form-input-wrapper :title="title">
    <div class="d-flex flex-row">
      <img
        v-if="previewImageSrc"
        width="100px"
        height="100px"
        class="previewImage"
        :src="previewImageSrc"
      />
      <ImageUploadPlaceholder
        v-else
        class="previewImage"
      ></ImageUploadPlaceholder>
      <div class="px-6 py-4">
        <div class="pb-2 align-center">
          <v-btn
            large
            class="text-none align-self-stretch align-self-sm-center mt-5 mt-sm-0 primary--text mr-3"
            elevation="0"
            tile
            color="#FEEFEB"
            @click="$refs.imageSelect.$refs.input.click()"
            >{{ buttonText }}</v-btn
          >
          <v-btn v-if="value" icon @click="$emit('clickClearFile')">
            <v-icon dark> mdi-trash-can-outline </v-icon>
          </v-btn>
        </div>
        <p v-if="value" class="text-caption mb-0">
          {{ selectedFileName }}
        </p>
        <p v-else class="text-caption mb-0">
          {{ subText }}
        </p>
      </div>
    </div>
    <v-file-input
      ref="imageSelect"
      class="d-none"
      v-model="valueProxy"
      :rules="rules"
      accept="image/png, image/jpeg, image/bmp"
      name="file"
      :placeholder="$t('pages.settings.index.fields.logo.placeholder')"
      :error-messages="errorMessages"
    >
    </v-file-input>
  </dialog-form-input-wrapper>
</template>

<script>
import DialogFormInputWrapper from "./DialogFormInputWrapper.vue";
import ImageUploadPlaceholder from "../../../assets/svg/image-upload-placeholder.svg";

export default {
  components: { DialogFormInputWrapper, ImageUploadPlaceholder },
  props: {
    title: String,
    previewImageSrc: String,
    value: File,
    errorMessages: Array,
    rules: Array,
    buttonText: String,
    subText: String,
  },
  data() {
    return {};
  },
  computed: {
    valueProxy: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("change", value);
      },
    },
    selectedFileName() {
      return this.value?.name;
    },
  },
  watch: {},
};
</script>

<style lang="scss" scoped>
.previewImage {
  width: 100px;
  height: 100px;
  object-fit: contain;
  background: #f7f7f7;
  padding: 2px;
}
</style>
