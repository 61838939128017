<script>
import debounce from "lodash/debounce";

import { EventBus } from "../eventBus.js";

export default {
  data() {
    return {
      debouncedUpdate: null,
    };
  },
  methods: {
    syncDateRange(update) {
      this.debouncedUpdate = debounce((dateRange) => {
        update(dateRange);
      }, 150);

      // Get global date range
      const getDateRange = JSON.parse(localStorage.getItem("globalDateRange"));
      if (getDateRange) {
        this.debouncedUpdate(getDateRange);
      }
      EventBus.$on("globalDateRangeChanged", this.debouncedUpdate);
    },
  },
  beforeDestroy() {
    EventBus.$off("globalDateRangeChanged", this.debouncedUpdate);
  },
};
</script>
