import dashboard from "./dashboard.json";
import assetIndex from "./assets/index.json";
import assetForm from "./assets/form.json";
import assetAttributes from "./assets/attributes.json";
import assetExport from "./assets/export.json";
import assetFilters from "./assets/filters.json";
import assetImport from "./assets/import.json";
import assetLayoutOptions from "./assets/layoutOptions.json";
import attributeIndex from "./attributes/index.json";
import attributeForm from "./attributes/form.json";
import attributeClassIndex from "./attributeClasses/index.json";
import attributeClassForm from "./attributeClasses/form.json";
import login from "./login.json";
import forgotPassword from "./forgotPassword.json";
import resetPassword from "./resetPassword.json";
import documentRequest from "./documentRequest.json";
import featureRequest from "./featureRequest.json";
import fileCreate from "./files/create.json";
import fileEdit from "./files/edit.json";
import fileIndex from "./files/index.json";
import logIndex from "./logs/index.json";
import organizationForm from "./organizations/form.json";
import organizationIndex from "./organizations/index.json";
import permissions from "./permissions.json";
import profile from "./profile.json";
import roleForm from "./roles/form.json";
import roleIndex from "./roles/index.json";
import settingsIndex from "./settings/index.json";
import userForm from "./users/form.json";
import userIndex from "./users/index.json";
import reportsIndex from "./reports/index.json";
import reportsCarbonFootprint from "./reports/carbonFootprint.json";
import reportsPrint from "./reports/print.json";
import climateReport from "./reports/climateReport.json";
import audits from "./audits/index.json";

export default {
  dashboard,
  assets: {
    index: assetIndex,
    form: assetForm,
    attributes: assetAttributes,
    export: assetExport,
    filters: assetFilters,
    import: assetImport,
    layoutOptions: assetLayoutOptions,
  },
  attributes: {
    index: attributeIndex,
    form: attributeForm,
  },
  attributeClasses: {
    index: attributeClassIndex,
    form: attributeClassForm,
  },
  login,
  forgotPassword,
  resetPassword,
  documentRequest,
  featureRequest,
  files: {
    create: fileCreate,
    edit: fileEdit,
    index: fileIndex,
  },
  logs: {
    index: logIndex,
  },
  organizations: {
    form: organizationForm,
    index: organizationIndex,
  },
  permissions,
  profile,
  roles: {
    form: roleForm,
    index: roleIndex,
  },
  settings: {
    index: settingsIndex,
  },
  users: {
    form: userForm,
    index: userIndex,
  },
  reports: {
    index: reportsIndex,
    carbonFootprint: reportsCarbonFootprint,
    print: reportsPrint,
    climateReport: climateReport,
  },
  audits,
};
