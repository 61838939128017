<template>
  <div class="activity-indicator">
    <div
      v-for="(line, index) in lines"
      :key="index"
      class="activity-line"
      :style="[line.style, { backgroundColor: color }]"
    ></div>
  </div>
</template>
<script>
export default {
  props: {
    color: { type: String, default: "#78909c" },
  },
  data() {
    return {
      lines: [
        {
          style: {
            opacity: 0.3,
            transform: "rotate(0deg) translateX(0) translateY(8px) scaleY(1)",
          },
        },
        {
          style: {
            opacity: 0.3,
            transform: "rotate(45deg) translateX(0) translateY(8px) scaleY(1)",
          },
        },
        {
          style: {
            opacity: 0.3,
            transform: "rotate(90deg) translateX(0) translateY(8px) scaleY(1)",
          },
        },
        {
          style: {
            opacity: 0.3,
            transform: "rotate(135deg) translateX(0) translateY(8px) scaleY(1)",
          },
        },
        {
          style: {
            opacity: 0.3,
            transform: "rotate(180deg) translateX(0) translateY(8px) scaleY(1)",
          },
        },
        {
          style: {
            opacity: 0.3,
            transform: "rotate(225deg) translateX(0) translateY(8px) scaleY(1)",
          },
        },
        {
          style: {
            opacity: 0.3,
            transform: "rotate(270deg) translateX(0) translateY(8px) scaleY(1)",
          },
        },
        {
          style: {
            opacity: 0.3,
            transform: "rotate(315deg) translateX(0) translateY(8px) scaleY(1)",
          },
        },
      ],
    };
  },
  mounted() {
    setInterval(() => {
      this.lines.forEach((line, index) => {
        const delay = (index + 1) * 150;
        line.style.opacity = Math.cos(Date.now() / 300 + delay) / 2 + 0.5;
      });
    }, 50);
  },
};
</script>
<style scoped>
.activity-indicator {
  width: 26px;
  height: 26px;
  position: relative;
}
.activity-line {
  height: 4px;
  width: 1px;
  border-radius: 2px;
  position: absolute;
  left: calc(50% - 1px);
  top: calc(50% - 2.5px);
}
</style>
