<template>
  <div class="d-flex flex-row pb-10">
    <div>
      <v-list-item-avatar
        :color="iconBackColor"
        width="72px"
        height="72px"
        class="d-flex align-center justify-center items-center"
      >
        <slot name="icon"></slot>
      </v-list-item-avatar>
    </div>
    <div class=" ">
      <div class="value-title">
        {{ title }}
      </div>
      <div class="value-percent">
        <span v-if="percentagePrefix">{{ percentagePrefix }}</span
        >&nbsp;{{ percentValue }} %
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    iconBackColor: String,
    percentValue: Number,
    percentagePrefix: String || undefined,
    title: String,
  },
};
</script>

<style lang="scss" scoped>
.value-title {
  font-size: 15px;
  color: #686868;
  line-height: 1.3;
  margin-top: 10px;
  min-height: 40px;
  margin-right: -20px !important;
}

.value-percent {
  font-size: 30px;
}
</style>
