var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{
    'outer-switch': true,
    'outer-switch-on': _vm.value,
    'outer-switch-off': !_vm.value,
  },on:{"click":_vm.toggleSwitch}},[_c('div',{class:{
      'inner-switch': true,
      'inner-switch-left': !_vm.value,
      'inner-switch-right': _vm.value,
    }})])
}
var staticRenderFns = []

export { render, staticRenderFns }