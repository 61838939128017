var render = function render(){var _vm=this,_c=_vm._self._c;return _c('dialog-form',{attrs:{"show-dialog":_vm.show,"max-width":"538px","persistent":false},on:{"showStateChanged":(val) => (_vm.show = val)}},[_c('div',{staticClass:"d-flex flex-column px-0 pt-16"},[_c('div',{staticClass:"pb-8 d-flex justify-center"},[_c('LogoBlack')],1),_c('step-visualizer',{ref:"stepVisualizer",staticClass:"pb-8",attrs:{"steps":3,"selected-step":_vm.selectedOnboardingStep},on:{"input":(step) => (_vm.selectedOnboardingStep = step)}}),_c('div',[_c('h1',{staticClass:"text-h4 black--text mb-2 text-center"},[_vm._v(" "+_vm._s(_vm.stepTitle)+" ")]),_c('h1',{staticClass:"text-subtitle-1 grey--text mb-6 font-weight-light text-center"},[_vm._v(" "+_vm._s(_vm.stepSubtitle)+" ")])]),_c('div',{staticClass:"d-flex align-center relative",staticStyle:{"overflow-y":"scroll","overflow-x":"hidden","min-height":"300px"},style:(_vm.selectedOnboardingStep === 3
          ? 'height: calc(100vh - 527px);max-height: 527px;'
          : null)},[_c('Transition',{attrs:{"name":"fade"}},[(_vm.selectedOnboardingStep === 1)?_c('onboarding-step-three',{staticClass:"transition-item",attrs:{"selected-integration":_vm.selectedIntegration,"show-continue":false},on:{"update:selectedIntegration":function($event){_vm.selectedIntegration=$event},"update:selected-integration":function($event){_vm.selectedIntegration=$event},"otherIntegration":_vm.handleOtherIntegration,"updateForm":_vm.updateForm}}):_vm._e(),(_vm.selectedOnboardingStep === 2)?_c('onboarding-step-four',{staticClass:"transition-item",attrs:{"selected-integration":_vm.selectedIntegration}}):(
            _vm.selectedOnboardingStep === 3 &&
            _vm.selectedIntegration === _vm.businessCentralOnPremise
          )?_c('onboarding-step-five',{staticClass:"transition-item",attrs:{"api-token":_vm.apiToken}}):_vm._e(),(
            _vm.selectedOnboardingStep === 3 &&
            _vm.selectedIntegration !== _vm.businessCentralOnPremise
          )?_c('onboarding-step-six',{ref:"account-mapper",staticClass:"transition-item",attrs:{"integrationId":_vm.integrationId}}):_vm._e()],1)],1),(_vm.selectedOnboardingStep == 1)?_c('v-card-actions',{staticClass:"d-flex flex-row footer-section pa-8"},[_c('v-btn',{staticClass:"font-weight-regular text-capitalize white--text",staticStyle:{"flex":"1"},attrs:{"tile":"","elevation":"0","color":"orange","type":"submit","disabled":_vm.isContinueDisabled},on:{"click":function($event){return _vm.moveToNextStep()}}},[_vm._v(" "+_vm._s(_vm.$t("components.onboarding.next"))+" ")])],1):_c('v-card-actions',{staticClass:"d-flex flex-column footer-section pa-8"},[_c('v-btn',{staticClass:"font-weight-regular text-capitalize white--text pa-0 mb-4",attrs:{"tile":"","block":"","elevation":"0","color":"orange","type":"submit","disabled":_vm.isContinueDisabled},on:{"click":function($event){return _vm.moveToNextStep()}}},[_vm._v(" "+_vm._s(_vm.connectSystemButtonText)+" ")]),(
          _vm.selectedOnboardingStep !== 2 &&
          _vm.selectedIntegration !== _vm.businessCentralOnPremise
        )?_c('v-btn',{staticClass:"border-sm text-capitalize black--text black--border white pa-0 ml-0",attrs:{"tile":"","outlined":"","block":"","elevation":"0","bg-color":"white"},on:{"click":function($event){return _vm.hideModal()}}},[_vm._v(" "+_vm._s(_vm.nextStepOtherLabel())+" ")]):_vm._e()],1),_c('integration-dynamics-365-environment-modal',{model:{value:(_vm.showDynamicsIntegration),callback:function ($$v) {_vm.showDynamicsIntegration=$$v},expression:"showDynamicsIntegration"}}),(_vm.showUnicontaIntegration && _vm.unicontaCompanies === null)?_c('integration-uniconta-credentials-modal',{on:{"update:unicontaCompanies":function($event){_vm.unicontaCompanies = $event},"update:unicontaUsername":function($event){_vm.unicontaUsername = $event},"update:unicontaPassword":function($event){_vm.unicontaPassword = $event}},model:{value:(_vm.showUnicontaIntegration),callback:function ($$v) {_vm.showUnicontaIntegration=$$v},expression:"showUnicontaIntegration"}}):(_vm.showUnicontaIntegration && _vm.unicontaCompanies !== null)?_c('integration-uniconta-company-modal',{attrs:{"uniconta-companies":_vm.unicontaCompanies,"uniconta-username":_vm.unicontaUsername,"uniconta-password":_vm.unicontaPassword},model:{value:(_vm.showUnicontaIntegration),callback:function ($$v) {_vm.showUnicontaIntegration=$$v},expression:"showUnicontaIntegration"}}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }