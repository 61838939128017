<template>
  <dialog-form-input-wrapper :title="title" :ai-status="aiStatus">
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :nudge-right="40"
      transition="scale-transition"
      offset-y
      min-width="auto"
      dark
      :disabled="readonly"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          class="rounded-0 mb-1"
          background-color="#F7F7F7"
          solo
          flat
          :label="placeholder"
          single-line
          filled
          :hide-details="true"
          :value="formattedDate"
          readonly
          v-bind="attrs"
          v-on="on"
          prepend-inner-icon="mdi-calendar"
          color="#000"
        ></v-text-field>
      </template>
      <v-date-picker
        v-model="date"
        first-day-of-week="1"
        @input="menu = false"
        scrollable
        :disabled="disabled"
        color="#F25D3B"
        light
      >
      </v-date-picker>
    </v-menu>
  </dialog-form-input-wrapper>
</template>

<script>
import DialogFormInputWrapper from "./DialogFormInputWrapper.vue";
import { addTimezoneOffset, removeTimezoneOffset } from "@/util/dateTime";

export default {
  components: { DialogFormInputWrapper },
  props: {
    title: String,
    placeholder: String,
    aiStatus: String,
    value: Date,
    disabled: Boolean,
    readonly: Boolean,
    dateFormat: String,
  },
  data() {
    return {
      menu: false,
      date:
        typeof this.value !== "undefined" &&
        this.value !== null &&
        !isNaN(new Date(this.value))
          ? removeTimezoneOffset(new Date(this.value))
              .toISOString()
              .split("T")[0]
          : "",
    };
  },
  computed: {
    formattedDate() {
      if (this.date) {
        if (this.dateFormat === "YYYY-MM-DD") {
          return this.date;
        } else if (this.dateFormat === "DD-MM-YYYY") {
          const [year, month, day] = this.date.split("-");
          return `${day}-${month}-${year}`;
        } else {
          return this.date;
        }
      }
      return "";
    },
  },
  watch: {
    date(to) {
      this.$emit("change", addTimezoneOffset(new Date(to)));
    },
    value(to) {
      this.date =
        typeof to !== "undefined" && to !== null && !isNaN(new Date(this.value))
          ? removeTimezoneOffset(new Date(to)).toISOString().split("T")[0]
          : "";
    },
  },
};
</script>

<style lang="scss" scoped></style>
