<template>
  <v-tab-item :value="tabValue">
    <attribute-link-input
      v-model="emissionSource"
      :attribute="emissionSourceAttribute"
      @input="handleEmissionSourceChanged"
    />
    <asset-rule-fixed-input
      v-if="emissionSource"
      v-model="ghgCategory"
      :items="filteredGhgCategories"
      :label="$t('pages.settings.assetRuleForm.stepTwo.fields.ghgCategory')"
    />
    <attribute-link-input
      v-model="calculationMethod"
      :attribute="calculationMethodAttribute"
      @input="handleCalculationMethodChanged"
    />
    <asset-rule-fixed-input
      v-model="sector"
      :items="sectors"
      :label="$t('pages.settings.assetRuleForm.stepTwo.fields.sector')"
      @input="handleSectorChanged"
    />
    <asset-rule-fixed-input
      v-model="category"
      :items="categories"
      :label="$t('pages.settings.assetRuleForm.stepTwo.fields.category')"
      @input="handleCategoryChanged"
    />
    <template v-if="!isSupplierSpecificMethod">
      <asset-rule-fixed-input
        v-model="region"
        :items="regions"
        :item-text="'countryName'"
        :label="$t('pages.settings.assetRuleForm.stepTwo.fields.region')"
        @input="handleRegionChanged"
      />
      <asset-rule-fixed-input
        v-model="emissionFactorName"
        :items="emissionFactors"
        :label="
          $t('pages.settings.assetRuleForm.stepTwo.fields.emissionFactorName')
        "
      >
        <template #selectItem="{ item }">
          <div class="d-flex flex-column">
            <div>{{ item.name }} {{ getFactorVersionLabel(item) }}</div>
            <div class="grey--text text--lighten-2 caption">
              {{ getEmissionsForFactor(item) }}
            </div>
          </div>
        </template>
      </asset-rule-fixed-input>
    </template>
    <template v-else>
      <attribute-link-input
        :value="emissions"
        :attribute="emissionsAttribute"
        @decimal_changed="
          $emit('setDynamicValue', emissionsAttribute.id, $event)
        "
      />
      <attribute-link-input
        v-model="documentation"
        :attribute="documentationAttribute"
      />
    </template>
  </v-tab-item>
</template>
<script>
import AssetRuleFixedInput from "../AssetRuleFixedInput.vue";
import AttributeLinkInput from "../../../Components/Attribute/AttributeLinkInput.vue";

import {
  assetRuleActionProperty,
  co2MethodOptions,
  fixedAttributes,
} from "../../../util/fixedAssetData";
import { formatEmissionFactorName } from "../../../util/formatters";

export default {
  components: {
    AssetRuleFixedInput,
    AttributeLinkInput,
  },
  props: {
    tabValue: Number,
    initialSectors: Array,
    attributes: Array,

    rerenderCounter: Number,

    reloadEmissionDropdowns: Boolean,

    getFixedValue: Function,
    getDynamicValue: Function,

    ghgCategories: Array,

    unitType: String,
  },
  emits: ["setDynamicValue", "setFixedValue", "emissionDropdownsReloaded"],
  data() {
    return {
      sectors: this.initialSectors ?? [],
      categories: [],
      regions: [],
      emissionFactors: [],
    };
  },
  computed: {
    emissionSourceAttribute() {
      return this.getAttribute(fixedAttributes.co2EmissionSourceId);
    },
    calculationMethodAttribute() {
      return this.getAttribute(fixedAttributes.co2MethodId);
    },
    emissionsAttribute() {
      return this.getAttribute(fixedAttributes.co2EmissionsId);
    },
    documentationAttribute() {
      return this.getAttribute(fixedAttributes.co2DocumentationId);
    },
    emissionSource: {
      get() {
        return this.getDynamicValue(this.emissionSourceAttribute.id);
      },
      set(value) {
        this.$emit("setDynamicValue", this.emissionSourceAttribute.id, value);
      },
    },
    calculationMethod: {
      get() {
        return this.getDynamicValue(this.calculationMethodAttribute.id);
      },
      set(value) {
        this.$emit(
          "setDynamicValue",
          this.calculationMethodAttribute.id,
          value
        );
      },
    },
    emissions() {
      this.rerenderCounter;

      return this.getDynamicValue(this.emissionsAttribute.id);
    },
    documentation: {
      get() {
        return this.getDynamicValue(this.documentationAttribute.id);
      },
      set(value) {
        this.$emit("setDynamicValue", this.documentationAttribute.id, value);
      },
    },
    ghgCategory: {
      get() {
        return this.getFixedValue(assetRuleActionProperty.ghgCategory);
      },
      set(value) {
        this.$emit("setFixedValue", assetRuleActionProperty.ghgCategory, value);
      },
    },
    sector: {
      get() {
        return this.getFixedValue(assetRuleActionProperty.sector);
      },
      set(value) {
        this.$emit("setFixedValue", assetRuleActionProperty.sector, value);
      },
    },
    category: {
      get() {
        return this.getFixedValue(assetRuleActionProperty.category);
      },
      set(value) {
        this.$emit("setFixedValue", assetRuleActionProperty.category, value);
      },
    },
    region: {
      get() {
        return this.getFixedValue(assetRuleActionProperty.region);
      },
      set(value) {
        this.$emit("setFixedValue", assetRuleActionProperty.region, value);
      },
    },
    emissionFactorName: {
      get() {
        return this.getFixedValue(assetRuleActionProperty.emissionFactorName);
      },
      set(value) {
        this.$emit(
          "setFixedValue",
          assetRuleActionProperty.emissionFactorName,
          value
        );
      },
    },
    isSpendBasedMethod() {
      return this.calculationMethod === co2MethodOptions.spendBasedMethodId;
    },
    isSupplierSpecificMethod() {
      return (
        this.calculationMethod === co2MethodOptions.supplierSpecificMethodId
      );
    },
    selectedCalculationMethod() {
      return this.getCalculationMethodIdentifier(this.calculationMethod);
    },
    filteredGhgCategories() {
      if (!this.emissionSource) return [];

      const categories = this.ghgCategories
        .filter((x) => x.scopeId === this.emissionSource)
        .sort((a, b) => ((a.sortOrder ?? 0) > (b.sortOrder ?? 0) ? 1 : -1));

      return categories;
    },
    emissionFactor() {
      if (!this.emissionFactorName) return null;

      return this.emissionFactors.find((x) => x.id === this.emissionFactorName);
    },
  },
  methods: {
    getEmissionsForFactor(factor) {
      let kgCO2e = factor.kgCo2eAr6;

      if (!kgCO2e) kgCO2e = factor.kgCo2eAr5;
      if (!kgCO2e) kgCO2e = factor.kgCo2eAr4;
      if (!kgCO2e) kgCO2e = 0;

      return (
        kgCO2e + "" + this.$t("common.units.kgCO2e") + "/" + factor.activityUnit
      );
    },
    handleSectorChanged(sectorId, preserveValues = false) {
      if (!preserveValues) {
        this.category = null;
        this.region = null;
        this.emissionFactorName = null;
      }

      this.categories = [];
      this.regions = [];
      this.emissionFactors = [];

      if (!sectorId) return;

      this.loading = true;

      return fetch(
        this.route("api.emission-categories.index", {
          emissionSectorId: sectorId,
          unitType: this.unitType,
          calculationMethod: this.selectedCalculationMethod,
        })
      )
        .then((res) => res.json())
        .then((data) => (this.categories = data))
        .finally(() => (this.loading = false));
    },
    handleCategoryChanged(categoryId, preserveValues = false) {
      if (!preserveValues) {
        this.region = null;
        this.emissionFactorName = null;
      }

      this.regions = [];
      this.emissionFactors = [];

      if (!categoryId) return;

      this.loading = true;

      return fetch(
        this.route("api.emission-regions.index", {
          emissionCategoryId: categoryId,
          unitType: this.unitType,
          spendBased: this.isSpendBasedMethod,
        })
      )
        .then((res) => res.json())
        .then((data) => (this.regions = data))
        .finally(() => (this.loading = false));
    },
    handleRegionChanged(regionId, preserveValues = false) {
      if (!preserveValues) {
        this.emissionFactorName = null;
      }

      this.emissionFactors = [];

      if (!regionId) return;

      this.loading = true;

      return fetch(
        this.route("api.emission-factors.index", {
          emissionSectorId: this.sector,
          emissionCategoryId: this.category,
          emissionRegionId: regionId,
          emissionFactorId: this.emissionFactorName,
          unitType: this.unitType,
          spendBased: this.isSpendBasedMethod,
        })
      )
        .then((res) => res.json())
        .then(
          (data) =>
            (this.emissionFactors = data.map((x) => ({
              ...x,
              name: formatEmissionFactorName(x),
            })))
        )
        .finally(() => (this.loading = false));
    },
    handleCalculationMethodChanged(calculationMethod) {
      this.sector = null;
      this.category = null;
      this.region = null;
      this.emissionFactorName = null;

      this.sectors = [];
      this.categories = [];
      this.regions = [];
      this.emissionFactors = [];

      this.loading = true;

      return fetch(
        this.route("api.emission-sectors.index", {
          calculationMethod:
            this.getCalculationMethodIdentifier(calculationMethod),
          unitType: this.unitType,
        })
      )
        .then((res) => res.json())
        .then((data) => (this.sectors = data))
        .finally(() => (this.loading = false));
    },
    handleEmissionSourceChanged() {
      this.ghgCategory = null;
    },
    getAttribute(attributeId) {
      return this.attributes.find((x) => x.id === attributeId);
    },
    async reloadEmissionDropdownData(preserveValues = true) {
      try {
        if (!this.sector) return;

        await this.handleSectorChanged(this.sector, preserveValues);

        if (!this.category) return;

        await this.handleCategoryChanged(this.category, preserveValues);

        if (!this.region) return;

        await this.handleRegionChanged(this.region, preserveValues);
      } finally {
        this.$emit("emissionDropdownsReloaded");
      }
    },
    getFactorVersionLabel(factor) {
      const {
        activityId,
        activityUnit,
        lcaActivity,
        yearReleased,
        yearsCalculatedFrom,
      } = this.emissionFactor ?? {};

      if (
        factor.activityId !== activityId ||
        factor.activityUnit !== activityUnit ||
        factor.lcaActivity !== lcaActivity
      )
        return "";

      const year = yearsCalculatedFrom ?? yearReleased;
      const factorYear = factor.yearsCalculatedFrom ?? factor.yearReleased;

      const labelText =
        factorYear > year
          ? this.$t("common.statuses.emissionFactor.version.updated")
          : this.$t("common.statuses.emissionFactor.version.outdated");

      return `(${labelText})`;
    },
    getCalculationMethodIdentifier(calculationMethod) {
      if (calculationMethod === co2MethodOptions.spendBasedMethodId) {
        return "spend";
      }

      if (calculationMethod === co2MethodOptions.supplierSpecificMethodId) {
        return "supplier";
      }

      return "average";
    },
  },
  watch: {
    reloadEmissionDropdowns(value) {
      if (!value) return;

      this.reloadEmissionDropdownData();
    },
    unitType(value) {
      const preserveValues = value === null;

      fetch(
        this.route("api.emission-sectors.index", {
          calculationMethod: this.getCalculationMethodIdentifier(
            this.calculationMethod
          ),
          unitType: this.unitType,
        })
      )
        .then((res) => res.json())
        .then((data) => {
          this.sectors = data;

          this.reloadEmissionDropdownData(preserveValues);
        })
        .finally(() => (this.loading = false));
    },
  },
  mounted() {
    if (this.reloadEmissionDropdowns) {
      this.reloadEmissionDropdownData();
    }
  },
};
</script>
