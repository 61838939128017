<template>
  <asset-form
    v-model="show"
    :asset="asset"
    :attributeClasses="attributeClasses"
    :attributes="attributes"
    :currencies="currencies"
    :ghgCategories="ghgCategories"
    :organizationId="currentOrganizationId"
    :defaultCurrencyCode="defaultCurrencyCode"
    @clear="asset = null"
    @reload="(assetId) => $emit('reloadAsset', assetId)"
    @deleteAsset="(assetId) => $emit('deleteAsset', assetId)"
    :settings="settings"
    :clearFormOnSave="false"
  >
  </asset-form>
</template>

<script>
import AssetForm from "../Asset/Form.vue";
import { fixedAttributes } from "@/util/fixedAssetData";
import { fixedClasses } from "../../util/fixedAssetData";

export default {
  components: { AssetForm },
  props: {
    assetId: String,
    attributes: Array,
    currencies: Array,
    ghgCategories: Array,
    attributeClasses: Array,
  },
  data() {
    return {
      asset: null,

      loadingAsset: false,

      show: false,

      loadingOptions: true,
    };
  },
  computed: {
    settings() {
      return this.$page.props.auth.settings;
    },
    defaultCurrencyCode() {
      const currency = this.currencies.find(
        (x) => x.id === this.settings.defaultCurrencyId
      );

      return currency?.currencyCode ?? "";
    },
    currentOrganizationId() {
      return this.settings.organizationId;
    },
  },
  watch: {
    show(to) {
      if (to === false) {
        this.$emit("clear-asset-id");
      }
    },
    assetId(to) {
      if (to === "") {
        this.asset = null;
        return null;
      }

      // On asset id change fetch asset for id
      this.loadingAsset = true;

      fetch(this.route("api.assets.get", to))
        .then((res) => res.json())
        .then((data) => {
          this.asset = this.formatAsset(data);
        })
        .finally(() => {
          this.loadingAsset = false;
          this.show = true;
        });
    },
  },
  methods: {
    formatAsset(asset) {
      let mappedAsset = {
        ...asset,
      };
      if (asset.assetAttributeLinks !== null) {
        asset.assetAttributeLinks.forEach((assetAttributeLink) => {
          mappedAsset[assetAttributeLink.attributeId] = assetAttributeLink;
        });
      }

      this.setMissingAssetName(mappedAsset);

      return mappedAsset;
    },
    setMissingAssetName(mappedAsset) {
      const name = mappedAsset[fixedAttributes.generalNameId];
      const entryText = mappedAsset[fixedAttributes.infoEntryTextId];

      if (!name && !!entryText) {
        if (!mappedAsset[fixedAttributes.generalNameId]) {
          mappedAsset[fixedAttributes.generalNameId] = {
            assetId: mappedAsset.id,
            attributeId: fixedAttributes.generalNameId,
            stringValue: entryText?.stringValue,
            searchString: entryText?.stringValue?.toUpperCase(),
            attributeClassId: fixedClasses.generalId,
            attributeClass: {
              id: fixedClasses.generalId,
              fixed: true,
              referenceKey: -1,
            },
            attribute: {
              format: "Text",
              inFixedClass: true,
              id: fixedAttributes.generalNameId,
              stringValue: entryText?.stringValue,
              name: "Asset name",
              referenceKey: -7,
            },
          };
        } else {
          mappedAsset[fixedAttributes.generalNameId].stringValue =
            entryText?.stringValue;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
