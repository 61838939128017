<template>
  <div class="page-container fill-height grow d-flex flex-column flex-nowrap">
    <vue-html2pdf
      :show-layout="false"
      :enable-download="true"
      :preview-modal="false"
      :pdf-quality="2"
      :manual-pagination="true"
      pdf-format="a4"
      pdf-orientation="landscape"
      ref="html2Pdf"
      pdf-content-width="100%"
      :filename="pdfFileName"
    >
      <section slot="pdf-content">
        <img v-for="(page, index) in output" :key="index" :src="page" />
      </section>
    </vue-html2pdf>
    <div class="print-view-container">
      <div class="print-view d-flex flex-column flex-nowrap" ref="printMe">
        <component
          :is="templateName"
          :organization="organization"
          :dateRange="generateReportDateRange"
          :data="reportData"
          :partner="getPartnerInfo"
          :totalNumberOfPages="totalNumberOfPages"
          :defaultDateFormat="auth.settings.defaultDateFormat"
        ></component>
      </div>
    </div>
    <dialog-form
      :showDialog="showPreview"
      @showStateChanged="(val) => (showPreview = val)"
    >
      <dialog-form-header :title="pdfFileName + '.pdf'"> </dialog-form-header>
      <dialog-form-section-one-col>
        <div class="py-3">
          <img v-for="(page, index) in output" :key="index" :src="page" />
        </div>
      </dialog-form-section-one-col>
      <dialog-form-footer>
        <template #right>
          <v-btn
            class="text-none ml-md-4 px-6 align-self-stretch"
            color="#266663"
            dark
            elevation="0"
            tile
            x-large
            @click="downloadReport()"
          >
            <v-icon class="white--text mr-2">mdi-download</v-icon>
            <p class="white--text mb-0">
              {{ $t("common.actions.download") }}
            </p>
          </v-btn></template
        >
      </dialog-form-footer>
    </dialog-form>
    <v-container fluid class="py-4">
      <v-row class="px-2">
        <v-col cols="12" class="px-2 pt-0">
          <div class="table-filter d-flex flex-row pa-4">
            <v-col cols="3">
              <v-text-field
                :label="$t('pages.reports.index.list.search')"
                color="#686868"
                class="search-field rounded-0 pt-0"
                height="44"
                light
                hide-details="auto"
                background-color="#F7F7F7"
                filled
                solo
                dense
                prepend-inner-icon="mdi-magnify"
                flat
              ></v-text-field>
            </v-col>
            <v-spacer></v-spacer>
            <div>
              <v-btn
                large
                class="align-self-stretch align-self-sm-center mt-5 mt-sm-0 text-none"
                elevation="0"
                tile
                color="#F25D3B"
                dark
                @click="showModal = true"
              >
                <v-icon class="pr-3 block" color="#fff" small>mdi-plus</v-icon>
                {{ $t("pages.reports.index.create.label") }}
              </v-btn>
            </div>
          </div>
          <v-data-table
            class="report-table"
            :headers="headers"
            :header-props="{ 'sort-icon': 'mdi-unfold-more-horizontal' }"
            :items="reports"
          >
            <template #item.dateRange="{ item }">
              <div class="d-flex flex-row align-center">
                {{ item.dateRange.from }}
                <ArrowRightIcon class="date-range-arrow mx-2"></ArrowRightIcon>
                {{ item.dateRange.to }}
              </div>
            </template>
            <template #item.actions="{ item }">
              <div class="d-flex align-center">
                <div class="flex-grow-1 mr-4">
                  <v-btn
                    text
                    class="text-none font-weight-regular orange--text px-2"
                    @click="generateReport(item.id)"
                    :loading="downloadingReport === item.id"
                    :disabled="downloadingReport !== null"
                  >
                    <DownloadIcon class="mr-2"></DownloadIcon>
                    {{ $t("pages.reports.index.list.downloadReport") }}</v-btn
                  >
                </div>

                <v-menu offset-y min-width="120px" class="px-4" left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      light
                      plain
                      small
                      icon
                      elevation="0"
                      class="px-0 mx-0"
                      ><v-icon>mdi-dots-vertical</v-icon></v-btn
                    >
                  </template>
                  <v-list>
                    <v-list-item @click="downloadExcel(item.id)">
                      <v-list-item-title>
                        {{ $t("pages.reports.index.list.downloadExcel") }}
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="deleteReport(item.id)">
                      <v-list-item-title>
                        {{ $t("common.actions.delete") }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </template>
            <template #item.createdBy="{ item }">
              <div class="d-flex align-center">
                <img
                  class="mr-4 rounded-circle"
                  width="32px"
                  height="32px"
                  :src="profilePictureUrl(item.createdBy)"
                />
                <div>{{ item.createdBy }}</div>
              </div>
            </template>
            <template #no-data>
              <div v-if="fetchingData" class="d-flex justify-center">
                <ActivityIndicator class=""></ActivityIndicator>
              </div>
              <div v-else class="py-16 d-flex align-center flex-column">
                <div class="mb-8"><ReportTable></ReportTable></div>
                <div class="mb-8 font-bold text-h5 black--text">
                  {{ $t("pages.reports.index.list.noReportsYet") }} <br />{{
                    $t("pages.reports.index.list.startByClickingButton")
                  }}
                </div>
                <div>
                  <v-btn
                    large
                    class="align-self-stretch align-self-sm-center mt-5 mt-sm-0 text-none"
                    elevation="0"
                    tile
                    color="#F25D3B"
                    dark
                    @click="showModal = true"
                    >{{
                      $t("pages.reports.index.create.createNewReport")
                    }}</v-btn
                  >
                </div>
              </div>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog
      v-model="showModal"
      :max-width="showMoreOptions ? '884px' : '484px'"
      persistent
    >
      <v-card>
        <dialog-form-header
          :title="$t('pages.reports.index.createReport')"
          :onDismiss="() => (showModal = false)"
        ></dialog-form-header>
        <p class="px-6 py-3 font-weight-medium grey--text">
          {{ $t("pages.reports.index.create.settingReport") }}
        </p>
        <div class="d-flex flex-row pb-10">
          <date-range-picker
            class="date-range-picker"
            :fromDate="generateReportDateRange.from"
            :toDate="generateReportDateRange.to"
            @change="(date) => setDateRange(date)"
            :defaultDateFormat="auth.settings.defaultDateFormat"
          >
            <dialog-form-date-selector
              :value="new Date(generateReportDateRange.from)"
              class="pl-2 pr-3"
              :title="$t('pages.reports.index.reportFrom')"
              readonly
              :dateFormat="auth.settings.defaultDateFormat"
            ></dialog-form-date-selector>
            <dialog-form-date-selector
              :value="new Date(generateReportDateRange.to)"
              class="pr-2 pl-3"
              :title="$t('pages.reports.index.reportTo')"
              readonly
              :dateFormat="auth.settings.defaultDateFormat"
            ></dialog-form-date-selector>
          </date-range-picker>
        </div>
        <div class="d-flex flex-column pb-5 px-6">
          <v-checkbox
            hide-details
            v-model="reportData.includeStatistics"
            class="mt-2"
          >
            <template v-slot:label>
              <span style="font-size: 0.875rem">
                {{ $t("pages.reports.index.create.includeStatistics") }}
              </span>
            </template>
          </v-checkbox>
          <v-checkbox
            hide-details
            v-model="reportData.includeEmissionFactorsAppendix"
            class="mt-2"
          >
            <template v-slot:label>
              <span style="font-size: 0.875rem">
                {{
                  $t("pages.reports.index.create.includeEmissionFactorAppendix")
                }}
              </span>
            </template>
          </v-checkbox>
          <a
            class="mt-4"
            @click="showMoreOptions = !showMoreOptions"
            style="font-size: 0.875rem"
          >
            {{
              showMoreOptions
                ? $t("pages.reports.index.create.lessOptions")
                : $t("pages.reports.index.create.moreOptions")
            }}
          </a>
        </div>
        <div v-if="showMoreOptions" class="d-flex flex-column pb-10 px-6">
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left" width="auto">
                    {{ $t("pages.reports.index.create.ghgCategories") }}
                  </th>
                  <th class="text-left text-no-wrap" width="20%">
                    {{ $t("pages.reports.index.create.include") }}
                  </th>
                  <th class="text-left text-no-wrap" width="20%">
                    {{ $t("pages.reports.index.create.notRelevant") }}
                  </th>
                  <th class="text-left text-no-wrap" width="20%">
                    {{ $t("pages.reports.index.create.notCalculated") }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Scope 1</td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr
                  v-for="key in Object.keys(includedGhgCategories).filter(
                    (key) =>
                      includedGhgCategories[key].scopeId ===
                      'cb483bd8-d20e-42a1-a252-c44ee12230ef'
                  )"
                  :key="key"
                  class="table-row"
                >
                  <td>
                    {{ $t(`components.cards.ghgCategories.categories.${key}`) }}
                  </td>
                  <td v-for="n in 3" :key="n">
                    <v-radio-group
                      class="my-1"
                      row
                      v-model="includedGhgCategories[key].value"
                      hide-details
                    >
                      <v-radio :value="n"></v-radio>
                    </v-radio-group>
                  </td>
                </tr>
                <tr>
                  <td>Scope 2</td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr
                  v-for="key in Object.keys(includedGhgCategories).filter(
                    (key) =>
                      includedGhgCategories[key].scopeId ===
                      '6045ba53-b1c4-4ac0-a4be-7d4e4acc9f2b'
                  )"
                  :key="key"
                  class="table-row"
                >
                  <td>
                    {{ $t(`components.cards.ghgCategories.categories.${key}`) }}
                  </td>
                  <td v-for="n in 3" :key="n">
                    <v-radio-group
                      class="my-1"
                      row
                      v-model="includedGhgCategories[key].value"
                      hide-details
                    >
                      <v-radio :value="n"></v-radio>
                    </v-radio-group>
                  </td>
                </tr>
                <tr>
                  <td>Scope 3</td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr
                  v-for="key in scope3GhgEmissions"
                  :key="key"
                  class="table-row"
                >
                  <td>
                    {{ $t(`components.cards.ghgCategories.categories.${key}`) }}
                  </td>
                  <td v-for="n in 3" :key="n">
                    <v-radio-group
                      class="my-1"
                      row
                      v-model="includedGhgCategories[key].value"
                      hide-details
                    >
                      <v-radio :value="n"></v-radio>
                    </v-radio-group>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
        <dialog-form-footer>
          <template #right>
            <v-spacer></v-spacer>
            <v-btn
              class="text-none px-4 mb-4 mb-md-0 align-self-stretch"
              color="#686868"
              large
              elevation="0"
              text
              tile
              @click="showModal = false"
              x-large
              ><!--<v-icon class="mr-3" dark>mdi-arrow-left</v-icon>-->{{
                $t("common.actions.cancel")
              }}
            </v-btn>
            <v-btn
              class="text-none white--text ml-0 mb-4 mb-md-0 ml-md-4 px-6 align-self-stretch"
              large
              color="#F25D3B"
              elevation="0"
              tile
              x-large
              @click="saveReport()"
              :disabled="fetchingData"
              :loading="fetchingData"
              >{{ $t("common.actions.generate") }}</v-btn
            >
          </template>
        </dialog-form-footer>
      </v-card>
    </v-dialog>
    <confirm-dialog
      v-model="showDeleteConfirmDialog"
      v-show="showDeleteConfirmDialog"
      :confirm-message="$t('pages.reports.index.delete.confirmDestroyMessage')"
      @confirmed="handleDeleteConfirmation"
    ></confirm-dialog>
  </div>
</template>

<script>
import { appLayout } from "@/util/layout";
import ReportTable from "../../assets/svg/report-table.svg";
import DialogFormHeader from "../../Components/Dialog/DialogFormHeader.vue";
import DialogFormDateSelector from "../../Components/Dialog/inputs/DialogFormDateSelector.vue";
import DialogFormFooter from "../../Components/Dialog/DialogFormFooter.vue";
import VueHtml2pdf from "vue-html2pdf";
import ClimateReport from "../../Components/ReportTemplates/ClimateReport.vue";
import DateRangePicker from "../../Components/Inputs/DateRangePicker.vue";
import { DateTime } from "luxon";
import ActivityIndicator from "../../Components/Outputs/ActivityIndicator.vue";
import ArrowRightIcon from "../../assets/svg/arrow-right.svg";
import DownloadIcon from "../../assets/svg/download.svg";
import ConfirmDialog from "../../Components/ConfirmDialog.vue";
import Vue from "vue";
import DialogFormSectionOneCol from "../../Components/Dialog/DialogFormSectionOneCol.vue";
import DialogForm from '../../Components/Dialog/DialogForm.vue';

/*const _scope1 = "cb483bd8-d20e-42a1-a252-c44ee12230ef";
const _scope2 = "6045ba53-b1c4-4ac0-a4be-7d4e4acc9f2b";
const _scope3 = "6d9ea628-4dcf-4d77-ad8b-7e8ea4d72dde";*/

export default {
  components: {
    ReportTable,
    DialogFormHeader,
    DialogFormDateSelector,
    DialogFormFooter,
    VueHtml2pdf,
    ClimateReport,
    DateRangePicker,
    ActivityIndicator,
    ArrowRightIcon,
    DownloadIcon,
    ConfirmDialog,
    DialogFormSectionOneCol,
    DialogForm,
  },
  layout: appLayout({ title: "pages.reports.index.title" }),
  props: {
    auth: Object,
  },
  data() {
    return {
      templateRoute: "climate-report",
      output: [],
      headers: [
        {
          text: this.$t("pages.reports.index.list.selectTimeframe"),
          align: "start",
          value: "dateRange",
        },
        {
          text: this.$t("pages.reports.index.list.reportCreatedAt"),
          sortable: true,
          value: "createdAt",
        },
        {
          text: this.$t("pages.reports.index.list.generatedBy"),
          value: "createdBy",
        },
        {
          text: this.$t("pages.reports.index.list.actions"),
          value: "actions",
          align: "end",
          class: "actions-column",
        },
      ],
      reports: [],
      showModal: false,
      reportData: {
        // Overview
        assetCardData: {},
        emissionsPerMonthCurrentYear: [],
        emissionsPerMonthLastYear: [],
        co2MethodsByMonths: [],
        scopes: {},

        // total ghg and scopes + Emissions by ghg
        ghgCategorySummariesCurrentPeriod: [],
        ghgCategorySummariesPreviousPeriod: [],

        includedGhgCategories: {},

        statisticsPageData: null,
        emissionFactorsAppendixPageData: null,

        includeStatistics: true,
        includeEmissionFactorsAppendix: false,
      },
      generateReportDateRange: {
        from: null,
        to: null,
      },
      fetchingData: false,
      showDeleteConfirmDialog: false,
      deleteId: null,
      partner: null,
      defaultPartner: {
        name: this.$t("pages.support.fallbackPartner.name"),
        address: this.$t("pages.support.fallbackPartner.address"),
        contactPerson: this.$t("pages.support.fallbackPartner.contactPerson"),
        email: this.$t("pages.support.fallbackPartner.email"),
        phoneNumber: this.$t("pages.support.fallbackPartner.phoneNumber"),
        description: this.$t("pages.support.fallbackPartner.description"),
        uniqueSellingPropositions: this.$t(
          "pages.support.fallbackPartner.uniqueSellingPropositions"
        ),
        profilePictureName: this.$t(
          "pages.support.fallbackPartner.profilePictureName"
        ),
      },
      includedGhgCategories: {},
      showMoreOptions: false,
      downloadingReport: null,

      // Custom pdf preview
      showPreview: false,
      pdfFileName: "",
    };
  },
  mounted() {
    this.resetGenerateDateRange();

    this.fetchReports();

    this.getOrganizationPartner();

    this.getGhgCategories();
  },
  methods: {
    emptyReportData() {
      return {
        // Overview
        assetCardData: {},
        emissionsPerMonthCurrentYear: [],
        emissionsPerMonthLastYear: [],
        co2MethodsByMonths: [],
        scopes: {},

        // total ghg and scopes + Emissions by ghg
        ghgCategorySummariesCurrentPeriod: [],
        ghgCategorySummariesPreviousPeriod: [],

        // ghg filters
        includedGhgCategories: {},

        statisticsPageData: null,
        emissionFactorsAppendixPageData: null,

        includeStatistics: true,
        includeEmissionFactorsAppendix: false,
      };
    },
    resetGenerateDateRange() {
      // Set default range to current year
      const today = DateTime.utc().startOf("day");
      const startOfYear = today.startOf("year").toJSDate();
      const endOfYear = today.endOf("Year").startOf("day").toJSDate();
      const fromDate = new Date(startOfYear).toISOString().split("T")[0];
      const toDate = new Date(endOfYear).toISOString().split("T")[0];
      this.generateReportDateRange = {
        from: fromDate,
        to: toDate,
      };
    },
    getGhgCategories() {
      // Get included ghg cats
      fetch(this.route("api.dashboard.infographics.lists.ghgCategorySummaries"))
        .then((response) => {
          if (!response.ok) {
            throw new Error("Response not OK");
          }
          return response.json();
        })
        .then((data) => {
          data?.forEach((ghgCategory) => {
            Vue.set(this.includedGhgCategories, ghgCategory.id, {
              name: this.$t(`common.ghgCategories.${ghgCategory.id}`),
              scopeId: ghgCategory.scopeId,
              value: 1,
              sortOrder: ghgCategory.sortOrder,
            });
          });

          // --- Sort ghgs based on sort order
          // Convert object to array of key-value pairs
          const entries = Object.entries(this.includedGhgCategories);

          // Sort the array based on sortOrder property
          entries.sort((a, b) => a[1].sortOrder - b[1].sortOrder);

          // Convert back to object
          this.includedGhgCategories = Object.fromEntries(entries);
        })
        .catch((error) => {
          console.warn("Could not load data", error);
        });
    },
    // Save report (mongo)
    async saveReport() {
      // Fetch report data first
      await this.fetchReportDataInRange(this.generateReportDateRange);

      console.log("sending data", this.reportData);

      // send data to mongodb
      fetch(
        this.route("api.reports.report.create", {
          fromDate: this.generateReportDateRange.from,
          toDate: this.generateReportDateRange.to,
        }),
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(this.reportData),
        }
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error("Response not OK");
          }
          return response.json();
        })
        .then(() => {
          this.fetchReports();
        })
        .catch((error) => {
          console.warn("Could not load data", error);
        });

      this.showModal = false;
    },
    async deleteReport(id) {
      this.deleteId = id;
      this.showDeleteConfirmDialog = true;
    },
    async downloadExcel(id) {
      fetch(this.route("report.export", { id: id }), {
        method: "POST",
      })
        .then(async (res) => ({
          fileName: this.getFileNameFromHeader(
            res.headers.get("content-disposition")
          ),
          contentType: res.headers.get("content-type"),
          blob: await res.blob(),
        }))
        .then((fileData) => {
          const blob = new Blob([fileData.blob], {
            type: fileData.contentType,
          });

          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob);
          } else {
            const blobUrl = URL.createObjectURL(blob);

            let link = document.createElement("a");
            link.href = blobUrl;
            link.download = fileData.fileName;
            link.click();

            setTimeout(() => {
              URL.revokeObjectURL(blobUrl);
            }, 250);
          }
        });
    },
    // Based on: https://gist.github.com/devloco/5f779216c988438777b76e7db113d05c
    getFileNameFromHeader(header) {
      let contentDispostion = header.split(";");
      const fileNameToken = `filename*=UTF-8''`;

      let fileName = null;
      for (let thisValue of contentDispostion) {
        if (thisValue.trim().indexOf(fileNameToken) === 0) {
          fileName = decodeURIComponent(
            thisValue.trim().replace(fileNameToken, "")
          );
          break;
        }
      }

      return fileName;
    },
    async handleDeleteConfirmation() {
      await fetch(
        this.route("api.reports.report.delete", { reportId: this.deleteId }),
        { method: "DELETE" }
      )
        .then((response) => {
          if (!response.ok) {
            return Promise.reject();
          }
          return response.json();
        })
        .then(() => {
          // item deleted
          this.fetchReports();
        })
        .catch((error) => {
          console.warn("Could not load data", error);
        });
    },
    async generateReport(id) {
      this.downloadingReport = id;

      this.output = [];
      this.reportData = this.emptyReportData();

      await this.fetchReportById(id);

      await this.generateImage();

      this.pdfFileName = this.generatePdfName();

      this.showPreview = true;

      this.downloadingReport = null;
    },
    downloadReport() {
      this.$refs.html2Pdf.downloadPdf();
    },
    generatePdfName() {

      let fromYear = this.generateReportDateRange.from?.split("-")[0];
      let toYear = this.generateReportDateRange.to?.split("-")[0];
      let organizationName = this.organization?.name;

      return (
        organizationName +
        " CO2 " +
        fromYear +
        (toYear !== fromYear ? "-" + toYear : "")
      );
    },
    async generateImage() {
      const el = this.$refs.printMe;

      const pageHeight = 1684 / 1.4142135624;

      // rendering (2) pages per canvas element to avoid hiting canvas limit
      for (var i = 0; i <= Math.round(this.totalNumberOfPages / 2); i++) {
        // add option type to get the image version
        // if not provided the promise will return
        // the canvas.
        const options = {
          type: "dataURL",
          scale: 2,
          height:
            i == Math.round(this.totalNumberOfPages / 2) &&
            this.totalNumberOfPages % 2 // only last page and only if uneven
              ? pageHeight
              : pageHeight * 2,
          y: pageHeight * 2 * i,
          logging: false,
        };

        const page = await this.$html2canvas(el, options);
        this.output.push(page);
      }
    },
    // Data fetch (Before generating)
    async fetchReportDataInRange(dateRange) {
      this.output = [];

      this.fetchingData = true;
      return new Promise((resolve, reject) => {
        const fetchAndSetData = (url, property, format = (r) => r) => {
          return fetch(url)
            .then((response) => {
              if (!response.ok) {
                throw new Error("Response not OK");
              }
              return response.json();
            })
            .then((data) => {
              this.reportData[property] = format(data);
            })
            .catch((error) => {
              console.warn("Could not load data", error);
            });
        };

        var dateFrom = new Date(dateRange.from);
        var pastYearFrom = dateFrom.getFullYear() - 1;
        let prevmm = dateFrom.getMonth() + 1; // Months start at 0!
        let prevdd = dateFrom.getDate();

        const prevFrom = pastYearFrom + "-" + prevmm + "-" + prevdd;

        var dateTo = new Date(dateRange.to);
        var pastYearTo = dateTo.getFullYear() - 1;
        let tomm = dateTo.getMonth() + 1; // Months start at 0!
        let todd = dateTo.getDate();

        const prevTo = pastYearTo + "-" + tomm + "-" + todd;

        Promise.all([
          fetchAndSetData(
            this.route("api.dashboard.infographics.assets.card-data", {
              fromDate: dateRange.from,
              toDate: dateRange.to,
            }),
            "assetCardData"
          ),

          fetchAndSetData(
            this.route("api.dashboard.infographics.emissions.monthly", {
              fromDate: dateRange.from,
              toDate: dateRange.to,
            }),
            "emissionsPerMonthCurrentYear"
          ),
          fetchAndSetData(
            this.route("api.dashboard.infographics.emissions.monthly", {
              fromDate: dateRange.from,
              toDate: dateRange.to,
              yearOffset: -1,
            }),
            "emissionsPerMonthLastYear"
          ),
          fetchAndSetData(
            this.route("api.dashboard.infographics.co2Methods.monthly", {
              fromDate: dateRange.from,
              toDate: dateRange.to,
              yearOffset: -1,
            }),
            "co2MethodsByMonths"
          ),
          // Calculate scopes on the spot instead
          fetchAndSetData(
            this.route("api.dashboard.infographics.scopes.grouped", {
              fromDate: dateRange.from,
              toDate: dateRange.to,
            }),
            "scopes"
          ),
          fetchAndSetData(
            this.route(
              "api.dashboard.infographics.lists.ghgCategorySummaries",
              {
                fromDate: dateRange.from,
                toDate: dateRange.to,
              }
            ),
            "ghgCategorySummariesCurrentPeriod",
            (ghgCategories) =>
              ghgCategories.map((ghgCategory) => {
                // transform/translate ghg category name
                ghgCategory.name = this.$t(
                  `common.ghgCategories.${ghgCategory.id}`
                );

                // Add included status to each
                return {
                  ...ghgCategory,
                  included: this.includedGhgCategories[ghgCategory.id].value,
                };
              })
          ),
          fetchAndSetData(
            this.route(
              "api.dashboard.infographics.lists.ghgCategorySummaries",
              {
                fromDate: prevFrom,
                toDate: prevTo,
              }
            ),
            "ghgCategorySummariesPreviousPeriod",
            (ghgCategories) =>
              ghgCategories.map((ghgCategory) => {
                // transform/translate ghg category name
                ghgCategory.name = this.$t(
                  `common.ghgCategories.${ghgCategory.id}`
                );

                // Add included status to each
                return {
                  ...ghgCategory,
                  included: this.includedGhgCategories[ghgCategory.id].value,
                };
              })
          ),
          fetchAndSetData(
            this.route("api.reports.statistics", {
              fromDate: dateRange.from,
              toDate: dateRange.to,
            }),
            "statisticsPageData"
          ),
          fetchAndSetData(
            this.route("api.reports.emissionFactorsAppendix", {
              fromDate: dateRange.from,
              toDate: dateRange.to,
            }),
            "emissionFactorsAppendixPageData",
            (data) =>
              data.sort((a, b) => {
                const sourceA = a.source ? a.source.toLowerCase() : "";
                const sourceB = b.source ? b.source.toLowerCase() : "";

                return sourceA.localeCompare(sourceB);
              })
          ),
        ])
          .then(() => {
            this.fetchingData = false;
            resolve(this.reportData);
          })
          .catch((error) => {
            this.fetchingData = false;
            reject(error);
          })
          .finally(() => {
            this.reportData = this.emptyReportData();
          });
      });
    },
    // Get reports list
    async fetchReports() {
      this.fetchingData = true;
      await fetch(this.route("api.reports.list"))
        .then((response) => {
          if (!response.ok) {
            return Promise.reject();
          }
          return response.json();
        })
        .then((data) => {
          this.reports = data.reports.map((report) => ({
            id: report.id,
            createdBy: report.createdBy.name,
            createdAt: report.createdAt,
            dateRange: report.dateRange,
          }));
        })
        .catch((error) => {
          console.warn("Could not load data", error);
        })
        .finally(() => {
          this.fetchingData = false;
        });
    },
    // Get report by ID (mongo)
    async fetchReportById(reportId) {
      await fetch(this.route("api.reports.report", { reportId: reportId }))
        .then((response) => {
          if (!response.ok) {
            return Promise.reject();
          }
          return response.json();
        })
        .then((data) => {
          this.reportData = JSON.parse(data.data);
          this.generateReportDateRange = data.dateRange;
        })
        .catch((error) => {
          console.warn("Could not load data", error);
        });
    },

    setDateRange(date) {
      this.generateReportDateRange = { ...date };
    },
    profilePictureUrl(name) {
      if (name) {
        const nameParts = name.split(" ");
        const initials = nameParts.map((name) => name.charAt(0)).join("");
        const initialsUppercase = initials.toUpperCase();

        return (
          "https://ui-avatars.com/api/?name=" +
          initialsUppercase +
          "&color=fff&background=F25D3B"
        );
      }
    },
    async getOrganizationPartner() {
      await fetch(this.route("api.reports.partner"))
        .then((response) => {
          if (!response.ok) {
            return Promise.reject();
          }
          return response.json();
        })
        .then((data) => {
          this.partner = data;
        })
        .catch((error) => {
          console.warn("Could not load data", error);
        });
    },
  },
  computed: {
    getPartnerInfo() {
      if (this.partner && this.partner.isSupport) {
        return this.partner;
      }

      return this.defaultPartner;
    },
    organization() {
      const org = this.auth.organizations.find(
        (organization) => organization.id === this.auth.organizationId
      );
      const orgGroup = this.auth.organizationGroups.find(
        (organization) => organization.id === this.auth.organizationId
      );

      if (org !== undefined) {
        return org;
      }

      return orgGroup;
    },
    templateName() {
      // Match route to component name
      if (this.templateRoute === "carbon-footprint") {
        return "carbon-footprint";
      }
      if (this.templateRoute === "climate-report") {
        return "climate-report";
      }
      return "no-template";
    },
    totalNumberOfPages() {
      let additionalPages = 0;
      if (
        this.reportData.includeStatistics &&
        this.reportData.statisticsPageData
      ) {
        additionalPages += 2;
      }
      if (
        this.reportData.includeEmissionFactorsAppendix &&
        this.reportData.emissionFactorsAppendixPageData
      ) {
        additionalPages += Math.ceil(
          this.reportData.emissionFactorsAppendixPageData.length / 20
        );
      }

      return 6 + additionalPages;
    },
    scope3GhgEmissions() {
      return Object.keys(this.includedGhgCategories).filter(
        (key) =>
          this.includedGhgCategories[key].scopeId ===
          "6d9ea628-4dcf-4d77-ad8b-7e8ea4d72dde"
      );
    },
  },
  watch: {
    showModal(to) {
      if (to) {
        // Reset create report modal
        this.reportData.includeStatistics = true;
        this.reportData.includeEmissionFactorsAppendix = false;
        this.showMoreOptions = false;
        this.getGhgCategories();
        this.resetGenerateDateRange();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.page-container {
  .table-filter {
    background-color: #fff;
  }
}
:deep(.actions-column) {
  width: 0;
}

.print-view-container {
  overflow-x: scroll;
  width: 100%;
  max-width: 1684px;
  align-self: center;
  height: 0;
  position: absolute;
  top: 0;

  .print-view {
    box-sizing: border-box;
    font-family: "Lexend", Arial, sans-serif;
    font-size: 12px;
    width: 1684px;
    min-width: 1684px;
    max-width: 1684px;
    align-self: center;
    background-color: #f7f7f7;
    pointer-events: none;

    .text-h5 {
      font-size: 18px !important;
    }
  }
}

.date-range-picker {
  cursor: pointer !important;
  input,
  :deep(.v-input__slot) {
    cursor: pointer !important;
  }
}

.date-range-arrow {
  path {
    fill: #c3c3c3;
  }
}

.report-table {
  :deep(th) {
    font-weight: normal !important;
    color: #b4b4b4 !important;
  }
}

.table-row {
  td {
    border-bottom: none !important;
  }
}
</style>
