<template>
  <v-row class="px-2">
    <v-col lg="3" class="pt-2 pb-0 px-2 pr-0 d-flex">
      <div class="flex-grow-1 white pa-4 pr-2 d-flex align-center">
        <v-text-field
          :label="$t('components.assets.listFilter.search')"
          color="#686868"
          :class="`search-field rounded-0 pt-0 ${
            postFilters.search ? 'no-result' : ''
          }`"
          height="44"
          light
          hide-details="auto"
          background-color="#F7F7F7"
          filled
          solo
          dense
          prepend-inner-icon="mdi-magnify"
          flat
          clearable
          @click:clear="updateSearch(null)"
          @change="(val) => updateSearch(val)"
          :value="postFilters.search"
        ></v-text-field>
      </div>
    </v-col>
    <v-col cols="auto" lg="2" class="pt-2 px-0 pr-2 pr-lg-0 pb-0 d-flex">
      <div class="flex-grow-1 white pa-4 pl-2 d-flex align-center">
        <v-badge
          overlap
          :content="currentAttributeFilters.length"
          :value="currentAttributeFilters.length > 0"
        >
          <filters-modal
            v-model="filtersDialog"
            :attributes="attributes"
            :attributeFilters="currentAttributeFilters"
            :selectedAttributeFilters="currentAttributeFilters"
            :filters="filters"
            @changeAttributeFilters="(value) => updateAttributeFilters(value)"
            @onApplyFilters="(value) => applyQuickFilters(value)"
          >
            <template #trigger-button>
              <v-icon color="#686868">mdi-filter-outline</v-icon>
              <span class="d-none d-sm-block">
                {{ $t("components.assets.listFilter.moreFilters") }}
              </span>
            </template>
          </filters-modal>
        </v-badge>
      </div>
      <!-- <div class="flex-grow-1 white pa-4 pl-2 d-flex align-center">
        <quick-filters-modal
          @onApplyFilters="(value) => applyQuickFilters(value)"
          :selectedAttributeFilters="currentAttributeFilters"
          :filters="filters"
        >
          <template #trigger-button>
            <v-icon color="#686868">mdi-filter-outline</v-icon>
            <span class="d-none d-sm-block"> Quick filters </span>
          </template>
        </quick-filters-modal>
      </div> -->
    </v-col>
    <v-col cols="12" lg="7" class="pt-0 pt-lg-2 pb-0 px-2 pl-lg-0 d-flex">
      <div
        class="flex-grow-1 white pa-4 d-flex d-flex-row align-center justify-end flex-column flex-sm-row"
      >
        <div class="mr-4 mr-lg-0 align-self-start align-self-sm-center">
          <v-menu
            v-if="layoutOptions.length > 0"
            class="layout-menu"
            offset-y
            light
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-show="false"
                class="text-none align-self-stretch align-self-sm-center px-2 px-xl-4 mt-5 mt-sm-0"
                elevation="0"
                tile
                text
                color="#B4B4B4"
                v-bind="attrs"
                v-on="on"
                ><presentation-icon class="mr-3"></presentation-icon>
                {{ $t("components.assets.listFilter.layout") }}:
                <span style="color: #686868">{{
                  layoutOptions[selectedLayoutOption]
                }}</span></v-btn
              >
            </template>
            <v-list>
              <v-list-item
                link
                v-for="(value, key) in layoutOptions"
                :key="key"
                @click="setLayoutMode(key)"
                ><v-list-item-title>{{ value }}</v-list-item-title></v-list-item
              >
            </v-list>
          </v-menu>
          <choose-attributes-modal
            class="d-inline-block"
            v-model="chooseAttributesDialog"
            :layout="currentAssetLayout"
            :attributes="attributes"
            :attributeClasses="attributeClasses"
            :organizationId="currentOrganizationId"
            @changeLayout="updateAssetLayout"
            @resetLayout="resetAssetLayout"
          >
            <template #trigger-button>
              <layout-icon class="mr-3"></layout-icon>
              {{ $t("components.assets.listFilter.layoutOptions") }}
            </template>
          </choose-attributes-modal>
          <!--<layout-options-modal
            v-model="layoutOptionsDialog"
            :layout="currentAssetLayout"
            :attributeDisplaySizes="attributeDisplaySizes"
            @changeLayout="changeLayout"
            @saveLayout="saveLayout"
          >
            <template #trigger-button>
              <layout-icon class="mr-3"></layout-icon>Layout options
            </template>
          </layout-options-modal>-->
          <export-assets
            ref="export-assets"
            class="d-inline-block"
            :assetLayout="currentAssetLayout"
            :attributeFilters="currentAttributeFilters"
            v-model="exportAssetsDialog"
            :attributes="attributes"
            :filteredAssets="assets"
            :selectedAssets="selectedAssets"
            :organizationId="currentOrganizationId"
            :postFilters="postFilters"
          >
            <template #trigger-button>
              <download-icon class="mr-3"></download-icon>
              {{ $t("components.assets.listFilter.export") }}
            </template>
          </export-assets>
        </div>
        <span
          class="d-none d-lg-block mx-4 mx-md-8"
          style="border-right: 1px solid #d9d9d9; height: 56px"
        ></span>

        <v-menu class="create-menu" offset-y light>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              large
              class="align-self-stretch align-self-sm-center mt-5 mt-sm-0"
              elevation="0"
              tile
              color="#F25D3B"
              v-bind="attrs"
              v-on="on"
              dark
            >
              <v-icon class="pr-3 block" color="#fff" small>mdi-plus</v-icon>
              {{ $t("components.assets.listFilter.create.label") }}
            </v-btn>
          </template>
          <v-list>
            <v-list-item link @click="$emit('clickCreateSingle', $event)">
              <v-list-item-title>
                {{ $t("components.assets.listFilter.create.manual") }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item link @click="$emit('clickImportFromCSV', $event)">
              <v-list-item-title>
                {{ $t("components.assets.listFilter.create.import") }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import DownloadIcon from "../../assets/svg/archive-download.svg";
import LayoutIcon from "../../assets/svg/layout.svg";
import PresentationIcon from "../../assets/svg/presentation.svg";
//import LayoutOptionsModal from "../Layout/LayoutOptionsModal.vue";
import ExportAssets from "./../../Pages/Asset/ExportAssets.vue";
import ChooseAttributesModal from "../../Components/Attribute/ChooseAttributesModal.vue";
import FiltersModal from "../../Components/Attribute/FiltersModal.vue";
// import QuickFiltersModal from "../../Components/Attribute/QuickFiltersModal.vue";
import { fixedFieldAttributes } from "../../util/fixedAssetData";

export default {
  components: {
    DownloadIcon,
    LayoutIcon,
    PresentationIcon,
    /*LayoutOptionsModal,*/
    ExportAssets,
    ChooseAttributesModal,
    FiltersModal,
    // QuickFiltersModal,
  },
  props: {
    attributeDisplaySizes: Array,
    currentAssetLayout: Object,
    changeLayout: Function,
    saveLayout: Function,

    attributes: Array,
    assets: Object,
    selectedAssets: Array,
    currentOrganizationId: String,
    postFilters: Object,

    attributeClasses: Array,
    updateAssetLayout: Function,
    resetAssetLayout: Function,
    currentAttributeFilters: Array,
    updateAttributeFilters: Function,

    layoutOptions: Array,
    selectedLayoutOption: Number,
  },
  data() {
    return {
      layoutOptionsDialog: false,
      exportAssetsDialog: false,
      chooseAttributesDialog: false,
      filtersDialog: false,
      // [24-02-09] SH: HARD CODED FILTERS (@TODO: Move to backend and add functionality to create/edit filters etc.)
      filters: [
        {
          name: "Scope 1",
          id: 1,
          filter: {
            sortDesc: null,
            attributeId: "fc24e517-72d1-4df1-9c73-9b0454d04c9a",
            filterBy: "1",
            filter: "Include",
            attribute: {
              id: "fc24e517-72d1-4df1-9c73-9b0454d04c9a",
              format: "",
            },
            sortOrder: 1,
            IsOR: true,
          },
        },
        {
          name: "Scope 2",
          id: 3,
          filter: {
            sortDesc: null,
            attributeId: "fc24e517-72d1-4df1-9c73-9b0454d04c9a",
            filterBy: "2",
            filter: "Include",
            attribute: {
              id: "fc24e517-72d1-4df1-9c73-9b0454d04c9a",
              format: "",
            },
            sortOrder: 2,
            IsOR: true,
          },
        },
        {
          name: "Scope 3",
          id: 4,
          filter: {
            sortDesc: null,
            attributeId: "fc24e517-72d1-4df1-9c73-9b0454d04c9a",
            filterBy: "3",
            filter: "Include",
            attribute: {
              id: "fc24e517-72d1-4df1-9c73-9b0454d04c9a",
              format: "",
            },
            sortOrder: 3,
            IsOR: true,
          },
        },
        {
          name: "CO2e emissions [descending]",
          id: 5,
          filter: {
            attributeId: "041a251a-2138-4e14-bdfd-876194f4e04f",
            attribute: {
              id: "041a251a-2138-4e14-bdfd-876194f4e04f",
              format: "Currency",
            },
            sortDesc: true,
            sortOrder: 4,
            IsOR: true,
          },
        },
        {
          name: "Purchase price [descending]",
          id: 6,
          filter: {
            attributeId: "8192dfef-ef4b-4ed1-9c9e-26ed84ef8de2",
            attribute: {
              id: "8192dfef-ef4b-4ed1-9c9e-26ed84ef8de2",
              format: "Currency",
            },
            sortDesc: true,
            sortOrder: 5,
            IsOR: true,
          },
        },
        {
          name: "Status = “Undefined”",
          id: 7,
          filter: {
            attributeId: fixedFieldAttributes.co2EmissionFactorStatusId,
            filterBy: "undefined",
            filter: "Include",
            attribute: {
              id: fixedFieldAttributes.co2EmissionFactorStatusId,
              format: "",
            },
            sortOrder: 2,
            sortDesc: null,
            IsOR: false,
          },
        },
      ],
    };
  },
  mounted() {
    // [2023-09-05] AHA: Enforce layout mode to be 1 (table) for now
    //const layoutMode = localStorage.getItem("layout-mode");
    const layoutMode = 1;
    this.$emit("selectLayoutOption", Number(layoutMode) ?? 0);
  },
  methods: {
    setLayoutMode(key) {
      key = 1;
      this.$emit("selectLayoutOption", key);
      localStorage.setItem("layout-mode", key);
    },
    updateSearch(val) {
      this.$emit("searchAttributes", val);
    },
    applyQuickFilters(filterIds) {
      const filters = this.filters
        .filter((filter) => filterIds.includes(filter.id))
        .map((filter) => {
          return { ...filter.filter, quickFilterId: filter.id };
        });

      this.updateAttributeFilters(filters);
    },
  },
};
</script>

<style lang="scss" scoped>
$no-search-result-color: #f44336;

.search-field {
  &:deep(.v-text-field__slot .v-label) {
    color: #aeaeae !important;
    font-size: 15px;
    font-weight: 400;
  }

  &.no-result {
    border-bottom: solid 2px $no-search-result-color;

    :deep(i.v-icon.v-icon::before) {
      color: $no-search-result-color;
    }
  }
}

.create-menu {
  z-index: 99999;
}
</style>
