<template>
  <v-banner single-line class="banner">
    <div class="d-flex flex-row align-center justify-space-between my-4">
      <div>
        <v-icon class="info-icon">mdi-information</v-icon>
        <span class="info-icon">
          {{
            $t(
              "components.banners.upgradeFromTrialBanner.upgradeFromTrialMessage"
            )
          }}
        </span>
      </div>
      <div>
        <v-btn
          tile
          class="upgrade-btn"
          @click="redirectToBilling()"
          elevation="0"
        >
          {{ $t("components.banners.upgradeFromTrialBanner.upgradePlan") }}
        </v-btn>
      </div>
    </div>
  </v-banner>
</template>
<script>
export default {
  methods: {
    redirectToBilling() {
      const url = `${this.route("settings.index")}#billing`;
      this.$inertia.get(url, { openPayment: true });
    },
  },
};
</script>
<style lang="scss" scoped>
$main-color: rgb(242, 93, 59) !important;
$secondary-color: #f7f7f7;

.banner {
  background-color: $main-color;
  z-index: 5 !important;

  .info-icon {
    color: $secondary-color !important;
  }

  .upgrade-btn {
    border: 0 !important;
    background-color: $secondary-color !important;
  }
}
</style>
