<template>
  <div class="flex-grow-1 d-flex justify-center align-center">
    <div class="d-flex justify-center align-center">
      <div class="px-0" fluid style="width: 1313px">
        <v-card
          light
          color="#fff"
          elevation="0"
          rounded="0"
          max-width="100%"
          class="flex-grow-1"
        >
          <div class="px-10 py-10">
            <v-row>
              <v-col cols="4"
                ><p class="page-title">
                  {{
                    $t(
                      "components.reportTemplates.reportAppendix1.theThreeScopes"
                    )
                  }}
                </p>
                <p class="page-description">
                  {{
                    $t(
                      "components.reportTemplates.reportAppendix1.pageDescription"
                    )
                  }}
                </p>
              </v-col>
              <v-col cols="8" class="d-flex">
                <div
                  class="flex-grow-1 d-flex flex-column justify-space-between"
                >
                  <div class="mt-8">
                    <v-row>
                      <v-col col="2"><CloudNo2Icon></CloudNo2Icon></v-col>
                      <v-col col="2"><CloudCh2Icon></CloudCh2Icon></v-col>
                      <v-col col="2"><CloudPfcsIcon></CloudPfcsIcon></v-col>
                      <v-col col="2"><CloudCo2Icon></CloudCo2Icon></v-col>
                      <v-col col="2"><CloudHcfsIcon></CloudHcfsIcon></v-col>
                      <v-col col="1"><CloudSf6Icon></CloudSf6Icon></v-col>
                      <v-col col="1"><CloudNf3Icon></CloudNf3Icon></v-col>
                    </v-row>
                  </div>
                  <div>
                    <v-row>
                      <v-col col="3">
                        <div class="d-flex align-center flex-column">
                          <span class="scope-label scope-3">Scope</span>
                          <span>{{
                            $t(
                              "components.reportTemplates.reportAppendix1.indirect"
                            )
                          }}</span>
                        </div>
                      </v-col>
                      <v-col col="3">
                        <div class="d-flex align-center flex-column">
                          <span class="scope-label scope-1">Scope</span>
                          <span>{{
                            $t(
                              "components.reportTemplates.reportAppendix1.direct"
                            )
                          }}</span>
                        </div>
                      </v-col>
                      <v-col col="3">
                        <div class="d-flex align-center flex-column">
                          <span class="scope-label scope-2">Scope</span>
                          <span>{{
                            $t(
                              "components.reportTemplates.reportAppendix1.indirect"
                            )
                          }}</span>
                        </div>
                      </v-col>
                      <v-col col="3">
                        <div class="d-flex align-center flex-column">
                          <span class="scope-label scope-3">Scope</span>
                          <span>{{
                            $t(
                              "components.reportTemplates.reportAppendix1.indirect"
                            )
                          }}</span>
                        </div>
                      </v-col>
                    </v-row>
                  </div>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <div class="info-box">
                  <p>
                    <strong>{{
                      $t(
                        "components.reportTemplates.reportAppendix1.infoBoxes.scope1.title"
                      )
                    }}</strong
                    >&nbsp;{{
                      $t(
                        "components.reportTemplates.reportAppendix1.infoBoxes.scope1.description"
                      )
                    }}
                  </p>
                  <p>
                    <strong>{{
                      $t(
                        "components.reportTemplates.reportAppendix1.infoBoxes.scope2.title"
                      )
                    }}</strong
                    >&nbsp;{{
                      $t(
                        "components.reportTemplates.reportAppendix1.infoBoxes.scope2.description"
                      )
                    }}
                  </p>
                  <p>
                    <strong>{{
                      $t(
                        "components.reportTemplates.reportAppendix1.infoBoxes.scope3.title"
                      )
                    }}</strong
                    >&nbsp;{{
                      $t(
                        "components.reportTemplates.reportAppendix1.infoBoxes.scope3.description"
                      )
                    }}
                  </p>
                </div>
              </v-col>
              <v-col cols="8">
                <v-row>
                  <v-col col="3">
                    <div class="house">
                      <div>
                        <PurchasedGoodsServicesIcon
                          width="18px"
                          height="17px"
                        ></PurchasedGoodsServicesIcon>
                        <p>
                          {{
                            $t(
                              "common.ghgCategories.a588c70c-a620-4d67-b65e-5ef7b11a8f87"
                            )
                          }}
                        </p>
                      </div>
                      <div>
                        <CapitalGoodsIcon
                          width="22px"
                          height="22px"
                        ></CapitalGoodsIcon>
                        <p>
                          {{
                            $t(
                              "common.ghgCategories.24f8d5db-3871-464a-a52f-af31df23c978"
                            )
                          }}
                        </p>
                      </div>
                      <div>
                        <FueldAndEnergyRelatedActivitiesIcon
                          width="16px"
                          height="18px"
                        ></FueldAndEnergyRelatedActivitiesIcon>
                        <p>
                          {{
                            $t(
                              "common.ghgCategories.023053e9-84e3-47cb-9692-dc0243794af9"
                            )
                          }}
                        </p>
                      </div>
                      <div>
                        <DownstreamTransportationAndDistributionIcon
                          width="17px"
                          height="18px"
                        ></DownstreamTransportationAndDistributionIcon>
                        <p>
                          {{
                            $t(
                              "common.ghgCategories.named.transportationAndDistribution"
                            )
                          }}
                        </p>
                      </div>
                      <div>
                        <WasteGeneratedInOperationsIcon
                          width="18px"
                          height="19px"
                        ></WasteGeneratedInOperationsIcon>
                        <p>
                          {{
                            $t(
                              "common.ghgCategories.e966141a-3d58-4096-967d-2f4e0776c75a"
                            )
                          }}
                        </p>
                      </div>
                      <div>
                        <BusinessTravelIcon
                          width="18px"
                          height="18px"
                        ></BusinessTravelIcon>
                        <p>
                          {{
                            $t(
                              "common.ghgCategories.97b1cb84-a7a0-4992-a0af-24d61f415510"
                            )
                          }}
                        </p>
                      </div>
                      <div>
                        <EmploteeCommutingIcon
                          width="20px"
                          height="15px"
                        ></EmploteeCommutingIcon>
                        <p>
                          {{
                            $t(
                              "common.ghgCategories.06b4a64c-d4be-43d9-bf73-6968fc778414"
                            )
                          }}
                        </p>
                      </div>
                      <div>
                        <UpstreamLeasedAssetsIcon
                          width="21px"
                          height="20px"
                        ></UpstreamLeasedAssetsIcon>
                        <p>
                          {{
                            $t(
                              "common.ghgCategories.3b8537c0-102c-40a1-be40-796ee826b9f8"
                            )
                          }}
                        </p>
                      </div>
                    </div>
                  </v-col>
                  <v-col col="3">
                    <div class="house">
                      <div>
                        <CompanyFacilitiesIcon
                          width="21px"
                          height="21px"
                        ></CompanyFacilitiesIcon>
                        <p>
                          {{
                            $t(
                              "common.ghgCategories.3edee4c8-a1e4-4a77-a978-5da86d2ad7ba"
                            )
                          }}
                        </p>
                      </div>
                      <div>
                        <CompanyVeiclesIcon
                          width="32px"
                          height="24px"
                        ></CompanyVeiclesIcon>
                        <p>
                          {{
                            $t(
                              "common.ghgCategories.b0b345b4-87ad-4de5-b98f-4648d4c51edf"
                            )
                          }}
                        </p>
                      </div>
                    </div>
                  </v-col>
                  <v-col col="3">
                    <div class="house">
                      <div>
                        <ElectricityHeatingIcon
                          width="21px"
                          height="21px"
                        ></ElectricityHeatingIcon>
                        <p>
                          {{
                            $t(
                              "common.ghgCategories.e82fdc22-1ae0-4066-a662-ffa744de11a3"
                            )
                          }}
                        </p>
                      </div>
                    </div>
                  </v-col>
                  <v-col col="3">
                    <div class="house">
                      <div>
                        <DownstreamTransportationAndDistributionIcon
                          width="23px"
                          height="24px"
                        ></DownstreamTransportationAndDistributionIcon>
                        <p>
                          {{
                            $t(
                              "common.ghgCategories.named.transportationAndDistribution"
                            )
                          }}
                        </p>
                      </div>
                      <div>
                        <ProcessingOfSoldProductsIcon
                          width="23px"
                          height="24px"
                        ></ProcessingOfSoldProductsIcon>
                        <p>
                          {{
                            $t(
                              "common.ghgCategories.4eddd004-d305-4f4c-8ed5-a7d131f31db2"
                            )
                          }}
                        </p>
                      </div>
                      <div>
                        <UseOfSoldProductsIcon
                          width="23px"
                          height="24px"
                        ></UseOfSoldProductsIcon>
                        <p>
                          {{
                            $t(
                              "common.ghgCategories.0fcb2df8-35bf-429e-bf6f-f73851616b58"
                            )
                          }}
                        </p>
                      </div>
                      <div>
                        <EndOfLifeTreatmentOfSoldProductsIcon
                          width="23px"
                          height="24px"
                        ></EndOfLifeTreatmentOfSoldProductsIcon>
                        <p>
                          {{
                            $t(
                              "common.ghgCategories.a147d878-dca6-436d-89ff-ab210f302d96"
                            )
                          }}
                        </p>
                      </div>
                      <div>
                        <DownStreamLeasedAssetsIcon
                          width="23px"
                          height="24px"
                        ></DownStreamLeasedAssetsIcon>
                        <p>
                          {{
                            $t(
                              "common.ghgCategories.3057fd53-9d3b-412a-9050-f8ad989e3727"
                            )
                          }}
                        </p>
                      </div>
                      <div>
                        <FranchisesIcon2
                          width="23px"
                          height="24px"
                        ></FranchisesIcon2>
                        <p>
                          {{
                            $t(
                              "common.ghgCategories.a431af20-eb08-420d-8a62-9e2d2f011f28"
                            )
                          }}
                        </p>
                      </div>
                      <div>
                        <Investments width="23px" height="24px"></Investments>
                        <p>
                          {{
                            $t(
                              "common.ghgCategories.c3e8af39-30ee-44ab-84f7-58b28077a0de"
                            )
                          }}
                        </p>
                      </div>
                    </div>
                  </v-col>
                </v-row>
                <div class="d-flex flex-row mt-10">
                  <div class="arrow flex-grow-1">
                    {{
                      $t(
                        "components.reportTemplates.reportAppendix1.upstreamActivitiesCapital"
                      )
                    }}
                  </div>
                  <div class="arrow light flex-grow-1">
                    {{
                      $t(
                        "components.reportTemplates.reportAppendix1.reportingCompanyCapital"
                      )
                    }}
                  </div>
                  <div class="arrow flex-grow-1">
                    {{
                      $t(
                        "components.reportTemplates.reportAppendix1.downstreamActivitiesCapital"
                      )
                    }}
                  </div>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import CloudNo2Icon from "../../assets/svg/cloud-no2.svg";
import CloudCh2Icon from "../../assets/svg/cloud-ch4.svg";
import CloudPfcsIcon from "../../assets/svg/cloud-pfcs.svg";
import CloudCo2Icon from "../../assets/svg/cloud-co2.svg";
import CloudHcfsIcon from "../../assets/svg/cloud-hcfs.svg";
import CloudSf6Icon from "../../assets/svg/cloud-sf6.svg";
import CloudNf3Icon from "../../assets/svg/cloud-nf3.svg";

import CompanyFacilitiesIcon from "../../../src/assets/svg/report-company-facilities.svg";
import CapitalGoodsIcon from "../../../src/assets/svg/report-capital-goods.svg";
import ElectricityHeatingIcon from "../../../src/assets/svg/report-electricity-and-heating.svg";
import BusinessTravelIcon from "../../../src/assets/svg/report-business-travel.svg";
import CompanyVeiclesIcon from "../../../src/assets/svg/report-company-vehicles.svg";
import DownStreamLeasedAssetsIcon from "../../../src/assets/svg/report-downstream-leased-assets.svg";
import FranchisesIcon2 from "../../../src/assets/svg/report-franchises-2.svg";
import Investments from "../../../src/assets/svg/report-investments.svg";
import DownstreamTransportationAndDistributionIcon from "../../../src/assets/svg/report-downstream-transportation-and-distribution.svg";
import EmploteeCommutingIcon from "../../../src/assets/svg/report-employee-commuting.svg";
import EndOfLifeTreatmentOfSoldProductsIcon from "../../../src/assets/svg/report-end-of-life-treatment-of-sold-products.svg";
import FueldAndEnergyRelatedActivitiesIcon from "../../../src/assets/svg/report-fuel-and-energy-related-activities.svg";
import ProcessingOfSoldProductsIcon from "../../../src/assets/svg/report-processing-of-sold-products.svg";
import PurchasedGoodsServicesIcon from "../../../src/assets/svg/report-purchased-goods-services.svg";
import UpstreamLeasedAssetsIcon from "../../../src/assets/svg/report-upstream-leased-assets.svg";
import WasteGeneratedInOperationsIcon from "../../../src/assets/svg/report-waste-generated-in-operations.svg";
import UseOfSoldProductsIcon from "../../../src/assets/svg/report-use-of-sold-products.svg";

export default {
  components: {
    CloudNo2Icon,
    CloudCh2Icon,
    CloudPfcsIcon,
    CloudCo2Icon,
    CloudHcfsIcon,
    CloudSf6Icon,
    CloudNf3Icon,
    PurchasedGoodsServicesIcon,
    CapitalGoodsIcon,
    FueldAndEnergyRelatedActivitiesIcon,
    DownstreamTransportationAndDistributionIcon,
    WasteGeneratedInOperationsIcon,
    BusinessTravelIcon,
    EmploteeCommutingIcon,
    UpstreamLeasedAssetsIcon,
    CompanyVeiclesIcon,
    CompanyFacilitiesIcon,
    ElectricityHeatingIcon,
    ProcessingOfSoldProductsIcon,
    UseOfSoldProductsIcon,
    EndOfLifeTreatmentOfSoldProductsIcon,
    DownStreamLeasedAssetsIcon,
    FranchisesIcon2,
    Investments,
  },
};
</script>

<style lang="scss" scoped>
.page-title {
  font-size: 35px;
}

.page-description {
  font-size: 14px;
  color: #666666;
}

.info-box {
  background-color: #266663;
  padding: 35px;
  p {
    color: #d1d1d1;
    font-size: 13px;
    strong {
      font-weight: normal;
      color: #fff;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.scope-label {
  font-size: 20px;
  color: #131313;
  position: relative;
  display: inline-block;
  margin-right: 25px;
  &:after {
    content: "";
    font-size: 17px;
    border-radius: 7px;
    width: 23px;
    height: 23px;
    display: block;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
    color: #fff;
    position: absolute;
    right: -28px;
    top: 3px;
    line-height: 0;
  }

  &.scope-1:after {
    content: "1";
    background-color: #266663;
  }
  &.scope-2:after {
    content: "2";
    background-color: #6493be;
  }
  &.scope-3:after {
    content: "3";
    background-color: #f25d3b;
  }
}

.house {
  background-color: #f4f4f4;
  position: relative;
  height: 100%;
  padding-top: 10px;
  padding-bottom: 10px;

  &:before {
    content: "";
    border-bottom: 25px solid #f4f4f4;
    border-left: 95px solid transparent;
    border-right: 90px solid transparent;
    width: 100%;
    height: 0px;
    display: block;
    position: absolute;
    top: -24px;
  }
  div {
    padding: 10px 16px;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    svg {
      position: absolute;
      left: 16px;
    }
    p {
      flex: 1;
      padding-left: 40px;
      margin-bottom: 0;
      font-size: 11px;
      color: #686868;
    }
  }
}

.arrow {
  height: 48px;
  width: 250px;
  background: #b7b7b7;
  position: relative;
  align-items: center;
  display: flex;
  justify-content: center;
  color: #fff;
  font-weight: 500;
  margin-right: 8px;
}

.arrow:before {
  content: "";
  border-bottom: 24px solid transparent;
  border-left: 18px solid #fff;
  border-top: 24px solid transparent;
  width: 10px;
  height: 0px;
  display: block;
  position: absolute;
  left: 0;
  top: 0;
}

.arrow:after {
  content: "";
  border-bottom: 24px solid transparent;
  border-left: 18px solid #b7b7b7;
  border-top: 24px solid transparent;
  width: 10px;
  height: 0px;
  display: block;
  position: absolute;
  right: -18px;
  top: 0;
  z-index: 99;
}

.arrow.light {
  background-color: #f4f4f4;
  color: #666666;
  &:after {
    border-left: 18px solid #f4f4f4;
  }
}
</style>
