<template>
  <div>
    <v-btn
      text
      light
      tile
      class="text-none font-weight-medium px-2 px-xl-4"
      color="#686868"
      @click="() => (show = !show)"
      ><slot name="trigger-button"></slot>
    </v-btn>
    <dialog-form
      :showDialog="show"
      @showStateChanged="(state) => (show = state)"
    >
      <dialog-form-header
        :title="$t('components.attributes.chooseAttributes.title')"
      ></dialog-form-header>
      <dialog-form-section-one-col>
        <v-row class="">
          <v-col cols="12" sm="5">
            <v-row dense>
              <v-col>
                <dialog-form-text-input
                  :placeholder="
                    $t('components.attributes.chooseAttributes.searchAttribute')
                  "
                  v-model="search"
                  solo
                  dense
                  type="text"
                ></dialog-form-text-input>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <dialog-form-title
          :title="$t('components.attributes.chooseAttributes.className')"
        ></dialog-form-title>
        <v-row class="">
          <v-col cols="12">
            <v-row dense class="py-2">
              <v-col cols="12">
                <v-row no-gutters>
                  <v-col>
                    <v-expansion-panels accordion class="accordeon-checkbox">
                      <v-expansion-panel
                        v-for="(
                          attributeList, classId
                        ) in attributeListsBasedOnClass"
                        :key="attributeList.id"
                      >
                        <v-expansion-panel-header>
                          <v-checkbox
                            class="no-gutters"
                            hide-details
                            :input-value="selectedPartial(classId)"
                            :indeterminate="
                              selectedPartialIndeterminate(classId)
                            "
                            @change="togglePartial(classId, $event)"
                            @click="stopPropagation($event)"
                          >
                          </v-checkbox>
                          <span class="" style="font-weight: bold">
                            {{ getClassName(classId) }}
                          </span>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content
                          v-for="attribute in attributeList"
                          :key="attribute.id"
                        >
                          <v-checkbox
                            class="no-gutters"
                            hide-details
                            v-model="form.attributeIds"
                            :value="attribute.id"
                          >
                            <template v-slot:label>
                              <span class="" style="font-size: 0.875rem">
                                {{ formatAttributeName(attribute.name) }}
                              </span>
                            </template>
                          </v-checkbox>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12">
            <v-row dense>
              <v-col cols="6">
                <div class="pl-2">
                  <v-checkbox hide-details v-model="selectedAll">
                    <template v-slot:label>
                      <span class="" style="font-size: 0.875rem">
                        {{ $t("common.actions.selectAll") }}
                      </span>
                    </template>
                  </v-checkbox>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </dialog-form-section-one-col>
      <v-card-actions class="py-6 px-6 d-flex flex-column-reverse flex-md-row">
        <v-btn
          class="text-none ml-0 mb-4 mb-md-0 align-self-stretch"
          color="#686868"
          elevation="0"
          tile
          dark
          x-large
          text
          @click="show = false"
        >
          <v-icon color="#686868">mdi-close</v-icon>
          <p class="mb-0">
            {{ $t("common.actions.close") }}
          </p>
        </v-btn>
        <v-btn
          class="text-none ml-0 mb-4 mb-md-0 align-self-stretch"
          color="#686868"
          elevation="0"
          tile
          dark
          x-large
          text
          @click="
            () => {
              $emit('resetLayout');
              show = false;
            }
          "
        >
          <v-icon color="#686868">mdi-arrow-u-left-top</v-icon>
          <p class="mb-0">
            {{ $t("common.filters.reset") }}
          </p>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          class="text-none ml-0 mb-4 mb-md-0 ml-md-4 px-6 align-self-stretch"
          color="#266663"
          dark
          elevation="0"
          tile
          x-large
          @click="changeLayout()"
          :disabled="loading"
        >
          <v-icon class="white--text">mdi-content-save-outline</v-icon>
          <p class="white--text mb-0">
            {{ $t("common.actions.save") }}
          </p>
        </v-btn>
      </v-card-actions>
    </dialog-form>
  </div>
</template>

<script>
import "core-js/actual/array/group-by";
import DialogForm from "../Dialog/DialogForm.vue";
import DialogFormHeader from "../Dialog/DialogFormHeader.vue";
import DialogFormSectionOneCol from "../Dialog/DialogFormSectionOneCol.vue";
import DialogFormTextInput from "../Dialog/inputs/DialogFormTextInput.vue";
import DialogFormTitle from "../Dialog/output/DialogFormTitle.vue";

export default {
  components: {
    DialogForm,
    DialogFormHeader,
    DialogFormSectionOneCol,
    DialogFormTextInput,
    DialogFormTitle,
  },
  props: {
    value: Boolean,
    dialog: Boolean,
    layout: Object,
    attributes: Array,
    attributeClasses: Array,
    organizationId: String,
  },
  emits: ["input", "changeLayout"],
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    availableAttributes() {
      var search = this.search.toLowerCase();
      return this.attributes.filter((x) => {
        return x.name.toLowerCase().includes(search);
      });
    },
    attributeListsBasedOnClass() {
      const combinedAttributes = [...this.attributes];

      const attributeGroups = combinedAttributes.groupBy((attribute) => {
        return attribute.attributeClass?.id;
      });

      return attributeGroups;
    },
    selectedAll: {
      set(val) {
        this.form.attributeIds = [];
        if (val) {
          this.form.attributeIds = this.attributes.map((x) => x.id);
        }
      },
      get() {
        return this.form.attributeIds.length === this.attributes.length;
      },
    },
  },
  data() {
    return {
      loading: false,
      form: this.$inertia.form({
        attributeIds: this.layout.attributeIds ?? [],
      }),
      search: "",
    };
  },
  watch: {
    layout() {
      this.form.attributeIds = this.layout.attributeIds;
    },
  },
  methods: {
    changeLayout() {
      this.$emit("changeLayout", {
        attributeIds: this.form.attributeIds,
      });
      this.show = false;
    },
    selectedPartial(classId) {
      let partialAttributeIds = this.getPartialAttributeIds(classId);

      let checkForAllSelected = partialAttributeIds.every((x) =>
        this.form.attributeIds.includes(x)
      );

      if (checkForAllSelected) return checkForAllSelected;

      let checkForNoneSelected = this.form.attributeIds.some((x) =>
        partialAttributeIds.includes(x)
      );

      if (checkForNoneSelected) return !checkForNoneSelected;
    },
    selectedPartialIndeterminate(classId) {
      let partialAttributeIds = this.getPartialAttributeIds(classId);

      let checkForAllSelected = partialAttributeIds.every((x) =>
        this.form.attributeIds.includes(x)
      );

      let checkForNoneSelected = this.form.attributeIds.some((x) =>
        partialAttributeIds.includes(x)
      );

      if (!checkForNoneSelected) return null;

      return !checkForAllSelected;
    },
    togglePartial(classId, value) {
      let partialAttributeIds = this.getPartialAttributeIds(classId);

      if (value) {
        let attributesNotIncluded = partialAttributeIds.filter(
          (x) => !this.form.attributeIds.includes(x)
        );

        this.form.attributeIds = [
          ...this.form.attributeIds,
          ...attributesNotIncluded,
        ];
      } else {
        let attributesIncluded = partialAttributeIds.filter((x) =>
          this.form.attributeIds.includes(x)
        );

        this.form.attributeIds = this.form.attributeIds.filter(
          (x) => !attributesIncluded.includes(x)
        );
      }
    },
    getPartialAttributeIds(classId) {
      return this.attributes
        .filter((x) => x.attributeClass?.id === classId)
        .map((x) => x.id);
    },
    stopPropagation(event) {
      event.stopPropagation();
    },
    getClassName(classId) {
      if (!classId || classId === "undefined")
        return this.$t("components.attributes.chooseAttributes.noClass");

      return this.attributeClasses.find((x) => x.id === classId)?.name;
    },
    formatAttributeName(name) {
      const translationKey = `common.attributeNames.${name}`;

      const isNameTranslated = this.$te(translationKey);

      return isNameTranslated ? this.$t(translationKey) : name;
    },
  },
};
</script>
<style scoped>
.solo-item-list-area {
  height: 25vh;
  overflow-y: auto;
  border-radius: 4px;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.87);
  background: #ffffff;
  background-origin: padding-box;
  background-clip: border-box;
  background-position-x: 0%;
  background-position-y: 0%;
  background-repeat: repeat;
  background-attachment: scroll;
  background-image: none;
  background-size: auto;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  transition: background 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.no-gutters {
  margin: 0;
  padding: 0;
}

.accordeon-checkbox
  .v-expansion-panel-header
  > :not(.v-expansion-panel-header__icon) {
  flex: unset !important;
}
</style>
