<template>
  <v-tooltip bottom color="#1E1E1E" min-width="320px" nudge-bottom="5px">
    <template v-slot:activator="{ on, attrs }">
      <div class="bar-chart" v-bind="attrs" v-on="on">
        <div
          v-for="(bar, index) in data"
          :key="index"
          class="bar"
          :style="styleForBar(bar)"
        ></div>
      </div>
    </template>
    <div class="tooltip">
      <div
        v-for="(bar, index) in data"
        :key="index"
        class="d-flex flex-row align-center rendered-value-row"
      >
        <div
          class="mr-2 rendered-color"
          :style="'background-color: ' + bar.color + ';'"
        ></div>
        <div class="flex-grow-1">{{ bar.label }}</div>
        <div class="mr-4">{{ bar.renderedValue }}</div>
        <div class="rendered-percentage">{{ renderedPercentage(bar) }}</div>
      </div>
    </div>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    data: Array,
  },
  methods: {
    styleForBar(bar) {
      let styles = "height: " + bar.fractionValue * 100 + "%;";
      styles += "background-color: " + bar.color + ";";
      return styles;
    },
    renderedPercentage(bar) {
      const percentage =
        Math.round((bar.rawValue / this.summedRawValues) * 10000) / 100;

      if (isNaN(percentage)) {
        return "0%";
      }

      return percentage + "%";
    },
  },
  computed: {
    summedRawValues() {
      var rawValues = this.data.map((b) => b.rawValue);
      return rawValues.reduce((a, b) => a + b);
    },
  },
};
</script>

<style lang="scss" scoped>
.bar-chart {
  display: flex;
  flex-direction: row;
  height: 25px;
  align-items: flex-end;
  .bar {
    background-color: grey;
    margin: 0 2px;
    width: 8px;
    &:hover {
      opacity: 0.6;
    }
  }
}
.tooltip {
  &:before {
    content: "";
    height: 17px;
    width: 17px;
    background: #1e1e1e;
    display: block;
    position: absolute;
    left: calc(50%);
    top: 0;
    transform: translate(-50%, -45%) rotate(-45deg) skew(10deg, 10deg);
    border-radius: 2px;
  }
  .rendered-value-row {
    margin: 5px 0;
    .rendered-color {
      height: 14px;
      width: 14px;
      border-radius: 4px;
    }
    .rendered-percentage {
      min-width: 55px;
      text-align: right;
      color: #b4b4b4;
    }
  }
}
</style>
