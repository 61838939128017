<template>
  <div class="error-container">
    <div>
      <h1 class="error-code">{{ errorCode }}</h1>
      <h2 class="error-code-text">Oops! The page</h2>
      <h2 class="error-code-text">doesn't exist</h2>
      <div class="button-container">
        <v-btn class="button" @click="goBack()"> Back </v-btn>
        <v-btn class="button" @click="goHome()"> Go Home </v-btn>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    errorCode: Number,
  },
  methods: {
    goBack() {
      window.history.back();
    },
    goHome() {
      this.$inertia.get(this.route("dashboard.index"));
    },
  },
};
</script>
<style scoped>
.error-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: black;
  font-family: Lexend, sans-serif !important;
}

.error-code {
  display: flex;
  color: white;
  font-size: 10rem;
  justify-content: center;
}

.error-code-text {
  display: flex;
  color: white;
  font-size: 5rem;
  justify-content: center;
}

.button-container {
  display: flex;
  justify-content: center;
  padding-top: 4rem;
}
.button {
  background-color: #f25d3b !important;
  color: white;
  font-weight: normal;
  font-family: "Lexend", sans-serif !important;
  border: none;
  border-radius: 0;
  font-size: 1.5rem;
  margin-left: 2rem;
  margin-right: 2rem;
  height: 4rem !important;
  width: 12rem !important;
}
</style>
