<template>
  <v-tab-item :transition="false" :value="tabHref">
    <v-card
      elevation="0"
      min-height="17.625rem"
      class="d-flex flex-column ma-4"
    >
      <v-card-text class="pa-0 flex-grow-1">
        <div>
          <v-row class="d-flex align-start px-2">
            <v-col md="3" cols="12">
              <dialog-form-text-input
                :value="filters.search"
                @change="filters.search = $event"
                append-icon="mdi-magnify"
                :title="$t('common.dropdowns.search.label')"
              ></dialog-form-text-input>
            </v-col>
            <v-col md="3" cols="12"></v-col>
            <v-spacer></v-spacer>
            <v-col col="1" class="d-flex justify-end">
              <v-btn
                large
                class="align-self-stretch align-self-sm-center mt-5 mt-sm-0"
                elevation="0"
                tile
                color="#F25D3B"
                dark
                v-inertia
                :href="route('users.create')"
              >
                <v-icon class="pr-3 block" color="#fff" small>mdi-plus</v-icon>
                {{ $t("pages.users.index.create") }}
              </v-btn>
            </v-col>
          </v-row>
          <v-row class="px-2">
            <v-col>
              <v-data-table
                class="elevation-0"
                :headers="headers"
                :items="users.data"
                :server-items-length="users.totalCount"
                :options.sync="pagination"
                :must-sort="true"
                :footer-props="tableFooterProps"
                @click:row="rowClick"
                @update:page="changePage(pagination)"
                @update:items-per-page="changePage(pagination)"
                @update:sort-desc="changePage(pagination)"
                @update:sort-by="changePage(pagination)"
              >
                <template v-slot:item.role="{ item }">
                  <v-chip :color="item.role.color" dark>
                    {{
                      $te(`common.roles.${item.role.name}`)
                        ? $t(`common.roles.${item.role.name}`)
                        : item.role.name
                    }}
                  </v-chip>
                </template>
                <template v-slot:item.disabledAt="{ item }">
                  {{
                    !!item.disabledAt
                      ? $t("pages.users.index.inactive")
                      : $t("pages.users.index.active")
                  }}
                </template>
                <template v-slot:item.actions>
                  <v-icon class="mr-2"> mdi-chevron-right </v-icon>
                </template>
                <template #footer.page-text="pageTextProps">
                  {{ $t("common.dataTable.visibleRows", pageTextProps) }}
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
    </v-card>
  </v-tab-item>
</template>

<script>
import debounce from "lodash/debounce";
import pickBy from "lodash/pickBy";
import { appLayout } from "@/util/layout";
import { footerProps } from "@/util/dataTable";
import DialogFormTextInput from "../../../Components/Dialog/inputs/DialogFormTextInput.vue";

export default {
  components: { DialogFormTextInput },
  layout: appLayout({ title: "pages.users.index.title" }),
  props: {
    users: Object,
    roles: Array,
    tabHref: String,
  },
  data() {
    var searchParams = new URLSearchParams(window.location.search);
    return {
      pagination: {
        page: this.users.currentPage,
        itemsPerPage: 50,
        sortBy: [searchParams.get("sortBy") || "name"],
        sortDesc: [searchParams.get("sortDesc") === "true"],
      },
      tableFooterProps: footerProps,
      filters: {
        search: searchParams.get("filters[search]"),
        role: searchParams.get("filters[role]"),
      },
    };
  },
  computed: {
    headers() {
      return [
        { text: this.$t("common.fieldNames.name"), value: "name" },
        { text: this.$t("pages.users.index.columns.email"), value: "email" },
        {
          text: this.$t("pages.users.index.columns.role"),
          value: "role",
          sortable: false,
        },
        {
          text: this.$t("pages.users.index.columns.status"),
          value: "disabledAt",
        },
        {
          text: this.$t("common.fieldNames.actions"),
          value: "actions",
          sortable: false,
          align: "right",
        },
      ];
    },
  },
  watch: {
    filters: {
      handler: debounce(function () {
        this.pagination = { page: 1, sortBy: [], sortDesc: [] };
        this.changePage(this.pagination);
      }, 150),
      deep: true,
    },
  },
  methods: {
    rowClick(item) {
      this.$inertia.get(this.route("users.edit", item.id));
    },
    changePage(options) {
      let query = {
        filters: pickBy(this.filters),
        sortBy: options.sortBy[0],
        sortDesc: options.sortDesc[0],
        page: options.page,
        pageSize: options.itemsPerPage,
        tab: this.tabHref,
      };
      const url = `${this.route("user-management.index", query)}#users`;
      this.$inertia.get(url);
    },
    resetFilter() {
      for (let filter in this.filters) {
        this.filters[filter] = null;
      }
    },
    getColor(role) {
      if (role == "Support") {
        return "green";
      } else if (role == "Admin") {
        return "blue";
      }
    },
  },
};
</script>
<style lang="css" scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
