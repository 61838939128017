var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-data-table',{directives:[{name:"sortable-table",rawName:"v-sortable-table",value:({ onEnd: _vm.sortTableHeaders }),expression:"{ onEnd: sortTableHeaders }"}],key:_vm.rerenderCount,ref:"entry-table",staticClass:"entry-table",attrs:{"headers":_vm.accountingEntryHeaders,"footer-props":_vm.tableFooterProps,"items":_vm.entries,"checkbox-color":"#F25D3B","server-items-length":_vm.entryItemsTotalCount ?? null,"items-per-page":_vm.entryItemsPerPage,"options":_vm.accountingEntryTableOptions,"multi-sort":"","show-select":"","no-data-text":_vm.$t('common.dataTable.noData')},on:{"update:options":function($event){_vm.accountingEntryTableOptions=$event},"update:page":function($event){return _vm.changePage()},"update:sort-desc":function($event){return _vm.changeSorting()},"toggle-select-all":({ value }) => {
        _vm.setSelectionForAllEntries(value);
        _vm.setSelectionForAllAssets(value);
      },"update:items-per-page":($event) => _vm.updatedItemsPerPage($event)},scopedSlots:_vm._u([_vm._l((_vm.headers),function(header){return {key:`header.${header.value}`,fn:function(){return [(header.value !== 'action')?_c('div',{key:header.key},[_c('div',{staticClass:"sticky-header-item"},[_c('span',{staticClass:"sticky-header",style:('top:' + _vm.headerTopOffset + 'px;')},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('em',_vm._g({},on),[_c('span',[_vm._v(_vm._s(header.text)+" ")])])]}}],null,true)},[_c('span',[_vm._v(_vm._s(header.text)+" ")])])],1)])]):_vm._e()]},proxy:true}}),{key:"item",fn:function({ isSelected, select, item }){return [_c('tr',[_c('td',{staticClass:"text-start fixed"},[_c('v-simple-checkbox',{attrs:{"color":"#F25D3B","ripple":false,"value":isSelected,"indeterminate":_vm.indeterminateEntries[item.id]},on:{"input":() => {
                _vm.setEntrySelection(item.id, !isSelected);
                select(!isSelected);
              }}})],1),_c('td',[(_vm.expandedItems[item.id])?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.setExpanded(item.id, false)}}},[_c('v-icon',[_vm._v("mdi-minus")])],1):_c('v-btn',{attrs:{"icon":""},on:{"click":() => {
                _vm.setExpanded(item.id, true, isSelected);
              }}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1),_vm._l((_vm.headers),function(header){return _c('td',{key:header.value,staticClass:"text-start"},[_c('div',{staticClass:"fill-height d-flex align-center"},[(header.value === 'Emissions')?_c('span',[_vm._v(_vm._s(_vm.emissionFormatter(item[header.value])))]):(header.value === 'EntryDate')?_c('span',[_vm._v(_vm._s(_vm.formatDate(item[header.value])))]):(header.value === 'EntryAmount')?_c('span',{staticStyle:{"text-align":"right","width":"100%"}},[_vm._v(_vm._s(item["EntryCurrency"] + " " + _vm.formatCurrency(item[header.value])))]):(header.value === 'File')?_c('a',{attrs:{"href":item?.File
                  ? _vm.route('api.files.content', {
                      id: item.File.id,
                      filename: item.File.originalName,
                    })
                  : undefined,"target":"_blank"}},[(item?.File)?_c('v-icon',[_vm._v("mdi-file-document-outline")]):_vm._e()],1):_c('span',[_vm._v(_vm._s(item[header.value]))])])])})],2),_c('tr',{staticClass:"assets-table",class:_vm.expandedItems[item.id] ? 'expanded' : null},[_c('td',{attrs:{"colspan":_vm.headers.length + 2}},[_c('v-data-table',{staticClass:"inner-table",attrs:{"headers":_vm.assetHeaders,"items":_vm.assetsForEntries[item.id],"hide-default-footer":"","loading":_vm.loadingAssetsForEntries[item.id],"items-per-page":-1},on:{"dblclick:row":(_, { item }) => {
                _vm.editAsset(item.id);
              }},scopedSlots:_vm._u([{key:"item.checkbox",fn:function(checkBoxProps){return [_c('v-simple-checkbox',{attrs:{"color":"#F25D3B","ripple":false,"value":_vm.selectedAssetsIds.includes(checkBoxProps.item.id) ||
                  isSelected},on:{"click":() => {
                    _vm.setAssetSelection(
                      checkBoxProps.item.id,
                      !!!_vm.selectedAssetsIds.includes(checkBoxProps.item.id)
                    );

                    // Set entry selected when all assets inside are selected
                    let entrySelected = _vm.entryAssetSelectionStatus[item.id]
                      ?.hasUnSelectedAssets
                      ? false
                      : true;
                    select(entrySelected);
                    _vm.setEntrySelection(item.id, entrySelected, true);
                  }}})]}},{key:"item.scope",fn:function({ item }){return [_c('ScopeBadge',{attrs:{"scope":_vm.getScopeNumberById(item.scopeId)}})]}},{key:"item.purchase_price",fn:function({ item }){return [_c('span',{staticStyle:{"display":"flex","justify-content":"right"}},[_vm._v(" "+_vm._s(item.purchasePriceCurrency)+" "+_vm._s(_vm.formatCurrency(item.purchasePriceAmount))+" ")])]}},{key:"item.calculation_method",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.getCalculationMethodIdentifier(item.calculationMethodId))+" ")]}},{key:"item.ghg_category",fn:function({ item }){return [_vm._v(" "+_vm._s(item.ghgCategory?.name)+" ")]}},{key:"item.emissions",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.emissionFormatter(item.co2Emissions))+" ")]}},{key:"item.category",fn:function({ item }){return [_c('div',{staticClass:"fill-height d-flex align-center"},[_c('span',[_vm._v(_vm._s(item?.emissionFactor?.category?.name))])])]}}],null,true)})],1)])]}},{key:"footer",fn:function(){return [_c('v-expand-transition',[_c('v-card',{directives:[{name:"show",rawName:"v-show",value:(_vm.selectedAssetsIds.length + _vm.selectedEntryIds.length > 0),expression:"selectedAssetsIds.length + selectedEntryIds.length > 0"}],staticStyle:{"position":"sticky","bottom":"0","z-index":"200"},attrs:{"transition":"scroll-y-transition","tile":"","elevation":"0","dark":""}},[_c('div',{staticClass:"px-4 d-flex flex-row py-4"},[_c('div',{staticClass:"flex-grow-1 align-center d-flex"},[_c('v-simple-checkbox',{attrs:{"on-icon":"mdi-minus-box","color":"#F25D3B","ripple":false,"value":true},on:{"input":() => {
                    _vm.setSelectionForAllAssets(false);
                    _vm.setSelectionForAllEntries(false);
                  }}}),_c('v-avatar',{staticClass:"ml-5 mr-3",attrs:{"color":"#545454","size":"24"}},[_c('span',{staticClass:"white--text text-body-2"},[_vm._v(_vm._s(_vm.selectionCount))])]),_c('span',[_vm._v(_vm._s(_vm.$t("common.dataTable.itemsSelected")))])],1),_c('div',{},[_c('v-btn',{staticClass:"text-none",attrs:{"large":"","elevation":"0","outlined":"","tile":""},on:{"click":() => _vm.showBulkEdit()}},[_c('v-icon',{staticClass:"mr-3",attrs:{"dark":""}},[_vm._v("mdi-pencil")]),_vm._v(" "+_vm._s(_vm.$t("common.dataTable.editAll"))+" ")],1),_c('v-btn',{staticClass:"text-none ml-4",attrs:{"color":"red","large":"","elevation":"0","tile":""},on:{"click":() => _vm.removeRange()}},[(false)?_c('v-progress-circular',{staticClass:"mr-3",attrs:{"size":24,"width":3,"indeterminate":""}}):_c('v-icon',{staticClass:"mr-3",attrs:{"dark":""}},[_vm._v("mdi-trash-can-outline")]),_vm._v(" "+_vm._s(_vm.$t("common.dataTable.deleteAll"))+" ")],1),_c('v-btn',{staticClass:"text-none ml-4",attrs:{"large":"","color":"#F25D3B","elevation":"0","tile":""},on:{"click":_vm.unVerifyRange}},[_c('AIRobot',{staticClass:"ai-robot-icon mr-3",attrs:{"width":"20px","height":"20px"}}),_vm._v(" "+_vm._s(_vm.$t("common.dataTable.unVerifyAll"))+" ")],1),_c('v-btn',{staticClass:"text-none ml-4",attrs:{"large":"","color":"#266663","elevation":"0","tile":""},on:{"click":_vm.verifyRange}},[_c('v-icon',{staticClass:"mr-3",attrs:{"dark":""}},[_vm._v("mdi-check-circle")]),_vm._v(" "+_vm._s(_vm.$t("common.dataTable.verifyAll"))+" ")],1)],1)])])],1)]},proxy:true}],null,true),model:{value:(_vm.selectedEntries),callback:function ($$v) {_vm.selectedEntries=$$v},expression:"selectedEntries"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }