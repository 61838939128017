<template>
  <div>
    <v-alert
      v-if="errors && Object.keys(errors).length > 0"
      class="error-messages"
    >
      <div>
        <ul>
          <li v-for="(message, field) in errors" :key="field">
            {{ message }}
          </li>
        </ul>
      </div>
    </v-alert>
  </div>
</template>

<script>
export default {
  props: {
    errors: Object,
  },
};
</script>

<style scoped>
.error-messages {
  color: red;
  margin-top: 10px;
}
</style>
