<template>
  <dialog-form
    :show-dialog="show"
    @showStateChanged="(val) => (show = val)"
    :maxWidth="'550px'"
    :key="rerender"
  >
    <dialog-form-header
      :title="
        $t('pages.assets.form.bulkEdit.edit') +
        ' ' +
        assetIds.length +
        ' ' +
        $t('pages.assets.form.bulkEdit.selectedItems')
      "
      :tabs="attributeClassTabs.map((tab) => tab.name)"
      :selected-tab="tab"
      @onChangedTab="(t) => (tab = t)"
    >
    </dialog-form-header>
    <dialog-form-section-one-col>
      <v-tabs-items v-model="tab">
        <template v-for="(attributeClass, index) in attributeClassTabs">
          <v-tab-item
            v-if="attributeClass.id === fixedClasses.generalId"
            :key="attributeClass.id"
            :value="index"
          >
            <asset-attribute-link-input
              :attribute="unitTypeAttribute"
              :value="unitTypeData"
              @input="updateData(unitTypeAttribute.id, $event)"
              @select_changed="updateSelectValue(unitTypeAttribute, $event)"
              :placeholder="$t('pages.assets.form.bulkEdit.selectUnit')"
            />
            <asset-attribute-link-input
              :attribute="purchaseDateAttribute"
              :value="purchaseDateData"
              @input="updateData(fixedAttributes.generalPurchaseDateId, $event)"
              @dateTime_changed="updateDateValue(purchaseDateAttribute, $event)"
            />
            <dialog-form-select-input
              :title="$t('pages.assets.form.fixedLabels.purchasedAs')"
              :items="purchasedAsOptions"
              v-model="selectedPurchasedAs"
              clearable
              item-text="text"
              item-value="id"
              hideDetails
              :placeholder="$t('pages.assets.form.bulkEdit.selectCondition')"
            ></dialog-form-select-input>
            <asset-attribute-link-input
              v-if="selectedPurchasedAs === 2"
              :attribute="productionDateAttribute"
              :value="productionDateData"
              @input="
                updateData(fixedAttributes.generalProductionDateId, $event)
              "
              @dateTime_changed="
                updateDateValue(productionDateAttribute, $event)
              "
            />
            <asset-attribute-link-input
              :attribute="modelAttribute"
              :value="modelData"
              @input="updateData(modelAttribute.id, $event)"
              @string_changed="handleModelChanged($event)"
            />
            <asset-attribute-link-input
              :attribute="purchasePriceAttribute"
              :value="purchasePriceData"
              :currencies="currencies"
              :onlyCurrency="true"
              :secondValue="purchasePriceSecondValueData"
              @input_secondValue="
                updateSecondValueData(
                  fixedAttributes.generalPurchasePriceId,
                  arguments[0]
                )
              "
              @currency_changed="
                updateCurrencyValue(
                  purchasePriceAttribute,
                  $event,
                  purchasePriceSecondValueData
                )
              "
              @currencyCode_changed="
                updateCurrencyCodeValue(
                  purchasePriceAttribute,
                  purchasePriceData,
                  $event
                )
              "
            />
          </v-tab-item>
          <v-tab-item
            :value="index"
            v-if="attributeClass.id === fixedClasses.co2Id"
            :key="attributeClass.id"
          >
            <asset-attribute-link-input
              :attribute="emissionSourceAttribute"
              :value="emissionSourceData"
              @input="updateData(fixedAttributes.co2EmissionSourceId, $event)"
              @select_changed="handleEmissionSourceChanged"
            />
            <asset-attribute-link-input
              v-if="emissionSourceData"
              :attribute="ghgCategoryAttribute"
              :value="form.ghgCategoryId"
              :select-options="filteredGhgCategories"
              select-item-text="name"
              select-item-value="id"
              @input="updateData(ghgCategoryAttribute.id, $event)"
              @select_changed="handleGhgCategoryIdChanged"
            />
            <asset-attribute-link-input
              :attribute="methodAttribute"
              :value="methodData"
              @input="updateData(fixedAttributes.co2MethodId, $event)"
              @select_changed="handleMethodChanged"
            />
            <asset-attribute-link-input
              :attribute="sectorAttribute"
              :value="sectorData"
              :selectOptions="emissionSectors"
              @input="updateData(fixedAttributes.co2SectorId, $event)"
              @select_changed="handleSectorChanged"
              :loading="loadingFields['sectors']"
            />
            <asset-attribute-link-input
              :attribute="categoryAttribute"
              :value="categoryData"
              :selectOptions="emissionCategories"
              @input="updateData(fixedAttributes.co2CategoryId, $event)"
              @select_changed="handleCategoryChanged"
              :loading="loadingFields['categories']"
            />
            <template v-if="!isSupplierSpecificMethod">
              <asset-attribute-link-input
                :attribute="regionAttribute"
                :value="regionData"
                :selectOptions="emissionRegions"
                @input="updateData(fixedAttributes.co2RegionId, $event)"
                @select_changed="handleRegionChanged"
                :loading="loadingFields['regions']"
              />
              <asset-attribute-link-input
                :attribute="nameAttribute"
                :value="nameData"
                :selectOptions="emissionFactors"
                @input="updateData(fixedAttributes.co2NameId, $event)"
                @select_changed="handleNameChanged"
                :loading="loadingFields['emissionFactors']"
              >
                <template #selectItem="{ item }">
                  {{ item.value }}
                </template>
              </asset-attribute-link-input>
            </template>
            <template v-if="isSupplierSpecificMethod">
              <asset-attribute-link-input
                :attribute="emissionsAttribute"
                :value="emissionsData"
                @input="updateData(emissionsAttribute.id, $event)"
                @decimal_changed="
                  updateAssetAttributeLinkDecimalValue(
                    $event,
                    emissionsAttribute
                  )
                "
              />
              <asset-attribute-link-input
                :attribute="documentationAttribute"
                :value="documentationData"
                :secondValue="documentationSecondValueData"
                @input="updateData(documentationAttribute.id, $event)"
                @input_secondValue="
                  updateSecondValueData(documentationAttribute.id, $event)
                "
                @file_changed="
                  updateAssetAttributeFileLink($event, documentationAttribute)
                "
                @file_removed="
                  removeAssetAttributeFileLink($event, documentationAttribute);
                "
              />
            </template>
          </v-tab-item>
          <v-tab-item
            :value="index"
            v-if="attributeClass.id === fixedClasses.infoId"
            :key="attributeClass.id"
          >
            <asset-attribute-link-input
              class="my-2"
              :attribute="supplierAttribute"
              :value="supplierData"
              @input="updateData(supplierAttribute.id, $event)"
              @string_changed="handleSupplierChanged"
          /></v-tab-item>
        </template>
      </v-tabs-items>
    </dialog-form-section-one-col>
    <dialog-form-footer>
      <template #right>
        <v-btn
          @click="
            () => {
              show = false;
              clearForm();
            }
          "
          class="text-none px-4 mb-4 mb-md-0 align-self-stretch"
          color="#686868"
          elevation="0"
          text
          tile
          x-large
        >
          <v-icon class="mr-3" color="#686868">mdi-close</v-icon>
          {{ $t("common.actions.cancel") }}
        </v-btn>
        <v-btn
          class="text-none ml-0 mb-4 mb-md-0 ml-md-4 align-self-stretch white--text"
          color="#F25D3B"
          elevation="0"
          tile
          x-large
          :disabled="loading"
          @click="saveAsset(false)"
        >
          {{ $t("common.actions.save") }}
        </v-btn>
        <v-btn
          class="text-none ml-0 mb-4 mb-md-0 ml-md-4 px-6 align-self-stretch white--text"
          color="#266663"
          elevation="0"
          tile
          x-large
          @click="saveAsset(true)"
          :disabled="loading"
        >
          {{ $t("common.actions.saveAndClose") }}
        </v-btn>
      </template>
    </dialog-form-footer>
  </dialog-form>
</template>

<script>
import DialogForm from "../Dialog/DialogForm.vue";
import DialogFormFooter from "../Dialog/DialogFormFooter.vue";
import DialogFormHeader from "../Dialog/DialogFormHeader.vue";
import {
  co2MethodOptions,
  fixedAttributes,
  fixedClasses,
} from "../../util/fixedAssetData";
import DialogFormSectionOneCol from "../Dialog/DialogFormSectionOneCol.vue";
import { generatePredefinedAttributeLinks } from "../../util/attributeForm";
import AssetAttributeLinkInput from "../../Components/Attribute/AttributeLinkInput.vue";
import { serialize } from "object-to-formdata";
import { removeTimezoneOffset } from "@/util/dateTime";
import DialogFormSelectInput from "../Dialog/inputs/DialogFormSelectInput.vue";
import { formatEmissionFactorName } from "../../util/formatters";

export default {
  components: {
    DialogForm,
    DialogFormHeader,
    DialogFormFooter,
    DialogFormSectionOneCol,
    AssetAttributeLinkInput,
    DialogFormSelectInput,
  },
  props: {
    value: Boolean,
    attributeClasses: Array,
    attributes: Array,
    assetIds: Array,
    ghgCategories: Array,
    currencies: Array,
  },
  mounted() {
    this.clearForm();
    this.getEmissionSectors();

    if (this.sectorData) {
      this.getEmissionCategories(this.sectorData);
    }

    if (this.categoryData) {
      this.getEmissionRegions(this.categoryData);
    }

    if (this.sectorData && this.categoryData && this.regionData) {
      this.getEmissionFactors(
        this.sectorData,
        this.categoryData,
        this.regionData
      );
    }
  },
  data() {
    const [assetAttributeLinkData] = generatePredefinedAttributeLinks(
      this.attributes
    );

    return {
      tab: 0,
      loading: false,
      fixedClasses,
      assetAttributeLinkData,
      form: this.$inertia.form({
        ids: [],
        emissionFactorId: null,
        ghgCategoryId: null,
        assetAttributeLinks: [],
      }),
      purchasedAsOptions: [
        { id: 1, text: "New" },
        { id: 2, text: "Used" },
      ],
      fixedAttributes,
      emissionFactor: null,
      emissionSectors: [],
      emissionCategories: [],
      emissionRegions: [],
      emissionFactors: [],
      loadingFields: {
        sectors: false,
        categories: false,
        regions: false,
        emissionFactors: false,
      },
      updatePurchasedAsOption: false,
      rerender: 0,
    };
  },
  watch: {
    assetIds(to) {
      this.form.ids = to;
    },
    methodData() {
      this.getEmissionSectors();
    },
    sectorData(value) {
      this.getEmissionCategories(value);
    },
    categoryData(value) {
      this.getEmissionRegions(value);
    },
    regionData(value) {
      if (!this.sectorData || !this.categoryData) return;
      this.getEmissionFactors(this.sectorData, this.categoryData, value);
    },
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
        this.rerender++;
      },
    },
    attributeClassTabs() {
      if (!this.attributes.some((x) => x.attributeClassId == null))
        return this.attributeClasses;
      return [
        ...this.attributeClasses,
        { name: this.$t("pages.assets.form.noClass"), id: null },
      ];
    },
    unitTypeAttribute() {
      return this.attributes.find(
        (x) => x.id === fixedAttributes.generalUnitTypeId
      );
    },
    unitTypeData() {
      return this.assetAttributeLinkData[fixedAttributes.generalUnitTypeId];
    },
    purchaseDateAttribute() {
      return this.attributes.find(
        (x) => x.id === fixedAttributes.generalPurchaseDateId
      );
    },
    purchaseDateData() {
      return this.assetAttributeLinkData[fixedAttributes.generalPurchaseDateId];
    },
    productionDateAttribute() {
      return this.attributes.find(
        (x) => x.id === fixedAttributes.generalProductionDateId
      );
    },
    selectedPurchasedAs: {
      get() {
        const productionDate =
          this.assetAttributeLinkData[fixedAttributes.generalProductionDateId];

        if (productionDate && isNaN(new Date(productionDate))) {
          this.updateDateValue(this.productionDateAttribute, null);
          this.updateData(fixedAttributes.generalProductionDateId, null);
          return 1;
        }

        if (productionDate) {
          return 2;
        } else {
          return null;
        }
      },
      set(value) {
        this.updatePurchasedAsOption = true;
        if (value === 1) {
          this.updateDateValue(this.productionDateAttribute, null);
          this.updateData(fixedAttributes.generalProductionDateId, null);
        } else if (value === 2) {
          let productionDate = new Date();
          this.updateData(
            fixedAttributes.generalProductionDateId,
            productionDate
          );
          this.updateDateValue(this.productionDateAttribute, productionDate);
        } else if (value === null) {
          this.updateDateValue(this.productionDateAttribute, null);
          this.updateData(fixedAttributes.generalProductionDateId, null);
          this.updatePurchasedAsOption = false;
        }
      },
    },
    productionDateData() {
      return this.assetAttributeLinkData[
        fixedAttributes.generalProductionDateId
      ];
    },
    modelAttribute() {
      return this.attributes.find(
        (x) => x.id === fixedAttributes.generalModelId
      );
    },
    modelData() {
      return this.assetAttributeLinkData[fixedAttributes.generalModelId];
    },
    ghgCategoryAttribute() {
      return this.attributes.find(
        (x) => x.id === fixedAttributes.co2GhgCategoryId
      );
    },
    emissionSourceData() {
      return this.assetAttributeLinkData[fixedAttributes.co2EmissionSourceId];
    },
    emissionSourceAttribute() {
      return this.attributes.find(
        (x) => x.id === fixedAttributes.co2EmissionSourceId
      );
    },
    filteredGhgCategories() {
      if (!this.emissionSourceData) return [];

      const categories = this.ghgCategories
        .filter((x) => x.scopeId === this.emissionSourceData)
        .sort((a, b) => ((a.sortOrder ?? 0) > (b.sortOrder ?? 0) ? 1 : -1));

      return categories;
    },
    methodAttribute() {
      return this.attributes.find((x) => x.id === fixedAttributes.co2MethodId);
    },
    methodData() {
      return this.assetAttributeLinkData[fixedAttributes.co2MethodId];
    },
    sectorAttribute() {
      return this.attributes.find((x) => x.id === fixedAttributes.co2SectorId);
    },
    sectorData() {
      return this.assetAttributeLinkData[fixedAttributes.co2SectorId];
    },
    formattedUnitType() {
      const defaultValue = this.$t(
        "pages.assets.form.fixedLabels.unknownUnitType"
      );

      if (!this.unitType || !this.unitTypeAttribute) return defaultValue;

      const selectedType = this.unitTypeAttribute.attributeSelectOptions.find(
        (x) => x.id === this.unitType
      )?.value;

      return selectedType ?? defaultValue;
    },
    isAverageDataMethod() {
      return this.methodData === co2MethodOptions.averageDataMethodId;
    },
    isSpendBasedMethod() {
      return this.methodData === co2MethodOptions.spendBasedMethodId;
    },
    isSupplierSpecificMethod() {
      return this.methodData === co2MethodOptions.supplierSpecificMethodId;
    },
    calculationMethod() {
      var calculationMethod = "average";
      if (this.isSpendBasedMethod) {
        calculationMethod = "spend";
      } else if (this.isSupplierSpecificMethod) {
        calculationMethod = "supplier";
      }
      return calculationMethod;
    },
    unitType() {
      return this.assetAttributeLinkData[fixedAttributes.generalUnitTypeId];
    },
    categoryAttribute() {
      return this.attributes.find(
        (x) => x.id === fixedAttributes.co2CategoryId
      );
    },
    categoryData() {
      return this.assetAttributeLinkData[fixedAttributes.co2CategoryId];
    },
    regionData() {
      const assetRegion =
        this.assetAttributeLinkData[fixedAttributes.co2RegionId];

      const fallbackRegion = this.$page.props.settings["defaultRegionId"];

      // When region data is automatically set to default region emission factors are not updated correctly.
      const selectedRegion = assetRegion ? assetRegion : fallbackRegion;

      // Made unconditional to fix VER-509
      // Added getEmissionFactors to force an update (ref VER-333)
      this.getEmissionFactors(
        this.sectorData,
        this.categoryData,
        selectedRegion
      );

      return selectedRegion;
    },
    regionAttribute() {
      return this.attributes.find((x) => x.id === fixedAttributes.co2RegionId);
    },
    nameData() {
      return this.assetAttributeLinkData[fixedAttributes.co2NameId];
    },
    nameAttribute() {
      return this.attributes.find((x) => x.id === fixedAttributes.co2NameId);
    },
    supplierData() {
      return this.assetAttributeLinkData[fixedAttributes.infoSupplierId];
    },
    supplierAttribute() {
      return this.attributes.find(
        (x) => x.id === fixedAttributes.infoSupplierId
      );
    },
    electricitySourceData() {
      return this.assetAttributeLinkData[
        fixedAttributes.co2ElectricitySourceId
      ];
    },
    purchasePriceAttribute() {
      return this.attributes.find(
        (x) => x.id === fixedAttributes.generalPurchasePriceId
      );
    },
    purchasePriceData() {
      return this.assetAttributeLinkData[
        fixedAttributes.generalPurchasePriceId
      ];
    },
    purchasePriceSecondValueData() {
      return this.assetAttributeLinkData[
        fixedAttributes.generalPurchasePriceId + "-secondValue"
      ];
    },
    emissionsAttribute() {
      return this.attributes.find(
        (x) => x.id === fixedAttributes.co2EmissionsId
      );
    },
    emissionsData() {
      return this.assetAttributeLinkData[fixedAttributes.co2EmissionsId];
    },
    documentationAttribute() {
      return this.attributes.find(
        (x) => x.id === fixedAttributes.co2DocumentationId
      );
    },
    documentationData() {
      return this.assetAttributeLinkData[fixedAttributes.co2DocumentationId];
    },
    documentationSecondValueData() {
      return this.assetAttributeLinkData[
        fixedAttributes.co2DocumentationId + "-secondValue"
      ];
    },
  },
  methods: {
    updateData(attributeId, value) {
      this.updateAssetAttributeLinkData(attributeId, value);
    },
    updateSelectValue(attribute, selectedOption) {
      this.updateAssetAttributeLinkSelectOption(selectedOption, attribute);
    },
    updateDateValue(attribute, value) {
      this.updateAssetAttributeLinkDateTimeValue(value, attribute);
    },
    updateAssetAttributeLinkData(attributeId, value) {
      this.assetAttributeLinkData[attributeId] = value;
      /*
      if (attributeId === fixedAttributes.generalPurchaseDateId && value) {
        this.assetAttributeLinkData[
          fixedAttributes.fixedAssetsTimeOfCommissioningId
        ] = null;
      }
      */
    },
    updateAssetAttributeLinkSelectOption(value, attribute) {
      if (value == null) {
        this.form.assetAttributeLinks = this.form.assetAttributeLinks.filter(
          (x) => x.attributeId != attribute.id
        );
        return;
      }

      var assetAttributeLink = this.form.assetAttributeLinks.find(
        (x) => x.attributeId == attribute.id
      );
      if (assetAttributeLink != null) {
        assetAttributeLink.attributeSelectOptionId = value;
      } else {
        this.form.assetAttributeLinks.push({
          attributeId: attribute.id,
          attributeSelectOptionId: value,
        });
      }
    },
    updateAssetAttributeLinkDateTimeValue(value, attribute) {
      if (value == null) {
        this.form.assetAttributeLinks = this.form.assetAttributeLinks.filter(
          (x) => x.attributeId != attribute.id
        );
        return;
      }

      const assetAttributeLink = this.form.assetAttributeLinks.find(
        (x) => x.attributeId == attribute.id
      );

      if (assetAttributeLink != null) {
        assetAttributeLink.dateTimeValue = removeTimezoneOffset(value);
      } else {
        this.form.assetAttributeLinks.push({
          attributeId: attribute.id,
          dateTimeValue: removeTimezoneOffset(value),
        });
      }

      if (
        attribute.id === fixedAttributes.generalPurchaseDateId &&
        value !== null
      ) {
        const timeOfCommissioningAssetAttributeLink = this.attributes.find(
          (x) => x.id == fixedAttributes.fixedAssetsTimeOfCommissioningId
        );

        if (timeOfCommissioningAssetAttributeLink)
          this.updateAssetAttributeLinkDateTimeValue(
            null,
            timeOfCommissioningAssetAttributeLink
          );
      }
    },
    updateAssetAttributeLinkStringValue(value, attribute) {
      if (value == null || !value) {
        this.form.assetAttributeLinks = this.form.assetAttributeLinks.filter(
          (x) => x.attributeId != attribute.id
        );
        return;
      }

      var assetAttributeLink = this.form.assetAttributeLinks.find(
        (x) => x.attributeId == attribute.id
      );
      if (assetAttributeLink != null) {
        assetAttributeLink.stringValue = value;
      } else {
        this.form.assetAttributeLinks.push({
          attributeId: attribute.id,
          stringValue: value,
        });
      }
    },
    updateAssetAttributeLinkBoolValue(value, attribute) {
      if (value == null) {
        this.form.assetAttributeLinks = this.form.assetAttributeLinks.filter(
          (x) => x.attributeId != attribute.id
        );
        return;
      }

      var assetAttributeLink = this.form.assetAttributeLinks.find(
        (x) => x.attributeId == attribute.id
      );
      if (assetAttributeLink != null) {
        assetAttributeLink.boolValue = value;
      } else {
        this.form.assetAttributeLinks.push({
          attributeId: attribute.id,
          boolValue: value,
        });
      }
    },
    handleModelChanged(value) {
      this.updateAssetAttributeLinkStringValue(value, this.modelAttribute);
    },
    handleEmissionSourceChanged(value) {
      this.updateAssetAttributeLinkSelectOption(
        value,
        this.emissionSourceAttribute
      );

      if (this.form.ghgCategoryId) {
        this.form.ghgCategoryId = null;
        this.updateData(this.ghgCategoryAttribute.id, null);
      }
    },
    handleGhgCategoryIdChanged(value) {
      this.updateAssetAttributeLinkStringValue(
        value,
        this.ghgCategoryAttribute
      );
      this.form.ghgCategoryId = value;
    },
    handleMethodChanged(value) {
      this.updateAssetAttributeLinkSelectOption(value, this.methodAttribute);

      if (this.sectorData) {
        this.updateAssetAttributeLinkStringValue(null, this.sectorAttribute);
        this.updateData(fixedAttributes.co2SectorId, null);
      }

      if (this.categoryData) {
        this.updateAssetAttributeLinkStringValue(null, this.categoryAttribute);
        this.updateData(fixedAttributes.co2CategoryId, null);
      }

      if (this.electricitySourceData) {
        this.updateAssetAttributeLinkBoolValue(
          null,
          this.electricitySourceAttribute
        );
        this.updateData(fixedAttributes.co2ElectricitySourceId, null);
      }

      if (this.regionData) {
        this.updateAssetAttributeLinkStringValue(null, this.regionAttribute);
        this.updateData(fixedAttributes.co2RegionId, null);
      }

      if (this.nameData) {
        this.updateAssetAttributeLinkStringValue(null, this.nameAttribute);
        this.updateData(fixedAttributes.co2NameId, null);

        this.emissionFactor = null;
        this.form.emissionFactorId = null;
      }

      if (this.emissionCategories.length) this.emissionCategories = [];
      if (this.emissionRegions.length) this.emissionRegions = [];
      if (this.emissionFactors.length) this.emissionFactors = [];
    },
    handleSectorChanged(value) {
      this.updateAssetAttributeLinkStringValue(value, this.sectorAttribute);

      if (this.categoryData) {
        this.updateAssetAttributeLinkStringValue(null, this.categoryAttribute);
        this.updateData(fixedAttributes.co2CategoryId, null);
      }

      if (this.electricitySourceData) {
        this.updateAssetAttributeLinkBoolValue(
          null,
          this.electricitySourceAttribute
        );
        this.updateData(fixedAttributes.co2ElectricitySourceId, null);
      }

      if (this.regionData) {
        this.updateAssetAttributeLinkStringValue(null, this.regionAttribute);
        this.updateData(fixedAttributes.co2RegionId, null);
      }

      if (this.nameData) {
        this.updateAssetAttributeLinkStringValue(null, this.nameAttribute);
        this.updateData(fixedAttributes.co2NameId, null);
        this.emissionFactor = null;
        this.form.emissionFactorId = null;
      }

      if (this.emissionRegions.length) this.emissionRegions = [];
      if (this.emissionFactors.length) this.emissionFactors = [];
    },
    handleCategoryChanged(value) {
      this.updateAssetAttributeLinkStringValue(value, this.categoryAttribute);

      if (this.electricitySourceData) {
        this.updateAssetAttributeLinkBoolValue(
          null,
          this.electricitySourceAttribute
        );
        this.updateData(fixedAttributes.co2ElectricitySourceId, null);
      }

      if (this.regionData) {
        this.updateAssetAttributeLinkStringValue(null, this.regionAttribute);
        this.updateData(fixedAttributes.co2RegionId, null);
      }

      if (this.nameData) {
        this.updateAssetAttributeLinkStringValue(null, this.nameAttribute);
        this.updateData(fixedAttributes.co2NameId, null);
        this.emissionFactor = null;
        this.form.emissionFactorId = null;
      }

      if (this.emissionFactors.length) this.emissionFactors = [];
    },
    handleRegionChanged(value) {
      this.updateAssetAttributeLinkStringValue(value, this.regionAttribute);

      if (this.nameData) {
        this.updateAssetAttributeLinkStringValue(null, this.nameAttribute);
        this.updateData(fixedAttributes.co2NameId, null);

        this.emissionFactor = null;
        this.form.emissionFactorId = null;
      }
    },
    getEmissionSectors() {
      this.loadingFields["sectors"] = true;

      fetch(
        this.route("api.emission-sectors.index", {
          //spendBased: this.isSpendBasedMethod,
          calculationMethod: this.calculationMethod,
          unitType: this.formattedUnitType,
        })
      )
        .then((res) => res.json())
        .then((data) => {
          this.emissionSectors = data.map((x) => ({
            ...x,
            value: x.name,
          }));
        })
        .finally(() => {
          this.loadingFields["sectors"] = false;
        });
    },
    getEmissionCategories(sectorId) {
      this.loadingFields["categories"] = true;
      fetch(
        this.route("api.emission-categories.index", {
          emissionSectorId: sectorId,
          //spendBased: this.isSpendBasedMethod,
          calculationMethod: this.calculationMethod,
          unitType:
            this.formattedUnitType !== "unknown"
              ? this.formattedUnitType
              : null,
        })
      )
        .then((res) => res.json())
        .then((data) => {
          this.emissionCategories = data.map((x) => ({
            ...x,
            value: x.name,
          }));
        })
        .finally(() => {
          this.loadingFields["categories"] = false;
        });
    },
    getEmissionFactors(sectorId, categoryId, regionId) {
      if (!sectorId || !categoryId || !regionId) return;

      this.loadingFields["emissionFactors"] = true;
      fetch(
        this.route("api.emission-factors.index", {
          emissionSectorId: sectorId,
          emissionCategoryId: categoryId,
          emissionRegionId: regionId,
          emissionFactorId: this.emissionFactor?.id ?? null,
          spendBased: this.isSpendBasedMethod,
          unitType: this.formattedUnitType,
        })
      )
        .then((res) => res.json())
        .then((data) => {
          this.emissionFactors = data.map((x) => ({
            ...x,
            value: formatEmissionFactorName(x),
          }));
        })
        .finally(() => {
          this.loadingFields["emissionFactors"] = false;
        });
    },
    getEmissionRegions(categoryId) {
      this.loadingFields["regions"] = true;
      fetch(
        this.route("api.emission-regions.index", {
          emissionCategoryId: categoryId,
          spendBased: this.isSpendBasedMethod,
          unitType: this.formattedUnitType,
        })
      )
        .then((res) => res.json())
        .then((data) => {
          this.emissionRegions = data.map((x) => ({
            ...x,
            value: x.countryName ? x.countryName : x.name,
          }));
        })
        .finally(() => {
          this.loadingFields["regions"] = false;
        });
    },
    handleNameChanged(value) {
      this.updateAssetAttributeLinkStringValue(value, this.nameAttribute);

      const emissionFactor =
        this.emissionFactors.find((x) => x.id === value) ?? null;

      this.emissionFactor = emissionFactor;
      this.form.emissionFactorId = emissionFactor?.id ?? null;
    },
    handleSupplierChanged(value) {
      this.updateAssetAttributeLinkStringValue(value, this.supplierAttribute);
    },
    updateSecondValueData(attributeId, secondValue) {
      this.updateAssetAttributeLinkDataSecondValue(attributeId, secondValue);
    },
    updateAssetAttributeLinkDataSecondValue(attributeId, value) {
      this.assetAttributeLinkData[attributeId + "-secondValue"] = value;
    },
    updateCurrencyValue(attribute, price, currency) {
      this.updateAssetAttributeLinkCurrency(price, currency, attribute);
    },
    updateAssetAttributeLinkCurrency(value, currencyCode, attribute) {
      if (value == null && currencyCode == null) {
        this.form.assetAttributeLinks = this.form.assetAttributeLinks.filter(
          (x) => x.attributeId != attribute.id
        );
        return;
      }

      if (!currencyCode && !!this.defaultCurrencyCode)
        currencyCode = this.defaultCurrencyCode;

      var assetAttributeLink = this.form.assetAttributeLinks.find(
        (x) => x.attributeId == attribute.id
      );
      if (assetAttributeLink != null) {
        assetAttributeLink.stringValue = currencyCode;
        assetAttributeLink.decimalValue = value;
      } else {
        this.form.assetAttributeLinks.push({
          attributeId: attribute.id,
          stringValue: currencyCode,
          decimalValue: value,
        });
      }
    },
    updateCurrencyCodeValue(attribute, price, currency) {
      this.updateAssetAttributeLinkCurrency(price, currency, attribute);
    },
    updateAssetAttributeLinkDecimalValue(value, attribute) {
      if (value == null || isNaN(value)) {
        this.form.assetAttributeLinks = this.form.assetAttributeLinks.filter(
          (x) => x.attributeId != attribute.id
        );
        return;
      }

      var assetAttributeLink = this.form.assetAttributeLinks.find(
        (x) => x.attributeId == attribute.id
      );
      if (assetAttributeLink != null) {
        assetAttributeLink.decimalValue = value;
      } else {
        this.form.assetAttributeLinks.push({
          attributeId: attribute.id,
          decimalValue: value,
        });
      }
    },
    updateAssetAttributeFileLink(value, attribute) {
      if (value == null || value.length === 0) {
        const assetAttributeLink = this.form.assetAttributeLinks.find(
          (x) => x.attributeId === attribute.id
        );

        if (
          assetAttributeLink.fileId == null &&
          !assetAttributeLink.fileIds.length
        ) {
          this.form.assetAttributeLinks = this.form.assetAttributeLinks.filter(
            (x) => x.attributeId != attribute.id
          );
        }

        return;
      }

      const newFiles = value.length === undefined ? [value] : value;

      var assetAttributeLink = this.form.assetAttributeLinks.find(
        (x) => x.attributeId == attribute.id
      );

      if (assetAttributeLink != null) {
        assetAttributeLink.newFiles = newFiles;
      } else {
        this.form.assetAttributeLinks.push({
          attributeId: attribute.id,
          newFiles: newFiles,
        });
      }
    },
    removeAssetAttributeFileLink(fileId, attribute) {
      var assetAttributeLink = this.form.assetAttributeLinks.find(
        (x) => x.attributeId == attribute.id
      );

      if (assetAttributeLink != null) {
        assetAttributeLink.fileIds = assetAttributeLink.fileIds.filter(
          (x) => x !== fileId
        );
        this.assetAttributeLinkData[attribute.id + "-secondValue"] =
          this.assetAttributeLinkData[attribute.id + "-secondValue"].filter(
            (x) => x.id !== fileId
          );
      }
    },
    clearForm() {
      Object.keys(this.assetAttributeLinkData).forEach((key) => {
        this.assetAttributeLinkData[key] = null;
      });

      this.form.assetAttributeLinks = [];
    },
    saveAsset(close = true) {
      if (this.loading) return;
      this.loading = true;

      const preparedForm = this.form.transform((data) => {
        const transformedData = {
          ...data,
          organizationId: this.organizationId,
          template: this.isTemplatePage,
          updatePurchasedAsOption: this.updatePurchasedAsOption,
          closedOnSave: close,
        };

        return serialize(transformedData, {
          dotsForObjectNotation: true,
          indices: true,
          nullsAsUndefineds: true,
          noFilesWithArrayNotation: true,
        });
      });

      if (this.form.ids) {
        preparedForm.put(this.route("assets.bulk-update"), {
          preserveScroll: true,
          onSuccess: () => {
            if (!close) {
              return;
            }
            this.clearForm();
            this.show = false;
          },
          onFinish: () => {
            this.$emit("reload", close);
            this.loading = false;
          },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
