<template>
  <div>
    <v-row no-gutters class="d-flex align-center mb-4 space-between">
      <v-col>
        <h4 class="text-h4">{{ $t("pages.logs.index.title") }}</h4>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
    <v-row>
      <v-col>
        <v-menu
          v-model="fromPickerActive"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template #activator="{ on, attrs }">
            <v-text-field
              :value="filters.from"
              append-icon="mdi-calendar"
              @click:append="fromPickerActive = true"
              readonly
              dense
              hide-details
              solo
              :label="$t('pages.logs.index.from')"
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            first-day-of-week="1"
            v-model="filters.from"
            @input="onFromUpdated"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col>
        <v-menu
          v-model="toPickerActive"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template #activator="{ on, attrs }">
            <v-text-field
              :value="filters.to"
              append-icon="mdi-calendar"
              @click:append="toPickerActive = true"
              readonly
              dense
              hide-details
              solo
              :label="$t('pages.logs.index.to')"
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            first-day-of-week="1"
            v-model="filters.to"
            :min="filters.from"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col>
        <form autocomplete="off" @submit.prevent="() => {}">
          <v-autocomplete
            v-model="filters.userId"
            :items="users"
            item-text="name"
            item-value="id"
            dense
            solo
            hide-details
            clearable
            :label="$t('pages.logs.index.user.label')"
            :placeholder="$t('pages.logs.index.user.placeholder')"
          ></v-autocomplete>
        </form>
      </v-col>
      <v-col cols="1" class="d-flex justify-center">
        <v-spacer></v-spacer>
        <v-btn
          icon
          :title="$t('pages.logs.index.clearFilters')"
          @click="resetFilters"
        >
          <v-icon>mdi-filter-off-outline</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-data-table
      class="elevation-1"
      :headers="headers"
      :items="logs.data"
      :server-items-length="logs.totalCount"
      :options.sync="pagination"
      :must-sort="true"
      :footer-props="tableFooterProps"
      @update:page="changePage(pagination)"
      @update:items-per-page="changePage(pagination)"
      @update:sort-desc="changePage(pagination)"
      @update:sort-by="changePage(pagination)"
    >
      <template #item.timeAgo="{ item }">
        {{ getEventTimeDiff(item.timestamp) }}
      </template>
      <template #item.timestamp="{ item }">
        {{ formatTimestamp(item.timestamp) }}
      </template>
      <template #item.action="{ item }">
        {{ formatAction(item) }}
      </template>
      <template #footer.page-text="pageTextProps">
        {{ $t("common.dataTable.visibleRows", pageTextProps) }}
      </template>
    </v-data-table>
  </div>
</template>
<script>
import debounce from "lodash/debounce";
import pickBy from "lodash/pickBy";
import { appLayout } from "@/util/layout";
import { footerProps } from "@/util/dataTable";
import {
  toLocaleUTCString,
  normalizeISOString,
  timeAgoInDaysAndHours,
} from "@/util/dateTime";

export default {
  layout: appLayout({ title: "pages.logs.index.title" }),
  props: {
    logs: Object,
    users: Array,
  },
  data() {
    const searchParams = new URLSearchParams(window.location.search);

    return {
      pagination: {
        page: this.logs.currentPage,
        itemsPerPage: 50,
        sortBy: [searchParams.get("sortBy") ?? "timestamp"],
        sortDesc: [
          searchParams.get("sortDesc") === "true" ||
            searchParams.get("sortDesc") === null,
        ],
      },
      tableFooterProps: footerProps,
      filters: {
        from: searchParams.get("filters[from]"),
        to: searchParams.get("filters[to]"),
        userId: searchParams.get("filters[userId]"),
      },
      fromPickerActive: false,
      toPickerActive: false,
    };
  },
  methods: {
    resetFilters() {
      this.filters = {};
    },
    changePage(options) {
      const query = {
        filters: pickBy(this.filters),
        sortBy: options.sortBy[0],
        sortDesc: options.sortDesc[0],
        page: options.page,
        pageSize: options.itemsPerPage,
      };

      this.$inertia.get(
        this.route("logs.index", query),
        {},
        { preserveState: true }
      );
    },
    onFromUpdated() {
      let fromDate = new Date(this.filters.from);
      let toDate = this.filters.to ? new Date(this.filters.to) : null;

      if (toDate && toDate < fromDate) {
        this.filters.to = null;
      }
    },
    formatTimestamp(timestamp) {
      timestamp = normalizeISOString(timestamp);

      return toLocaleUTCString(timestamp);
    },
    getEventTimeDiff(timestamp) {
      return timeAgoInDaysAndHours(timestamp);
    },
    formatAction(logEntry) {
      return this.$t(
        `pages.logs.index.actions.${logEntry.action}`,
        logEntry.parameters ?? {}
      );
    },
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t("common.fieldNames.id"),
          value: "referenceKey",
        },
        {
          text: this.$t("pages.logs.index.fields.timeAgo"),
          value: "timeAgo",
        },
        {
          text: this.$t("pages.logs.index.fields.timestamp"),
          value: "timestamp",
        },
        {
          text: this.$t("pages.logs.index.fields.user"),
          value: "user.name",
        },
        {
          text: this.$t("pages.logs.index.fields.activity"),
          value: "action",
          sortable: false,
        },
      ];
    },
  },
  watch: {
    filters: {
      handler: debounce(function () {
        this.pagination = { page: 1, sortBy: [], sortDesc: [] };
        this.changePage(this.pagination);
      }, 250),
      deep: true,
    },
  },
};
</script>
