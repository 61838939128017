<template>
  <dialog-form :show-dialog="show" max-width="450px" :persistent="true">
    <div class="pa-4">
      <div class="pb-8 d-flex justify-center">
        <LogoBlack />
      </div>

      <div v-if="elementsOptions.clientSecret">
        <h1 class="text-subtitle-1 black--text pb-2">
          {{ $t("pages.payment.titles.addCard") }}
        </h1>
      </div>

      <stripe-element-payment
        ref="paymentRef"
        :pk="publishableKey"
        :elements-options="elementsOptions"
        :confirm-params="confirmParams"
      />

      <div class="pt-4 d-flex justify-end sticky-buttons">
        <v-btn
          tile
          outlined
          elevation="0"
          bg-color="white"
          class="border-sm text-capitalize black--text black--border white"
          @click="closePaymentPopup"
          >{{ $t("pages.payment.buttons.cancel") }}</v-btn
        >
        <v-btn
          tile
          elevation="0"
          color="orange"
          class="font-weight-regular text-capitalize mb-4 mx-4 white--text"
          type="submit"
          @click="pay"
        >
          {{ $t("pages.payment.buttons.pay") }}
        </v-btn>
      </div>
    </div>
  </dialog-form>
</template>

<script>
import { StripeElementPayment } from "@vue-stripe/vue-stripe";
import DialogForm from "../../Components/Dialog/DialogForm.vue";
import LogoBlack from "../../assets/svg/verarca-logo-black.svg";

export default {
  name: "paymentInfo",
  components: {
    StripeElementPayment,
    DialogForm,
    LogoBlack,
  },
  props: {
    value: Boolean,
    subscriptionClientSecret: String,
    publishableKey: String,
    paymentReturnUrl: String,
  },
  data() {
    return {
      elementsOptions: {
        appearance: {
          theme: "stripe",

          variables: {
            colorPrimary: "#000",
            colorBackground: "#ffffff",
            colorText: "#30313d",
            colorDanger: "#df1b41",
            fontFamily: "Lexend, system-ui, sans-serif",
            spacingUnit: "2px",
            borderRadius: "0px",
            gridRowSpacing: "16px",
          },
          rules: {
            ".Tab, .Input, .Block, .CheckboxInput, .CodeInput": {
              boxShadow: "0px 3px 10px rgba(18, 42, 66, 0.08)",
            },
            ".Block": {
              borderColor: "transparent",
            },
            ".BlockDivider": {
              backgroundColor: "#ebebeb",
            },
            ".Tab, .Tab:hover, .Tab:focus": {
              border: "0",
            },
            ".Tab--selected, .Tab--selected:hover": {
              backgroundColor: "#f25d3b",
              color: "#fff",
            },
          },
        },
      },

      confirmParams: {
        return_url: this.paymentReturnUrl,
      },
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  watch: {
    subscriptionClientSecret(to) {
      this.elementsOptions.clientSecret = to;
    },
  },
  methods: {
    pay() {
      this.$refs.paymentRef.submit();
    },
    closePaymentPopup() {
      fetch(this.route("payments.cancel-pending-subscription"), {
        method: "POST",
      });
      this.show = false;
    },
  },
};
</script>

<style scoped>
:deep(#stripe-payment-element-errors) {
  color: red;
}

.sticky-buttons {
  position: sticky;
  background: white;
  bottom: 0;
  z-index: 1;
}
</style>
