import Vue from "vue";
import VueI18n from "vue-i18n";

import merge from "lodash/merge";

import defaultEn from "@/locales/en";
import defaultDa from "@/locales/da";

Vue.use(VueI18n);

const messages = {};

const languageCodeString = localStorage.getItem("language-code-list");

if (languageCodeString) {
  const languageCodes = JSON.parse(languageCodeString);

  languageCodes.forEach((languageCode) => {
    const translationsString = localStorage.getItem(
      `language-${languageCode}-translations`
    );

    const translations = JSON.parse(translationsString);

    if (!translations) return;

    const defaults = structuredClone(defaultEn);

    // Merge remote and local translations so nothing is missing from the translations tables.
    let translationsWithDefaults = merge(defaults, translations);

    messages[languageCode] = translationsWithDefaults;
  });
}

if (!messages.en) messages.en = defaultEn;
if (!messages.da) messages.da = defaultDa;

const i18n = new VueI18n({
  locale: "en",
  fallbackLocale: "en",
  messages,
});

export default i18n;

export function initializeRemoteLanguages(languageFileUrls) {
  if (!languageFileUrls) return;

  const languageCodes = Object.keys(languageFileUrls);

  localStorage.setItem("language-code-list", JSON.stringify(languageCodes));

  languageCodes.forEach((languageCode) => {
    const existingUrl = localStorage.getItem(`language-${languageCode}-url`);
    const settingsUrl = languageFileUrls[languageCode];

    if (!settingsUrl) return;

    const existingTranslations = localStorage.getItem(
      `language-${languageCode}-translations`
    );

    if (existingUrl === settingsUrl && !!existingTranslations) return;

    fetch(settingsUrl)
      .then((res) => res.json())
      .then((data) => {
        localStorage.setItem(
          `language-${languageCode}-translations`,
          JSON.stringify(data)
        );
        localStorage.setItem(`language-${languageCode}-url`, settingsUrl);

        let mergedTranslations = data;

        const defaults = structuredClone(defaultEn);

        if (languageCode === "en") {
          mergedTranslations = merge(defaults, data);
        }

        i18n.setLocaleMessage(languageCode, mergedTranslations);
      });
  });
}
