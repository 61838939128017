export const fixedClasses = {
  generalId: "738ea8b4-d5f0-4dbf-aaf6-d46415faacb8",
  assetsId: "b7ef14fa-034e-43e1-bafd-4da6e784355a",
  legacyId: "0b12ba7b-f6f5-4e62-9ff7-38a7a08375f5",
  primeId: "67e5f441-1af2-43a3-a0d4-eed8d727ac44",
  slaId: "f381661f-a7d3-41e1-861c-4705826d04b2",
};

export const fixedAttributes = {
  generalNameId: "f12086dd-9c82-4ddb-bf1a-4430ddf8292f",
  generalLocationId: "b439da35-6d4a-44de-947c-33e1163eb100",
  generalTemplateId: "ebe643a5-0f1f-4368-a737-bfec9580d299",
  generalQuantityId: "fc0695e0-83c6-4694-947a-6e4d94b13c46",
  slaStartDateId: "7e76f293-f3c1-446d-986d-21384e659f01",
  slaEndDateId: "872fd121-0787-45b4-b95f-489a956b1cde",
};

export const fixedFieldAttributes = {
  generalCreatedById: "302e17ab-c9a2-488d-b9e5-3f6f79cd0df7",
  generalUpdatedById: "5fb3be39-3583-47ab-aef4-f1230ca5c4f9",
  generalCreatedAtId: "3884aaed-ab7a-4c41-8c50-5384f532b263",
  generalUpdatedAtId: "c3eaeee2-2fad-4cc4-ab2e-1429afda06ce",
};
