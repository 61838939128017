var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex-grow-1 d-flex justify-center align-center"},[_c('div',{staticClass:"d-flex justify-center align-center"},[_c('div',{staticClass:"px-0",staticStyle:{"width":"1313px"},attrs:{"fluid":""}},[_c('div',{staticClass:"d-flex report-header"},[_c('div',{staticClass:"report-title flex-grow-1 align-self-center"},[_vm._v(" "+_vm._s(_vm.$t("components.reportTemplates.carbonFootprintOverview.title"))+" ")]),_c('div',{staticClass:"d-flex flex-row"},[_c('div',{staticClass:"date-range d-flex flex-row align-center justify-center mr-8"},[_vm._v(" "+_vm._s(_vm.formattedDateRange.from)+" "),_c('ArrowRightIcon',{staticClass:"mx-4 date-arrow"}),_vm._v(" "+_vm._s(_vm.formattedDateRange.to)+" ")],1),_c('div',{staticClass:"company-name d-flex flex-row align-center"},[_vm._v(" "+_vm._s(_vm.organization.name)+" ")])])]),_c('div',{staticClass:"climate-report-container"},[_c('amount-change-card',{staticClass:"card-asset-num",attrs:{"title":_vm.$t('components.cards.titles.numberOfAssets'),"iconBackColor":"#6493BE1f","value":_vm.assetCardData?.numberOfAssetsCard?.value?.toString() ?? '0',"hideBenchmark":true},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('Package',{staticStyle:{"width":"20px","height":"20px"}})]},proxy:true}])}),_c('amount-change-card',{staticClass:"card-total-co2",attrs:{"title":_vm.$t('components.cards.titles.totalCo2'),"iconBackColor":"#E5EDEC","value":_vm.formatCurrency(_vm.assetCardData?.totalCo2UsedCard?.value),"unit":_vm.$t('common.units.kgCO2e'),"hideBenchmark":true},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('Leaf',{staticStyle:{"width":"20px","height":"20px"}})]},proxy:true}])}),_c('PieChartCard',{staticClass:"card-co2-category",attrs:{"labels":_vm.co2CategoryLabels,"colors":['#266663', '#6493BE', '#E35E3E', '#B7B7B7'],"dataSeries":_vm.getScopes,"title":_vm.$t('components.cards.co2Categories.title')}}),(
            _vm.emissionsPerMonthCurrentYear?.length > 0 &&
            _vm.emissionsPerMonthLastYear?.length > 0
          )?_c('LineChartCard',{staticClass:"card-co2-emissions-overview",attrs:{"animate-chart":false,"title":_vm.$t('components.cards.co2Emissions.title'),"series":_vm.emissionsPerMonthCurrentYear,"compare-series":_vm.emissionsPerMonthLastYear,"x-axis-categories":_vm.months1YearToCurrent,"seriesLabel":_vm.$t('components.cards.co2Emissions.labels.thisYear'),"compareSeriesLabel":_vm.$t('components.cards.co2Emissions.labels.lastYear'),"maxVal":Math.ceil(
              Math.max(
                ...[
                  ..._vm.emissionsPerMonthCurrentYear,
                  ..._vm.emissionsPerMonthLastYear,
                ]
              )
            )}}):_vm._e(),_c('horizontal-stacked-bar-chart',{staticClass:"emission-overview-by-calc-method",attrs:{"data":_vm.formatEmissionByCalcMethod}})],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }