import i18n from "@/i18n.js";
import Sortable from "sortablejs";
import { fixedFieldAttributes } from "./fixedAssetData";

export const footerProps = {
  itemsPerPageOptions: [25, 50, 100, 250, 500],
  itemsPerPageText: i18n.t("common.dataTable.rowsPerPage"),
  itemsPerPageAllText: i18n.t("common.dataTable.all"), 
  showFirstLastPage: true,
};

export function generateAttributeTableHeaders(
  currentLayout,
  attributes,
  hideActionHeader
) {
  let headers = [];
  /*= [
    {
      text: i18n.t("common.fieldNames.id"),
      value: "referenceKey",
      width: getHeaderColumnWidth(currentLayout, "Number"),
      class: "attributes-header-text id-column",
    },
  ];*/

  const { attributeIds, sortOrderMappings, defaultSortOrderMappings } =
    currentLayout;

  const defaultSortOrderKeys = Object.keys(defaultSortOrderMappings);
  defaultSortOrderKeys.sort((a, b) => a - b);

  for (let key of defaultSortOrderKeys) {
    const lookupId = defaultSortOrderMappings[key];

    let attribute = null;

    if (attributeIds && attributeIds.includes(lookupId)) {
      attribute = attributes.find((x) => x.id === lookupId);
    }

    if (!attribute) continue;

    let attributeName = attribute.name;

    const translationKey = `common.attributeNames.${attributeName}`;

    let isNameTranslated = i18n.te(translationKey);

    if (attribute.id === fixedFieldAttributes.generalVerarcaIdId) {
      attributeName = i18n.t("common.fieldNames.id");
      isNameTranslated = false;
    }

    headers.push({
      text: isNameTranslated ? i18n.t(translationKey) : attributeName,
      value: attribute.id,
      width: getHeaderColumnWidth(currentLayout, attribute.format),
      class: "attributes-header-text",
      dynamic: true,
    });
  }

  const actionOrder = Object.keys(sortOrderMappings);
  actionOrder.sort((a, b) => a - b);

  for (let key of actionOrder) {
    const { attributeId, index } = sortOrderMappings[key];

    const existingHeader = headers.find((x) => x.value === attributeId);

    if (!existingHeader) continue;

    headers = headers.filter((x) => x.value !== existingHeader.value);

    headers.splice(index, 0, existingHeader);
  }

  if (attributeIds) {
    attributeIds.forEach((x) => {
      if (headers.find((y) => y.value === x)) return;

      const attribute = attributes.find((y) => y.id === x);

      if (!attribute) return;

      const translationKey = `common.attributeNames.${attribute.name}`;

      const isNameTranslated = i18n.te(translationKey);

      let attributeName = isNameTranslated
        ? i18n.t(translationKey)
        : attribute.name;

      if (attribute.id === fixedFieldAttributes.generalVerarcaIdId) {
        attributeName = i18n.t("common.fieldNames.id");
      }

      headers.push({
        text: attributeName,
        value: attribute.id,
        width: getHeaderColumnWidth(currentLayout, attribute.format),
        class: "attributes-header-text",
        dynamic: true,
      });
    });
  }

  if (!hideActionHeader) {
    headers.push({
      text: "", //i18n.t("common.actions.edit"),
      value: "action",
      width: 20,
      sortable: false,
      align: "center fixed",
      class: "fixed",
    });
  }

  return headers;
}

function watchClass(targetNode, classToWatch) {
  let lastClassState = targetNode.classList.contains(classToWatch);
  const observer = new MutationObserver((mutationsList) => {
    for (let i = 0; i < mutationsList.length; i++) {
      const mutation = mutationsList[i];
      if (
        mutation.type === "attributes" &&
        mutation.attributeName === "class"
      ) {
        const currentClassState =
          mutation.target.classList.contains(classToWatch);
        if (lastClassState !== currentClassState) {
          lastClassState = currentClassState;
          if (!currentClassState) {
            mutation.target.classList.add("sortHandle");
          }
        }
      }
    }
  });
  observer.observe(targetNode, { attributes: true });
}

function getHeaderColumnWidth(currentLayout, attributeFormat) {
  const numberFormats = ["Number", "Percentage", "Currency"];

  switch (currentLayout.attributeDisplaySize) {
    case "Minimal":
      return undefined;
    case "NarrowNumbers":
      return numberFormats.includes(attributeFormat) ? 150 : 240;
    case "Fixed":
    default:
      return 150;
  }
}

export const sortableTableDirective = {
  inserted: (el, binding) => {
    el.querySelectorAll(
      "th:not(.not-draggable):not(.id-column):not(.fixed):not(:nth-child(1))"
    ).forEach((draggableEl) => {
      // Need a class watcher because sorting v-data-table rows asc/desc removes the sortHandle class
      watchClass(draggableEl, "sortHandle");
      draggableEl.classList.add("sortHandle");
    });
    Sortable.create(
      el.querySelector("tr"),
      binding.value
        ? {
            ...binding.value,
            handle: ".sortHandle",
            draggable: ".sortHandle",
          }
        : {}
    );
  },
};

export function sortTableColumnLayout(event, headers, currentLayout) {
  const headersTmp = [...headers];

  // Indices are offset by checkbox column
  const oldIndex = event.oldIndex - 1;
  const newIndex = event.newIndex - 1;

  if (newIndex >= headersTmp.length) {
    let k = newIndex - headersTmp.length + 1;
    while (k--) {
      headersTmp.push(undefined);
    }
  }
  headersTmp.splice(newIndex, 0, headersTmp.splice(oldIndex, 1)[0]);

  const sortedAttributeIds = headersTmp
    .filter((x) => x.dynamic)
    .map((x) => x.value);
  currentLayout.attributeIds = sortedAttributeIds;

  const headerValue = headersTmp[newIndex].value;

  let sortOrderMappings = { ...currentLayout.sortOrderMappings } ?? {};

  let mappingList = Object.values(sortOrderMappings);

  mappingList = mappingList.filter((x) => x.attributeId !== headerValue);

  mappingList.forEach((mapping) => {
    mapping.index = headersTmp.findIndex(
      (x) => x.value === mapping.attributeId
    );
  });

  mappingList.push({
    attributeId: headerValue,
    index: newIndex,
  });

  sortOrderMappings = {};

  for (let i = 0; i < mappingList.length; i++) {
    sortOrderMappings[i] = mappingList[i];
  }

  currentLayout.sortOrderMappings = sortOrderMappings;

  return currentLayout;
}

export function changeLayoutSorting(options, attributeFilters, attributes) {
  let currentAttributeFilters = attributeFilters.filter(
    (attributeFilter) => attributeFilter.filter != null
  );

  currentAttributeFilters.forEach((attributeFilter) => {
    attributeFilter.sortDesc = null;
  });

  options.sortBy.forEach((x, index) => {
    if (attributes.some((attribute) => attribute.id == x)) {
      let attributeFilter = currentAttributeFilters.find(
        (attributeFilter) => attributeFilter.attributeId == x
      );
      if (attributeFilter != null) {
        attributeFilter.sortDesc = options.sortDesc[index];
        attributeFilter.sortOrder = index;
      } else {
        currentAttributeFilters.push({
          attributeId: x,
          sortDesc: options.sortDesc[index],
          sortOrder: index,
          attribute: attributes.find((attribute) => attribute.id == x),
        });
      }
    } else {
      let attributeFilter = currentAttributeFilters.find(
        (attributeFilter) => attributeFilter.propertyName == x
      );
      if (attributeFilter != null) {
        attributeFilter.sortDesc = options.sortDesc[index];
        attributeFilter.sortOrder = index;
      } else {
        currentAttributeFilters.push({
          propertyName: x,
          sortDesc: options.sortDesc[index],
          sortOrder: index,
        });
      }
    }
  });

  return currentAttributeFilters;
}
