<template>
  <v-tab-item :value="tabValue">
    <attribute-link-input v-model="assetName" :attribute="assetNameAttribute" />
    <attribute-link-input
      v-model="purchasePrice"
      :second-value="purchasePriceCurrency"
      :attribute="purchasePriceAttribute"
      :currencies="currencies"
      @input_secondValue="purchasePriceCurrency = $event"
    />
    <v-row dense>
      <v-col cols="6">
        <attribute-link-input
          v-model="unitType"
          :attribute="unitTypeAttribute"
        />
      </v-col>
      <v-col cols="6">
        <attribute-link-input
          :value="quantity"
          :attribute="quantityAttribute"
          :numberMinValue="1"
          @decimal_changed="
            $emit('setDynamicValue', quantityAttribute.id, $event)
          "
        />
      </v-col>
    </v-row>
    <attribute-link-input v-model="model" :attribute="modelAttribute" />
    <attribute-link-input
      v-model="purchaseDate"
      :attribute="purchaseDateAttribute"
      :date-format="settings.defaultDateFormat"
      @dateTime_changed="purchaseDate = $event"
    />
    <attribute-link-input
      v-model="selectedPurchaseAs"
      :attribute="productionDateAttribute"
      :custom-label="$t('pages.assets.form.fixedLabels.purchasedAs')"
      :select-options="purchaseAsOptions"
      select-item-text="text"
      select-item-value="id"
    />
    <attribute-link-input
      v-if="selectedPurchaseAs === 2"
      v-model="productionDate"
      :attribute="productionDateAttribute"
      :date-format="settings.defaultDateFormat"
      @dateTime_changed="productionDate = $event"
    />
  </v-tab-item>
</template>
<script>
import AttributeLinkInput from "../../../Components/Attribute/AttributeLinkInput.vue";
import { fixedAttributes } from "../../../util/fixedAssetData";

export default {
  components: {
    AttributeLinkInput,
  },
  props: {
    tabValue: Number,
    attributes: Array,
    currencies: Array,

    rerenderCounter: Number,

    getDynamicAction: Function,
    getDynamicValue: Function,
    getDynamicSecondValue: Function,
    getFixedValue: Function,
    settings: Object,
  },
  data() {
    return {
      purchaseAsOptions: [
        { id: 1, text: "New" },
        { id: 2, text: "Used" },
      ],
      valueCounter: 0,
    };
  },
  computed: {
    assetNameAttribute() {
      return this.getAttribute(fixedAttributes.generalNameId);
    },
    purchasePriceAttribute() {
      return this.getAttribute(fixedAttributes.generalPurchasePriceId);
    },
    purchaseDateAttribute() {
      return this.getAttribute(fixedAttributes.generalPurchaseDateId);
    },
    quantityAttribute() {
      return this.getAttribute(fixedAttributes.generalQuantityId);
    },
    unitTypeAttribute() {
      return this.getAttribute(fixedAttributes.generalUnitTypeId);
    },
    modelAttribute() {
      return this.getAttribute(fixedAttributes.generalModelId);
    },
    productionDateAttribute() {
      return this.getAttribute(fixedAttributes.generalProductionDateId);
    },
    assetName: {
      get() {
        this.valueCounter;
        return this.getDynamicValue(this.assetNameAttribute.id);
      },
      set(value) {
        this.$emit("setDynamicValue", this.assetNameAttribute.id, value);
        this.valueCounter++;
      },
    },
    purchasePrice: {
      get() {
        this.valueCounter;
        return this.getDynamicValue(this.purchasePriceAttribute.id);
      },
      set(value) {
        this.$emit("setDynamicValue", this.purchasePriceAttribute.id, value);
        this.valueCounter++;
      },
    },
    purchasePriceCurrency: {
      get() {
        this.valueCounter;
        return this.getDynamicSecondValue(this.purchasePriceAttribute.id);
      },
      set(value) {
        this.$emit(
          "setDynamicSecondValue",
          this.purchasePriceAttribute.id,
          value
        );
      },
    },
    purchaseDate: {
      get() {
        this.valueCounter;
        return this.getDynamicValue(this.purchaseDateAttribute.id);
      },
      set(value) {
        this.$emit("setDynamicValue", this.purchaseDateAttribute.id, value);
        this.valueCounter++;
      },
    },
    quantity() {
      this.rerenderCounter;

      return this.getDynamicValue(this.quantityAttribute.id);
    },
    unitType: {
      get() {
        this.valueCounter;
        return this.getDynamicValue(this.unitTypeAttribute.id);
      },
      set(value) {
        this.$emit("setDynamicValue", this.unitTypeAttribute.id, value);
        this.valueCounter++;
      },
    },
    model: {
      get() {
        this.valueCounter;
        return this.getDynamicValue(this.modelAttribute.id);
      },
      set(value) {
        this.$emit("setDynamicValue", this.modelAttribute.id, value);
        this.valueCounter++;
      },
    },
    productionDate: {
      get() {
        this.valueCounter;
        return this.getDynamicValue(this.productionDateAttribute.id);
      },
      set(value) {
        this.$emit("setDynamicValue", this.productionDateAttribute.id, value);
        this.valueCounter++;
      },
    },
    selectedPurchaseAs: {
      get() {
        this.valueCounter;

        const productionDateAction = this.getDynamicAction(
          this.productionDateAttribute.id
        );

        if (productionDateAction?.dateTimeValue) {
          return 2;
        } else if (productionDateAction) {
          return 1;
        } else {
          return null;
        }
      },
      set(value) {
        console.log(value);
        if (value === 1) {
          this.$emit(
            "setDynamicValue",
            this.productionDateAttribute.id,
            null,
            false
          );
        } else if (value === 2) {
          this.$emit(
            "setDynamicValue",
            this.productionDateAttribute.id,
            new Date()
          );
        } else {
          this.$emit("removeDynamicAction", this.productionDateAttribute.id);
        }

        this.valueCounter++;
      },
    },
  },
  methods: {
    getAttribute(attributeId) {
      return this.attributes.find((x) => x.id === attributeId);
    },
  },
};
</script>
