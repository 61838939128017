<template>
   <v-tab-item :transition="false" :value="tabHref">
    <v-card elevation="0" class="d-flex flex-column ma-4">
      <v-card-text class="pa-0 flex-grow-1">
        <v-data-table
          class="elevation-0"
          :headers="headers"
          :items="mappedPermissions"
          :options.sync="pagination"
          :footer-props="tableFooterProps"
          :must-sort="true"
          :hide-default-footer="true"
        >
          <template v-slot:item.referenceKey="{ item }">
            <div class="font-weight-bold">
              {{ "#" + item.referenceKey }}
            </div>
          </template>
          <template #item.name="{ item }">
            {{ $t(`pages.permissions.names.${item.name}`) }}
          </template>
          <template #item.description="{ item }">
            {{ $t(`pages.permissions.descriptions.${item.description}`) }}
          </template>
          <template v-for="role in roles" v-slot:[`item.${role.id}`]="{ item }">
            <div :key="role.id + item.id" class="d-flex justify-center">
              <v-checkbox
                class="remove-margin"
                :key="role.id + item.id"
                :input-value="item[role.id]"
                :disabled="role.organizationId == null"
                @change="updatePermission($event, role, item)"
              />
            </div>
          </template>
          <template #footer.page-text="pageTextProps">
            {{ $t("common.dataTable.visibleRows", pageTextProps) }}
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-tab-item>
</template>

<script>
import debounce from "lodash/debounce";
import pickBy from "lodash/pickBy";
import { appLayout } from "@/util/layout";
import { footerProps } from "@/util/dataTable";

export default {
  layout: appLayout({ title: "pages.permissions.title" }),
  props: {
    permissions: Array,
    roles: Array,
    organizations: Array,
    tabHref: String,
  },
  data() {
    var searchParams = new URLSearchParams(window.location.search);

    return {
      pagination: {
        sortBy: [searchParams.get("sortBy") || "referenceKey"],
        sortDesc: [searchParams.get("sortDesc") === "true"],
        itemsPerPage: 9999,
      },
      tableFooterProps: footerProps,
      filters: {
        organizationId: searchParams.get("filters[organizationId]"),
      },
    };
  },
  watch: {
    filters: {
      handler: debounce(function () {
        this.pagination = { page: 1, sortBy: [], sortDesc: [] };
        this.changePage(this.pagination);
      }, 150),
      deep: true,
    },
  },
  computed: {
    mappedPermissions() {
      let mappedPermissions = [];
      this.permissions.forEach((permission) => {
        let mappedPermission = {
          ...permission,
        };
        this.roles.forEach((role) => {
          mappedPermission = {
            ...mappedPermission,
            [role.id]:
              role.roleClaims != null
                ? role.roleClaims.some(
                    (x) => x.claimType == permission.claimType
                  )
                : false,
          };
        });
        mappedPermissions.push(mappedPermission);
      });
      // Remove all except user management and settings
      return mappedPermissions.filter(
        (permission) =>
          permission.id == "d1ddb5bc-31c2-43b6-8fcc-ccead1bd1537" ||
          permission.id == "3aa94bc7-f8af-45d3-83f8-2f32866efe2d"
      );
    },
    headers() {
      let headers = [
        { text: this.$t("common.fieldNames.id"), value: "referenceKey" },
        { text: this.$t("common.fieldNames.name"), value: "name" },
        {
          text: this.$t("pages.permissions.columns.description"),
          value: "description",
        },
      ];
      this.roles.forEach((role) => {
        const roleNameAsKey = `common.roles.${role.name}`;

        const roleName = this.$te(roleNameAsKey)
          ? this.$t(roleNameAsKey)
          : role.name;

        // Remove employee and executive
        if (
          role.id == "5f7c2eae-52f0-4ee4-bc3d-c74d16e964bc" ||
          role.id == "8457ab36-c569-4d68-8b6b-de463c969c94"
        ) {
          return;
        }

        headers.push({
          text: roleName,
          value: role.id,
          sortable: false,
          align: "center",
        });
      });

      return headers;
    },
  },
  methods: {
    updatePermission(event, role, permission) {
      if (event) {
        this.createRoleClaim(role.id, permission.claimType);
      } else {
        let roleClaim = role.roleClaims.find(
          (x) => x.claimType == permission.claimType
        );
        this.destroyRoleClaim(roleClaim.id);
      }
    },
    createRoleClaim(roleId, claimType) {
      this.$inertia.post(this.route("role-claim.store"), { roleId, claimType });
    },
    destroyRoleClaim(roleClaimId) {
      this.$inertia.delete(this.route("role-claim.destroy", roleClaimId));
    },
    changePage(options) {
      let query = {
        filters: pickBy(this.filters),
        sortBy: options.sortBy[0],
        sortDesc: options.sortDesc[0],
        page: options.page,
        pageSize: options.itemsPerPage,
      };
      this.$inertia.get(this.route("permissions.index", query));
    },
    resetFilter() {
      for (let filter in this.filters) {
        this.filters[filter] = null;
      }
    },
  },
};
</script>
<style lang="css" scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
.remove-margin >>> .v-input--selection-controls__input {
  margin-right: 0;
}
</style>
