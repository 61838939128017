<template>
  <div>
    <v-alert v-if="errorMessage && show" dense outlined type="error">
      {{ errorMessage }}
    </v-alert>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: true,
    };
  },
  computed: {
    errorMessage() {
      const error = this.$page.props.flash.error;

      const localeKey = `common.flashMessages.error.${error}`;

      if (!this.$te(localeKey)) return error;

      return this.$t(localeKey);
    },
  },
  watch: {
    "$page.props.flash": {
      handler() {
        this.show = true;
      },
      deep: true,
    },
  },
};
</script>
