import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: false,
    themes: {
      light: {
        black: "#131313",
        grey: "#686868",
        orange: "#F25D3B",
        lightGrey: "#F7F7F7",
        textGrey: "#B4B4B4",
        primary: "#F25D3B",
      },
      dark: {
        black: "#131313",
        grey: "#686868",
        orange: "#F25D3B",
        white: "#fff",
        lightGrey: "#F7F7F7",
        textGrey: "#B4B4B4",
      },
    },
  },
});
