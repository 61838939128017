// Put fake dashboard data here
export default {
  dateRange: {
    from: "2023-01-01",
    to: "2023-12-31",
  },
  assetCardData: {
    numberOfAssetsCard: { value: 25041, unit: "" },
    accumulatedDepreciationCard: { value: 0, unit: "DKK" },
    bookValueCard: { value: 0, unit: "DKK" },
    monthlyDepreciationCard: { value: 0, unit: "DKK" },
    totalCo2UsedCard: { value: 12581, unit: "kg" },
    co2CurrentYearCard: { value: 7547, unit: "kg" },
    numberOfAssetsApproved: { value: 2, unit: "" },
    numberOfAssetsDefined: { value: 1, unit: "" },
    scope1Emissions: { value: 3253.03, unit: "kg" },
    scope2Emissions: { value: 0.42, unit: "kg" },
    scope3Emissions: { value: 12341, unit: "kg" },
    numberOfAssetsUndefined: { value: 3, unit: "" },
  },
  emissionsPerMonthCurrentYear: [
    9876.12, 4029.88, 5529.88, 6129.88, 3529.88, 9837.5, 7837.5, 1288.78, 4122,
    1123, 1337, 1000,
  ],
  emissionsPerMonthLastYear: [
    3419.88, 411.99, 4319.88, 14919.88, 5755.68, 3419.88, 4419.88, 9419.88,
    13419.88, 2999, 1337, 9876.12,
  ],
  co2MethodsByMonths: [
    {
      name: "Supplier specific",
      data: [
        1213, 122, 144, 152, 111, 989, 4234.5, 1132.78, 5121, 1643, 4852, 5423,
      ],
    },
    {
      name: "Spend based",
      data: [
        4123, 1441, 1224, 1555, 6322, 1555, 7837.5, 288.78, 6111, 3411, 1451,
        2354,
      ],
    },
    {
      name: "Average data",
      data: [
        1424, 7298, 1614, 6412, 4529.88, 1122, 1765, 2322, 1411, 3000, 4400,
        1000,
      ],
    },
  ],
  scopes: {
    "cb483bd8-d20e-42a1-a252-c44ee12230ef": 1231,
    "6045ba53-b1c4-4ac0-a4be-7d4e4acc9f2b": 911,
    "6d9ea628-4dcf-4d77-ad8b-7e8ea4d72dde": 711,
    other: 123,
  },
  highestCarbonSuppliers: [{ name: "test", emissions: 7837.5 }],
  highestCarbonAssets: [
    {
      id: "1",
      assetAttributeLinks: [
        {
          assetId: "1",
          attributeId: "649f345f-bb47-47b5-af15-6ef294e72afd",
          stringValue: "Tesla Model S",
        },
        {
          assetId: "1",
          attributeId: "8192dfef-ef4b-4ed1-9c9e-26ed84ef8de2",
          stringValue: "DKK",
          decimalValue: 298775.96,
        },
        {
          assetId: "1",
          attributeId: "041a251a-2138-4e14-bdfd-876194f4e04f",
          decimalValue: 0.93,
        },
        {
          assetId: "1",
          attributeId: "5ac7671d-fb9d-4441-8203-a09763fbcff8",
          searchString: "Vehicles",
        },
      ],
    },
    {
      id: "2",
      assetAttributeLinks: [
        {
          assetId: "2",
          attributeId: "649f345f-bb47-47b5-af15-6ef294e72afd",
          stringValue: "Miele CVA 7840",
        },
        {
          assetId: "2",
          attributeId: "8192dfef-ef4b-4ed1-9c9e-26ed84ef8de2",
          stringValue: "DKK",
          decimalValue: 34060.46,
        },
        {
          assetId: "2",
          attributeId: "041a251a-2138-4e14-bdfd-876194f4e04f",
          decimalValue: 0.64,
        },
        {
          assetId: "2",
          attributeId: "5ac7671d-fb9d-4441-8203-a09763fbcff8",
          searchString: "Small acquisitions",
        },
      ],
    },
    {
      id: "3",
      assetAttributeLinks: [
        {
          assetId: "3",
          attributeId: "649f345f-bb47-47b5-af15-6ef294e72afd",
          stringValue: "Ikea JÄTTEBO",
        },
        {
          assetId: "3",
          attributeId: "8192dfef-ef4b-4ed1-9c9e-26ed84ef8de2",
          stringValue: "DKK",
          decimalValue: 350.31,
        },
        {
          assetId: "3",
          attributeId: "041a251a-2138-4e14-bdfd-876194f4e04f",
          decimalValue: 0.33,
        },
        {
          assetId: "3",
          attributeId: "5ac7671d-fb9d-4441-8203-a09763fbcff8",
          searchString: "Small acquisitions",
        },
      ],
    },
    {
      id: "4",
      assetAttributeLinks: [
        {
          assetId: "4",
          attributeId: "649f345f-bb47-47b5-af15-6ef294e72afd",
          stringValue: "Byens tjenester",
        },
        {
          assetId: "4",
          attributeId: "8192dfef-ef4b-4ed1-9c9e-26ed84ef8de2",
          stringValue: "DKK",
          decimalValue: 21437.18,
        },
        {
          assetId: "4",
          attributeId: "041a251a-2138-4e14-bdfd-876194f4e04f",
          decimalValue: 9.93,
        },
        {
          assetId: "4",
          attributeId: "5ac7671d-fb9d-4441-8203-a09763fbcff8",
          searchString: "Services",
        },
      ],
    },
    {
      id: "5",
      assetAttributeLinks: [
        {
          assetId: "5",
          attributeId: "649f345f-bb47-47b5-af15-6ef294e72afd",
          stringValue: "Kontordør",
        },
        {
          assetId: "5",
          attributeId: "8192dfef-ef4b-4ed1-9c9e-26ed84ef8de2",
          stringValue: "DKK",
          decimalValue: 21437.18,
        },
        {
          assetId: "5",
          attributeId: "041a251a-2138-4e14-bdfd-876194f4e04f",
          decimalValue: 0.37,
        },
        {
          assetId: "5",
          attributeId: "5ac7671d-fb9d-4441-8203-a09763fbcff8",
          searchString: "Office",
        },
      ],
    },
    {
      id: "6",
      assetAttributeLinks: [
        {
          assetId: "6",
          attributeId: "649f345f-bb47-47b5-af15-6ef294e72afd",
          stringValue: "Tesla Model S",
        },
        {
          assetId: "6",
          attributeId: "8192dfef-ef4b-4ed1-9c9e-26ed84ef8de2",
          stringValue: "DKK",
          decimalValue: 298775.96,
        },
        {
          assetId: "6",
          attributeId: "041a251a-2138-4e14-bdfd-876194f4e04f",
          decimalValue: 0.93,
        },
        {
          assetId: "6",
          attributeId: "5ac7671d-fb9d-4441-8203-a09763fbcff8",
          searchString: "Vehicles",
        },
      ],
    },
    {
      id: "7",
      assetAttributeLinks: [
        {
          assetId: "7",
          attributeId: "649f345f-bb47-47b5-af15-6ef294e72afd",
          stringValue: "Miele CVA 7840",
        },
        {
          assetId: "7",
          attributeId: "8192dfef-ef4b-4ed1-9c9e-26ed84ef8de2",
          stringValue: "DKK",
          decimalValue: 34060.46,
        },
        {
          assetId: "7",
          attributeId: "041a251a-2138-4e14-bdfd-876194f4e04f",
          decimalValue: 0.64,
        },
        {
          assetId: "7",
          attributeId: "5ac7671d-fb9d-4441-8203-a09763fbcff8",
          searchString: "Small acquisitions",
        },
      ],
    },
    {
      id: "8",
      assetAttributeLinks: [
        {
          assetId: "8",
          attributeId: "649f345f-bb47-47b5-af15-6ef294e72afd",
          stringValue: "Ikea JÄTTEBO",
        },
        {
          assetId: "8",
          attributeId: "8192dfef-ef4b-4ed1-9c9e-26ed84ef8de2",
          stringValue: "DKK",
          decimalValue: 350.31,
        },
        {
          assetId: "8",
          attributeId: "041a251a-2138-4e14-bdfd-876194f4e04f",
          decimalValue: 0.33,
        },
        {
          assetId: "8",
          attributeId: "5ac7671d-fb9d-4441-8203-a09763fbcff8",
          searchString: "Small acquisitions",
        },
      ],
    },
    {
      id: "9",
      assetAttributeLinks: [
        {
          assetId: "9",
          attributeId: "649f345f-bb47-47b5-af15-6ef294e72afd",
          stringValue: "Byens tjenester",
        },
        {
          assetId: "9",
          attributeId: "8192dfef-ef4b-4ed1-9c9e-26ed84ef8de2",
          stringValue: "DKK",
          decimalValue: 21437.18,
        },
        {
          assetId: "9",
          attributeId: "041a251a-2138-4e14-bdfd-876194f4e04f",
          decimalValue: 9.93,
        },
        {
          assetId: "9",
          attributeId: "5ac7671d-fb9d-4441-8203-a09763fbcff8",
          searchString: "Services",
        },
      ],
    },
    {
      id: "10",
      assetAttributeLinks: [
        {
          assetId: "10",
          attributeId: "649f345f-bb47-47b5-af15-6ef294e72afd",
          stringValue: "Kontordør",
        },
        {
          assetId: "10",
          attributeId: "8192dfef-ef4b-4ed1-9c9e-26ed84ef8de2",
          stringValue: "DKK",
          decimalValue: 21437.18,
        },
        {
          assetId: "10",
          attributeId: "041a251a-2138-4e14-bdfd-876194f4e04f",
          decimalValue: 0.37,
        },
        {
          assetId: "10",
          attributeId: "5ac7671d-fb9d-4441-8203-a09763fbcff8",
          searchString: "Office",
        },
      ],
    },
  ],
};
