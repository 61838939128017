<template>
  <dialog-form
    :show-dialog="show"
    @showStateChanged="show = $event"
    max-width="600px"
  >
    <dialog-form-section-one-col class="py-6 px-2">
      <div class="py-8 d-flex justify-center">
        <LogoBlack />
      </div>
      <h6 class="text-subtitle-1 grey--text mb-1 font-weight-light text-center">
        {{ $t("pages.settings.integrationUniconta.company.chooseCompany") }}
      </h6>
      <form @submit.prevent="submit">
        <v-autocomplete
          v-model="selectedCompanyId"
          :items="unicontaCompanies"
          item-text="name"
          item-value="companyId"
          :label="$t('pages.settings.integrationUniconta.company.company')"
          hide-details="auto"
          background-color="#F7F7F7"
          filled
          solo
          dense
          flat
          class="black--text py-2 mb-6"
        ></v-autocomplete>
        <v-btn
          tile
          block
          elevation="0"
          color="orange"
          class="font-weight-regular text-capitalize mb-2 white--text"
          type="submit"
          :disabled="submitDisabled || formSubmitting"
        >
          {{ $t("common.actions.submit") }}
        </v-btn>
      </form>
    </dialog-form-section-one-col>
  </dialog-form>
</template>
<script>
import { serialize } from "object-to-formdata";

import DialogForm from "../../Components/Dialog/DialogForm.vue";
import DialogFormSectionOneCol from "../../Components/Dialog/DialogFormSectionOneCol.vue";
import LogoBlack from "../../assets/svg/verarca-logo-black.svg";

export default {
  components: {
    DialogForm,
    DialogFormSectionOneCol,
    LogoBlack,
  },
  props: {
    value: Boolean,
    unicontaCompanies: Array,
    unicontaUsername: String,
    unicontaPassword: String,
    redirectToOnboarding: Boolean,
  },
  emits: ["input"],
  data() {
    return {
      selectedCompanyId: null,
      formSubmitting: false,
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    submitDisabled() {
      return !this.selectedCompanyId;
    },
  },
  methods: {
    submit() {
      if (this.submitDisabled || this.formSubmitting) return;

      this.formSubmitting = true;

      fetch(
        this.route("api.settings.uniconta.install", {
          redirectToOnboarding: !!this.redirectToOnboarding,
        }),
        {
          method: "POST",
          redirect: "follow",
          body: serialize({
            username: this.unicontaUsername,
            password: this.unicontaPassword,
            companyId: this.selectedCompanyId,
          }),
        }
      ).then((res) => {
        this.formSubmitting = false;

        if (res.redirected) window.location.href = res.url + "#integrations";
      });
    },
  },
};
</script>
