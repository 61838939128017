<template>
  <dialog-form :show-dialog="show" max-width="450px" :persistent="true">
    <div class="pa-4">
      <div class="pb-8 d-flex justify-center">
        <LogoBlack />
      </div>

      <payment-card-popup
        v-model="showPaymentCardPopup"
        v-show="showPaymentCardPopup"
        :subscription-client-secret="clientSecret"
        :publishable-key="publishableKey"
        :payment-return-url="paymentReturnUrl"
      ></payment-card-popup>

      <div>
        <h1 class="text-subtitle-1 black--text pt-2">
          {{ $t("pages.payment.titles.billingDetails") }}
        </h1>
      </div>

      <dialog-form-text-input
        v-model="form.companyName"
        :title="$t('pages.payment.form.companyName')"
        :placeholder="$t('pages.payment.form.companyName')"
        :error-messages="form.errors.companyName"
        :disabled="true"
      ></dialog-form-text-input>

      <dialog-form-text-input
        v-model="form.companyCvr"
        :title="$t('pages.payment.form.companyCvr')"
        :placeholder="$t('pages.payment.form.companyCvr')"
        :error-messages="form.errors.companyCvr"
        @input="validateCVR"
        :disabled="true"
      ></dialog-form-text-input>

      <dialog-form-text-input
        v-model="form.name"
        :title="$t('pages.payment.form.name')"
        :placeholder="$t('pages.payment.form.name')"
        :error-messages="form.errors.name"
      ></dialog-form-text-input>

      <dialog-form-text-input
        v-model="form.email"
        :title="$t('pages.payment.form.email')"
        :placeholder="$t('pages.payment.form.email')"
        :error-messages="form.errors.email"
        @input="validateEmail"
      ></dialog-form-text-input>

      <dialog-form-text-input
        v-model="form.address1"
        :title="$t('pages.payment.form.addres1')"
        :placeholder="$t('pages.payment.form.addres1')"
        :error-messages="form.errors.address1"
      ></dialog-form-text-input>

      <dialog-form-text-input
        v-model="form.address2"
        :title="$t('pages.payment.form.addres2')"
        :placeholder="$t('pages.payment.form.optional')"
        :error-messages="form.errors.address2"
      ></dialog-form-text-input>

      <div class="d-flex justify-space-between">
        <dialog-form-text-input
          class="pr-2"
          style="width: 110px"
          v-model="form.postCode"
          :title="$t('pages.payment.form.postCode')"
          :placeholder="$t('pages.payment.form.postCode')"
          :error-messages="form.errors.postCode"
          @input="validatePostCode"
        ></dialog-form-text-input>

        <dialog-form-text-input
          style="width: 300px"
          v-model="form.city"
          :title="$t('pages.payment.form.city')"
          :placeholder="$t('pages.payment.form.city')"
          :error-messages="form.errors.city"
        ></dialog-form-text-input>
      </div>

      <dialog-form-text-input
        v-model="form.region"
        :title="$t('pages.payment.form.region')"
        :placeholder="$t('pages.payment.form.optional')"
        :error-messages="form.errors.region"
      ></dialog-form-text-input>

      <div class="error-container">
        <ul>
          <li v-for="(errors, field) in form.errors" :key="field">
            <ul>
              <li v-for="error in errors" :key="error">
                {{ $t("pages.payment.form." + field) }} : {{ error }}
              </li>
            </ul>
          </li>
        </ul>
      </div>

      <div class="pt-4 d-flex justify-end sticky-buttons">
        <v-btn
          tile
          outlined
          elevation="0"
          bg-color="white"
          class="border-sm text-capitalize black--text black--border white"
          @click="closePaymentPopup"
          >{{ $t("pages.payment.buttons.cancel") }}</v-btn
        >
        <v-btn
          tile
          elevation="0"
          color="orange"
          class="font-weight-regular text-capitalize mb-4 mx-4 white--text"
          type="submit"
          @click="pay"
          :disabled="!areFieldsFilled"
        >
          {{ $t("pages.payment.buttons.continue") }}
        </v-btn>
      </div>
    </div>
  </dialog-form>
</template>

<script>
import DialogForm from "../../Components/Dialog/DialogForm.vue";
import DialogFormTextInput from "../../Components/Dialog/inputs/DialogFormTextInput.vue";
import LogoBlack from "../../assets/svg/verarca-logo-black.svg";
import PaymentCardPopup from "./PaymentCardPopup.vue";

export default {
  name: "paymentInfo",
  components: {
    DialogForm,
    DialogFormTextInput,
    LogoBlack,
    PaymentCardPopup,
    // LockIcon,
  },
  props: {
    value: Boolean,
    publishableKey: String,
    paymentReturnUrl: String,
    organization: Object,
    selectedPlan: String,
  },
  data() {
    return {
      elementsOptions: {
        appearance: {
          theme: "stripe",

          variables: {
            colorPrimary: "#000",
            colorBackground: "#ffffff",
            colorText: "#30313d",
            colorDanger: "#df1b41",
            fontFamily: "Lexend, system-ui, sans-serif",
            spacingUnit: "2px",
            borderRadius: "0px",
            gridRowSpacing: "16px",
          },
          rules: {
            ".Tab, .Input, .Block, .CheckboxInput, .CodeInput": {
              boxShadow: "0px 3px 10px rgba(18, 42, 66, 0.08)",
            },
            ".Block": {
              borderColor: "transparent",
            },
            ".BlockDivider": {
              backgroundColor: "#ebebeb",
            },
            ".Tab, .Tab:hover, .Tab:focus": {
              border: "0",
            },
            ".Tab--selected, .Tab--selected:hover": {
              backgroundColor: "#f25d3b",
              color: "#fff",
            },
          },
        },
      },

      form: this.$inertia.form({
        companyName: this.organization?.name,
        companyCvr: this.organization?.taxNumber,
        name: null,
        email: null,
        address1: null,
        address2: null,
        postCode: null,
        city: null,
        region: null,
      }),

      confirmParams: {
        return_url: this.paymentReturnUrl,
      },

      showPaymentCardPopup: false,
      clientSecret: null,
      subscriptionId: null,

      subscriptionCreateForm: this.$inertia.form({
        subscriptionId: "",
      }),
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },

    areFieldsFilled() {
      return (
        this.form.companyName &&
        this.form.companyCvr &&
        this.form.name &&
        this.form.email &&
        this.form.address1 &&
        this.form.postCode &&
        this.form.city
      );
    },
  },
  watch: {
    subscriptionClientSecret(to) {
      this.elementsOptions.clientSecret = to;
    },
  },
  methods: {
    validateEmail() {
      const email = this.form.email;
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      if (!emailRegex.test(email)) {
        this.form.errors.email = ["Invalid email format"];
      } else {
        delete this.form.errors.email;
      }
    },
    validateCVR() {
      const companyCvr = this.form.companyCvr;
      const cvrRegex = /^\d{8}$/;

      if (!cvrRegex.test(companyCvr) || companyCvr === null) {
        this.form.errors.companyCvr = ["Invalid CVR format"];
      } else {
        delete this.form.errors.companyCvr;
      }
    },
    validatePostCode() {
      const postCode = this.form.postCode;
      const postCodeRegex = /^\d+$/;

      if (!postCodeRegex.test(postCode)) {
        this.form.errors.postCode = ["Invalid postal code"];
      } else {
        delete this.form.errors.postCode;
      }
    },
    pay() {
      const body = {
        companyName: this.form.companyName,
        companyCvr: this.form.companyCvr,
        name: this.form.name,
        email: this.form.email,
        address1: this.form.address1,
        address2: this.form.address2,
        postCode: this.form.postCode,
        city: this.form.city,
        region: this.form.region,
      };

      fetch(this.route("payments.setup-customer"), {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.isUpdated) {
            this.createSubscription();
          }
        });
    },
    createSubscription() {
      this.subscriptionCreateForm.priceId = this.selectedPlan;

      const body = {
        priceId: this.subscriptionCreateForm.priceId,
      };

      fetch(this.route("payments.create-subscription"), {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.clientSecret = data.clientSecret;
          this.subscriptionId = data.subscriptionId;

          this.showPaymentCardPopup = true;
        });
    },
    closePaymentPopup() {
      fetch(this.route("payments.cancel-pending-subscription"), {
        method: "POST",
      });
      this.show = false;
    },
  },
  mounted() {
    this.validateCVR();
  },
};
</script>

<style scoped>
:deep(#stripe-payment-element-errors) {
  color: red;
}

.sticky-buttons {
  position: sticky;
  background: white;
  bottom: 0;
  z-index: 1;
}

.error-container {
  color: red;
  margin-top: 8px;
  font-size: small;
}

.error-container ul {
  list-style-type: none;
  padding-left: 0;
}

.error-container li {
  margin-bottom: 4px;
}
</style>
