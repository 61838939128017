<template>
  <div class="flex d-flex justify-center align-center">
    <inertia-link id="back-arrow" :href="route('login')">
      <v-icon x-large>mdi-arrow-left</v-icon>
    </inertia-link>
    <v-form class="col-10 col-md-7">
      <h1 class="text-h4 black--text mb-2 text-center">
        {{ $t("pages.forgotPassword.confirmation.title") }}
      </h1>
      <h1 class="text-subtitle-1 grey--text mb-6 font-weight-light text-center">
        {{ $t("pages.forgotPassword.confirmation.sentInstructions") }}
      </h1>

      <i18n
        path="pages.forgotPassword.confirmation.didntReceive"
        tag="p"
        class="text-subtitle-1 grey--text mb-6 font-weight-light text-center"
      >
        <inertia-link
          :href="route('password.request')"
          class="text-subtitle-1 black--text mb-6 font-weight-light text-center"
        >
          <br />{{ $t("pages.forgotPassword.confirmation.tryAnother") }}
        </inertia-link>
      </i18n>
    </v-form>
  </div>
</template>

<script>
import Layout from "@/Shared/AuthLayout";

export default {
  metaInfo: { title: "pages.forgotPassword.confirmation.pageTitle" },
  layout: Layout,
};
</script>
<style scoped>
.relative {
  position: relative;
}

#back-arrow {
  position: absolute;
  top: 0;
  left: 0;
  text-decoration: none;
}
</style>
