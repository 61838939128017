<template>
  <v-dialog
    light
    v-model="dialog"
    :width="maxWidth"
    :persistent="persistent"
    @click:outside="clickOutside()"
  >
    <v-card
      loader-height="4px"
      :loading="loading ? '#F25D38' : false"
      class="dialog-card"
    >
      <slot></slot>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  components: {},
  props: {
    showDialog: Boolean,
    maxWidth: {
      type: String,
      default: "929px",
    },
    persistent: {
      type: Boolean,
      default: false,
    },
    loading: Boolean,
  },
  data() {
    return {
      dialog: this.showDialog,
    };
  },
  methods: {
    clickOutside($event) {
      this.$emit("clickOutside", $event);
    },
  },
  watch: {
    showDialog(to) {
      this.dialog = to;
    },
    dialog(to) {
      if (to !== this.showDialog) {
        this.$emit("showStateChanged", to);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.dialog-card:deep(.v-progress-linear) {
  z-index: 99;
}
</style>
