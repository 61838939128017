<template>
  <div class="copilot">
    <Transition name="expand">
      <div
        v-if="expanded"
        class="copilot-form"
        :class="fullscreen ? 'fullscreen' : null"
      >
        <div v-if="copilotQuotaLimitTranslationKey" class="limit-reached">
          {{ $t(`components.copilot.${copilotQuotaLimitTranslationKey}`) }}
        </div>
        <div class="search-results" v-else-if="hasSubmittedQuery">
          <div class="search-header d-flex">
            <div
              class="search-title text-overflow-wrap d-flex flex-grow-1 flex-row align-center"
            >
              <div v-if="hasSubmittedQuery" class="d-flex mt-4 align-center">
                <div>Copilot</div>
                <v-btn
                  class="d-flex flex-row align-center rounded px-2 py-3 red--text mx-2"
                  x-small
                  color="#F25D3B"
                  text
                  elevation="0"
                  style="background-color: #fdece7; pointer-events: none"
                  >Beta 1.0</v-btn
                >
              </div>
            </div>
            <v-btn icon class="search-expand" @click="fullscreen = !fullscreen"
              ><v-icon v-if="!fullscreen">mdi-arrow-expand</v-icon
              ><v-icon v-else>mdi-arrow-collapse</v-icon></v-btn
            >
          </div>
          <div class="scrollable-content" ref="scrollable-content">
            <div class="ef-header" v-if="fullscreen">
              <div class="ef-overview d-flex">
                <div
                  v-for="(item, index) in fullscreenColumns"
                  :key="index"
                  class="ef-overview-info text-overflow-wrap pr-2"
                >
                  {{ item.name }}
                </div>
                <div style="width: 32px"></div>
              </div>
            </div>
            <div>
              <div class="search-label d-flex align-center">
                <div class="text-overflow-wrap">
                  {{ $t("components.copilot.resultsFor") }}
                  <span class="search-word">“{{ query }}”</span>
                </div>
                <v-btn icon @click="editQuery(query)"
                  ><PencilIcon></PencilIcon
                ></v-btn>
              </div>
              <emission-factor-row
                v-for="(result, index) in queryResults"
                v-show="
                  !(
                    queryResults.length === 1 &&
                    queryResults[0].id === queryAiResult.id
                  )
                "
                :key="index"
                :groupKey="'qr-'"
                :fullscreenColumns="fullscreenColumns"
                :result="result"
                :fullscreen="fullscreen"
                :expandedResult="expandedResult"
                @expandResult="(index) => (expandedResult = index)"
                @create="(value) => createEmissionWithFactor(value)"
              ></emission-factor-row>
              <div
                v-if="queryResults.length === 0 && queryAiResult === null"
                class="no-result"
              >
                {{ $t("components.copilot.noResults") }}
              </div>
              <!-- AI FACTOR -->
              <div
                v-if="loadingAiResult"
                class="d-flex flex-row align-center justify-center"
              >
                <activity-indicator
                  color="red"
                  class="mr-2"
                ></activity-indicator>
                Generating AI result
              </div>
              <emission-factor-row
                v-else-if="queryAiResult !== null"
                class="ai-factor-row mt-4"
                :fullscreenColumns="fullscreenColumns"
                :result="queryAiResult"
                :fullscreen="fullscreen"
                :expandedResult="expandedResult"
                @expandResult="(index) => (expandedResult = index)"
                @create="(value) => createEmissionWithFactor(value)"
              ></emission-factor-row>
            </div>
            <!-- COMMENT THIS IN TO ENABLE HISTORY -->
            <!--<div class="search-history-divider">
              <div class="line"></div>
              <div class="divider-text">
                {{ $t("components.copilot.previouslySearched") }}
              </div>
              <div class="line"></div>
            </div>

            <div
              v-for="(historicSearch, hindex) in searchHistory"
              :key="hindex"
            >
              <div class="search-label text-overflow-wrap">
                <HistoryIcon class="d-inline mr-1"></HistoryIcon
                >{{ $t("components.copilot.resultsFor") }}
                <span class="search-word">“{{ historicSearch.text }}”</span>
              </div>
              <emission-factor-row
                v-for="(result, index) in historicSearch.wildcardFactors"
                :key="index"
                :groupKey="'h-' + hindex"
                :fullscreenColumns="fullscreenColumns"
                :result="result"
                :fullscreen="fullscreen"
                :expandedResult="expandedResult"
                @expandResult="(index) => (expandedResult = index)"
                @create="(value) => createEmissionWithFactor(value)"
              ></emission-factor-row>
              <div
                v-if="historicSearch.wildcardFactors.length === 0"
                class="no-result"
              >
                {{ $t("components.copilot.noResults") }}
              </div>
            </div>-->
          </div>
        </div>
        <div v-if="!hasSubmittedQuery" class="d-flex mt-4">
          Copilot
          <v-btn
            class="d-flex flex-row align-center rounded px-2 py-3 red--text mx-2"
            x-small
            color="#F25D3B"
            text
            elevation="0"
            style="background-color: #fdece7; pointer-events: none"
            >Beta 1.0</v-btn
          >
        </div>
        <div
          class="form-field"
          style="height: 52px"
          :style="
            showAutoCompleteSuggestions &&
            fullscreen /* remove fullscreen here to enable suggestions when not expanded */
              ? 'height: 122.5px'
              : null
          "
        >
          <div class="d-flex flex-row">
            <v-text-field
              class="search-text-field flex-grow-1 py-0 mr-2"
              type="text"
              clearable
              flat
              background-color="transparent"
              hide-details
              :placeholder="$t('components.copilot.searchFieldPlaceHolder')"
              v-model="emissionFactorSeachFieldInput"
              @click:clear="clearSearchInput"
              ref="search-input"
              @keyup.enter="submitQuery(emissionFactorSeachFieldInput)"
            />
            <Transition name="expand-left">
              <v-btn
                class="send-btn"
                tile
                fab
                width="40"
                height="40"
                color="#F25D3B"
                elevation="0"
                v-if="showAutoCompleteSuggestions"
                @click="submitQuery(emissionFactorSeachFieldInput)"
              >
                <SendIcon v-if="!loadingQueryResults" /><activity-indicator
                  v-else
                  color="white"
                ></activity-indicator>
              </v-btn>
            </Transition>
          </div>
          <Transition name="expand">
            <div
              v-if="
                showAutoCompleteSuggestions &&
                fullscreen /* remove fullscreen here to enable suggestions when not expanded */
              "
              class="suggestion-title"
            >
              {{ $t("components.copilot.doYouMean") }}
            </div>
          </Transition>
          <Transition name="expand">
            <div
              v-if="
                showAutoCompleteSuggestions &&
                fullscreen /* remove fullscreen here to enable suggestions when not expanded */
              "
              class="d-flex flex-row"
            >
              <button
                v-for="(item, index) in autoCompleteSuggestions"
                :key="index"
                class="suggestion"
                @click="submitQuery(item)"
              >
                {{ item }}
              </button>
              <div
                v-if="loadingAutoCompleteSuggestions"
                class="no-suggestions pl-0"
              >
                <activity-indicator class="d-inline pr-8"></activity-indicator>
                {{ $t("components.copilot.gettingSuggestions") }}
              </div>
              <div
                v-if="
                  !loadingAutoCompleteSuggestions &&
                  autoCompleteSuggestions.length === 0
                "
                class="no-suggestions"
              >
                {{ $t("components.copilot.noMatches") }}
              </div>
            </div>
          </Transition>
        </div>
        <div class="d-flex flex-row bottom-section">
          <div class="location-text flex-grow-1">
            <a href="/settings"
              ><LocationIcon class="mr-1"></LocationIcon> Denmark, Europe,
              Global</a
            >
          </div>
          <div class="settings-link">
            <a href="/settings"
              ><GearIcon class="mr-1"></GearIcon>
              {{ $t("components.copilot.settings") }}</a
            >
          </div>
        </div>
      </div>
    </Transition>
    <v-btn
      icon
      class="copilot-btn"
      :class="expanded ? 'expanded' : null"
      @click="expanded = !expanded"
    >
      <CloseIcon v-if="expanded" class="btn-icon"></CloseIcon>
      <CopilotLogo v-else class="btn-icon"></CopilotLogo>
    </v-btn>
  </div>
</template>

<script>
import CopilotLogo from "../../assets/svg/co-pilot.svg";
import CloseIcon from "../../assets/svg/remove.svg";
import SendIcon from "../../assets/svg/sent-fast.svg";
import PencilIcon from "../../assets/svg/pencil.svg";
import { serialize } from "object-to-formdata";
import ActivityIndicator from "../Outputs/ActivityIndicator.vue";
import EmissionFactorRow from "./EmissionFactorRow.vue";
// import HistoryIcon from "../../assets/svg/history.svg";
import GearIcon from "../../assets/svg/gear.svg";
import LocationIcon from "../../assets/svg/location.svg";
import debounce from "lodash/debounce";
import Formatters from "../../mixins/Formatters.vue";
import { currentOrganizationKey } from "../../Constants/organization";

export default {
  mixins: [Formatters],
  components: {
    CopilotLogo,
    CloseIcon,
    SendIcon,
    PencilIcon,
    ActivityIndicator,
    EmissionFactorRow,
    // HistoryIcon,
    GearIcon,
    LocationIcon,
  },
  data() {
    return {
      expanded: false,
      emissionFactorSeachFieldInput: "",
      loadingAutoCompleteSuggestions: false,
      autoCompleteSuggestions: [],
      query: "",
      loadingQueryResults: false,
      queryResults: [],
      loadingAiResult: false,
      queryAiResult: null,
      searchHistory: [],
      expandedResult: null,
      copilotQuotaLimitTranslationKey: null,
      fullscreen: false,
      fullscreenColumns: [
        {
          key: "name",
          name: "Activity name",
        },
        {
          key: "sector",
          name: this.$t("components.copilot.sector"),
        },
        {
          key: "category",
          name: this.$t("components.copilot.category"),
        },
        {
          key: "source",
          name: this.$t("components.copilot.source"),
        },
        {
          key: "region",
          name: this.$t("components.copilot.region"),
        },
        {
          key: "unityTypes",
          name: this.$t("components.copilot.unityTypes"),
        },
      ],
      autoCompleteDebounce: debounce((value) => {
        this.autoComplete(value);
      }, 640),
    };
  },
  methods: {
    autoComplete(text) {
      const form = {
        text: text,
        limit: 3,
        start: true,
      };

      const formData = serialize(form, {
        indices: true,
      });
      const currentOrganization = sessionStorage.getItem(
        currentOrganizationKey
      );

      fetch(this.route("api.copilot.autocomplete"), {
        method: "POST",
        body: formData,
        headers: {
          "X-Organization": currentOrganization,
        },
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          this.autoCompleteSuggestions = data;
        })
        .finally(() => {
          this.loadingAutoCompleteSuggestions = false;
        });
    },
    createEmissionWithFactor(value) {
      const savedAssetLayout = JSON.parse(
        localStorage.getItem("saved-asset-layout")
      );

      const dateRange = JSON.parse(localStorage.getItem("globalDateRange"));

      this.$inertia.get(
        this.route("assets.index", {
          page: 1,
          pageSize: savedAssetLayout?.itemsPerPage,
          dateRangeFrom: dateRange.from,
          dateRangeTo: dateRange.to,
          assetFormFactorIdPreset: value,
        })
      );
      this.expanded = false;
    },
    submitQuery(query) {
      if (typeof this.$refs["scrollable-content"] !== "undefined") {
        this.$refs["scrollable-content"].scrollTop = 0;
      }
      this.getSearchHistory();

      this.loadingQueryResults = true;
      const form = {
        text: query,
        limit: 4,
      };

      const formData = serialize(form, {
        indices: true,
      });
      const currentOrganization = sessionStorage.getItem(
        currentOrganizationKey
      );

      fetch(this.route("api.copilot.search"), {
        method: "POST",
        body: formData,
        headers: {
          "X-Organization": currentOrganization,
        },
      })
        .then((res) => {
          this.copilotQuotaLimitTranslationKey = null;
          return res.json();
        })
        .then((data) => {
          if (data.translationKey) {
            this.copilotQuotaLimitTranslationKey = data.translationKey;
          } else {
            this.queryResults = data.wildcardFactors.map((factor) =>
              this.formatFactor(factor)
            );
          }

          // Moved to own method
          /*this.queryAiResults = data.aiFactors.map((factor) =>
            this.formatFactor(factor)
          );*/

          this.getAIResult(query);
        })
        .finally(() => {
          this.emissionFactorSeachFieldInput = "";
          this.query = query;
          this.loadingQueryResults = false;
        });
    },
    getAIResult(query) {
      this.loadingAiResult = true;

      const currentOrganization = sessionStorage.getItem(
        currentOrganizationKey
      );

      fetch(
        this.route("api.copilot.ai.factor", {
          query: query,
          headers: {
            "X-Organization": currentOrganization,
          },
        })
      )
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          this.queryAiResult = this.formatFactor(data);
        })
        .finally(() => {
          // loading history
          this.loadingAiResult = false;
        });
    },
    getSearchHistory() {
      const currentOrganization = sessionStorage.getItem(
        currentOrganizationKey
      );

      fetch(this.route("api.copilot.history"), {
        headers: {
          "X-Organization": currentOrganization,
        },
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          this.searchHistory = data["data"].map((search) => {
            return {
              ...search,
              wildcardFactors: search.wildcardFactors.map((factor) =>
                this.formatFactor(factor)
              ),
            };
          });
        })
        .finally(() => {
          // loading history
        });
    },
    clearSearchInput() {
      this.emissionFactorSeachFieldInput = "";
      this.query = "";
      this.autoCompleteSuggestions = [];
      this.queryResults = [];
      this.fullscreen = false;
    },
    editQuery(query) {
      this.emissionFactorSeachFieldInput = query;
      this.$refs["search-input"].focus();
    },
    formatFactor(factor) {
      {
        let kgCO2e = factor.kgCo2eAr6;

        if (!kgCO2e) kgCO2e = factor.kgCo2eAr5;
        if (!kgCO2e) kgCO2e = factor.kgCo2eAr4;
        if (!kgCO2e) kgCO2e = 0;

        return {
          id: factor.id,
          name: factor.name,
          emissions:
            this.formatCurrency(kgCO2e) +
            "" +
            this.$t("common.units.kgCO2e") +
            "/" +
            factor.activityUnit,
          description: factor.description,
          info: [
            {
              key: "sector",
              name: this.$t("components.copilot.sector"),
              value: factor.sector?.name,
            },
            {
              key: "category",
              name: this.$t("components.copilot.category"),
              value: factor.category?.name,
            },
            {
              key: "source",
              name: this.$t("components.copilot.source"),
              value: factor.source,
            },
            {
              key: "region",
              name: this.$t("components.copilot.region"),
              value: factor.region?.countryName,
            },
            {
              key: "unityTypes",
              name: this.$t("components.copilot.unityTypes"),
              value: factor.activityUnit,
            },
            {
              key: "uuid",
              name: this.$t("components.copilot.uuid"),
              value: factor.id,
            },
            {
              key: "yearReleased",
              name: this.$t("components.copilot.yearReleased"),
              value: factor.yearReleased,
            },
            {
              key: "emissionFactors",
              name: this.$t("components.copilot.emissionFactors"),
              value:
                this.formatCurrency(
                  factor.kgCo2eAr6 !== null
                    ? factor.kgCo2eAr6
                    : factor.kgCo2eAr5 !== null
                    ? factor.kgCo2eAr5
                    : factor.kgCo2eAr4
                ) +
                "" +
                this.$t("common.units.kgCO2e") +
                "/" +
                factor.activityUnit,
            },
            {
              key: "calculationMethods",
              name: this.$t("components.copilot.calculationMethods"),
              value:
                this.$t("components.copilot.methodsSupported") +
                (factor.kgCo2eAr6 !== null
                  ? "AR6"
                  : factor.kgCo2eAr5 !== null
                  ? "AR5"
                  : "AR4"),
            },
            {
              key: "lcaActivity",
              name: this.$t("components.copilot.lcaActivity"),
              value: factor.lcaActivity,
            },
          ],
        };
      }
    },
  },
  computed: {
    hasEmissionFactorSeachFieldInput() {
      return !(
        this.emissionFactorSeachFieldInput === "" ||
        this.emissionFactorSeachFieldInput === null
      );
    },
    hasSubmittedQuery() {
      return !(this.query === "" || this.query === null);
    },
    showAutoCompleteSuggestions() {
      return this.hasEmissionFactorSeachFieldInput;
    },
  },
  watch: {
    emissionFactorSeachFieldInput(to) {
      this.autoCompleteSuggestions = [];
      this.loadingAutoCompleteSuggestions = true;

      this.autoCompleteDebounce(to);
    },
    expanded(to) {
      this.copilotQuotaLimitTranslationKey = null;

      if (to) {
        this.getSearchHistory();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.copilot {
  z-index: 200;
  position: fixed !important;
  bottom: 0 !important;
  right: 0; //calc(60px + 20px);
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  flex-direction: column;
  padding-right: 16px;
  padding-bottom: 16px;
  //width: 100px;
  width: 100%;
  pointer-events: none;

  .copilot-form {
    z-index: 1;
    width: 460px;
    background: #fff;
    margin-bottom: 8px;
    border-radius: 18px;
    overflow: hidden;
    padding-bottom: 16px;
    transition: all 245ms ease;
    pointer-events: all;

    box-shadow: rgba(0, 0, 0, 0.04) 0px 1px 4px,
      rgba(0, 0, 0, 0.04) 0px 2px 12px;

    > div {
      margin-left: 16px;
      margin-right: 16px;
    }
    .search-results {
      margin-left: 0px;
      margin-right: 0px;

      .no-result {
        margin-left: 16px;
        margin-right: 16px;
        color: #b4b4b4;
      }

      .search-history-divider {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 25px 0;

        position: sticky;
        top: 2px;
        z-index: 9;

        .line {
          flex: 1;
          height: 1px;
          background-color: #e7e6e6;
        }
        .divider-text {
          margin: 0 20px;
          color: #b4b4b4;
          font-weight: 500;
          font-size: 10px;
        }
      }

      .search-header {
        border-bottom: 1px solid #f4f2f2;
        padding-left: 16px;
        padding-right: 16px;
        height: 78px;
        display: flex;
        align-items: center;
        .search-title {
          font-size: 24px;
        }

        .search-expand {
          margin-right: 10px;
        }
      }

      .scrollable-content {
        overflow-y: scroll;
        height: calc(100vh - 419px);
        max-height: 550px;
        transition: all 245ms ease;

        .ai-factor-row {
          border: 2px solid #f25d3b;
          position: relative;
          background-color: #fdece7;
          :deep(.ef-name) {
            color: #f25d3b;
          }

          &:before {
            content: "AI";
            font-size: 11px;
            top: 0;
            left: 0;
            position: absolute;
            background-color: #f25d3b;
            color: #fff;
            padding: 1px 5px;
            font-weight: bold;
            border-bottom-right-radius: 5px;
          }
        }

        .search-label {
          margin: 0 16px;
          padding: 16px 0;
          padding-bottom: 5px;

          color: #b4b4b4;
          position: sticky;
          top: 0;
          background: #fff;

          z-index: 1;

          .search-word {
            color: #686868;
          }
        }

        .ef-header {
          background-color: #f9f9f9;
          border-radius: 12px;
          padding-top: 16px;
          margin-bottom: 0px;
          margin-left: 16px;
          margin-right: 16px;
          overflow: hidden;
          position: sticky;
          top: 0;
          z-index: 2;
          background: white;
          border-radius: 0;
          font-weight: 500;
          font-size: 14px;
          .ef-overview {
            padding: 0 16px;
            .ef-overview-info {
              padding-bottom: 8px;
              flex: 1;
            }
          }
        }
      }
    }

    .form-field {
      background: #f7f7f7;
      padding: 8px 8px 8px 16px;
      border-radius: 12px;
      margin-bottom: 16px;
      transition: all 245ms;
      overflow: hidden;

      margin-top: 16px;
      .send-btn {
        border-radius: 8px;
      }
      .suggestion-title {
        font-size: 11px;
        color: #9f9d9d;
        margin-bottom: 6px;
      }

      .search-text-field {
        &:deep(.v-input__slot::before) {
          border-style: none !important;
        }
        &:deep(.v-input__slot::after) {
          border-style: none !important;
        }
      }

      .suggestion {
        background: #beb8b65b;
        padding: 6px 12px;
        border-radius: 4px;
        margin-right: 4px;
        margin-bottom: 8px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: pre;
        flex: 1;
        max-width: 200px;
      }

      .no-suggestions {
        padding: 6px 12px;
        border-radius: 4px;
        margin-right: 4px;
        margin-bottom: 8px;
        overflow: hidden;
        flex: 1;
        color: #9f9d9d;
      }
    }
    .bottom-section {
      .location-text {
        a {
          font-size: 11px;
          color: #9f9d9d;
          display: flex;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }
      }
      .settings-link {
        a {
          font-size: 11px;
          color: #f25d3b;
          font-weight: 500;
          text-decoration: none;
          display: flex;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    &.fullscreen {
      //width: calc(100vw - 32px);
      width: calc(100% - 32px);
      transform: translateX(0%);
      .search-results {
        .scrollable-content {
          overflow: scroll;
          height: calc(100vh - 382px);
          max-height: 100%;

          .search-label {
            /*background-color: transparent;*/
            padding: 9px 0;
            z-index: 1;
            top: 45px;
          }

          .search-history-divider {
            top: 38px;
          }
        }
      }
    }
  }
  @media screen and (max-width: 560px) {
    .copilot-form {
      position: fixed;
      left: 0;
      right: 0;
      bottom: 80px;
      max-width: 100%;

      .search-results {
        .search-expand {
          display: none;
        }
        .scrollable-content {
          height: calc(100vh - 345px);
          max-height: none;
        }
      }
    }
  }

  .copilot-btn {
    border-radius: 50%;
    height: 60px;
    width: 60px;
    background: #f25d3b;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 4px, rgba(0, 0, 0, 0.2) 0px 2px 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 100ms ease-in-out 0s;
    pointer-events: all;

    .btn-icon {
      width: 24px;
      height: 24px;
    }

    &:hover {
      box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 6px,
        rgba(0, 0, 0, 0.2) 0px 4px 16px;
      transform: scale(1.1);
    }

    &.expanded {
      .btn-icon {
        width: 32px;
        height: 32px;
        path {
          fill: #f25d3b;
        }
      }

      background-color: #ffffff;
    }
  }
}

.limit-reached {
  color: #f44336;
}

:deep(.text-overflow-wrap) {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.expand-enter-active,
.expand-leave-active {
  transition: all 0.5s;
}

.expand-enter,
.expand-leave-to {
  opacity: 0;
  transform: translateY(50px);
}

.expand-left-enter-active,
.expand-left-leave-active {
  transition: all 0.5s;
}

.expand-left-enter,
.expand-left-leave-to {
  opacity: 0;
  transform: translateX(50px);
}
</style>
