import { addTimezoneOffset } from "@/util/dateTime";

export function generatePredefinedAttributeLinks(
  attributes,
  data = {},
  attributeLinks = []
) {
  attributes?.forEach((attribute) => {
    const attributeLinkExists = attributeLinks.some(
      (x) => x.attributeId === attribute.id
    );

    if (attributeLinkExists) return;

    switch (attribute.format) {
      case "Text":
        data[attribute.id] = attribute.stringValue
          ? attribute.stringValue
          : null;
        break;
      case "TextArea":
        data[attribute.id] = attribute.stringValue
          ? attribute.stringValue
          : null;
        break;
      case "Select":
        data[attribute.id] = attribute.attributeSelectOptionId;
        break;
      case "Number":
      case "Percentage":
        data[attribute.id] = attribute.decimalValue;
        break;
      case "DateTime":
      case "Date":
        data[attribute.id] = attribute.dateTimeValue
          ? addTimezoneOffset(new Date(attribute.dateTimeValue + "Z"))
          : null;
        break;
      case "Currency":
        data[attribute.id + "-secondValue"] = attribute.stringValue;
        data[attribute.id] = attribute.decimalValue;
        break;
      case "Boolean":
        data[attribute.id] = attribute.boolValue;
        break;
      case "File":
        data[attribute.id] = null;
        data[attribute.id + "-secondValue"] = null;
        break;
    }
    if (data[attribute.id] != null) {
      attributeLinks.push({
        attributeId: attribute.id,
        stringValue: attribute.stringValue,
        dateTimeValue: attribute.dateTimeValue,
        decimalValue: attribute.decimalValue,
        boolValue: attribute.boolValue,
        attributeSelectOptionId: attribute.attributeSelectOptionId,
      });
    }
  });

  return [data, attributeLinks];
}
