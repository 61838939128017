<template>
  <div class="percent-bar-list-item py-2">
    <p class="text-right mb-0">{{ emissions }}</p>
    <div class="d-flex align-center">
      <div class="mr-2 caption grey--text emission-percent">
        {{ formattedPercentage }}
      </div>
      <div class="percent-bar">
        <div
          class="percent-bar-bg"
          :style="'width:' + percentValue + '%'"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    emissions: String,
    formattedPercentage: String,
    percentValue: Number,
  },
};
</script>

<style lang="scss" scoped>
.percent-bar-list-item {
  width: 200px;
  .emission-percent {
    width: 33px;
    text-align: right;
  }
  .percent-bar {
    background: #f4f2f2;
    height: 8px;
    flex: 1;
    border-radius: 4px;
    overflow: hidden;
    justify-content: flex-end;
    display: flex;

    .percent-bar-bg {
      background: #266663;
      height: 8px;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
  }
}
</style>
