<template>
  <div class="page-container fill-height grow d-flex flex-column flex-nowrap">
    <v-card
      elevation="0"
      min-height="17.625rem"
      class="d-flex flex-column ma-4"
    >
      <v-card-text class="pa-0 flex-grow-1">
        <v-tabs
          v-model="tab"
          class="mb-4"
          style="border-bottom: 1px solid #e8e8e8"
          show-arrows
        >
          <v-tab
            v-for="contentTab in tabs"
            :key="contentTab.id"
            :href="`#${getTabHref(contentTab.id)}`"
            @change="setTabInUrl(contentTab.id)"
          >
            {{ contentTab.name }}
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab" class="fill-height fill-width px-0 pb-4">
          <tab-general
            v-if="!isOrganizationGroup"
            v-bind="generalBindings"
            @updateForm="updateForm"
            ref="generalTab"
            class="px-4"
          />
          <tab-system
            v-if="!isOrganizationGroup"
            v-bind="systemBindings"
            @updateForm="updateForm"
            ref="systemTab"
            class="px-4"
          />
        </v-tabs-items>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          class="text-none ml-0 mb-4 mb-md-0 ml-md-4 px-6 align-self-stretch"
          large
          color="#F25D3B"
          dark
          elevation="0"
          tile
          x-large
          :loading="form.processing"
          @click="submit"
        >
          {{ $t("common.actions.saveChanges") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
import qs from "qs";
import { appLayout } from "@/util/layout";
import { serialize } from "object-to-formdata";
import TabGeneral from "./Tabs/TabGeneral.vue";
import TabSystem from "./Tabs/TabSystem.vue";
import Formatters from "../../mixins/Formatters.vue";


export default {
  layout: appLayout({ title: "pages.settings.index.title" }),
  mixins: [Formatters],
  components: {
    TabGeneral,
    TabSystem,
  },
  props: {
    settings: Object,
    currencies: Array,
    organization: Object,
    fiscalYears: Array,
    dateFormats: Array,
    isOrganizationGroup: Boolean,
  },
  data() {
    return {
      form: this.$inertia.form(this.generateInitialForm()),
      tab: window.location.hash?.substring(1) ?? null,
      emissionRegions: [],
    };
  },
  mounted() {
    this.getEmissionRegions();
  },
  computed: {
    generalBindings() {
      return {
        tabHref: this.getTabHref(1),
        organization: this.organization,
      };
    },
    systemBindings() {
      return {
        tabHref: this.getTabHref(2),
        currencies: this.currencies,
        defaultCurrencyId: this.form.defaultCurrencyId,
        emissionRegions: this.emissionRegions,
        defaultRegionId: this.form.defaultRegionId,
        fiscalYear: this.form.fiscalYear,
        fiscalYears: this.fiscalYears,
        dateFormats: this.dateFormats,
        defaultDateFormat: this.form.defaultDateFormat,
      };
    },
    fixedAssetsBindings() {
      return {
        tabHref: this.getTabHref(4),
        fiscalYear: this.form.fiscalYear,
        fiscalYears: this.fiscalYears,
      };
    },
    tabs() {
      let tabs = [
        {
          id: 1,
          name: this.$t("pages.settings.index.tabs.general"),
        },
        {
          id: 2,
          name: this.$t("pages.settings.index.tabs.system"),
        },

      ];

      if (this.moduleFixedAssetsEnabled) {
        tabs.push({
          id: 4,
          name: this.$t("pages.settings.index.tabs.fixedAssets"),
        });
      }

      if (this.isOrganizationGroup) {
        tabs = tabs.filter((x) => [6, 7].includes(x.id));
      }

      return tabs;
    },
    moduleFixedAssetsEnabled() {
      return this.$inertia.page.props.auth.settings.moduleFixedAssets;
    },
  },
  methods: {
    submit() {
      if (this.tab === "general") {
        this.$refs.generalTab.submit();
      } else if (this.tab === "integrations") {
        this.$refs.integrationsTab.submit();
      } else {
        this.form
          .transform((data) =>
            serialize(data, { noFilesWithArrayNotation: true })
          )
          .put(this.route("settings.update"), {
            onSuccess: () => {
              this.form = this.$inertia.form(this.generateInitialForm());

              if (this.tab) window.location.hash = `#${this.tab}`;
            },
          });
      }
    },
    reloadSettings(updatedSettings) {
      this.form.defaultCurrencyId = updatedSettings.defaultCurrencyId;
      this.form.defaultRegionId = updatedSettings.defaultRegionId;
    },
    updateForm(key, value) {
      this.form[key] = value;
    },
    generateInitialForm() {
      return {
        defaultCurrencyId: this.settings?.defaultCurrencyId,
        fiscalYear: this.settings?.fiscalYear,
        defaultRegionId: this.settings?.defaultRegionId,
        defaultDateFormat: this.settings?.defaultDateFormat,
      };
    },
    getTabHref(tabId) {
      switch (tabId) {
        case 1:
          return "general";
        case 2:
          return "system";
      }
    },
    setTabInUrl(tabId) {
      const tabHref = this.getTabHref(tabId);

      if (!tabHref) return;

      const existingQuery = qs.parse(window.location.search.substring(1));

      const newUrl =
        this.route("settings.index", existingQuery) + `#${tabHref}`;

      window.history.replaceState(null, "", newUrl);
    },
    getEmissionRegions() {
      fetch(this.route("api.emission-regions.index"))
        .then((res) => res.json())
        .then((data) => {
          this.emissionRegions = data.map((x) => ({
            ...x,
            value: x.countryName ? x.countryName : x.name,
          }));
        });
    },
  },
  watch: {
    settings(value) {
      this.reloadSettings(value);
    },
  },
};
</script>
