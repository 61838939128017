<template>
  <dialog-form
    :maxWidth="(selectedImportStep === 2 && !disableTable) ? '100%' : '929px'"
    :show-dialog="show"
    :persistent="true"
    @clickOutside="hideModal()"
  >
    <asset-import-step-one
      v-if="selectedImportStep === 1"
      :selectedImportFiles="selectedImportFiles"
      @errorChanged="handleStepOneError"
      @invalidFormatChanged="(value) => (invalidImportFileFormat = value)"
      @filesChanged="handleImportFilesChanged"
    />

    <asset-import-step-two
      v-if="selectedImportStep === 2"
      :attributes="attributes"
      :headerMappings="headerMappings"
      :assetRows="form.assetRows"
      :loading="tableIsLoading"
      :isEditing="tableIsEditing"
      :hasErrors="tableHasErrors"
      :updateOnId="form.updateOnId"
      :importStatusCode="importStatusCode"
      @assetAttributeLinkUpdated="handleAssetAttributeLinkUpdated"
      @assetRemoved="handleAssetRemoved"
      @assetsRemoved="handleAssetsRemoved"
      @headerMappingChanged="handleHeaderMappingChanged"
      @isEditingUpdated="(isEditing) => (tableIsEditing = isEditing)"
      @updateOnIdChanged="(value) => (form.updateOnId = value)"
      :disableImportTable="disableTable"
    />
    <v-card-actions class="py-6 px-6 d-flex flex-column-reverse flex-md-row">
      <v-btn
        class="text-none px-4 mb-4 mb-md-0 align-self-stretch"
        color="#686868"
        large
        elevation="0"
        text
        tile
        x-large
        @click="hideModal"
        :loading="closeButtonLoading"
        ><v-icon class="mr-3" dark>mdi-close</v-icon
        >{{ $t("common.actions.close") }}
      </v-btn>
      <v-btn
        class="text-none px-4 mb-4 mb-md-0 align-self-stretch"
        color="#686868"
        large
        elevation="0"
        text
        tile
        x-large
        :loading="closeButtonLoading"
        v-if="selectedImportStep > 1 && selectedImportStep < 3"
        @click="moveToPreviousStep"
        ><v-icon class="mr-3" dark>mdi-chevron-left</v-icon
        >{{ $t("pages.assets.import.back") }}
      </v-btn>

      <v-spacer></v-spacer>
      <v-btn
        class="text-none ml-0 mb-4 mb-md-0 ml-md-4 px-6 align-self-stretch"
        large
        color="#266663"
        dark
        elevation="0"
        tile
        x-large
        @click="moveToNextStep()"
        :disabled="isNextButtonDisabled"
        :loading="nextButtonLoading"
      >
        <v-icon class="white--text">mdi-chevron-right</v-icon>
        {{
          selectedImportStep === 2
            ? $t("pages.assets.import.import")
            : $t("pages.assets.import.next")
        }}
      </v-btn>
    </v-card-actions>
    <v-alert
      dense
      v-if="errorMessage && errorMessageStep === selectedImportStep"
      type="error"
      class="mb-0"
    >
      {{ errorMessage }}
    </v-alert>
  </dialog-form>
</template>
<script>
import { serialize } from "object-to-formdata";

import AssetImportStepOne from "./ImportSteps/AssetImportStepOne.vue";
import AssetImportStepTwo from "./ImportSteps/AssetImportStepTwo.vue";
import DialogForm from "../Dialog/DialogForm.vue";

export default {
  components: {
    AssetImportStepOne,
    AssetImportStepTwo,
    DialogForm,
  },
  props: {
    value: Boolean,
    filesFromParent: [File, Array],
    importStepFromParent: Number,
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    currentStep() {
      return this.importSteps.find((x) => x.id === this.selectedImportStep);
    },
    isNextButtonDisabled() {
      switch (this.selectedImportStep) {
        case 1:
          return this.invalidImportFileFormat || !this.selectedImportFiles;
        case 2:
          return (
            this.tableIsEditing ||
            Object.values(this.headerMappings).filter(
              (x) => x.type === "attribute" && x.attribute !== null
            ).length <= 0 ||
            !this.form.assetRows.length
          );
        default:
          return true;
      }
    },
    importSteps() {
      return [
        { id: 1, title: this.$t("pages.assets.import.stepTitles.method") },
        { id: 2, title: this.$t("pages.assets.import.stepTitles.validate") },
        {
          id: 3,
          title: this.$t("pages.assets.import.stepTitles.success"),
          color: "light-green",
        },
      ];
    },
    tableIsLoading() {
      return this.tableIsLoadingCount > 0;
    },
  },
  data() {
    return {
      selectedImportStep: 1,
      //selectedImportFile: null, // Not used anymore ?
      selectedImportFiles: [],
      errorMessage: null,
      errorMessageStep: null,
      invalidImportFileFormat: false,
      attributes: [],
      headerMappings: {},
      form: {
        headerToAttributeIdMappings: {},
        headerToFieldNameMappings: {},
        assetRows: [],
        updateOnId: true,
      },
      tableIsLoadingCount: 0,
      tableIsEditing: false,
      tableHasErrors: false,
      nextButtonLoading: false,
      closeButtonLoading: false,
      importSuccess: {
        importCount: 0,
        updateCount: 0,
      },
      saveImportedFile: true,
      importedFileName: null,
      importStatusCode: null,
      disableTable: false,
    };
  },
  methods: {
    fetchParsedAssetsFromImportFilePDF() {
      if (
        this.selectedImportFiles.length <= 0 ||
        this.form.assetRows?.length > 0
      )
        return;

      const formData = new FormData();

      const files = this.selectedImportFiles.filter(
        (item) => item.type === "application/pdf"
      );
      files.forEach((file) => {
        formData.append("invoiceFiles", file);
      });

      this.tableIsLoadingCount++;

      fetch(this.route("api.assets.invoices.parse"), {
        method: "POST",
        body: formData,
      })
        .then((res) => res.json())
        .then((data) => {
          this.attributes = data.attributes;
          this.headerMappings = data.headerMappings;

          const headers = Object.keys(this.headerMappings);

          const encounteredAttributes = [];
          const encounteredFields = [];

          headers.forEach((header) => {
            const mapping = this.headerMappings[header];

            if (mapping.type === "attribute") {
              let attribute = mapping.attribute;

              if (encounteredAttributes.includes(attribute)) {
                this.headerMappings[header] = {
                  id: null,
                  name: this.$t("pages.assets.import.skip"),
                };
                attribute = null;
              }

              this.form.headerToAttributeIdMappings[header] =
                attribute?.id ?? null;

              if (attribute) {
                encounteredAttributes.push(attribute);
              }
            } else if (mapping.type === "field") {
              let field = mapping.fieldName;

              if (encounteredFields.includes(field)) {
                this.headerMappings[header] = {
                  type: "attribute",
                  attribute: {
                    id: null,
                    name: this.$t("pages.assets.import.skip"),
                  },
                };
                field = null;
              }

              this.form.headerToFieldNameMappings[header] = field ?? null;

              if (field) {
                encounteredFields.push(field);
              }
            }
          });

          this.form.assetRows = this.form.assetRows.concat(data.parsedAssets);
        })
        .finally(() => this.tableIsLoadingCount--);
    },
    moveToPreviousStep() {
      const newStep = this.selectedImportStep - 1;

      if (newStep < 1) {
        this.selectedImportStep = 1;
        return;
      }

      this.selectedImportStep = newStep;
    },
    async moveToNextStep(step2Validated) {
      if (this.selectedImportStep === 2) {
        if (!step2Validated) await this.submitImportData();

        if (
          !this.tableHasErrors &&
          this.importStatusCode >= 200 &&
          this.importStatusCode <= 299
        ) {
          this.hideModal(true);
        }

        return;
      }
      if (this.selectedImportFiles.length > 0) {
        this.selectedImportStep++;
      }

      this.runPageChangeSideEffects();
    },
    runPageChangeSideEffects() {
      if (this.selectedImportStep === 2) {
        // Check if we have PDF files, and run thoose
        if (
          this.selectedImportFiles.filter(
            (item) => item.type === "application/pdf"
          ).length > 0
        ) {
          this.fetchParsedAssetsFromImportFilePDF();
        }

        // Check if we have another files then PDF's and run thoose
        if (
          this.selectedImportFiles.filter(
            (item) => item.type !== "application/pdf"
          ).length > 0
        ) {
          this.fetchParsedAssetsFromImportFile();
        }
      }
    },
    fetchParsedAssetsFromImportFile() {
      if (
        this.selectedImportFiles.length <= 0 ||
        this.form.assetRows?.length > 0
      )
        return;

      const formData = new FormData();

      const files = this.selectedImportFiles.filter(
        (item) => item.type !== "application/pdf"
      );
      files.forEach((file) => {
        formData.append("assetImportSpreadsheet", file);
      });

      this.tableIsLoadingCount++;

      fetch(this.route("api.assets.parse"), {
        method: "POST",
        body: formData,
      })
        .then((res) => res.json())
        .then((data) => {
          this.attributes = data.attributes;
          this.headerMappings = data.headerMappings;

          const headers = Object.keys(this.headerMappings);

          const encounteredAttributes = [];
          const encounteredFields = [];

          headers.forEach((header) => {
            const mapping = this.headerMappings[header];

            if (mapping.type === "attribute") {
              let attribute = mapping.attribute;

              if (encounteredAttributes.includes(attribute)) {
                this.headerMappings[header] = {
                  id: null,
                  name: this.$t("pages.assets.import.skip"),
                };
                attribute = null;
              }

              this.form.headerToAttributeIdMappings[header] =
                attribute?.id ?? null;

              if (attribute) {
                encounteredAttributes.push(attribute);
              }
            } else if (mapping.type === "field") {
              let field = mapping.fieldName;

              if (encounteredFields.includes(field)) {
                this.headerMappings[header] = {
                  type: "attribute",
                  attribute: {
                    id: null,
                    name: this.$t("pages.assets.import.skip"),
                  },
                };
                field = null;
              }

              this.form.headerToFieldNameMappings[header] = field ?? null;

              if (field) {
                encounteredFields.push(field);
              }
            }
          });

          this.form.assetRows = this.form.assetRows.concat(data.parsedAssets);
        })
        .finally(() => this.tableIsLoadingCount--);
    },
    async submitImportData() {
      this.nextButtonLoading = true;

      const omittedMappings = [];

      const attributeMappings = { ...this.form.headerToAttributeIdMappings };

      for (let mapping of Object.keys(attributeMappings)) {
        if (!attributeMappings[mapping]) {
          delete attributeMappings[mapping];
          omittedMappings.push(mapping);
        }
      }

      const fieldMappings = { ...this.form.headerToFieldNameMappings };

      for (let mapping of Object.keys(fieldMappings)) {
        if (!fieldMappings[mapping]) {
          delete fieldMappings[mapping];
          omittedMappings.push(mapping);
        }
      }

      let assetRows = [...this.form.assetRows.map((x) => x.row)];

      for (let mapping of omittedMappings) {
        assetRows.forEach((row) => {
          delete row[mapping];
        });
      }

      const bodyContent = {
        ...this.form,
        assetRowsJson: JSON.stringify(assetRows),
        headerToAttributeIdMappingsJson: JSON.stringify(attributeMappings),
        headerToFieldNameMappingsJson: JSON.stringify(fieldMappings),
      };

      delete bodyContent.assetRows;
      delete bodyContent.headerToAttributeIdMappings;
      delete bodyContent.headerToFieldNameMappings;

      this.errorMessageStep = null;
      this.tableHasErrors = false;

      return fetch(this.route("api.assets.import"), {
        method: "POST",
        body: serialize(bodyContent, {
          indices: true,
          dotsForObjectNotation: true,
        }),
      })
        .then((res) => {
          const statusCode = this.getStatusCode(res);

          this.importStatusCode = statusCode;

          if (statusCode >= 200 && statusCode <= 299) {
            res.json().then((data) => {
              this.handleSuccessfulImport(data);
            });

            return;
          }

          if (statusCode !== 422) return;

          return res.json();
        })
        .then((data) => {
          if (!data || data.status !== "error") return;

          this.errorMessage = this.$t(
            `pages.assets.import.resultDescriptions.${data.description}`
          );
          this.errorMessageStep = 2;
          this.form.assetRows = data.assetRows;
          this.tableHasErrors = true;
        })
        .finally(() => {
          this.nextButtonLoading = false;
        });
    },
    async submitImportedFile() {
      this.closeButtonLoading = true;

      const blob = this.selectedImportFile.slice(
        0,
        this.selectedImportFile.size,
        this.selectedImportFile.type
      );
      const renamedFile = new File([blob], this.importedFileName, {
        type: this.selectedImportFile.type,
      });

      const formData = new FormData();
      formData.append("files", renamedFile);

      return fetch(this.route("api.files.store"), {
        method: "POST",
        body: formData,
      }).finally(() => {
        this.closeButtonLoading = false;
      });
    },
    resetModalState() {
      this.disableTable = false;
      this.selectedImportStep = 1;
      this.selectedImportFile = null;
      this.selectedImportFiles = [];
      this.errorMessage = null;
      this.attributes = [];
      this.headerMappings = {};
      this.form = {
        headerToAttributeIdMappings: {},
        headerToFieldNameMappings: {},
        assetRows: [],
      };
      this.tableIsEditing = false;
      this.tableHasErrors = false;
      this.nextButtonLoading = false;
      this.importStatusCode = null;
    },
    handleStepOneError(error) {
      this.errorMessage = error;
      this.errorMessageStep = 1;
    },
    handleImportFilesChanged(value) {
      this.selectedImportFiles = value;

      this.form.assetRows = [];
      this.form.headerToAttributeIdMappings = {};
      this.form.headerToFieldNameMappings = {};
      this.headerMappings = {};

      if (value) {
        this.importedFileName = value.name;
      }
    },
    handleAssetRemoved(assetRow) {
      this.form.assetRows = this.form.assetRows.filter((x) => x !== assetRow);
    },
    handleAssetsRemoved(assetRows) {
      this.form.assetRows = this.form.assetRows.filter(
        (x) => !assetRows.includes(x)
      );
    },
    handleHeaderMappingChanged(header, attribute) {
      let mapping = this.headerMappings[header];

      if (!mapping) {
        mapping = {
          type: "attribute",
          attribute: null,
        };
      }

      this.headerMappings[header] = {
        ...mapping,
        attribute: attribute,
      };

      const attributeId = attribute?.id;

      this.form.headerToAttributeIdMappings[header] = attributeId;
    },
    handleAssetAttributeLinkUpdated(assetRow, header, value) {
      const index = this.form.assetRows.indexOf(assetRow);

      if (index === -1) return;

      this.form.assetRows[index].row[header] = value;

      if (this.form.assetRows[index].columnErrorMessages) {
        delete this.form.assetRows[index].columnErrorMessages[header];
      }
    },
    handleSuccessfulImport(data) {
      this.tableHasErrors = false;
      this.errorMessage = false;

      this.importSuccess.importCount = data.importedAssetCount;
      this.importSuccess.updateCount = data.updatedAssetCount;

      this.moveToNextStep(true);
    },
    async hideModal(skipConfirmation = false) {
      if (
        !skipConfirmation &&
        !window.confirm(this.$t("pages.assets.import.confirmClose"))
      ) {
        this.$emit("reloadPage");
        return;
      }

      // TODO: change save file functionality to run on step 2 (with dialog?)
      if (this.selectedImportStep === 3 && this.saveImportedFile) {
        await this.submitImportedFile();
      } else if (this.selectedImportStep === 2) {
        this.$emit("reloadPage");
      }

      this.show = false;
      this.resetModalState();
    },
    getStatusCode(res) {
      if (res.url.includes("/errors/")) {
        const index = res.url.lastIndexOf("/") + 1;

        const statusCodeString = res.url.substring(index);

        return parseInt(statusCodeString);
      }

      return res.status;
    },
  },
  mounted() {},
  watch: {
    filesFromParent(to) {
      console.log(to);
      this.handleImportFilesChanged(to);
      if (to[0]?.type === "application/pdf") {
        this.disableTable = true;
      }
      this.moveToNextStep()
    },
  },
};
</script>
<style scoped>
.import-card {
  position: relative;
}

.import-card .step-label {
  position: absolute;
  top: 8px;
  left: 0;
  right: 0;
  text-align: center;
  font-weight: bolder;
}
</style>
