<template>
  <dialog-form
    :show-dialog="show"
    @showStateChanged="show = $event"
    max-width="600px"
  >
    <dialog-form-section-one-col class="py-6 px-2">
      <div class="py-8 d-flex justify-center">
        <LogoBlack />
      </div>
      <h6 class="text-subtitle-1 grey--text mb-1 font-weight-light text-center">
        {{
          $t("pages.settings.integrationUniconta.credentials.enterCredentials")
        }}
      </h6>
      <v-alert v-if="errorMessage" dense outlined type="error" class="mt-4">
        <template
          v-if="
            $te(
              `common.integrations.uniconta.errorMessages.${errorMessage}`,
              'en'
            )
          "
        >
          {{ $t(`common.integrations.uniconta.errorMessages.${errorMessage}`) }}
        </template>
        <template v-else>
          {{ $t("common.integrations.uniconta.errorMessages.Unknown") }}
        </template>
      </v-alert>
      <form @submit.prevent="submit">
        <v-text-field
          autofocus
          autocomplete="username"
          name="username"
          v-model="form.username"
          :label="$t('pages.settings.integrationUniconta.credentials.username')"
          hide-details="auto"
          background-color="#F7F7F7"
          filled
          solo
          dense
          flat
          class="black--text mt-4 pt-2 pb-2 input"
        ></v-text-field>
        <v-text-field
          type="password"
          autocomplete="password"
          name="password"
          v-model="form.password"
          :label="$t('pages.settings.integrationUniconta.credentials.password')"
          hide-details="auto"
          background-color="#F7F7F7"
          filled
          solo
          dense
          flat
          class="black--text pt-2 pb-2 mb-6 input"
        ></v-text-field>
        <v-btn
          tile
          block
          elevation="0"
          color="orange"
          class="font-weight-regular text-capitalize mb-2 white--text"
          type="submit"
          @click="submit"
          :disabled="submitDisabled"
        >
          {{ $t("common.actions.submit") }}
        </v-btn>
      </form>
    </dialog-form-section-one-col>
  </dialog-form>
</template>
<script>
import { serialize } from "object-to-formdata";

import DialogForm from "../../Components/Dialog/DialogForm.vue";
import DialogFormSectionOneCol from "../../Components/Dialog/DialogFormSectionOneCol.vue";
import LogoBlack from "../../assets/svg/verarca-logo-black.svg";

export default {
  components: {
    DialogForm,
    DialogFormSectionOneCol,
    LogoBlack,
  },
  props: {
    value: Boolean,
  },
  emits: [
    "input",
    "update:unicontaCompanies",
    "update:unicontaUsername",
    "update:unicontaPassword",
  ],
  data() {
    return {
      form: {
        username: null,
        password: null,
      },
      errorMessage: null,
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    submitDisabled() {
      return !this.form.username || !this.form.password;
    },
  },
  methods: {
    submit() {
      if (this.submitDisabled) return;

      fetch(this.route("api.settings.uniconta.companies.fetch"), {
        method: "POST",
        body: serialize(this.form),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.errorCode) {
            this.errorMessage = data.errorCode;
            return;
          }

          this.errorMessage = null;
          this.$emit("update:unicontaCompanies", data);
          this.$emit("update:unicontaUsername", this.form.username);
          this.$emit("update:unicontaPassword", this.form.password);
        });
    },
  },
};
</script>
