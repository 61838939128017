<template>
  <v-tab-item :value="tabValue">
    <v-card flat>
      <dialog-form-section-two-cols no-bottom-border>
        <template slot="left">
          <div>
            <asset-attribute-link-input
              :attribute="timeOfCommissioningAttribute"
              :value="timeOfCommissioningData"
              @input="handleTimeOfCommissioningData"
              @dateTime_changed="updateTimeOfCommissioningValue"
            />
            <asset-attribute-link-input
              :attribute="startOfDepreciationAttribute"
              :value="startOfDepreciationData"
              @input="updateData(startOfDepreciationAttribute.id, $event)"
              @select_changed="
                updateSelectValue(startOfDepreciationAttribute, $event)
              "
            />
            <asset-attribute-link-input
              :attribute="methodAttribute"
              :value="methodData"
              @input="updateData(methodAttribute.id, $event)"
              @select_changed="updateSelectValue(methodAttribute, $event)"
            />
            <asset-attribute-link-input
              :attribute="depreciationPeriodAttribute"
              :value="depreciationPeriodData"
              integerInput
              :numberMinValue="1"
              :numberMaxValue="1000"
              @input="updateData(depreciationPeriodAttribute.id, $event)"
              @decimal_changed="
                updateDecimalValue(depreciationPeriodAttribute, $event)
              "
            />

            <v-row dense v-if="false">
              <v-col cols="4" align-self="center">
                <span class="mr-14">
                  {{ $t("pages.assets.form.fixedLabels.depreciationPercent") }}:
                </span>
              </v-col>
              <v-col>
                <v-text-field
                  dense
                  hide-details
                  v-model="depreciationPercentage"
                  clearable
                  type="text"
                  solo
                >
                </v-text-field>
              </v-col>
            </v-row>
            <asset-attribute-link-input
              :attribute="scrapValueAttribute"
              :value="scrapValueData"
              :currencies="currencies"
              @input="updateData(scrapValueAttribute.id, $event)"
              :secondValue="scrapValueCurrencyData"
              @input_secondValue="
                updateSecondValueData(scrapValueAttribute.id, arguments[0])
              "
              @currency_changed="
                updateCurrencyValue(
                  scrapValueAttribute,
                  $event,
                  scrapValueCurrencyData
                )
              "
              @currencyCode_changed="
                updateCurrencyCodeValue(
                  scrapValueAttribute,
                  scrapValueData,
                  $event
                )
              "
            />
            <asset-attribute-link-input
              :attribute="dateOfSaleAttribute"
              :value="dateOfSaleData"
              @input="updateData(dateOfSaleAttribute.id, $event)"
              @dateTime_changed="
                updateDateTimeValue(dateOfSaleAttribute, $event)
              "
            />
            <asset-attribute-link-input
              :attribute="salePriceAttribute"
              :value="salePriceData"
              :secondValue="salePriceCurrencyData"
              :currencies="currencies"
              @input="updateData(salePriceAttribute.id, $event)"
              @input_secondValue="
                updateSecondValueData(salePriceAttribute.id, arguments[0])
              "
              @currency_changed="
                updateCurrencyValue(
                  salePriceAttribute,
                  $event,
                  salePriceCurrencyData
                )
              "
              @currencyCode_changed="
                updateCurrencyCodeValue(
                  salePriceAttribute,
                  salePriceData,
                  $event
                )
              "
            />
            <asset-attribute-link-input
              v-if="false"
              :attribute="regionAttribute"
              :value="regionData"
              @input="updateData(fixedAttributes.fixedAssetsRegionId, $event)"
              @select_changed="updateSelectValue(regionAttribute, $event)"
            />

            <v-row dense>
              <v-col>
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  :return-value.sync="date"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <dialog-form-input-wrapper
                      :title="
                        $t('pages.assets.form.fixedLabels.depreciationMonth')
                      "
                    >
                      <v-text-field
                        append-icon="mdi-calendar-blank-outline"
                        solo
                        hide-details
                        v-model="date"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        class="rounded-0 mb-1"
                        background-color="#F7F7F7"
                        color="#F25D3B"
                        flat
                        single-line
                        filled
                      ></v-text-field>
                    </dialog-form-input-wrapper>
                  </template>
                  <v-date-picker
                    first-day-of-week="1"
                    v-model="date"
                    no-title
                    scrollable
                    type="month"
                    :min="
                      timeOfCommissioningData
                        ? timeOfCommissioningData.toISOString()
                        : null
                    "
                    @change="$refs.menu.save(date)"
                    @input="updateDepreciationMonth(date)"
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </div>
        </template>
        <template slot="right">
          <div>
            <v-row dense class="mt-3">
              <v-col cols="5">
                {{ $t("pages.assets.form.fixedLabels.purchasePrice") }}:
              </v-col>
              <v-col cols="7">
                <span
                  v-if="!purchasePrice || !purchasePriceCurrency"
                  class="red--text"
                >
                  {{ $t("pages.assets.form.validation.missingPurchasePrice") }}
                </span>
                <template v-else>
                  {{ formattedPurchasePrice }}
                </template>
              </v-col>
            </v-row>
            <v-row dense class="mt-3">
              <v-col cols="5">
                {{ $t("pages.assets.form.fixedLabels.purchaseDate") }}:
              </v-col>
              <v-col cols="7">
                <span v-if="!purchaseDate" class="red--text">
                  {{ $t("pages.assets.form.validation.missingPurchaseDate") }}
                </span>
                <template v-else>
                  {{ formattedPurchaseDate }}
                </template>
              </v-col>
            </v-row>
            <v-row dense class="mt-3">
              <v-col cols="5">
                {{
                  $t("pages.assets.form.fixedLabels.depreciationStartMonth")
                }}:
              </v-col>
              <v-col cols="7">
                <span v-if="!timeOfCommissioningData" class="red--text">
                  {{ $t("pages.assets.form.validation.missingPurchaseDate") }}
                </span>
                <template v-else>
                  {{ formattedDepreciationStartMonth }}
                </template>
              </v-col>
            </v-row>
            <v-row dense class="mt-3">
              <v-col cols="5">
                {{ $t("pages.assets.form.fixedLabels.yearlyDepreciation") }}:
              </v-col>
              <v-col cols="7">
                <span
                  :class="`${isEmpty(yearlyDepreciations) ? 'red--text' : ''}`"
                >
                  {{ formattedYearlyDepreciation }}
                </span>
              </v-col>
            </v-row>
            <v-row dense class="mt-5"> </v-row>
            <v-card
              outlined
              elevation="2"
              class="mt-3"
              rounded="xl"
              style="padding: 16px; margin-top: 20px"
            >
              <v-menu
                ref="menuBox"
                v-model="menuBox"
                :close-on-content-click="false"
                :return-value.sync="date"
                transition="scale-transition"
                offset-y
                max-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    style="
                      all: unset;
                      border-radius: 10px;
                      position: absolute;
                      width: 130px;
                      top: 0%;
                      left: 50%;
                      transform: translate(-50%, -50%);
                      font-weight: bold;
                      font-size: larger;
                    "
                    append-icon="mdi-calendar-blank-outline"
                    solo
                    hide-details
                    :value="formattedMonthlyDepreciationString"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                  <p
                    style="
                      position: absolute;
                      width: 130px;
                      top: 6%;
                      left: 52%;
                      transform: translate(-50%, -50%);
                      font-size: x-small;
                      text-decoration: underline;
                      pointer-events: none;
                    "
                  >
                    {{ $t("pages.assets.form.fixedLabels.clickToChange") }}
                  </p>
                </template>
                <v-date-picker
                  first-day-of-week="1"
                  v-model="date"
                  no-title
                  scrollable
                  type="month"
                  :min="
                    timeOfCommissioningData
                      ? timeOfCommissioningData.toISOString()
                      : null
                  "
                  @change="$refs.menuBox.save(date)"
                  @input="updateDepreciationMonth(date)"
                >
                </v-date-picker>
              </v-menu>
              <v-row dense class="mt-3">
                <v-col cols="5">
                  {{ $t("pages.assets.form.fixedLabels.monthlyDepreciation") }}
                </v-col>
                <v-col cols="7">
                  <span
                    :class="`${
                      isEmpty(yearlyDepreciations) ? 'red--text' : ''
                    }`"
                  >
                    {{ formattedMonthlyDepreciation }}
                  </span>
                </v-col>
              </v-row>
              <v-row dense class="mt-3">
                <v-col cols="5">
                  {{
                    $t("pages.assets.form.fixedLabels.accumulatedDepreciation")
                  }}:
                </v-col>
                <v-col cols="7">
                  <span
                    :class="`${
                      isEmpty(yearlyDepreciations) ? 'red--text' : ''
                    }`"
                  >
                    {{ formattedAccumulatedDepreciation }}
                  </span>
                </v-col>
              </v-row>
              <v-row dense class="mt-3">
                <v-col cols="5">
                  {{ $t("pages.assets.form.fixedLabels.bookValue") }}:
                </v-col>
                <v-col cols="7">
                  {{ formattedCurrentBookValue }}
                </v-col>
              </v-row>
            </v-card>
            <v-row v-if="dateOfSaleData && salePriceData" dense class="mt-3">
              <v-col cols="5">
                {{ $t("pages.assets.form.fixedLabels.gainLoss") }}:
              </v-col>
              <v-col cols="7">
                <template>
                  {{ formattedGainLoss }}
                </template>
              </v-col>
            </v-row>
          </div>
        </template>
      </dialog-form-section-two-cols>
    </v-card>
  </v-tab-item>
</template>
<script>
import {
  calculateDepreciations,
  calculateBookValue,
  calculateGainLoss,
  getDepreciationInYears,
} from "../../../util/calculators";
import {
  fixedAttributes,
  fixedFieldAttributes,
  depreciationStartModes,
} from "../../../util/fixedAssetData";
import {
  fullNumberFormatter,
  integerNumberFormatter,
  roundedNumberFormatter,
} from "../../../util/formatters";
import { removeTimezoneOffset } from "@/util/dateTime";
import Decimal from "decimal.js";
import { DateTime } from "luxon";
import { isEmpty } from "lodash";
import DialogFormSectionTwoCols from "../../../Components/Dialog/DialogFormSectionTwoCols.vue";

import AssetAttributeLinkInput from "../../../Components/Attribute/AttributeLinkInput.vue";
import DialogFormInputWrapper from "../../../Components/Dialog/inputs/DialogFormInputWrapper.vue";

export default {
  components: {
    DialogFormSectionTwoCols,
    AssetAttributeLinkInput,
    DialogFormInputWrapper,
  },
  props: {
    assetAttributeLinkData: Object,
    attributes: Array,
    currencies: Array,
    defaultCurrencyCode: String,
    tabValue: [String, Number],
  },
  data() {
    return {
      fixedAttributes,
      date: this.getDate(),
      menu: false,
      menuBox: false,
    };
  },
  methods: {
    getDate() {
      let dateAttribute =
        this.assetAttributeLinkData[
          fixedFieldAttributes.fixedAssetsDepreciationMonthId
        ];

      if (dateAttribute === null) {
        dateAttribute = new Date();
      }

      return DateTime.fromJSDate(dateAttribute).toFormat("yyyy-MM");
    },
    formatMonthPickerDateTime(dateString) {
      return DateTime.fromISO(dateString);
    },
    updateData(attributeId, value) {
      this.$emit("asset_attribute_data_changed", attributeId, value);
    },
    updateSecondValueData(attributeId, secondValue) {
      this.$emit(
        "asset_attribute_data_second_value_changed",
        attributeId,
        secondValue
      );
    },
    updateSelectValue(attribute, selectedOption) {
      this.$emit("select_changed", selectedOption, attribute);
    },
    updateCurrencyValue(attribute, price, currency) {
      this.$emit("currency_changed", price, currency, attribute);
    },
    updateCurrencyCodeValue(attribute, price, currency) {
      this.$emit("currencyCode_changed", price, currency, attribute);
    },
    updateDecimalValue(attribute, decimalValue) {
      this.$emit("decimal_changed", decimalValue, attribute);
    },
    updateDateTimeValue(attribute, dateTimeValue) {
      this.$emit("dateTime_changed", dateTimeValue, attribute);
    },
    updateDepreciationMonth(date) {
      let attribute = this.getAttribute(
        fixedFieldAttributes.fixedAssetsDepreciationMonthId
      );

      const [year, month] = date.split("-");
      const saveDate = new Date(year, month - 1);

      this.$emit("dateTime_changed", saveDate, attribute);
    },
    isEmpty(value) {
      return isEmpty(value);
    },
    splitFormattedCurrency(value) {
      const priceParts = (value ?? "")
        .split(" ")
        .filter((x) => x.trim() !== "");

      if (priceParts.length !== 2) {
        return [null, null];
      }

      const currencyCode = priceParts[0];
      const currency = priceParts[1].replaceAll(".", "").replaceAll(",", ".");

      return [currency, currencyCode];
    },
    handleTimeOfCommissioningData(value) {
      const adjustedValue = removeTimezoneOffset(value);

      const timeOfCommisioning = adjustedValue?.toISOString();
      const purchaseDate = this.purchaseDate?.toISOString();

      if (timeOfCommisioning === purchaseDate) return;

      this.$emit(
        "asset_attribute_data_changed",
        fixedAttributes.fixedAssetsTimeOfCommissioningId,
        value
      );
    },
    updateTimeOfCommissioningValue(value) {
      const adjustedValue = removeTimezoneOffset(value);

      const timeOfCommisioning = adjustedValue?.toISOString();
      const purchaseDate = this.purchaseDate?.toISOString();

      if (timeOfCommisioning === purchaseDate) return;

      this.$emit("dateTime_changed", value, this.timeOfCommissioningAttribute);
    },
    getAttribute(attributeId) {
      return this.attributes.find((x) => x.id === attributeId);
    },
  },
  computed: {
    defaultStartOfDepreciationId() {
      return this.$inertia.page.props.auth.settings.defaultStartOfDepreciationId;
    },
    defaultDepreciationMethodId() {
      return this.$inertia.page.props.auth.settings.defaultDepreciationMethodId;
    },
    depreciationPercentage() {
      let percentageResult =
        100 / getDepreciationInYears(this.depreciationPeriodData);

      return roundedNumberFormatter.format(percentageResult);
    },
    timeOfCommissioningData() {
      let data =
        this.assetAttributeLinkData[
          fixedAttributes.fixedAssetsTimeOfCommissioningId
        ];

      if (data) data = removeTimezoneOffset(data);

      return data ?? this.purchaseDate;
    },
    timeOfCommissioningAttribute() {
      return this.getAttribute(
        fixedAttributes.fixedAssetsTimeOfCommissioningId
      );
    },
    methodData() {
      return this.assetAttributeLinkData[fixedAttributes.fixedAssetsMethodId] ?? this.defaultDepreciationMethodId;
    },
    methodAttribute() {
      return this.getAttribute(fixedAttributes.fixedAssetsMethodId);
    },
    depreciationPeriodData() {
      return this.assetAttributeLinkData[
        fixedAttributes.fixedAssetsDepreciationPeriodId
      ];
    },
    depreciationPeriodAttribute() {
      return this.getAttribute(fixedAttributes.fixedAssetsDepreciationPeriodId);
    },
    scrapValueData() {
      return this.assetAttributeLinkData[
        fixedAttributes.fixedAssetsScrapValueId
      ];
    },
    scrapValueCurrencyData() {
      return (
        this.assetAttributeLinkData[
          fixedAttributes.fixedAssetsScrapValueId + "-secondValue"
        ] ?? this.defaultCurrencyCode
      );
    },
    scrapValueAttribute() {
      return this.getAttribute(fixedAttributes.fixedAssetsScrapValueId);
    },
    regionData() {
      return this.assetAttributeLinkData[fixedAttributes.fixedAssetsRegionId];
    },
    regionAttribute() {
      return this.getAttribute(fixedAttributes.fixedAssetsRegionId);
    },
    bookValueAttribute() {
      return this.getAttribute(fixedFieldAttributes.fixedAssetsBookValueId);
    },
    dateOfSaleData() {
      return this.assetAttributeLinkData[
        fixedAttributes.fixedAssetsDateOfSaleId
      ];
    },
    dateOfSaleAttribute() {
      return this.getAttribute(fixedAttributes.fixedAssetsDateOfSaleId);
    },
    salePriceData() {
      return this.assetAttributeLinkData[
        fixedAttributes.fixedAssetsSalePriceId
      ];
    },
    salePriceCurrencyData() {
      return (
        this.assetAttributeLinkData[
          fixedAttributes.fixedAssetsSalePriceId + "-secondValue"
        ] ?? this.defaultCurrencyCode
      );
    },
    salePriceAttribute() {
      return this.getAttribute(fixedAttributes.fixedAssetsSalePriceId);
    },
    gainLossAttribute() {
      return this.getAttribute(fixedFieldAttributes.fixedAssetsGainLossId);
    },
    startOfDepreciationAttribute() {
      return this.getAttribute(
        fixedAttributes.fixedAssetsStartOfDepreciationId
      );
    },
    startOfDepreciationData() {
      return this.assetAttributeLinkData[
        fixedAttributes.fixedAssetsStartOfDepreciationId
      ] ?? this.defaultStartOfDepreciationId;
    },
    yearlyDepreciationAttribute() {
      return this.attributes.find(
        (x) => x.id === fixedFieldAttributes.fixedAssetsYearlyDepreciationId
      );
    },
    depreciationStartMonthAttribute() {
      return this.attributes.find(
        (x) => x.id === fixedFieldAttributes.fixedAssetsDepreciationStartMonthId
      );
    },
    currentYearDepreciationAttribute() {
      return this.attributes.find(
        (x) =>
          x.id === fixedFieldAttributes.fixedAssetsCurrentYearDepreciationId
      );
    },
    accumulatedDepreciationAttribute() {
      return this.attributes.find(
        (x) =>
          x.id === fixedFieldAttributes.fixedAssetsAccumulatedDepreciationId
      );
    },
    monthlyDepreciationAttribute() {
      return this.attributes.find(
        (x) => x.id === fixedFieldAttributes.fixedAssetsMonthlyDepreciationId
      );
    },
    purchasePrice() {
      return this.assetAttributeLinkData[
        fixedAttributes.generalPurchasePriceId
      ];
    },
    purchasePriceCurrency() {
      const currency =
        this.assetAttributeLinkData[
          fixedAttributes.generalPurchasePriceId + "-secondValue"
        ];

      return currency ?? this.defaultCurrencyCode;
    },
    assetQuantity() {
      return this.assetAttributeLinkData[fixedAttributes.generalQuantityId];
    },
    formattedPurchasePrice() {
      if (!this.purchasePrice || !this.purchasePriceCurrency) return null;

      let quantity = this.assetQuantity > 0 ? this.assetQuantity : 1;
      let multipliedPurchasePrice = quantity * this.purchasePrice;

      return `${this.purchasePriceCurrency} ${fullNumberFormatter.format(
        multipliedPurchasePrice
      )}`;
    },
    purchaseDate() {
      const purchaseDate =
        this.assetAttributeLinkData[fixedAttributes.generalPurchaseDateId];

      if (!purchaseDate) return null;

      return removeTimezoneOffset(purchaseDate);
    },
    formattedPurchaseDate() {
      if (!this.purchaseDate) return null;

      return this.purchaseDate.toISOString().split("T")[0];
    },
    formattedDepreciationStartMonth() {
      if (!this.timeOfCommissioningData || !this.startOfDepreciationData)
        return this.availabilityCheck;

      let depreciationStartMonth = DateTime.fromJSDate(
        this.timeOfCommissioningData
      );

      if (this.startOfDepreciationData === depreciationStartModes.nextMonth)
        depreciationStartMonth = depreciationStartMonth.plus({ months: 1 });

      return depreciationStartMonth.toJSDate().toLocaleString("en-us", {
        month: "long",
        year: "numeric",
      });
    },
    formattedMonthlyDepreciationString() {
      if (this.date !== null) {
        var dateMonth = new Date(this.date);
        var dateString = dateMonth.toLocaleString("en-us", {
          month: "short",
          year: "numeric",
        });

        return dateString.toUpperCase();
      } else {
        return "";
      }
    },
    yearlyDepreciations() {
      let selectedDate = this.formatMonthPickerDateTime(this.date);

      return calculateDepreciations(
        this.timeOfCommissioningData,
        this.purchasePrice,
        this.purchasePriceCurrency,
        this.scrapValueData,
        this.scrapValueCurrencyData,
        this.depreciationPeriodData,
        this.startOfDepreciationData,
        false,
        selectedDate
      );
    },
    monthlyDepreciations() {
      let selectedDate = this.formatMonthPickerDateTime(this.date);

      return calculateDepreciations(
        this.timeOfCommissioningData,
        this.purchasePrice,
        this.purchasePriceCurrency,
        this.scrapValueData,
        this.scrapValueCurrencyData,
        this.depreciationPeriodData,
        this.startOfDepreciationData,
        true,
        selectedDate
      );
    },
    availabilityCheck() {
      if (!this.purchasePrice || !this.purchasePriceCurrency)
        return this.$t("pages.assets.form.validation.missingPurchasePrice");

      if (!this.timeOfCommissioningData)
        return this.$t("pages.assets.form.validation.missingPurchaseDate");

      if (!this.startOfDepreciationData)
        return this.$t(
          "pages.assets.form.validation.missingStartOfDepreciation"
        );

      if (
        (!this.scrapValueData && this.scrapValueData !== 0) ||
        !this.scrapValueCurrencyData
      )
        return this.$t("pages.assets.form.validation.missingScrapValue");

      if (!this.depreciationPeriodData)
        return this.$t(
          "pages.assets.form.validation.missingDepreciationPeriod"
        );

      if (this.purchasePriceCurrency !== this.scrapValueCurrencyData)
        return this.$t(
          "pages.assets.form.validation.depreciationCurrencyMismatch"
        );

      if (this.purchasePrice < this.scrapValueData)
        return this.$t("pages.assets.form.validation.notDepreciating");

      if (isEmpty(this.yearlyDepreciations))
        return this.$t("pages.assets.form.validation.depreciationUnknownError");

      return null;
    },
    formattedMonthlyDepreciation() {
      if (this.availabilityCheck !== null) {
        return this.availabilityCheck;
      }

      if (this.date === null)
        return this.$t("pages.assets.form.validation.missingDepreciationMonth");

      const startDate = DateTime.fromJSDate(this.timeOfCommissioningData);

      const from = startDate.startOf("month");
      const to = startDate
        .plus({ months: this.depreciationPeriodData })
        .startOf("month");

      const selectedDate = DateTime.fromISO(this.date).startOf("month");

      if (selectedDate < from || selectedDate > to) {
        return this.$t("pages.assets.form.validation.wrongDepreciationMonth", {
          currencyType: this.purchasePriceCurrency,
        });
      }

      const selectedMonth = `${selectedDate.year}|${selectedDate.month}`;

      var monthlyDepreciation =
        this.monthlyDepreciations[selectedMonth] ?? new Decimal(0);

      Decimal.set({ rounding: Decimal.ROUND_HALF_UP });

      const roundedNumber = monthlyDepreciation.round().toNumber();

      return `${this.purchasePriceCurrency} ${integerNumberFormatter.format(
        roundedNumber
      )}`;
    },
    formattedAccumulatedDepreciation() {
      if (this.availabilityCheck !== null) {
        return this.availabilityCheck;
      }

      var accumulatedDepreciation =
        this.yearlyDepreciations.accumulated ?? new Decimal(2);

      Decimal.set({ rounding: Decimal.ROUND_HALF_UP });

      const roundedNumber = accumulatedDepreciation.round().toNumber();

      return `${this.purchasePriceCurrency} ${integerNumberFormatter.format(
        roundedNumber
      )}`;
    },
    formattedYearlyDepreciation() {
      if (this.availabilityCheck !== null) {
        return this.availabilityCheck;
      }

      var yearlyDepreciation =
        this.yearlyDepreciations.yearly ?? new Decimal(2);

      return `${this.purchasePriceCurrency} ${integerNumberFormatter.format(
        yearlyDepreciation
      )}`;
    },
    formattedCurrentYearDepreciation() {
      if (this.availabilityCheck !== null) {
        return this.availabilityCheck;
      }

      const depreciation = new Decimal(
        this.yearlyDepreciations.currentYearDepreciation ?? new Decimal(0)
      );

      Decimal.set({ rounding: Decimal.ROUND_HALF_UP });

      const roundedNumber = depreciation.round().toNumber();

      return `${this.purchasePriceCurrency} ${integerNumberFormatter.format(
        roundedNumber
      )}`;
    },
    formattedCurrentBookValue() {
      let selectedDate = this.formatMonthPickerDateTime(this.date);

      return calculateBookValue(
        this.purchasePrice,
        this.purchasePriceCurrency,
        this.timeOfCommissioningData,
        this.monthlyDepreciations,
        this.startOfDepreciationData,
        selectedDate
      );
    },
    formattedGainLoss() {
      return calculateGainLoss(
        this.purchasePrice,
        this.purchasePriceCurrency,
        this.salePriceData,
        this.salePriceCurrencyData,
        this.dateOfSaleData,
        this.monthlyDepreciations,
        this.timeOfCommissioningData,
        this.startOfDepreciationData
      );
    },
  },
};
</script>
