<template>
  <v-row class="px-2">
    <v-col cols="12" md="6" lg="3" class="px-2 py-2 pt-4 d-flex">
      <amount-change-card
        :title="$t('components.cards.titles.numberOfAssets')"
        iconBackColor="#6493BE1f"
        :value="numberOfAssets"
        :hideBenchmark="true"
        :bars="getAssetCountBars"
      >
        <template v-slot:icon>
          <Package style="width: 20px; height: 20px"></Package>
        </template>
      </amount-change-card>
    </v-col>
    <v-col cols="12" md="6" lg="3" class="px-2 py-2 pt-4 d-flex">
      <amount-change-card
        :title="$t('components.cards.titles.accumulatedDepreciation')"
        iconBackColor="#F25D3B1f"
        :value="
          formatCurrency(assetCardData?.accumulatedDepreciationCard?.value)
        "
        :unit="assetCardData?.accumulatedDepreciationCard?.unit"
        :hideBenchmark="true"
      >
        <template v-slot:icon>
          <MoneyBag style="width: 20px; height: 20px"></MoneyBag>
        </template>
      </amount-change-card>
    </v-col>
    <v-col cols="12" md="6" lg="3" class="px-2 py-2 pt-4 d-flex">
      <amount-change-card
        :title="$t('components.cards.titles.bookValue')"
        iconBackColor="#FFF6EA"
        :value="formatCurrency(assetCardData?.bookValueCard?.value)"
        :unit="assetCardData?.bookValueCard?.unit"
        :hideBenchmark="true"
      >
        <template v-slot:icon>
          <Sun style="width: 20px; height: 20px"></Sun>
        </template>
      </amount-change-card>
    </v-col>
    <v-col cols="12" md="6" lg="3" class="px-2 py-2 pt-4 d-flex">
      <amount-change-card
        :title="$t('components.cards.titles.monthlyDepreciation')"
        iconBackColor="#0000ff1f"
        :value="formatCurrency(assetCardData?.monthlyDepreciationCard?.value)"
        :unit="assetCardData?.monthlyDepreciationCard?.unit"
        :hideBenchmark="true"
      >
        <template v-slot:icon>
          <Flash style="width: 20px; height: 20px"></Flash>
        </template>
      </amount-change-card>
    </v-col>
  </v-row>
</template>

<script>
import AmountChangeCard from "../Cards/AmountChangeCard.vue";

import Package from "../../../src/assets/svg/package.svg";
import MoneyBag from "../../../src/assets/svg/money-bag.svg";
import Sun from "../../../src/assets/svg/sun.svg";
import Flash from "../../../src/assets/svg/flash.svg";

import { integerNumberFormatter } from "../../util/formatters";

import Formatters from "../../mixins/Formatters.vue";

export default {
  components: { AmountChangeCard, Package, MoneyBag, Sun, Flash },
  mixins: [Formatters],
  props: {
    assetCardData: Object,
  },
  methods: {
    barChartCalculateScale(value) {
      // Set height to 0 if value is 0
      if (value === 0 || value === null) {
        return 0;
      }

      // If value is really small but still above 0 return 0.1
      //just to make sure there's something there for the cursor to hover.
      else if (value <= 0.1 && value !== 0) {
        return 0.1;
      }

      // Else return value
      return value;
    },
  },
  computed: {
    numberOfAssets() {
      let number = this.assetCardData?.numberOfAssetsCard?.value;

      if (!number) return "0";

      return integerNumberFormatter.format(number);
    },
    getAssetCountBars() {
      const approvedCount = this.assetCardData?.numberOfAssetsApproved?.value;
      const definedCount = this.assetCardData?.numberOfAssetsDefined?.value;
      const undefinedCount = this.assetCardData?.numberOfAssetsUndefined?.value;

      const maxCount = Math.max(
        approvedCount,
        definedCount,
        undefinedCount,
        1 // Avoid dividing by zero
      );

      return [
        {
          label: this.$t("components.cards.titles.defined"),
          renderedValue: definedCount,
          fractionValue: this.barChartCalculateScale(definedCount / maxCount),
          color: "#898989",
          rawValue: definedCount,
        },
        {
          label: this.$t("components.cards.titles.undefined"),
          renderedValue: undefinedCount,
          fractionValue: this.barChartCalculateScale(undefinedCount / maxCount),
          color: "#F25D3B",
          rawValue: undefinedCount,
        },
        {
          label: this.$t("components.cards.titles.verified"),
          renderedValue: approvedCount,
          fractionValue: this.barChartCalculateScale(approvedCount / maxCount),
          color: "#266663",
          rawValue: approvedCount,
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped></style>
