var render = function render(){var _vm=this,_c=_vm._self._c;return _c('dialog-form',{attrs:{"show-dialog":_vm.show,"loading":_vm.loading,"max-width":"500px","persistent":""},on:{"showStateChanged":function($event){_vm.show = $event}}},[_c('dialog-form-header',{attrs:{"title":_vm.$t('pages.settings.customEmissionFactorsForm.customEmissionFactor')}}),_c('dialog-form-section-one-col',{staticClass:"pb-3",staticStyle:{"height":"calc(81vh - 175px)","overflow-y":"scroll"}},[_c('dialog-form-text-input',{attrs:{"title":_vm.$t(
          'pages.settings.customEmissionFactorsForm.fields.emissionFactorName'
        ),"id":"emission-factor-name","value":_vm.form.name,"error-messages":_vm.formErrors['name'],"hide-details":!!!_vm.formErrors['name']},on:{"input":(val) => {
          _vm.form.name = val;
          _vm.form.activityId = _vm.slugify(val); // Generate activityId
        }}}),_c('dialog-form-select-input',{attrs:{"title":_vm.$t(
          'pages.settings.customEmissionFactorsForm.fields.calculationMethod'
        ),"id":"calculationMethod","items":_vm.calculationMethods,"value":_vm.selectedCalculationMethod,"error-messages":_vm.formErrors['calculationMethod'],"hide-details":!!!_vm.formErrors['calculationMethod']},on:{"input":(val) => {
          _vm.selectedCalculationMethod = val;
          // Clear depending fields
          _vm.form.activityUnit = '';
          _vm.form.sectorId = null;
          _vm.emissionSectors = null;
          _vm.form.categoryId = null;
          _vm.emissionCategories = null;
          _vm.form.regionId = null;
        }}}),_c('dialog-form-select-input',{attrs:{"title":_vm.$t('pages.settings.customEmissionFactorsForm.fields.activityUnit'),"id":"activity-unit","items":_vm.activityUnits,"value":_vm.form.activityUnit,"error-messages":_vm.formErrors['activityUnit'],"hide-details":!!!_vm.formErrors['activityUnit']},on:{"change":(val) => {
          _vm.form.sectorId = null;
          _vm.emissionSectors = null;
          _vm.form.categoryId = null;
          _vm.emissionCategories = null;
          _vm.form.regionId = null;

          _vm.form.activityUnit = val;
          _vm.getEmissionSectors();
        }}}),_c('dialog-form-select-input',{attrs:{"title":_vm.$t('pages.settings.customEmissionFactorsForm.fields.sector'),"id":"sector","items":_vm.emissionSectors,"itemText":"name","itemValue":"id","value":_vm.form.sectorId,"error-messages":_vm.formErrors['sector'],"hide-details":!!!_vm.formErrors['sector']},on:{"input":(val) => {
          _vm.form.sectorId = val;
          _vm.form.categoryId = null;
        }}}),_c('dialog-form-select-input',{attrs:{"title":_vm.$t('pages.settings.customEmissionFactorsForm.fields.category'),"id":"category","items":_vm.emissionCategories,"itemText":"name","itemValue":"id","value":_vm.form.categoryId,"error-messages":_vm.formErrors['category'],"hide-details":!!!_vm.formErrors['category']},on:{"input":(val) => {
          _vm.form.categoryId = val;
        }}}),_c('dialog-form-select-input',{attrs:{"title":_vm.$t('pages.settings.customEmissionFactorsForm.fields.region'),"id":"region","items":_vm.emissionRegions,"itemText":"countryName","itemValue":"id","value":_vm.form.regionId,"error-messages":_vm.formErrors['region'],"hide-details":!!!_vm.formErrors['region']},on:{"input":(val) => (_vm.form.regionId = val)}}),_c('dialog-form-text-input',{ref:"emission-amount",attrs:{"title":_vm.$t('pages.settings.customEmissionFactorsForm.fields.emissionFactor'),"id":"emission-amount","value":_vm.emissionFormattedForInput(_vm.emissionAmount),"error-messages":_vm.formErrors['emissionAmount'],"hide-details":!!!_vm.formErrors['emissionAmount']},on:{"input":(val) => (_vm.emissionAmount = val),"change":(val) => (_vm.form.kgCo2eAr6 = _vm.emissionFormattedForSystem(val))},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.$t( "pages.settings.customEmissionFactorsForm.fields.kgCo2PerFormActivityUnit" ))+" / "+_vm._s(_vm.form.activityUnit))])]},proxy:true}])}),_c('dialog-form-text-area-input',{attrs:{"title":_vm.$t('pages.settings.customEmissionFactorsForm.fields.info'),"value":_vm.form.description},on:{"input":(val) => (_vm.form.description = val)}}),_c('dialog-form-input-wrapper',{attrs:{"title":_vm.$t('pages.settings.customEmissionFactorsForm.fields.documentation')}},[(_vm.form.documentationFileId !== null)?_c('div',{staticClass:"documentation-item d-flex flex-row align-center pl-4 mb-2"},[_c('PdfIcon'),_c('v-btn',{staticClass:"documentation-btn flex-grow-1 mb-0 ml-0 px-2 justify-start text-none font-weight-regular",attrs:{"href":_vm.route('api.files.content', {
              id: _vm.uploadedFile.id,
              filename: _vm.uploadedFile.originalName,
            }),"target":"_blank","left":"","plain":""}},[_vm._v(_vm._s(_vm.renderFileUrlLabel))]),_c('v-btn',{staticClass:"mr-1",attrs:{"icon":"","plain":""},on:{"click":function($event){return _vm.handleDocumentationRemoved()}}},[_c('v-icon',{attrs:{"size":"20","color":"#686868"}},[_vm._v("mdi-close")])],1)],1):(_vm.form.documentationFile !== null)?_c('div',{staticClass:"documentation-item d-flex flex-row align-center pl-4 mb-2",staticStyle:{"background":"#fef2ef","border":"1px solid #fef2ef"}},[_c('PdfIcon'),_c('p',{staticClass:"flex-grow-1 mb-0 ml-2"},[_vm._v(_vm._s(_vm.renderFileUrlLabel))]),_c('v-btn',{staticClass:"mr-1",attrs:{"icon":"","plain":""},on:{"click":function($event){return _vm.handleNotUploadedDocumentationRemoved()}}},[_c('v-icon',{attrs:{"size":"20","color":"#686868"}},[_vm._v("mdi-close")])],1)],1):_c('dialog-form-file-upload-input',{attrs:{"small":"","allowedImportMimeTypes":[
          'application/pdf',
          'image/png',
          'image/jpeg',
        ],"allowedFileExpensions":".pdf,.png,.jpg,.jpeg","multiple":false},on:{"onFileUploadChanged":($event) => {
            _vm.handleDocumentationChanged($event.target.files[0]);
          },"fileChanged":($event) => {
            _vm.handleDocumentationChanged($event);
          }}})],1)],1),_c('v-card-actions',{staticClass:"py-6 px-6 d-flex flex-column-reverse flex-md-row form-bottom-section"},[(_vm.form.id)?_c('v-btn',{staticClass:"text-none px-4 mb-4 mb-md-0 align-self-stretch",attrs:{"color":"#686868","elevation":"0","text":"","tile":"","x-large":""},on:{"click":function($event){return _vm.destroyEmissionFactor(_vm.form.id)}}},[_c('v-icon',{staticClass:"mr-3",attrs:{"color":"#686868"}},[_vm._v("mdi-delete-outline")]),_vm._v(" "+_vm._s(_vm.$t("common.actions.delete"))+" ")],1):_vm._e(),_c('v-spacer'),_c('v-btn',{staticClass:"text-none px-4 mb-4 mb-md-0 align-self-stretch",attrs:{"color":"#686868","elevation":"0","text":"","tile":"","x-large":""},on:{"click":function($event){_vm.show = false}}},[_c('v-icon',{staticClass:"mr-3",attrs:{"color":"#686868"}},[_vm._v("mdi-close")]),_vm._v(" "+_vm._s(_vm.$t("common.actions.cancel"))+" ")],1),_c('v-btn',{staticClass:"text-none ml-0 mb-4 mb-md-0 ml-md-4 align-self-stretch white--text",attrs:{"color":"#F25D3B","elevation":"0","tile":"","x-large":"","disabled":_vm.loading},on:{"click":function($event){return _vm.submit()}}},[_vm._v(" "+_vm._s(_vm.$t("common.actions.save"))+" ")])],1),_c('confirm-dialog',{directives:[{name:"show",rawName:"v-show",value:(_vm.showConfirmDialog),expression:"showConfirmDialog"}],attrs:{"confirm-message":_vm.confirmMessage},on:{"confirmed":_vm.handleConfirmation},model:{value:(_vm.showConfirmDialog),callback:function ($$v) {_vm.showConfirmDialog=$$v},expression:"showConfirmDialog"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }