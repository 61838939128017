<template>
  <div class="fill-width d-flex align-center">
    <div
      v-if="alignment === 'center' || alignment === 'right'"
      class="line"
    ></div>
    <div :class="contentClass">
      <template v-if="text"
        ><div class="d-flex flex-row">
          {{ text
          }}<v-btn
            v-if="showOperatorDelete"
            @click="$emit('delete-rule')"
            icon
            class="pa-0 mx-0"
            width="24px"
            height="24px"
            ><v-icon size="15px">mdi-close</v-icon></v-btn
          >
        </div></template
      >
      <slot v-else></slot>
    </div>
    <div
      v-if="alignment === 'left' || alignment === 'center'"
      class="line"
    ></div>
  </div>
</template>
<script>
export default {
  props: {
    left: Boolean,
    right: Boolean,
    text: String,
    showOperatorDelete: Boolean,
  },
  computed: {
    alignment() {
      if (this.left) {
        return "left";
      } else if (this.right) {
        return "right";
      } else {
        return "center";
      }
    },
    contentClass() {
      switch (this.alignment) {
        case "left":
          return "mr-4";
        case "right":
          return "ml-4";
        case "center":
          return "mx-4";
        default:
          return "";
      }
    },
  },
};
</script>
<style scoped>
.line {
  height: 2px;
  width: 100%;
  background-color: #f7f7f7;
}
</style>
