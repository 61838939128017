<template>
  <div>
    <v-dialog v-model="show" max-width="484px" persistent>
      <v-card>
        <dialog-form-header
          :title="$t('pages.settings.index.integrations.reset.title')"
          :onDismiss="() => (show = false)"
        ></dialog-form-header>
        <p class="px-6 py-3 font-weight-medium grey--text">
          {{ $t("pages.settings.index.integrations.reset.subTitle") }}
        </p>
        <div class="d-flex flex-row pb-10">
          <date-range-picker
            class="date-range-picker"
            :fromDate="resetDateRange.from"
            :toDate="resetDateRange.to"
            @change="(date) => setDateRange(date)"
          >
            <dialog-form-date-selector
              :value="new Date(resetDateRange.from)"
              class="pl-2 pr-3"
              :title="$t('pages.settings.index.integrations.reset.resetFrom')"
              readonly
            ></dialog-form-date-selector>
            <dialog-form-date-selector
              :value="new Date(resetDateRange.to)"
              class="pr-2 pl-3"
              :title="$t('pages.settings.index.integrations.reset.resetTo')"
              readonly
            ></dialog-form-date-selector>
          </date-range-picker>
        </div>
        <div class="pb-10 px-6">
          <dialog-form-select-input
            v-model="selectedResetOption"
            :title="$t('common.actions.reset')"
            :items="resetOptions"
            itemText=""
            item-value=""
          ></dialog-form-select-input>
          <div v-if="isFullReset" class="mt-n8" style="font-size: 12px">
            {{ $t("pages.settings.index.integrations.reset.resetAllLabel") }}
          </div>
          <div v-else class="mt-n8" style="font-size: 12px">
            {{
              $t("pages.settings.index.integrations.reset.resetUnverifiedLabel")
            }}
          </div>
        </div>
        <dialog-form-footer>
          <template #right>
            <v-spacer></v-spacer>
            <v-btn
              class="text-none px-4 mb-4 mb-md-0 align-self-stretch"
              color="#686868"
              large
              elevation="0"
              text
              tile
              @click="show = false"
              x-large
              ><!--<v-icon class="mr-3" dark>mdi-arrow-left</v-icon>-->{{
                $t("common.actions.cancel")
              }}
            </v-btn>
            <v-btn
              class="text-none white--text ml-0 mb-4 mb-md-0 ml-md-4 px-6 align-self-stretch"
              large
              color="#F25D3B"
              elevation="0"
              tile
              x-large
              @click="resetIntegration()"
              >{{ $t("common.actions.reset") }}</v-btn
            >
          </template>
        </dialog-form-footer>
      </v-card>
    </v-dialog>
    <confirm-dialog
      v-model="showResetConfirmDialog"
      v-show="showResetConfirmDialog"
      :confirm-message="confirmResetMessage"
      @confirmed="handleResetConfirmation"
    ></confirm-dialog>
  </div>
</template>

<script>
import { DateTime } from "luxon";
import GlobalDateRange from "../../mixins/GlobalDateRange.vue";
import ConfirmDialog from "../../Components/ConfirmDialog.vue";
import DialogFormHeader from "../../Components/Dialog/DialogFormHeader.vue";
import DialogFormDateSelector from "../../Components/Dialog/inputs/DialogFormDateSelector.vue";
import DialogFormFooter from "../../Components/Dialog/DialogFormFooter.vue";
import DateRangePicker from "../../Components/Inputs/DateRangePicker.vue";
import DialogFormSelectInput from "../../Components/Dialog/inputs/DialogFormSelectInput.vue";

export default {
  mixins: [GlobalDateRange],
  components: {
    ConfirmDialog,
    DialogFormHeader,
    DialogFormDateSelector,
    DialogFormFooter,
    DateRangePicker,
    DialogFormSelectInput,
  },
  props: {
    value: Boolean,
    integration: Object,
  },
  data() {
    return {
      resetDateRange: {
        from: null,
        to: null,
      },
      isFullReset: true,
      resetOptions: [
        this.$t("pages.settings.index.integrations.reset.resetAll"),
        this.$t("pages.settings.index.integrations.reset.resetUnverified"),
      ],
      selectedResetType: this.$t(
        "pages.settings.index.integrations.reset.resetAll"
      ),
      showResetConfirmDialog: false,
      confirmResetMessage: this.$t(
        "pages.settings.index.integrations.confirmReset"
      ),
      resetId: String,
      form: this.$inertia.form(this.generateInitialForm()),
    };
  },
  methods: {
    resetIntegration() {
      this.resetId = this.integration.id;
      this.showResetConfirmDialog = true;
    },
    handleResetConfirmation(status) {
      this.isConfirmed = status;
      if (status) {
        this.confirmResetIntegration();
      }
    },
    confirmResetIntegration() {
      this.show = false;

      this.form.integrationId = this.integration.id;
      this.form.fromDate = this.resetDateRange.from;
      this.form.toDate = this.resetDateRange.to;
      this.form.isFullReset = this.isFullReset;

      this.form.post(
        this.route("settings.integrations.reset"),
        {},
        {
          onSuccess: () => {
            setTimeout(
              () =>
                this.$emit(
                  "reloadPageAfterReset",
                  this.integration.id,
                  0,
                  5,
                  15000
                ),
              15000
            );
          },
        }
      );
    },
    setDateRange(date) {
      this.resetDateRange = { ...date };
    },
    generateInitialForm() {
      return {
        integrationId: null,
        fromDate: null,
        toDate: null,
        isFullReset: true,
      };
    },
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    selectedResetOption: {
      get() {
        return this.selectedResetType;
      },
      set(value) {
        this.selectedResetType = value;

        if (value === this.resetOptions[0]) {
          this.isFullReset = true;
        } else {
          this.isFullReset = false;
        }
      },
    },
  },
  mounted() {
    const today = DateTime.utc().startOf("day");
    const startOfYear = today.startOf("year").toJSDate();
    const endOfYear = today.endOf("Year").startOf("day").toJSDate();
    const fromDate = new Date(startOfYear).toISOString().split("T")[0];
    const toDate = new Date(endOfYear).toISOString().split("T")[0];
    this.resetDateRange = {
      from: fromDate,
      to: toDate,
    };
  },
};
</script>

<style></style>
