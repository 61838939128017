import { render, staticRenderFns } from "./PaymentDialog.vue?vue&type=template&id=37ba45c8&scoped=true"
import script from "./PaymentDialog.vue?vue&type=script&lang=js"
export * from "./PaymentDialog.vue?vue&type=script&lang=js"
import style0 from "./PaymentDialog.vue?vue&type=style&index=0&id=37ba45c8&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37ba45c8",
  null
  
)

export default component.exports