<template>
  <div class="px-8">
    <div class="">
      <dialog-form-text-input
        title="Company reg. (CVR) no *"
        @input="handleCVRChanged"
        :value="companyRegNoProxy"
      ></dialog-form-text-input>
      <dialog-form-text-input
        title="Company *"
        v-model="companyNameProxy"
        :loading="companyNameLoading"
      ></dialog-form-text-input>
      <dialog-form-text-input
        title="Email *"
        @input="(val) => (companyEmailProxy = validateEmail(val))"
        :value="companyEmailProxy"
      ></dialog-form-text-input>
    </div>
  </div>
</template>
<script>
import DialogFormTextInput from "../../Dialog/inputs/DialogFormTextInput.vue";
export default {
  components: {
    DialogFormTextInput,
  },
  props: {
    companyRegNo: String,
    companyName: String,
    companyEmail: String,
  },
  data() {
    return {
      companyNameLoading: false,
      latestCvrLookupValue: null,
    };
  },
  computed: {
    companyRegNoProxy: {
      get() {
        return this.companyRegNo;
      },
      set(value) {
        this.$emit("updateForm", "companyRegNo", value);
        this.$emit("update:companyRegNo", value);
      },
    },
    companyNameProxy: {
      get() {
        return this.companyName;
      },
      set(value) {
        this.$emit("updateForm", "companyName", value);
        this.$emit("update:companyName", value);
      },
    },
    companyEmailProxy: {
      get() {
        return this.companyEmail;
      },
      set(value) {
        this.$emit("updateForm", "companyEmail", value);
        this.$emit("update:companyEmail", value);
      },
    },
  },
  methods: {
    validateEmail(email) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email) ? email : this.companyEmailProxy;
    },
    validateCVR(cvr) {
      const cvrRegex = /^\d{8}$/;

      if (!cvrRegex.test(cvr)) return false;

      // All CVR numbers can be verified with a modulo-11 test: https://da.wikipedia.org/wiki/Det_Centrale_Virksomhedsregister
      const moduloWeights = [2, 7, 6, 5, 4, 3, 2, 1];
      const cvrDigits = cvr.split("").map(Number);

      let cvrSum = 0;

      for (let i = 0; i < 8; i++) {
        const weight = moduloWeights[i];
        const digit = cvrDigits[i];

        cvrSum += weight * digit;
      }

      return cvrSum % 11 === 0;
    },
    handleCVRChanged(cvr) {
      if (!this.validateCVR(cvr)) return;

      this.companyRegNoProxy = cvr;

      if (this.latestCvrLookupValue === cvr) return;

      this.companyNameLoading = true;
      this.latestCvrLookupValue = cvr;

      fetch(this.route("api.cvr.company.name", { cvrNumber: cvr }))
        .then((res) => res.json())
        .then((data) => {
          if (!data?.name) return;

          this.companyNameProxy = data.name;
        })
        .finally(() => (this.companyNameLoading = false));
    },
  },
};
</script>
