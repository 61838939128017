<template>
  <v-tab-item :value="tabValue">
    <v-card flat>
      <dialog-form-section-two-cols no-bottom-border>
        <template slot="left">
          <div>
            <asset-attribute-link-input
              :attribute="assessmentPrice1Attribute"
              :value="assessmentPrice1Data"
              :currencies="currencies"
              @input="updateData(fixedAttributes.assessmentPrice1Id, $event)"
              :secondValue="assessmentPrice1CurrencyData"
              @input_secondValue="
                updateSecondValueData(
                  fixedAttributes.assessmentPrice1Id,
                  arguments[0]
                )
              "
              @currency_changed="
                updateCurrencyValue(
                  assessmentPrice1Attribute,
                  $event,
                  assessmentPrice1CurrencyData
                )
              "
              @currencyCode_changed="
                updateCurrencyCodeValue(
                  assessmentPrice1Attribute,
                  assessmentPrice1Data,
                  $event
                )
              "
            />
            <asset-attribute-link-input
              :attribute="assessmentPrice2Attribute"
              :value="assessmentPrice2Data"
              :currencies="currencies"
              @input="updateData(fixedAttributes.assessmentPrice2Id, $event)"
              :secondValue="assessmentPrice2CurrencyData"
              @input_secondValue="
                updateSecondValueData(
                  fixedAttributes.assessmentPrice2Id,
                  arguments[0]
                )
              "
              @currency_changed="
                updateCurrencyValue(
                  assessmentPrice2Attribute,
                  $event,
                  assessmentPrice2CurrencyData
                )
              "
              @currencyCode_changed="
                updateCurrencyCodeValue(
                  assessmentPrice2Attribute,
                  assessmentPrice2Data,
                  $event
                )
              "
            />
          </div>
        </template>
        <template slot="right">
          <div>
            <asset-attribute-link-input
              :attribute="assessmentPrice3Attribute"
              :value="assessmentPrice3Data"
              :currencies="currencies"
              @input="updateData(fixedAttributes.assessmentPrice3Id, $event)"
              :secondValue="assessmentPrice3CurrencyData"
              @input_secondValue="
                updateSecondValueData(
                  fixedAttributes.assessmentPrice3Id,
                  arguments[0]
                )
              "
              @currency_changed="
                updateCurrencyValue(
                  assessmentPrice3Attribute,
                  $event,
                  assessmentPrice3CurrencyData
                )
              "
              @currencyCode_changed="
                updateCurrencyCodeValue(
                  assessmentPrice3Attribute,
                  assessmentPrice3Data,
                  $event
                )
              "
            />
            <asset-attribute-link-input
              :attribute="assessmentPrice4Attribute"
              :value="assessmentPrice4Data"
              :currencies="currencies"
              @input="updateData(fixedAttributes.assessmentPrice4Id, $event)"
              :secondValue="assessmentPrice4CurrencyData"
              @input_secondValue="
                updateSecondValueData(
                  fixedAttributes.assessmentPrice4Id,
                  arguments[0]
                )
              "
              @currency_changed="
                updateCurrencyValue(
                  assessmentPrice4Attribute,
                  $event,
                  assessmentPrice4CurrencyData
                )
              "
              @currencyCode_changed="
                updateCurrencyCodeValue(
                  assessmentPrice4Attribute,
                  assessmentPrice4Data,
                  $event
                )
              "
            />
          </div>
        </template>
      </dialog-form-section-two-cols>
    </v-card>
  </v-tab-item>
</template>
<script>
import AssetAttributeLinkInput from "../../../Components/Attribute/AttributeLinkInput.vue";
import { fixedAttributes } from "../../../util/fixedAssetData";
import { isEmpty } from "lodash";
import DialogFormSectionTwoCols from "../../../Components/Dialog/DialogFormSectionTwoCols.vue";

export default {
  components: { AssetAttributeLinkInput, DialogFormSectionTwoCols },
  props: {
    assetAttributeLinkData: Object,
    attributes: Array,
    currencies: Array,
    tabValue: [String, Number],
  },
  data() {
    return {
      fixedAttributes,
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substring(0, 10),
      menu: false,
    };
  },
  methods: {
    updateData(attributeId, value) {
      this.$emit("asset_attribute_data_changed", attributeId, value);
    },
    updateSecondValueData(attributeId, secondValue) {
      this.$emit(
        "asset_attribute_data_second_value_changed",
        attributeId,
        secondValue
      );
    },
    updateCurrencyValue(attribute, price, currency) {
      this.$emit("currency_changed", price, currency, attribute);
    },
    updateCurrencyCodeValue(attribute, price, currency) {
      this.$emit("currencyCode_changed", price, currency, attribute);
    },
    isEmpty(value) {
      return isEmpty(value);
    },
  },
  computed: {
    assessmentPrice1Data() {
      return this.assetAttributeLinkData[fixedAttributes.assessmentPrice1Id];
    },
    assessmentPrice1CurrencyData() {
      return this.assetAttributeLinkData[
        fixedAttributes.assessmentPrice1Id + "-secondValue"
      ];
    },
    assessmentPrice1Attribute() {
      return this.attributes.find(
        (x) => x.id === fixedAttributes.assessmentPrice1Id
      );
    },
    assessmentPrice2Data() {
      return this.assetAttributeLinkData[fixedAttributes.assessmentPrice2Id];
    },
    assessmentPrice2CurrencyData() {
      return this.assetAttributeLinkData[
        fixedAttributes.assessmentPrice2Id + "-secondValue"
      ];
    },
    assessmentPrice2Attribute() {
      return this.attributes.find(
        (x) => x.id === fixedAttributes.assessmentPrice2Id
      );
    },
    assessmentPrice3Data() {
      return this.assetAttributeLinkData[fixedAttributes.assessmentPrice3Id];
    },
    assessmentPrice3CurrencyData() {
      return this.assetAttributeLinkData[
        fixedAttributes.assessmentPrice3Id + "-secondValue"
      ];
    },
    assessmentPrice3Attribute() {
      return this.attributes.find(
        (x) => x.id === fixedAttributes.assessmentPrice3Id
      );
    },
    assessmentPrice4Data() {
      return this.assetAttributeLinkData[fixedAttributes.assessmentPrice4Id];
    },
    assessmentPrice4CurrencyData() {
      return this.assetAttributeLinkData[
        fixedAttributes.assessmentPrice4Id + "-secondValue"
      ];
    },
    assessmentPrice4Attribute() {
      return this.attributes.find(
        (x) => x.id === fixedAttributes.assessmentPrice4Id
      );
    },
  },
};
</script>
