<template>
  <dialog-form-input-wrapper :title="title" :ai-status="aiStatus">
    <v-select
      class="rounded-0 mb-1"
      style="cursor: pointer"
      background-color="#F7F7F7"
      solo
      flat
      label="Select region"
      single-line
      filled
      light
      :menu-props="{ top: false, offsetY: true }"
      color="red"
      item-color="red"
      :items="regions"
      :hide-details="true"
      error-messages=""
      item-text="value"
      item-value="key"
      v-model="value"
      @change="changedVal"
    >
      <template #item="{ item }">
        <component :is="item.icon" class="mr-3"></component>{{ item.value }}
      </template>
      <template #selection="{ item }">
        <component :is="item.icon" class="mr-3"></component>{{ item.value }}
      </template>
    </v-select>
  </dialog-form-input-wrapper>
</template>

<script>
import DialogFormInputWrapper from "./DialogFormInputWrapper.vue";
import DaDkIcon from "../../../assets/svg/select-flag_da-dk.svg";
export default {
  components: { DialogFormInputWrapper, DaDkIcon },
  props: {
    title: String,
    placeholder: String,
    aiStatus: String,
    countries: { type: Array, default: () => [] },
    initialValue: String,
  },
  data() {
    return {
      regions: this.countries.map((country) => {
        return { value: country, key: country.toLowerCase() };
      }),
      value: this.initialValue,
    };
  },
  watch: {
    regions() {
      return this.countries.map((country) => ({
        value: country,
        key: country.toLowerCase(),
      }));
    },
  },
  emits: ["update", "input"],
  methods: {
    changedVal(value) {
      this.$emit("update", value);
      this.$emit("change", value);
    },
    inputVal(value) {
      this.$emit("input", value);
    },
  },
};
</script>

<style lang="scss" scoped></style>
