<template>
  <dialog-form :show-dialog="show" max-width="450px" :persistent="true">
    <div class="d-flex flex-column px-6 py-4">
      <div class="d-flex justify-space-between">
        <h1 class="text-h4 black--text mb-2">
          {{ $t("components.onboarding.warning.header") }}
        </h1>
        <remove
          @click="close"
          class="pt-1"
          style="cursor: pointer; width: 30px"
        />
      </div>
      <div class="mx-n6 pt-2 border-line" />
      <div class="d-flex flex-column align-center py-10">
        <div class="icon-container">
          <warning />
        </div>
        <h1 class="text-h6 black--text mb-2 text-center pt-6">
          {{ $t("components.onboarding.warning.aboutToLeave") }}
        </h1>
        <h1
          class="text-body-2 grey--text mb-6 font-weight-light text-center px-4"
        >
          {{ $t("components.onboarding.warning.subText") }}
        </h1>
      </div>
      <div class="mx-n6 pt-2 border-line" />
      <div class="pt-4 d-flex justify-end">
        <v-btn
          tile
          outlined
          elevation="0"
          bg-color="white"
          class="border-sm text-capitalize text-body-2 grey--text grey--border white mr-2"
          @click="close"
        >
          {{ $t("components.onboarding.warning.leave") }}
        </v-btn>
        <v-btn
          tile
          elevation="0"
          color="orange"
          class="font-weight-regular text-capitalize text-body-2 white--text"
          @click="continueOnboarding"
        >
          {{ $t("components.onboarding.warning.continue") }}
        </v-btn>
      </div>
    </div>
  </dialog-form>
</template>
<script>
import DialogForm from "../Dialog/DialogForm.vue";
import Warning from "../../assets/svg/warning.svg";
import Remove from "../../assets/svg/remove.svg";

export default {
  components: {
    DialogForm,
    Warning,
    Remove,
  },
  data() {
    return {};
  },
  props: {
    value: Boolean,
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    async close() {
      fetch(this.route("api.settings.skip.onboarding", {}), {
        method: "POST",
      }).then((res) => {
        if (!res.ok) return Promise.reject();
      });

      this.$emit("close-all");
      this.show = false;
    },
    continueOnboarding() {
      this.$emit("continue-onboarding");
    },
  },
};
</script>
<style lang="scss" scoped>
.icon-container {
  background-color: #fdece7;
  border-radius: 50%;
  width: 62px;
  height: 62px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
}

.border-line {
  border-bottom: solid 2px #f7f7f7;
}
</style>
