<template>
  <dialog-form :show-dialog="show" max-width="450px">
    <div class="d-flex flex-column px-6 py-4">
      <div class="d-flex justify-space-between">
        <h1 class="text-h4 black--text mb-2">
          {{ $t("components.onboarding.apiFields.header") }}
        </h1>
        <remove @click="close" class="pt-1" style="cursor: pointer" />
      </div>
      <div class="mx-n6 pt-2 border-line" />
      <div class="py-3">
        <p class="text-caption grey--text mb-6 mt-4 font-weight-light">
          {{ $t("components.onboarding.apiFields.enterInfo") }}
        </p>
        <input
          autofocus
          type="APIUser"
          v-model="apiUser"
          :placeholder="$t('components.onboarding.apiFields.apiUser')"
          name="apiUser"
          class="lightGrey black--text pl-4 pt-2 pb-2 mb-4 text-caption input"
          style="width: 100%"
        />
        <input
          autofocus
          type="AUTHID"
          v-model="authId"
          :placeholder="$t('components.onboarding.apiFields.authId')"
          name="authId"
          class="lightGrey black--text pl-4 pt-2 pb-2 mb-4 text-caption input"
          style="width: 100%"
        />
        <input
          autofocus
          type="APIKey"
          v-model="apiKey"
          :placeholder="$t('components.onboarding.apiFields.apiKey')"
          name="apiKey"
          class="lightGrey black--text pl-4 pt-2 pb-2 mb-4 text-caption input"
          style="width: 100%"
        />
      </div>
      <div class="mx-n6 pt-2 border-line" />
      <div class="pt-4 d-flex justify-end">
        <v-btn
          tile
          elevation="0"
          color="orange"
          class="font-weight-regular text-capitalize mb-2 white--text"
          type="submit"
          :disabled="!areFiledsFilledIn"
          @click="submit()"
        >
          {{ $t("components.onboarding.apiFields.saveConnect") }}
        </v-btn>
      </div>
    </div>
  </dialog-form>
</template>
<script>
import DialogForm from "../Dialog/DialogForm.vue";
import Remove from "../../assets/svg/remove.svg";

export default {
  components: {
    DialogForm,
    Remove,
  },
  data() {
    return {
      apiUser: null,
      authId: null,
      apiKey: null,
    };
  },
  props: {
    value: Boolean,
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    areFiledsFilledIn() {
      if (
        this.apiUser === null ||
        this.authId === null ||
        this.apiKey === null
      ) {
        return false;
      }
      return true;
    },
  },
  methods: {
    async close() {
      this.$emit("continue-onboarding");
    },
    submit() {
      this.$emit("submit-api-fields", {
        apiUser: this.apiUser,
        authId: this.authId,
        apiKey: this.apiKey,
      });
      this.$emit("continue-onboarding");
    },
  },
};
</script>
<style lang="scss" scoped>
.border-line {
  border-bottom: solid 2px #f7f7f7;
}
input:focus {
  outline: none !important;
  box-shadow: 0 0 3px black;
}
</style>
