<template>
  <dialog-form :show-dialog="show" max-width="800px">
    <div class="d-flex flex-column px-6 py-8">
      <div class="pb-8 d-flex justify-center">
        <LogoBlack />
      </div>

      <dialog-form-text-input
        v-model="form.name"
        :title="$t('pages.payment.form.name')"
        :placeholder="$t('pages.payment.form.name')"
        :error-messages="form.errors.name"
      ></dialog-form-text-input>

      <dialog-form-text-input
        v-model="form.email"
        :title="$t('pages.payment.form.email')"
        :placeholder="$t('pages.payment.form.email')"
        :error-messages="form.errors.email"
      ></dialog-form-text-input>

      <div class="pt-4 d-flex justify-end">
        <v-btn
          tile
          outlined
          elevation="0"
          bg-color="white"
          class="border-sm text-capitalize black--text black--border white"
          @click="closeModal"
          >{{ $t("pages.payment.buttons.cancel") }}</v-btn
        >
        <v-btn
          tile
          elevation="0"
          color="orange"
          class="font-weight-regular text-capitalize mb-4 mx-4 white--text"
          type="submit"
          @click="submit"
        >
          {{ $t("common.actions.submit") }}
        </v-btn>
      </div>
    </div>
  </dialog-form>
</template>

<script>
import DialogForm from "../../Components/Dialog/DialogForm.vue";
import DialogFormTextInput from "../../Components/Dialog/inputs/DialogFormTextInput.vue";
import LogoBlack from "../../assets/svg/verarca-logo-black.svg";

export default {
  components: {
    DialogForm,
    DialogFormTextInput,
    LogoBlack,
  },
  props: {
    value: Boolean,
    paymentId: String,
    id: String,
    name: String,
    email: String,
    edit: Boolean,
  },
  data() {
    return {
      form: this.$inertia.form({
        paymentId: this.paymentId,
        id: null,
        name: null,
        email: null,
      }),
    };
  },
  watch: {
    edit: function (newVal) {
      if (newVal === true) {
        this.form.id = this.id;
        this.form.name = this.name;
        this.form.email = this.email;
      } else {
        this.resetForm();
      }
    },
    id: function () {
      this.form.id = this.id;
      this.form.name = this.name;
      this.form.email = this.email;
    },
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    submit() {
      if (this.edit) {
        this.form.post(this.route("payments.edit-contact"));
      } else {
        this.form.post(this.route("payments.add-contact"));
      }
      this.closeModal();
    },
    closeModal() {
      this.resetForm();
      this.show = false;
    },
    resetForm() {
      this.form = this.$inertia.form({
        paymentId: this.paymentId,
        id: null,
        name: null,
        email: null,
      });
    },
  },
};
</script>
<style scoped></style>
