export function merge(arr1, arr2) {
  const newArr = [...arr1.filter((x) => x !== null)];

  for (let i = 0; i < arr2.length; i++) {
    const item = arr2[i];

    if (item === null) continue;
    else if (newArr.includes(item)) continue;

    newArr.push(item);
  }

  return newArr;
}
