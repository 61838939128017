<template>
  <div class="badge" :class="badgeClass">
    <span>{{ badgeText }}</span>
  </div>
</template>

<script>
export default {
  props: {
    scope: String,
  },
  computed: {
    badgeClass() {
      return "scope-" + this.scope;
    },
    badgeText() {
      if (this.scope === "1") {
        return "1";
      } else if (this.scope === "2") {
        return "2";
      } else if (this.scope === "3") {
        return "3";
      }
      return "?";
    },
  },
};
</script>

<style lang="scss" scoped>
.badge {
  color: #626262;
  background: #eaeaea;
  padding: 6px 12px;
  display: inline-block;
  border-radius: 4px;
  line-height: 1.2;
  font-size: 14px;
  font-weight: 400;

}
.scope-1 {
  color: #386563;
  background: #ebf0f0;
}
.scope-2 {
  color: #557396;
  background: #d4dfec;
}
.scope-3 {
  color: #e1613e;
  background: #FAEBE7;
}
</style>
