<template>
  <div class="px-5">
    <v-row align="center">
      <div
        class="col-12 border mb-3 justify-center d-flex align-center"
        style="height: 90px"
      >
        <EConomicLogo v-if="selectedIntegration === integrations.economic" />
        <UnicontaLogo v-if="selectedIntegration === integrations.uniconta" />
        <Dynamic365Logo
          v-if="selectedIntegration === integrations.businessCentral"
        />
        <img
          v-if="selectedIntegration === integrations.businessCentralOnPremise"
          :src="
            require('../../../assets/integration-logos/dynamic365-on-premise.webp')
          "
        />
      </div>
    </v-row>
  </div>
</template>
<script>
import EConomicLogo from "../../../assets/svg/visma-e-conomic.svg";
import UnicontaLogo from "../../../assets/svg/uniconta.svg";
import Dynamic365Logo from "../../../assets/svg/dynamic365.svg";

import {
  economic,
  businessCentral,
  businessCentralOnPremise,
  uniconta,
} from "@/util/integrations";

export default {
  components: {
    EConomicLogo: EConomicLogo,
    UnicontaLogo: UnicontaLogo,
    Dynamic365Logo: Dynamic365Logo,
  },
  data() {
    return {
      integrations: {
        economic: economic,
        businessCentral: businessCentral,
        businessCentralOnPremise: businessCentralOnPremise,
        uniconta: uniconta,
      },
    };
  },
  props: {
    selectedIntegration: String,
  },
};
</script>
