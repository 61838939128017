<template>
  <p class="text-h6 grey--text text--darken-2">{{ title }}</p>
</template>

<script>
export default {
  props: {
    title: String,
  },
};
</script>

<style lang="scss" scoped></style>
