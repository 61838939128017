<template>
  <div class="d-flex flex justify-center align-center white">
    <div class="col-10 col-md-7">
      <div
        class="d-flex justify-end mb-4"
        style="position: absolute; top: 30px; right: 50px"
      >
        <a
          href="https://verarca.com/kontakt/"
          target="_blank"
          rel="noopener noreferrer"
          class="text-body-2 black--text"
          >{{ $t("pages.login.needHelp") }}</a
        >
      </div>
      <div class="d-flex justify-center pb-14">
        <MailArrowRight />
      </div>
      <h1 class="text-h4 black--text mb-2 text-center">
        {{ $t("pages.verifyMail.title") }}
      </h1>
      <div
        class="text-subtitle-1 grey--text mb-6 font-weight-light text-center"
      >
        {{ $t("pages.verifyMail.subTitle") }}
        <span class="black--text"> {{ email }}</span>
      </div>
      <flash-messages />
      <v-btn
        tile
        block
        elevation="0"
        color="orange"
        class="font-weight-regular text-capitalize mb-6 white--text"
        @click="submit()"
      >
        {{ $t("pages.verifyMail.sendVerification") }}
      </v-btn>

      <p class="text-subtitle-1 grey--text mb-6 font-weight-light text-center">
        {{ $t("pages.verifyMail.help.needHelp") }}
        <a
          href="mailto:support@verarca.com"
          class="text-subtitle-1 black--text mb-6 font-weight-light text-center"
          target="_blank"
          rel="noopener noreferrer"
        >
          {{ $t("pages.verifyMail.help.infoMail") }}
        </a>
      </p>
    </div>
  </div>
</template>

<script>
import Layout from "@/Shared/AuthLayout";
import FlashMessages from "@/Shared/FlashMessages";
import MailArrowRight from "../../assets/svg/mail-arrow-right.svg";

export default {
  metaInfo: { title: "pages.verifyMail.title" },
  layout: Layout,
  components: {
    FlashMessages,
    MailArrowRight,
  },
  props: {
    email: String,
    errors: Object,
  },
  data() {
    return {};
  },
  methods: {
    submit() {
      this.$inertia.post(this.route("verifyMail.attempt"));
    },
  },
};
</script>
<style scoped>
input:focus {
  outline: none !important;
  box-shadow: 0 0 3px black;
}
.text-subtitle-1 span {
  display: inline-block;
}
</style>
