<template>
  <div class="page-container fill-height grow d-flex flex-column flex-nowrap">
    <v-card
      elevation="0"
      min-height="17.625rem"
      class="d-flex flex-column ma-4 py-10 px-12"
    >
      <v-col md="8" v-if="getPartnerInfo">
        <v-row class="mb-8">
          <div>
            <img
              class="rounded-circle mr-3"
              width="48px"
              height="48px"
              :src="
                getPartnerInfo.picture?.url
                  ? route('api.files.content', {
                      id: getPartnerInfo.picture.id,
                      filename: getPartnerInfo.picture.originalName,
                    })
                  : profilePictureUrl(getPartnerInfo?.profilePictureName) ??
                    profilePictureUrl(getPartnerInfo.name)
              "
            />
          </div>
          <div class="flex-grow-1">
            <div class="company-name">{{ getPartnerInfo?.name }}</div>
            <div class="grey-text">{{ getPartnerInfo?.address }}</div>
          </div>
        </v-row>
        <v-row class="mb-8">
          <v-col cols="4" v-if="getPartnerInfo?.contactPerson">
            <div class="d-flex">
              <v-icon class="align-start mr-2" color="#F25D3B"
                >mdi-account</v-icon
              >
              <div>
                <div class="grey-text mb-2">
                  {{ $t("pages.support.contactPerson") }}
                </div>
                <div>{{ getPartnerInfo?.contactPerson }}</div>
              </div>
            </div>
          </v-col>
          <v-col cols="4" v-if="getPartnerInfo?.email">
            <div class="d-flex">
              <v-icon class="align-start mr-2" color="#F25D3B"
                >mdi-email</v-icon
              >
              <div>
                <div class="grey-text mb-2">
                  {{ $t("pages.support.email") }}
                </div>
                <div>{{ getPartnerInfo?.email }}</div>
              </div>
            </div>
          </v-col>
          <v-col cols="4" v-if="getPartnerInfo?.phoneNumber">
            <div class="d-flex">
              <v-icon class="align-start mr-2" color="#F25D3B"
                >mdi-phone</v-icon
              >
              <div>
                <div class="grey-text mb-2">
                  {{ $t("pages.support.phone") }}
                </div>
                <div>{{ getPartnerInfo?.phoneNumber }}</div>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row class="mb-8" v-if="getPartnerInfo?.description">
          <v-col cols="12">
            <div class="d-flex">
              <v-icon class="align-start mr-2" color="#F25D3B"
                >mdi-note-edit-outline</v-icon
              >
              <div>
                <div class="grey-text mb-2">
                  {{ $t("pages.support.about") }} {{ getPartnerInfo?.name }}
                </div>
                <div>
                  {{ getPartnerInfo.description }}
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row v-if="uniqueSellingPoints">
          <v-col cols="12">
            <div class="d-flex">
              <v-icon class="align-start mr-2" color="#F25D3B"
                >mdi-note-edit-outline</v-icon
              >
              <div>
                <div class="grey-text">
                  {{ $t("pages.support.sellingPoints") }}
                </div>
              </div>
            </div>
          </v-col>
          <v-col
            cols="6"
            v-for="(item, index) in uniqueSellingPoints"
            :key="index"
          >
            <div class="d-flex">
              <v-icon class="align-start mr-2" color="#F25D3B"
                >mdi-check</v-icon
              >
              <div>{{ item }}</div>
            </div>
          </v-col>
        </v-row>
      </v-col>
      <v-col md="6" v-else>
        <div class="grey-text">No partner for this organization</div>
      </v-col>
    </v-card>
  </div>
</template>

<script>
import { appLayout } from "@/util/layout";
export default {
  layout: appLayout({ title: "pages.support.title" }),
  props: {
    partner: Object,
  },
  data() {
    return {
      defaultPartner: {
        name: this.$t("pages.support.fallbackPartner.name"),
        address: this.$t("pages.support.fallbackPartner.address"),
        contactPerson: this.$t("pages.support.fallbackPartner.contactPerson"),
        email: this.$t("pages.support.fallbackPartner.email"),
        phoneNumber: this.$t("pages.support.fallbackPartner.phoneNumber"),
        description: this.$t("pages.support.fallbackPartner.description"),
        uniqueSellingPropositions: this.$t(
          "pages.support.fallbackPartner.uniqueSellingPropositions"
        ),
        profilePictureName: this.$t(
          "pages.support.fallbackPartner.profilePictureName"
        ),
      },
    };
  },
  computed: {
    getPartnerInfo() {
      if (this.partner && this.partner.isSupport) {
        return this.partner;
      }

      return this.defaultPartner;
    },
    uniqueSellingPoints() {
      if (!this.getPartnerInfo?.uniqueSellingPropositions) {
        return null;
      }

      return this.getPartnerInfo?.uniqueSellingPropositions.split("\n");
    },
  },
  methods: {
    profilePictureUrl(name) {
      if (name) {
        const nameParts = name.split(" ");
        const initials = nameParts.map((name) => name.charAt(0)).join("");
        const initialsUppercase = initials.toUpperCase();

        return (
          "https://ui-avatars.com/api/?name=" +
          initialsUppercase +
          "&color=fff&background=F25D3B"
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.grey-text {
  color: #abaaaa;
}

.company-name {
  font-size: 18px;
}
</style>
