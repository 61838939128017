<template>
  <div class="d-flex flex justify-center align-center white">
    <v-form class="col-10 col-md-7" @submit.prevent="submit">
      <div
        class="d-flex justify-end mb-4"
        style="position: absolute; top: 30px; right: 50px"
      >
        <a
          href="https://verarca.com/kontakt/"
          target="_blank"
          rel="noopener noreferrer"
          class="text-body-2 black--text"
          >{{ $t("pages.login.needHelp") }}</a
        >
      </div>
      <h1 class="text-h4 black--text mb-2 text-center">
        {{ $t("pages.mailVerified.title") }}
      </h1>
      <h1 class="text-subtitle-1 grey--text mb-6 font-weight-light text-center">
        {{ $t("pages.mailVerified.subTitle") }}
      </h1>
      <flash-messages />
    </v-form>
  </div>
</template>

<script>
import Layout from "@/Shared/AuthLayout";
import FlashMessages from "@/Shared/FlashMessages";
import { objectToFormData } from "@/util/formData";

export default {
  metaInfo: { title: "pages.mailVerified.title" },
  layout: Layout,
  components: {
    FlashMessages,
  },
  props: {
    userId: String,
    verifyToken: String,
    userEmail: String,
  },
  data() {
    return {
      form: this.$inertia.form({
        userId: this.userId,
        verifyToken: this.verifyToken,
        userEmail: this.userEmail,
      }),
    };
  },
  mounted() {
    this.submit();
  },
  methods: {
    submit() {
      this.form
        .transform((data) => {
          data.verifyToken = data.verifyToken.replaceAll(/ /g, "+");

          return objectToFormData(data);
        })
        .put(this.route("verifyMail.acceptVerification"));
    },
  },
};
</script>
<style scoped>
input:focus {
  outline: none !important;
  box-shadow: 0 0 3px black;
}
</style>
