<template>
  <div class="d-flex flex justify-center align-center white">
    <div class="col-10 col-md-7">
      <div
        class="d-flex justify-end mb-4"
        style="position: absolute; top: 30px; right: 50px"
      >
        <a
          href="https://verarca.com/kontakt/"
          target="_blank"
          rel="noopener noreferrer"
          class="text-body-2 black--text"
          >{{ $t("pages.login.needHelp") }}</a
        >
      </div>
      <div class="d-flex justify-center pb-14">
        <MailChecked />
      </div>
      <h1 class="text-h4 black--text mb-2 text-center">
        {{ $t("pages.verificationMailSent.title") }}
      </h1>
      <div
        class="text-subtitle-1 grey--text mb-6 font-weight-light text-center"
      >
        <span class="black--text"> {{ email }}</span>
      </div>
      <div
        class="text-subtitle-1 grey--text mb-6 font-weight-light text-center"
      >
        {{ $t("pages.verificationMailSent.subTitle") }} <br />
        <inertia-link
          id="back-arrow"
          :href="route('register')"
          class="text-subtitle-1 black--text mb-6 font-weight-light text-center"
        >
          {{ $t("pages.verificationMailSent.resend") }}
        </inertia-link>
      </div>
      <flash-messages />

      <p class="text-subtitle-1 grey--text mb-6 font-weight-light text-center">
        {{ $t("pages.verificationMailSent.help.needHelp") }}
        <a
          href="mailto:support@verarca.com"
          class="text-subtitle-1 black--text mb-6 font-weight-light text-center"
          target="_blank"
          rel="noopener noreferrer"
        >
          {{ $t("pages.verificationMailSent.help.infoMail") }}
        </a>
      </p>
    </div>
  </div>
</template>

<script>
import Layout from "@/Shared/AuthLayout";
import FlashMessages from "@/Shared/FlashMessages";
import MailChecked from "../../assets/svg/mail-checked.svg";

export default {
  metaInfo: { title: "pages.verificationMailSent.title" },
  layout: Layout,
  components: {
    FlashMessages,
    MailChecked,
  },
  props: {
    email: String,
    errors: Object,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
<style scoped>
input:focus {
  outline: none !important;
  box-shadow: 0 0 3px black;
}
.text-subtitle-1 span {
  display: inline-block;
}
</style>
