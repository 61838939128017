<template>
  <v-tab-item :transition="false" :value="tabHref">
    <div class="fill-height fill-width">
      <v-row>
        <v-col>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    {{ $t("pages.settings.factorSources.table.useFactors") }}
                  </th>
                  <th class="text-left">
                    {{ $t("pages.settings.factorSources.table.name") }}
                  </th>
                  <th class="text-left">
                    {{ $t("pages.settings.factorSources.table.noOfFactors") }}
                  </th>
                  <th class="text-left">
                    {{ $t("pages.settings.factorSources.table.typeOfData") }}
                  </th>
                  <th class="text-left">
                    {{ $t("pages.settings.factorSources.table.region") }}
                  </th>
                  <th class="text-left"></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(factorSource, index) in [...factorSources].sort(
                    (a, b) =>
                      a.name.localeCompare(b.name, undefined, {
                        sensitivity: 'base',
                      })
                  )"
                  :key="index"
                >
                  <td>
                    <activity-indicator
                      style="width: 41px"
                      v-if="loadingEmissionSources[factorSource.id]"
                    ></activity-indicator>
                    <toggle-button
                      v-else
                      v-model="factorSource.enabledOnOrganization"
                      @change="
                        (val) => toggleFactorSource(factorSource.id, val)
                      "
                    ></toggle-button>
                  </td>
                  <td>{{ factorSource.name }}</td>
                  <td>{{ factorSource.factorAmount }}</td>
                  <td>{{ factorSource.dataType }}</td>
                  <td>{{ factorSource.regions }}</td>

                  <td class="text-right">
                    <v-btn
                      text
                      color="primary"
                      @click="showDialog(factorSource)"
                      >{{ $t("pages.settings.factorSources.info") }}</v-btn
                    >
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
    </div>
    <dialog-form
      :show-dialog="show"
      @showStateChanged="show = false"
      :maxWidth="'800px'"
    >
      <div class="">
        <div class="dialog-header d-flex pb-4 px-8 pt-8">
          <div class="flex-grow-1">
            <div class="name">{{ factorSource.name }}</div>
            <a class="link" :href="factorSource.link" target="_blank">{{
              factorSource.link
            }}</a>
          </div>
          <div class="factor-source-image-container pl-4 pb-2">
            <img
              class="factor-source-logo"
              v-if="factorSource.id"
              :src="
                require('./../../../assets/factor-sources-logos/' +
                  factorSource?.id +
                  '.png')
              "
              :alt="factorSource?.name"
            />
          </div>
        </div>
        <div class="description pa-8">
          {{ factorSource.description }}
        </div>
        <div class="factor-source-info d-flex flex-row py-10">
          <div class="factor-source-info-col">
            <div class="info-title pb-4">
              {{ $t("pages.settings.factorSources.noOfFactors") }}
            </div>
            <div class="info-value">{{ factorSource.factorAmount }}</div>
          </div>
          <div class="factor-source-info-col">
            <div class="info-title pb-4">
              {{ $t("pages.settings.factorSources.typeOfData") }}
            </div>
            <div class="info-value">{{ factorSource.dataType }}</div>
          </div>
          <div class="factor-source-info-col">
            <div class="info-title pb-4">
              {{ $t("pages.settings.factorSources.regions") }}
            </div>
            <div class="info-value">{{ factorSource.regions }}</div>
          </div>
        </div>
      </div>
    </dialog-form>
    <v-dialog max-width="600" v-model="showAlert">
      <v-card>
        <v-toolbar color="primary" dark elevation="0">{{
          $t("pages.settings.factorSources.alerts.upgradeRequired")
        }}</v-toolbar>
        <v-card-text>
          <div class="my-8">
            {{
              $t(
                "pages.settings.factorSources.alerts.upgradeRequiredForFactorSource"
              )
            }}
          </div>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            text
            tile
            class="text-none ml-0 mb-4 mb-md-0 ml-md-4 align-self-stretch orange--text"
            elevation="0"
            @click="showAlert = false"
            >{{ $t("pages.settings.factorSources.alerts.close") }}</v-btn
          >
          <v-btn
            color="orange"
            class="text-none ml-0 mb-4 mb-md-0 ml-md-4 align-self-stretch white--text"
            tile
            elevation="0"
            @click="() => $inertia.get(route('support.index'))"
            >{{ $t("pages.settings.factorSources.alerts.goToSupport") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-tab-item>
</template>

<script>
import Vue from "vue";
import ToggleButton from "../../../Components/Buttons/ToggleButton.vue";
import DialogForm from "../../../Components/Dialog/DialogForm.vue";
import ActivityIndicator from "../../../Components/Outputs/ActivityIndicator.vue";
export default {
  components: { ToggleButton, DialogForm, ActivityIndicator },
  props: {
    tabHref: String,
    tabId: Number,
    factorSources: Array,
  },
  data() {
    return {
      show: false,
      factorSource: {},
      loadingEmissionSources: {},
      showAlert: false,
    };
  },
  methods: {
    showDialog(factorSource) {
      this.show = true;
      this.factorSource = factorSource;
    },
    toggleFactorSource(factorSourceId, value) {
      Vue.set(this.loadingEmissionSources, factorSourceId, true);
      fetch(
        this.route("api.emission-sources.toggle", {
          id: factorSourceId,
          enabled: value,
        }),
        {
          method: "POST",
        }
      )
        .then((res) => res.json())
        .then((data) => {
          if (data.status === "Success") {
            // Anti pattern, dont update the prop here
            this.factorSources.find(
              (factorSource) => factorSource.id === factorSourceId
            ).enabledOnOrganization = value;
          } else if (data.status === "RequiresUpgrade") {
            // show alert box
            this.showAlert = true;
          }
        })
        .finally(() => {
          Vue.set(this.loadingEmissionSources, factorSourceId, false);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog-header {
  border-bottom: 1px solid #ececec;
  .name {
    font-size: 30px;
    font-weight: bold;
  }
  .link {
    font-size: 12px;
    font-weight: bold;
    color: #808080;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  .factor-source-image-container {
    display: flex;
    align-items: center;
    .factor-source-logo {
      max-width: 240px;
      max-height: 70px;
    }
  }
}
.description {
  font-size: 12px;
  height: calc(40vh);
  max-height: 300px;
}
.factor-source-info {
  .info-title {
    font-size: 12px;
    font-weight: bold;
    color: #808080;
    text-align: center;
  }
  .info-value {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    padding: 0 20px;
  }
  > .factor-source-info-col {
    display: flex;
    flex-direction: column;
    flex: 1;

    .info-value {
      flex: 1;
      align-content: center;
    }

    &:first-child {
      .info-value {
        font-size: 40px;
      }
    }
  }
}
</style>
