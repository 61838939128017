<template>
  <div class="ef-row" :class="fullscreen ? 'fullscreen' : null">
    <div class="ef-overview d-flex">
      <div
        class="ef-overview-info text-overflow-wrap pr-2 cursor-pointer"
        @click="expandResult(expandedResult === key ? null : key)"
      >
        <div class="ef-name text-overflow-wrap">
          {{ result?.name }}
        </div>
        <div class="ef-emissions">{{ result.emissions }}</div>
      </div>
      <template v-if="fullscreen">
        <div
          class="ef-overview-info text-overflow-wrap pr-2 cursor-pointer"
          v-for="(infoItem, infoindex) in result?.info?.filter((infoItem) =>
            fullscreenColumns.find((item) => item.key === infoItem.key)
          )"
          :key="infoindex"
          @click="expandResult(expandedResult === key ? null : key)"
        >
          <div class="text-overflow-wrap">
            {{ infoItem.value }}
          </div>
        </div>
      </template>
      <v-btn
        v-if="expandedResult !== key && !fullscreen"
        class="mr-2"
        color="#F25D3B"
        elevation="0"
        fab
        height="32px"
        width="32px"
        @click="create(result.id)"
        ><PlusIcon height="20px" width="20px"></PlusIcon
      ></v-btn>
      <v-btn
        :color="expandedResult === key ? '#F2E0DC' : '#E5E3E3'"
        elevation="0"
        fab
        height="32px"
        width="32px"
        @click="expandResult(expandedResult === key ? null : key)"
        ><ArrowDown
          class="result-expand-btn"
          :class="expandedResult === key ? 'result-expand-btn-active' : null"
          height="24px"
          width="24px"
        ></ArrowDown
      ></v-btn>
    </div>
    <div
      class="ef-info"
      :style="
        expandedResult === key
          ? 'height: ' + infoContentHeight + 'px'
          : 'height: 0px'
      "
    >
      <div ref="infoArea">
        <div class="ef-description">
          {{ result.description }}
        </div>
        <div
          class="ef-info-row"
          v-for="infoRow in result.info"
          :key="infoRow.key"
        >
          <div class="key">{{ infoRow.name }}</div>
          <div class="value">{{ infoRow.value }}</div>
        </div>

        <v-btn
          v-if="!fullscreen"
          color="#F25D3B"
          elevation="0"
          dark
          class="add-purchase-btn text-none"
          block
          tile
          @click="create(result.id)"
          >Add purchase</v-btn
        >
        <v-btn
          v-else
          class="add-purchase-btn-small"
          color="#F25D3B"
          elevation="0"
          fab
          height="52px"
          width="52px"
          @click="create(result.id)"
          ><PlusIcon height="20px" width="20px"></PlusIcon
        ></v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import ArrowDown from "../../assets/svg/copilot-arrow-down.svg";
import PlusIcon from "../../assets/svg/plus.svg";
export default {
  components: {
    ArrowDown,
    PlusIcon,
  },
  props: {
    result: Object,
    fullscreen: Boolean,
    expandedResult: String,
    fullscreenColumns: Array,
    groupKey: String,
  },
  data() {
    return {
      infoContentHeight: null,
    };
  },
  emits: ["expandResult", "create"],
  mounted() {
  },
  methods: {
    expandResult(id) {
      this.$emit("expandResult", id);
    },
    create(id) {
      this.$emit("create", id);
    },
  },
  computed: {
    key() {
      return this.groupKey + this.result.id;
    },
  },
  watch: {
    expandedResult() {
      this.$nextTick(() => {
        this.infoContentHeight = this.$refs["infoArea"].clientHeight;
      });
    },
    fullscreen() {
      this.expandResult(null);
    },
  },
};
</script>

<style lang="scss" scoped>
.ef-row {
  background-color: #f9f9f9;
  border-radius: 12px;
  padding-top: 16px;
  margin-bottom: 12px;
  margin-left: 16px;
  margin-right: 16px;
  overflow: hidden;

  .ef-overview {
    padding: 0 16px;

    .result-expand-btn {
      transition: all 245ms ease;
      &.result-expand-btn-active {
        transform: rotate(180deg);

        path {
          fill: #f25d3b;
        }
      }
    }
    .ef-overview-info {
      padding-bottom: 14px;
      flex: 1;
      div {
        color: #131313;
        font-weight: 500;
        font-size: 14px;
      }
      .ef-emissions {
        color: #aeaeae;
        font-size: 14px;
      }
    }
  }

  .ef-info {
    color: #686868;
    font-size: 12px;
    position: relative;

    transition: all 245ms ease;
    /*height: 0px;
    &.expanded {
      height: 529px;
    }*/

    .ef-description {
      padding: 0 16px;
      padding-top: 20px;
      padding-bottom: 24px;
    }
    .ef-info-row {
      display: flex;
      flex-direction: row;
      padding: 0 16px;
      padding-bottom: 12px;
      .key {
        color: #aeaeae;
        flex: 1;
        margin-right: 20px;
      }

      .value {
        color: #686868;
        text-align: right;
        font-weight: 500;
      }
    }
    .add-purchase-btn {
      height: 44px;
    }
    .add-purchase-btn-small {
      position: absolute;
      bottom: 0;
      right: 0;
      margin-right: 17px;
      margin-bottom: 17px;
    }
  }

  &.fullscreen {
    .ef-info {
      overflow: hidden;
      .ef-info-row {
        .key {
          flex: none;
          width: 160px;
        }
      }
    }
  }
}
</style>
