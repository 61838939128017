<template>
  <dialog-form :show-dialog="show" max-width="500px" :persistent="true">
    <div class="d-flex flex-column px-0 pt-16">
      <div class="text-center mb-2 pt-10">
        <div class="check">
          <check-circle class="check-icon"></check-circle>
        </div>

        <h1 class="text-h6 black--text mb-2 text-center pt-2 pb-16 px-4">
          {{ confirmMessage }}
        </h1>
      </div>
      <v-card-actions class="d-flex footer-section justify-end pa-4">
        <v-btn
          tile
          elevation="0"
          color="orange"
          class="text-none ml-0 mb-4 mb-md-0 ml-md-4 align-self-stretch white--text"
          type="submit"
          @click="confirm()"
          >{{ $t("common.actions.ok") }}</v-btn
        >
      </v-card-actions>
    </div>
  </dialog-form>
</template>

<script>
import DialogForm from "./Dialog/DialogForm.vue";
import CheckCircle from "../assets/svg/check-circle.svg";
export default {
  components: { DialogForm, CheckCircle },
  props: {
    value: Boolean,
    confirmMessage: String,
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    confirm() {
      this.show = false;
    },
  },
};
</script>

<style scoped>
.check {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: rgba(229, 237, 236);
  position: relative;
  z-index: 9998;
  margin: 0 auto;
}
.check-icon {
  position: absolute;
  top: 47%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 1;
  z-index: 9999;
}
</style>
