<template>
  <div class="flex-grow-1 d-flex justify-center align-center">
    <div class="d-flex justify-center align-center">
      <div class="px-0" fluid style="width: 1313px">
        <div class="d-flex report-header">
          <div class="report-title flex-grow-1 align-self-center">
            {{ $t("components.reportTemplates.carbonFootprintOverview.title") }}
          </div>
          <div class="d-flex flex-row">
            <div
              class="date-range d-flex flex-row align-center justify-center mr-8"
            >
              {{ formattedDateRange.from }}
              <ArrowRightIcon class="mx-4 date-arrow"></ArrowRightIcon>
              {{ formattedDateRange.to }}
            </div>
            <div class="company-name d-flex flex-row align-center">
              {{ organization.name }}
            </div>
          </div>
        </div>
        <div class="climate-report-container">
          <amount-change-card
            class="card-asset-num"
            :title="$t('components.cards.titles.numberOfAssets')"
            iconBackColor="#6493BE1f"
            :value="assetCardData?.numberOfAssetsCard?.value?.toString() ?? '0'"
            :hideBenchmark="true"
          >
            <template v-slot:icon>
              <Package style="width: 20px; height: 20px"></Package>
            </template>
          </amount-change-card>

          <amount-change-card
            class="card-total-co2"
            :title="$t('components.cards.titles.totalCo2')"
            iconBackColor="#E5EDEC"
            :value="formatCurrency(assetCardData?.totalCo2UsedCard?.value)"
            :unit="$t('common.units.kgCO2e')"
            :hideBenchmark="true"
          >
            <template v-slot:icon>
              <Leaf style="width: 20px; height: 20px"></Leaf>
            </template>
          </amount-change-card>

          <PieChartCard
            :labels="co2CategoryLabels"
            :colors="['#266663', '#6493BE', '#E35E3E', '#B7B7B7']"
            :dataSeries="getScopes"
            :title="$t('components.cards.co2Categories.title')"
            class="card-co2-category"
          ></PieChartCard>

          <LineChartCard
            v-if="
              emissionsPerMonthCurrentYear?.length > 0 &&
              emissionsPerMonthLastYear?.length > 0
            "
            :animate-chart="false"
            :title="$t('components.cards.co2Emissions.title')"
            :series="emissionsPerMonthCurrentYear"
            :compare-series="emissionsPerMonthLastYear"
            :x-axis-categories="months1YearToCurrent"
            :seriesLabel="$t('components.cards.co2Emissions.labels.thisYear')"
            :compareSeriesLabel="
              $t('components.cards.co2Emissions.labels.lastYear')
            "
            :maxVal="
              Math.ceil(
                Math.max(
                  ...[
                    ...emissionsPerMonthCurrentYear,
                    ...emissionsPerMonthLastYear,
                  ]
                )
              )
            "
            class="card-co2-emissions-overview"
          ></LineChartCard>
          <horizontal-stacked-bar-chart
            :data="formatEmissionByCalcMethod"
            class="emission-overview-by-calc-method"
          ></horizontal-stacked-bar-chart>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AmountChangeCard from "../Cards/AmountChangeCard.vue";
import Package from "../../../src/assets/svg/package.svg";
import PieChartCard from "../Cards/PieChartCard.vue";
import Leaf from "../../../src/assets/svg/leaf.svg";
import LineChartCard from "../Cards/LineChartCard.vue";

import HorizontalStackedBarChart from "../Cards/HorizontalStackedBarChart.vue";
import ArrowRightIcon from "../../assets/svg/arrow-right-dark.svg";
import Formatters from "../../mixins/Formatters.vue";

const _scope1 = "scope1";
const _scope2 = "scope2";
const _scope3 = "scope3";

export default {
  mixins: [Formatters],
  components: {
    AmountChangeCard,
    Package,
    Leaf,
    PieChartCard,
    LineChartCard,
    HorizontalStackedBarChart,
    ArrowRightIcon,
  },
  props: {
    dateRange: Object,
    assetCardData: Object,
    organization: Object,
    emissionsPerMonthCurrentYear: Array,
    emissionsPerMonthLastYear: Array,
    co2MethodsByMonths: Array,
    scopes: Object,
    defaultDateFormat: String,
  },
  computed: {
    formattedDateRange() {
      const formattedFrom = this.formatDate(this.dateRange?.from);
      const formattedTo = this.formatDate(this.dateRange?.to);
      return { from: formattedFrom, to: formattedTo };
    },
    formatEmissionByCalcMethod() {
      return this.co2MethodsByMonths?.map((x) => {
        return {
          name: this.$t(
            `components.reportTemplates.carbonFootprintOverview.${x.name}`
          ),
          value: x.data.reduce((a, b) => a + b),
        };
      });
    },
    getScopeCountBars() {
      if (this.scopes !== null) {
        const scope1 = this.assetCardData.scope1Emissions;
        const scope2 = this.assetCardData.scope2Emissions;
        const scope3 = this.assetCardData.scope3Emissions;

        let maxCount = Math.max(
          scope1?.value ?? 0,
          scope2?.value ?? 0,
          scope3?.value ?? 0,
          1 // Avoid dividing by zero
        );

        return [
          {
            label: "Scope 1",
            renderedValue:
              this.formatCurrency(scope1?.value ?? 0) +
              " " +
              this.$t("common.units.kgCO2e"),
            fractionValue: this.barChartCalculateScale(
              scope1?.value / maxCount
            ),
            color: "#898989",
            rawValue: scope1?.value,
          },
          {
            label: "Scope 2",
            renderedValue:
              this.formatCurrency(scope2?.value ?? 0) +
              " " +
              this.$t("common.units.kgCO2e"),
            fractionValue: this.barChartCalculateScale(
              scope2?.value / maxCount
            ),
            color: "#F25D3B",
            rawValue: scope2?.value,
          },
          {
            label: "Scope 3",
            renderedValue:
              this.formatCurrency(scope3?.value) +
              " " +
              this.$t("common.units.kgCO2e"),
            fractionValue: this.barChartCalculateScale(
              scope3?.value / maxCount
            ),
            color: "#266663",
            rawValue: scope3?.value,
          },
        ];
      }

      return [];
    },
    months1YearToCurrent() {
      const monthCountInSelection = this.monthDiff(
        new Date(this.dateRange.from),
        new Date(this.dateRange.to)
      );

      const months = [
        this.$t("common.months.short.january"),
        this.$t("common.months.short.february"),
        this.$t("common.months.short.march"),
        this.$t("common.months.short.april"),
        this.$t("common.months.short.may"),
        this.$t("common.months.short.june"),
        this.$t("common.months.short.july"),
        this.$t("common.months.short.august"),
        this.$t("common.months.short.september"),
        this.$t("common.months.short.october"),
        this.$t("common.months.short.november"),
        this.$t("common.months.short.december"),
      ];

      const currentMonthIndex = new Date(this.dateRange.from).getMonth();

      var rMonths = [];
      for (var i = 0; i <= monthCountInSelection; i++) {
        var month = months[(currentMonthIndex + i) % 12];
        rMonths.push(month);
      }
      return rMonths;
    },
    getScopes() {
      if (this.scopes === null) {
        return [];
      }

      let scopes = [];
      let scopeKeys = [
        _scope1,
        _scope2,
        _scope3,
        this.$t("common.rootCauses.Other"),
      ];

      for (let key of scopeKeys) {
        try {
          let scope = this.scopes[key];

          if (scope === undefined || scope === null) scope = 0;

          scopes.push(scope);
        } catch (e) {
          console.warn(e);
        }
      }

      return scopes;
    },
    co2CategoryLabels() {
      return [
        this.$t("components.cards.co2Categories.scope1"),
        this.$t("components.cards.co2Categories.scope2"),
        this.$t("components.cards.co2Categories.scope3"),
        this.$t("components.cards.co2Categories.unassigned"),
      ];
    },
    getAssetCountBars() {
      const approvedCount = this.assetCardData?.numberOfAssetsApproved?.value;
      const definedCount = this.assetCardData?.numberOfAssetsDefined?.value;
      const undefinedCount = this.assetCardData?.numberOfAssetsUndefined?.value;

      const maxCount = Math.max(
        approvedCount,
        definedCount,
        undefinedCount,
        1 // Avoid dividing by zero
      );

      return [
        {
          label: this.$t("components.cards.titles.defined"),
          renderedValue: definedCount,
          fractionValue: this.barChartCalculateScale(definedCount / maxCount),
          color: "#898989",
          rawValue: definedCount,
        },
        {
          label: this.$t("components.cards.titles.undefined"),
          renderedValue: undefinedCount,
          fractionValue: this.barChartCalculateScale(undefinedCount / maxCount),
          color: "#F25D3B",
          rawValue: undefinedCount,
        },
        {
          label: this.$t("components.cards.titles.verified"),
          renderedValue: approvedCount,
          fractionValue: this.barChartCalculateScale(approvedCount / maxCount),
          color: "#266663",
          rawValue: approvedCount,
        },
      ];
    },
  },
  methods: {
    formatDate(dateString) {
      const date = new Date(dateString);

      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      if (this.defaultDateFormat === "YYYY-MM-DD") {
        return `${year}-${month}-${day}`;
      }
      if (this.defaultDateFormat === "DD-MM-YYYY") {
        return `${day}-${month}-${year}`;
      }
    },
    barChartCalculateScale(value) {
      // Set height to 0 if value is 0
      if (value === 0 || value === null) {
        return 0;
      }

      // If value is really small but still above 0 return 0.1
      //just to make sure there's something there for the cursor to hover.
      else if (value <= 0.1 && value !== 0) {
        return 0.1;
      }

      // Else return value
      return value;
    },
    monthDiff(d1, d2) {
      var months;
      months = (d2.getFullYear() - d1.getFullYear()) * 12;
      months -= d1.getMonth();
      months += d2.getMonth();
      return months <= 0 ? 0 : months;
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-skeleton {
  background: rgba(0, 0, 0, 0.12);
  overflow: hidden;
  position: relative;
}
.custom-skeleton::after {
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0)
  );
  animation: skeleton-loading 1.5s infinite;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transform: translateX(-100%);
  z-index: 1;
}

@keyframes skeleton-loading {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(100%);
  }
}

.climate-report-container {
  justify-items: stretch;
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-template-areas:
    "card-asset-num card-total-co2 card-co2-emissions-overview card-co2-emissions-overview"
    "card-co2-category card-co2-category card-co2-emissions-overview card-co2-emissions-overview"
    "card-co2-category card-co2-category card-co2-emissions-overview card-co2-emissions-overview"
    "emission-overview-by-calc-method emission-overview-by-calc-method emission-overview-by-calc-method emission-overview-by-calc-method";
  gap: 18px;

  .card-asset-num {
    grid-area: card-asset-num;
  }

  .card-total-co2 {
    grid-area: card-total-co2;
  }
  .card-co2-emissions-overview {
    grid-area: card-co2-emissions-overview;
    overflow-x: hidden;
  }
  .card-co2-category {
    grid-area: card-co2-category;
  }
  .emission-overview-by-calc-method {
    grid-area: emission-overview-by-calc-method;
  }
}

.report-title {
  font-size: 35px;
}

.date-range {
  font-size: 16px;
}

.company-name {
  font-size: 35px;
  max-width: 519px;
  text-wrap: wrap;
}

.date-arrow {
  path {
    fill: #131313 !important;
  }
}

.report-header {
  padding-bottom: 34px;
}
</style>
