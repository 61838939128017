<template>
  <div>
    <v-btn
      dark
      id="parent"
      height="auto"
      elevation="0"
      tile
      class="profile-button pa-1 d-flex flex-row align-self-end mb-5 mb-sm-0 align-center flex-grow justify-center"
      plain
      text
    >
      <div class="d-flex flex-row align-center">
        <img
          class="rounded-circle mr-3"
          width="40px"
          height="40px"
          :src="profilePictureUrl"
        />
        <div class="flex-grow-1">
          <div
            class="text-left caption text-capitalize text-lg-body-1 white--text"
          >
            {{ username }}
          </div>
          <div
            class="text-left caption text-capitalize grey--text text--lighten-1"
          >
            {{ companyName }}
          </div>
        </div>
      </div>
      <v-icon class="pl-4 pl-lg-10" :color="'#686868'">mdi-chevron-down</v-icon>
    </v-btn>
    <v-menu light tile activator="#parent" offset-y>
      <v-list class="theme--light" dense>
        <v-list-item href="/profile" v-inertia:get>
          <v-list-item-title>
            {{ $t("components.profile.title") }}
          </v-list-item-title>
        </v-list-item>
        <v-list-item href="/settings" v-inertia:get>
          <v-list-item-title>
            {{ $t("components.profile.settings") }}
          </v-list-item-title>
        </v-list-item>
        <v-list-item href="/logout" v-inertia:post>
          <v-list-item-title>{{
            $t("components.profile.signOut")
          }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
export default {
  props: {
    username: String,
    companyName: String,
    profilePictureUrl: String,
  },
};
</script>

<style lang="scss" scoped>
.profile-button {
  &:deep(.v-btn__content) {
    opacity: 1 !important;
  }
}
</style>
