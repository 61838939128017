<template>
  <v-tab-item :transition="false" :value="tabHref">
    <div class="fill-height fill-width"></div>
    <form @submit.prevent="submit">
      <v-card-text>
        <v-row no-gutters>
          <v-col cols="12" md="4" class="pr-xl-16">
            <dialog-form-text-input
              v-model="form.name"
              :title="$t('common.fieldNames.name')"
              :placeholder="`Enter ${$t('common.fieldNames.name')}`"
              :error-messages="form.errors.name"
            ></dialog-form-text-input>
            <dialog-form-text-input
              v-model="form.email"
              :title="$t('pages.profile.email')"
              :placeholder="`Enter ${$t('pages.profile.email')}`"
              :error-messages="form.errors.email"
              autocomplete="email"
            ></dialog-form-text-input>
            <dialog-form-text-input
              v-model="updatedAt"
              :title="$t('common.fieldNames.lastUpdated')"
              disabled
            ></dialog-form-text-input>
            <dialog-form-text-input
              v-model="createdAt"
              :title="$t('common.fieldNames.createdAt')"
              disabled
            ></dialog-form-text-input>
            <dialog-form-select-input
              :title="$t('pages.profile.language')"
              v-model="form.locale"
              :items="formattedLocales"
              hide-details
            >
            </dialog-form-select-input>
            <dialog-form-upload-image
              :value="form.profilePicture"
              @change="(val) => (form.profilePicture = val)"
              @clickClearFile="() => (form.profilePicture = null)"
              :rules="profilePictureRules"
              :preview-image-src="
                auth.user.profilePictureFile
                  ? route('api.files.content', {
                      id: auth.user.profilePictureFile.id,
                      filename: auth.user.profilePictureFile.originalName,
                    })
                  : null
              "
              :title="$t('pages.profile.profilePicture')"
              :error-messages="form.errors.profilePicture"
              :button-text="$t('pages.profile.changeProfilePicture')"
            ></dialog-form-upload-image>
            <div class="pt-4">
              <v-btn
                tile
                block
                elevation="0"
                color="orange"
                class="font-weight-regular text-capitalize mb-2 white--text"
                :disabled="form.processing"
                @click="changePassword()"
                >{{ $t("pages.profile.changePassword") }}</v-btn
              >
            </div>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <flash-messages />
        <v-spacer></v-spacer>
        <v-col cols="2" class="d-flex justify-end">
          <v-btn
            type="submit"
            tile
            block
            elevation="0"
            color="orange"
            class="font-weight-regular text-capitalize mb-2 white--text"
            :disabled="form.processing"
            >{{ $t("pages.profile.save") }}</v-btn
          >
        </v-col>
      </v-card-actions>
    </form>
    <change-password-dialog
      v-model="showChangePasswordDialog"
      v-show="showChangePasswordDialog"
      :auth="auth"
    ></change-password-dialog>
  </v-tab-item>
</template>
<script>
import FlashMessages from "@/Shared/FlashMessages";
import DialogFormTextInput from "../../../Components/Dialog/inputs/DialogFormTextInput.vue";
import DialogFormSelectInput from "../../../Components/Dialog/inputs/DialogFormSelectInput.vue";
import DialogFormUploadImage from "../../../Components/Dialog/inputs/DialogFormUploadImage.vue";
import ChangePasswordDialog from "../../../Components/ChangePasswordDialog.vue";
import { objectToFormData } from "@/util/formData";
import { DateTime } from "luxon";

export default {
  components: {
    FlashMessages,
    DialogFormTextInput,
    DialogFormSelectInput,
    DialogFormUploadImage,
    ChangePasswordDialog,
  },
  props: {
    tabHref: String,
    auth: Object,
    locales: Array,
  },
  data() {
    return {
      showChangePasswordDialog: false,
      profilePictureRules: [
        (value) =>
          !value ||
          value.size < 1000000 ||
          this.$t("pages.settings.index.fileSizeRule"),
      ],
      form: this.$inertia.form({
        name: this.auth.user.name,
        email: this.auth.user.email,
        locale: this.auth.user.preferredLocale,
        profilePicture: null,
      }),
    };
  },
  computed: {
    createdAt() {
      return DateTime.fromISO(this.auth.user.createdAt).toFormat(
        "yyyy-MM-dd HH:mm"
      );
    },
    updatedAt() {
      return DateTime.fromISO(this.auth.user.updatedAt).toFormat(
        "yyyy-MM-dd HH:mm"
      );
    },
    formattedLocales() {
      return this.locales.map((x) => ({
        text: this.$t(`pages.profile.languages.${x}`),
        value: x,
      }));
    },
  },
  methods: {
    submit() {
      const preparedForm = this.form.transform((data) => {
        const transformedData = {
          ...data,
        };
        return objectToFormData(transformedData);
      });

      preparedForm.put(this.route("profile.update"), {
        preserveScroll: true,
        onSuccess: () => {
          this.form.profilePicture = null;
          this.$i18n.locale = this.form.locale;
        },
      });
    },
    changePassword() {
      this.showChangePasswordDialog = true;
    },
  },
};
</script>
