<template>
  <v-card-text class="main-section px-6 py-0" :class="{'no-bottom-border': noBottomBorder}">
    <v-container class="pa-0">
      <v-row>
        <v-col class="column-left pt-6" cols="12" sm="6" md="6">
          <slot name="left"></slot>
        </v-col>
        <v-col class="column-right pt-6" cols="12" sm="6" md="6">
          <slot name="right"></slot>
        </v-col>
      </v-row>
    </v-container>
  </v-card-text>
</template>

<script>
export default {
  components: {},
  props: {
    noBottomBorder: Boolean
  },
  data() {
    return {
      dialog: true,
      // TODO: Determine if the following static data can be removed
      categories: [
        { value: "Biler", key: "car" },
        { value: "Små anskaffelser", key: "small-purchases" },
        { value: "Kontor", key: "office" },
        { value: "Tjenester", key: "services" },
      ],
      regions: [
        { value: "Denmark", key: "da-dk", icon: "da-dk-icon" },
        { value: "United Kingdom ", key: "en-gb", icon: "" },
      ],
    };
  },
  methods: {
    // TODO: Rewrite to use something else to determine color
    getChipColorForCategory(key) {
      if (key === "car") {
        return {
          accent: "#26666319",
          primary: "#266663",
        };
      }
      if (key === "small-purchases") {
        return {
          accent: "#F6F0EB",
          primary: "#9B8675",
        };
      }
      if (key === "office") {
        return {
          accent: "#6493BE4c",
          primary: "#4B7397",
        };
      }
      if (key === "services") {
        return {
          accent: "#F25D3B19",
          primary: "#F25D3B",
        };
      }

      return {
        accent: "#00000019",
        primary: "#444",
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.main-section {
  border-bottom: 1px solid #e8e8e8;
  @media screen and (min-width: 600px) {
    .column-left {
      border-right: 1px solid #e8e8e8;
    }
  }
}

.no-bottom-border {
  border-bottom: none;
}
</style>
