<template>
  <div class="flex-grow-1 d-flex justify-center" style="margin-top: 130px">
    <div class="d-flex justify-center">
      <div class="px-0" fluid style="width: 1313px">
        <div class="mb-4">
          <div class="page-title">
            {{ $t("pages.reports.climateReport.emissionTypesAppendix.title") }}
          </div>
          <div class="page-subtitle">
            {{
              $t("pages.reports.climateReport.emissionTypesAppendix.subtitle")
            }}
          </div>
        </div>
        <v-card
          light
          color="#fff"
          elevation="0"
          rounded="0"
          max-width="100%"
          class="flex-grow-1"
        >
          <div class="px-5 pb-5 pt-2">
            <v-simple-table class="statistics-table">
              <template v-slot:default>
                <thead>
                  <tr class="table-header">
                    <th width="auto" class="text-left">
                      {{
                        $t(
                          "components.reportTemplates.reportEmissionTypesAppendix.tableHeaders.source"
                        )
                      }}
                    </th>
                    <th class="text-left">
                      {{
                        $t(
                          "components.reportTemplates.reportEmissionTypesAppendix.tableHeaders.activityName"
                        )
                      }}
                    </th>
                    <!--<th class="text-left">Sector</th>-->
                    <!--<th class="text-left">Category</th>-->
                    <th class="text-left">
                      {{
                        $t(
                          "components.reportTemplates.reportEmissionTypesAppendix.tableHeaders.year"
                        )
                      }}
                    </th>
                    <th class="text-left">
                      {{
                        $t(
                          "components.reportTemplates.reportEmissionTypesAppendix.tableHeaders.region"
                        )
                      }}
                    </th>
                    <th class="text-left">
                      {{
                        $t(
                          "components.reportTemplates.reportEmissionTypesAppendix.tableHeaders.lcaActivity"
                        )
                      }}
                    </th>
                    <th class="text-left">
                      {{
                        $t(
                          "components.reportTemplates.reportEmissionTypesAppendix.tableHeaders.unitType"
                        )
                      }}
                    </th>
                    <th class="text-left">
                      {{
                        $t(
                          "components.reportTemplates.reportEmissionTypesAppendix.tableHeaders.id"
                        )
                      }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(
                      emissionFactorsAppendixItem, index
                    ) in emissionFactorsAppendixPageData"
                    :key="index"
                  >
                    <td class="text-left">
                      {{ emissionFactorsAppendixItem?.source }}
                    </td>
                    <td class="text-left" style="max-width: 480px">
                      {{ emissionFactorsAppendixItem?.name }}
                    </td>
                    <!--<td class="text-left">
                      {{ emissionFactorsAppendixItem?.sector }}
                    </td>
                    <td class="text-left">
                      {{ emissionFactorsAppendixItem?.category }}
                    </td>-->
                    <td class="text-left">
                      {{ emissionFactorsAppendixItem?.year }}
                    </td>
                    <td class="text-left">
                      {{ emissionFactorsAppendixItem?.region }}
                    </td>
                    <td class="text-left">
                      {{ emissionFactorsAppendixItem?.lcaActivity }}
                    </td>
                    <td class="text-left">
                      {{ emissionFactorsAppendixItem?.unitType }}
                    </td>
                    <td class="text-left">
                      {{ emissionFactorsAppendixItem?.id }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    emissionFactorsAppendixPageData: Array,
  },
};
</script>

<style lang="scss" scoped>
.card-title {
  margin-bottom: 0;
  color: #707070;
}

.card-value {
  font-size: 25px;
}

.page-title {
  font-size: 35px;
}
.page-subtitle {
  font-size: 20px;
  color: #707070;
}

.statistics-table {
  thead {
    .table-header {
      th {
        font-weight: 200;
        color: #707070;
        font-size: 14px;
        padding-bottom: 10px;
        /*&:first-child {
          font-size: 18px;
        }*/
      }
    }
  }
  tbody {
    tr {
      background: #fbfbfb;

      td {
        border-bottom: thin solid #fff !important;
        height: 40px !important;
        font-size: 12px !important;
      }
    }
    .sub-header {
      background: #f5f5f5;
    }
  }
}
</style>
