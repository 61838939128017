<template>
  <div class="page-container fill-height grow d-flex flex-column flex-nowrap">
    <v-container class="d-flex justify-end">
      <v-btn
        v-if="templateName !== 'no-template'"
        color="#f25d3b"
        class="white--text"
        tile
        @click="generateReport"
      >
        <v-icon class="mr-2">mdi-printer</v-icon>
        {{ $t("pages.reports.print.printPage") }}
      </v-btn>
    </v-container>
    <vue-html2pdf
      :show-layout="false"
      :enable-download="false"
      :preview-modal="true"
      :pdf-quality="2"
      :manual-pagination="true"
      pdf-format="a4"
      pdf-orientation="landscape"
      ref="html2Pdf"
      pdf-content-width="100%"
    >
      <section slot="pdf-content">
        <img :src="output" />
      </section>
    </vue-html2pdf>
    <div class="print-view-container">
      <div class="print-view d-flex flex-column flex-nowrap" ref="printMe">
        <component
          :is="templateName"
          :assetCardData="assetCardData"
          :organization="organization"
        ></component>
      </div>
    </div>
  </div>
</template>

<script>
import { appLayout } from "@/util/layout";
import CarbonFootprintOverview from "../../Components/ReportTemplates/CarbonFootprintOverview.vue";
import ClimateReport from "../../Components/ReportTemplates/ClimateReport.vue";
import VueHtml2pdf from "vue-html2pdf";

export default {
  components: { CarbonFootprintOverview, ClimateReport, VueHtml2pdf },
  props: {
    auth: Object,
    templateRoute: String,
  },
  data() {
    return { 
      output: null,
      assetCardData: null,
      dateRange: {
        from: null,
        to: null,
      },
    };
  },
  layout: appLayout({ title: "pages.reports.index.printTitle" }),
  computed: {
    organization() {
      const org = this.auth.organizations.find(
        (organization) => organization.id === this.auth.organizationId
      );
      const orgGroup = this.auth.organizationGroups.find(
        (organization) => organization.id === this.auth.organizationId
      );

      if (org !== undefined) {
        return org;
      }

      return orgGroup;
    },
    templateName() {
      // Match route to component name
      if (this.templateRoute === "carbon-footprint") {
        return "carbon-footprint";
      }
      if (this.templateRoute === "climate-report") {
        return "climate-report";
      }
      return "no-template";
    },
  },
  methods: {
    async generateReport() {
      await this.generateImage();
      this.$refs.html2Pdf.generatePdf();
    },
    async generateImage() {
      const el = this.$refs.printMe;
      // add option type to get the image version
      // if not provided the promise will return
      // the canvas.
      const options = {
        type: "dataURL",
        scale: 2,
      };
      this.output = await this.$html2canvas(el, options);
    },
  },
};
</script>

<style lang="scss" scoped>
.print-view-container {
  overflow-x: scroll;
  width: 100%;
  max-width: 1684px;
  align-self: center;
  padding: 20px;
  border: 1px solid black;
  .print-view {
    box-sizing: border-box;
    font-family: "Lexend", Arial, sans-serif;
    font-size: 12px;
    width: 1684px;
    min-width: 1684px;
    max-width: 1684px;
    align-self: center;
    background-color: #f7f7f7;
    pointer-events: none;

    .text-h5 {
      font-size: 18px !important;
    }
  }
}

.page-container {
  background-color: #2a2a2a;
  padding-bottom: 20px;
}
/*
@media print {
  body {
    color: #000;
    font-size: 10px;
    background: none;
    -webkit-print-color-adjust: exact;
    size: A4;
  }
  .page-container {
    //page-break-inside: avoid !important;
  }
  .apexcharts-canvas{

  }
  .main-navigation,
  .content-header {
    display: none;
  }
  .main-content {
    padding: 0 !important;
  }
  .page-break {
    page-break-before: always;
  }
}*/
</style>
