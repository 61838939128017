<template>
  <dialog-form :show-dialog="show" max-width="600px">
    <div class="d-flex flex-column px-6 py-8">
      <div class="pb-8 d-flex justify-center">
        <LogoBlack />
      </div>

      <stripe-element-card
        ref="elementRef"
        :pk="publishableKey"
        :hidePostalCode="true"
        @token="tokenCreated"
      />

      <div class="pt-4 d-flex justify-end">
        <v-btn
          tile
          outlined
          elevation="0"
          bg-color="white"
          class="border-sm text-capitalize black--text black--border white"
          @click="closeModal"
          >{{ $t("pages.payment.buttons.cancel") }}</v-btn
        >
        <v-btn
          tile
          elevation="0"
          color="orange"
          class="font-weight-regular text-capitalize mb-4 mx-4 white--text"
          type="submit"
          @click="submit"
        >
          {{ $t("common.actions.submit") }}
        </v-btn>
      </div>
    </div>
  </dialog-form>
</template>

<script>
import DialogForm from "../../Components/Dialog/DialogForm.vue";
import LogoBlack from "../../assets/svg/verarca-logo-black.svg";
import { StripeElementCard } from "@vue-stripe/vue-stripe";

export default {
  components: {
    DialogForm,
    LogoBlack,
    StripeElementCard,
  },
  props: {
    value: Boolean,
    publishableKey: String,
  },
  data() {
    return {
      token: null,
      form: this.$inertia.form({
        token: null,
        expMonth: null,
        expYear: null,
        last4: null,
        brand: null,
      }),
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    submit() {
      this.$refs.elementRef.submit();
    },
    tokenCreated(token) {
      this.form.token = token.id;
      this.form.expMonth = token.card.exp_month;
      this.form.expYear = token.card.exp_year;
      this.form.last4 = token.card.last4;
      this.form.brand = token.card.brand;

      this.form.post(this.route("payments.add-card"));

      this.closeModal();
    },
    closeModal() {
      this.show = false;
    },
  },
};
</script>

<style scoped></style>
