<template>
  <v-tab-item :transition="false" :value="tabHref">
    <div class="fill-height fill-width">
      <v-row no-gutters>
        <v-col md="4">
          <dialog-form-select-input
            :title="$t('pages.settings.index.fields.fiscalYear')"
            v-model="fiscalYearProxy"
            :items="fiscalYears"
            item-text=""
            item-value=""
          ></dialog-form-select-input>
        </v-col>
      </v-row>
    </div>
  </v-tab-item>
</template>
<script>
import DialogFormSelectInput from "../../../Components/Dialog/inputs/DialogFormSelectInput.vue";

export default {
  components: { DialogFormSelectInput },
  props: {
    tabHref: String,
    fiscalYear: String,
    fiscalYears: Array,
  },
  emits: ["updateForm"],
  computed: {
    fiscalYearProxy: {
      get() {
        return this.fiscalYear;
      },
      set(value) {
        this.$emit("updateForm", "fiscalYear", value);
      },
    },
  },
};
</script>
