<template>
  <div>
    <div class="d-flex py-4 px-4 list-filter">
      <dialog-form-input-wrapper class="flex-grow-1">
        <v-text-field
          class="rounded-0 pt-0"
          v-model="accountMapperSearch"
          background-color="#F7F7F7"
          solo
          flat
          :label="$t('components.integrations.chooseAccounts.search')"
          single-line
          filled
          clearable
          type="search"
          elevation="0"
          hide-details
          color="#686868"
          light
          prepend-inner-icon="mdi-magnify"
        ></v-text-field>
      </dialog-form-input-wrapper>

      <v-spacer></v-spacer>
      <div
        v-if="!readOnly && allowStandardAccount"
        class="d-flex flex-row align-center"
      >
        Map by:
        <dialog-form-select-input
          class="ml-4"
          style="max-width: 250px"
          hideDetails
          v-model="categoryModeProxy"
          :items="[
            {
              text: 'Standard accounts',
              value: 0,
            },
            {
              text: 'GHG categories',
              value: 1,
            },
          ]"
        ></dialog-form-select-input>
      </div>
    </div>
    <v-list class="category-account-mapper pt-0" tile dense>
      <v-list-group
        v-for="(accountGroup, aindex) in filteredAccounts"
        :key="aindex"
        :value="readOnly ? true : null"
        class="category-groups"
        append-icon="mdi-menu-down"
        :hidden="
          (readOnly &&
            readOnlyAccounts(accountGroup.accountNumbers).length === 0) ||
          accountGroup.hidden
        "
      >
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title>
              <div
                class="d-inline"
                v-html="highlightText(accountGroup.name, accountMapperSearch)"
              ></div>
              <v-chip
                :color="countInGroup(accountGroup) > 0 ? '#F25D3B' : '#F7F7F7'"
                :text-color="countInGroup(accountGroup) > 0 ? '#fff' : 'B4B4B4'"
                small
                class="pa-1 ml-2 align-center justify-center"
                style="height: 23px; min-width: 23px"
                >{{ countInGroup(accountGroup) }}</v-chip
              >
            </v-list-item-title>
          </v-list-item-content>
        </template>
        <div v-if="!readOnly" class="list-header">
          <p class="sync">
            {{ $t("components.onboarding.accountMapper.sync") }}
          </p>
          <p class="name">
            {{ $t("components.onboarding.accountMapper.name") }}
          </p>
          <p class="syncTo">
            {{ $t("components.onboarding.accountMapper.syncTo") }}
          </p>
        </div>
        <div v-else class="list-header">
          <p class="sync flex-grow-1">
            {{ $t("components.onboarding.accountMapper.nameOfCategory") }}
          </p>
          <p class="syncTo text-right">
            {{ $t("components.onboarding.accountMapper.suggestedMapping") }}
          </p>
        </div>
        <template
          v-for="(account, anindex) in readOnly
            ? readOnlyAccounts(accountGroup.accountNumbers)
            : accountGroup.accountNumbers"
        >
          <v-list-item
            :key="anindex"
            class="account-number"
            v-if="!account.hidden"
          >
            <toggle-button
              class="mr-8"
              :value="syncAccounts[account.id]"
              @change="(val) => updateSyncLines(account, val)"
              v-if="!readOnly"
            ></toggle-button>
            <v-list-item-title
              class="account-mapping-line"
              :class="syncAccounts[account.accountId] ? 'sync-account' : ''"
            >
              <div
                v-html="
                  highlightText(
                    `${account.number} ${account.name}`,
                    accountMapperSearch
                  )
                "
              ></div>
              <v-btn
                v-if="autoMappedAccounts[account.id] && !readOnly"
                class="d-flex flex-row align-center rounded px-2 py-3 red--text mx-2"
                x-small
                color="#F25D3B"
                text
                elevation="0"
                style="background-color: #fdece7; pointer-events: none"
              >
                <AIRobot class="mr-1"></AIRobot>
                {{ $t("components.onboarding.accountMapper.autoSuggestion") }}
              </v-btn>
            </v-list-item-title>
            <div v-if="readOnly">
              <ArrowRightIcon class="arrow-icon"></ArrowRightIcon>
            </div>
            <div
              v-if="readOnly"
              class="read-only-category d-flex flex-row justify-end align-center"
            >
              {{ selectedCategoryForAccount(account)?.name }}
              <ScopeBadge
                class="ml-4"
                :scope="
                  selectedCategoryForAccount(account)?.scope?.value?.split(
                    ' '
                  )[1]
                "
              ></ScopeBadge>
            </div>
            <div
              v-else-if="
                (account.system === 'economic' &&
                  account.accountType === 'totalFrom') ||
                (account.system === 'dynamics365' &&
                  account.accountType === 'Total') ||
                (account.system === 'uniconta' && account.accountType === 'Sum')
              "
              class="d-flex flex-row justify-center align-center no-activity-label"
            >
              <v-icon color="red" class="mr-2">mdi-alert-circle</v-icon>
              <span>
                {{ $t("components.onboarding.accountMapper.sumAccount") }}
              </span>
            </div>
            <SelectGHGCategory
              v-else-if="syncAccounts[account.id]"
              :items="categories"
              :valueKey="categoryMode === 1 ? 'id' : 'standardAccountId'"
              @change="
                (val) =>
                  categoryMode == 1
                    ? selectedGhgForAccount(account, val)
                    : selectedStandardAccountsForAccount(account, val)
              "
              :initialValue="selectedCategoryForAccount(account)"
            ></SelectGHGCategory>
            <div
              v-else-if="account.balance === 0"
              class="d-flex flex-row justify-center align-center no-activity-label"
            >
              <v-icon color="red" class="mr-2">mdi-alert-circle</v-icon>
              <span>
                {{ $t("components.onboarding.accountMapper.noActivity") }}
              </span>
            </div>
          </v-list-item>
        </template>
      </v-list-group>
    </v-list>
    <div
      v-if="!hasAnyReadOnlyAccounts && readOnly"
      class="pa-8 text-center font-weight-light text-subtitle-1 grey--text"
    >
      {{
        $t("components.onboarding.accountMapper.autoSuggestionWasNotPossible")
      }}
    </div>
  </div>
</template>
<script>
import Vue from "vue";

import ToggleButton from "../Buttons/ToggleButton.vue";
import SelectGHGCategory from "./SelectGHGCategory.vue";

import AIRobot from "../../assets/svg/ai-robot.svg";

import ArrowRightIcon from "../../assets/svg/arrow-right.svg";
import ScopeBadge from "../../Components/Attribute/ScopeBadge.vue";
import DialogFormSelectInput from "../Dialog/inputs/DialogFormSelectInput.vue";
import DialogFormInputWrapper from "../Dialog/inputs/DialogFormInputWrapper.vue";

export default {
  components: {
    ToggleButton,
    SelectGHGCategory,
    AIRobot,
    ArrowRightIcon,
    ScopeBadge,
    DialogFormSelectInput,
    DialogFormInputWrapper,
  },
  props: {
    categories: Array,
    accounts: Array,
    syncAccounts: Object,
    autoMappedAccounts: Object,
    readOnly: {
      type: Boolean,
      default: false,
    },
    allowStandardAccount: {
      type: Boolean,
      default: true,
    },
    categoryMode: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      accountMapperSearch: "",
    };
  },
  computed: {
    categoryModeProxy: {
      get() {
        return this.categoryMode;
      },
      set(val) {
        this.$emit("changedCategoryMode", val);
      },
    },
    hasAnyReadOnlyAccounts() {
      return Object.keys(this.autoMappedAccounts).length > 0;
    },
    filteredAccounts() {
      var filtered = this.accounts.map((accountGroup) => {
        var accNums = accountGroup.accountNumbers.map((acc) => {
          // Create a regular expression pattern to match the search term ignoring case
          const searchTermRegex = new RegExp(
            this.accountMapperSearch ?? "",
            "i"
          );
          // Test if the name or number includes the search term (ignoring case)
          const matchesSearch =
            searchTermRegex.test(acc.name) ||
            searchTermRegex.test(acc.number) ||
            searchTermRegex.test(accountGroup.name) ||
            searchTermRegex.test(accountGroup.accountNumber);
          return {
            ...acc,
            hidden: !matchesSearch, // Add a hidden property based on whether it matches the search
          };
        });

        const accGroup = {
          ...accountGroup,
          accountNumbers: accNums,
          // Hide entire group if no accountnumbers are visible
          hidden: accNums.filter((a) => !a.hidden).length === 0 ? true : false,
        };

        return accGroup;
      });

      return filtered;
    },
  },
  methods: {
    readOnlyAccounts(accountNumbers) {
      return accountNumbers.filter(
        (accountNumber) => this.syncAccounts[accountNumber.id]
      );
    },
    selectedCategoryForAccount(account) {
      return this.categories?.find((cat) =>
        cat.accountNumbers.find((acc) => acc.id === account.id)
      );
    },
    updateSyncLines(account, val) {
      this.$emit("toggleSync", { id: account.id, value: val });
    },
    selectedGhgForAccount(account, categoryId) {
      // copy object
      const categories = Vue.util.extend([], this.categories);

      // remove account from any categories that contain it
      categories.forEach((cat, catIndex) => {
        cat.accountNumbers.forEach(() => {
          categories[catIndex].accountNumbers = categories[
            catIndex
          ].accountNumbers.filter((a) => a.id !== account.id);
        });
      });

      // find index for category to update
      const categoryIndex = categories.findIndex(
        (category) => category.id === categoryId
      );

      // add account
      categories[categoryIndex].accountNumbers.push(account);

      // send updated categories to parent
      this.$emit("updateCategories", categories);
    },
    selectedStandardAccountsForAccount(account, standardAccountId) {
      // This is not confusing at all..

      // copy object
      const categories = Vue.util.extend([], this.categories);

      // remove account from any categories that contain it
      categories.forEach((cat, catIndex) => {
        cat.accountNumbers.forEach(() => {
          categories[catIndex].accountNumbers = categories[
            catIndex
          ].accountNumbers.filter((a) => a.id !== account.id);
        });
      });

      // find index for category to update
      const categoryIndex = categories.findIndex(
        (category) => category.standardAccountId === standardAccountId
      );

      // add account
      categories[categoryIndex].accountNumbers.push(account);

      // send updated categories to parent
      this.$emit("updateCategories", categories);
    },
    countInGroup(group) {
      let count = 0;

      group.accountNumbers.forEach((account) => {
        if (this.syncAccounts[account.id] === true) {
          count++;
        }
      });

      return count;
    },
    highlightText(text, term) {
      if (!term) return text;
      const regex = new RegExp(term, "gi");
      return text.replace(
        regex,
        (match) => `<span class="highlight">${match}</span>`
      );
    },
  },
  watch: {},
};
</script>

<style lang="scss" scoped>
.category-account-mapper {
  border-top: 1px solid #e8e8e8;
  /*border-bottom: 1px solid #e8e8e8;*/

  .v-list-item__title {
    font-weight: normal !important;
  }

  .category-groups {
    &:not(:last-child) {
      > :deep(.v-list-group__header) {
      }
    }

    > :deep(.v-list-group__header
        > .v-list-item__icon
        > .v-icon:before, .v-list-group__header
        > .v-list-item__icon
        > .v-icon:after) {
      color: #b4b4b4 !important;
    }

    &:deep(.v-list-group__header) {
      height: 63px;
      border-bottom: 1px solid #e8e8e8;
    }
  }

  &:deep(.v-list-group__header .v-list-item__icon) {
    margin-right: 0px !important;
    align-self: center;
  }

  > &:deep(.v-list-group__header.v-list-item) {
    padding: 0 16px !important;
  }

  .account-number {
    background: #fafafa !important;
    height: 48px;

    &:not(:last-child) {
      border-bottom: 1px solid #e8e8e8;
    }
  }

  .assign-account {
    background: #fff;
    border-bottom: 1px solid #e8e8e8;
    text-align: center;
    min-height: 50px;
    letter-spacing: unset;
    justify-content: center;
    padding: 0px !important;
  }
}

.list-header {
  border-bottom: 1px solid #e8e8e8;
  background: #ffffff;
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0 16px;

  p {
    font-size: 12px;
    color: #b4b4b4;
    margin-bottom: 0;
  }

  .sync {
    width: 41px;
    margin-right: 32px;
  }

  .name {
    flex: 1;
  }

  .syncTo {
    width: 380px;
  }
}

.info-btn {
  float: right;
  min-width: 20px !important;
  height: 20px !important;
}

.footer-section {
  position: sticky;
  bottom: 0;
  background: #fff;
  border-top: 1px solid #e8e8e8;

  .add-account-number-btn {
    width: 100%;
  }
}

.account-mapping-line {
  flex: 1;
  color: #868686;
  display: flex;
  align-items: center;

  div {
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1;
  }

  &.sync-account {
    color: #000;
  }
}

.no-activity-label {
  max-width: 380px;
  width: 100%;
  font-size: 14px;
  color: #868686;
}

.arrow-icon {
  path {
    fill: #b4b4b4;
  }
}

.read-only-category {
  flex: 1;
  font-weight: lighter;
  font-size: 14px;
}

.list-filter {
  position: sticky;
  top: 0;
  z-index: 999;
  background: #fff;
}

:deep(.highlight) {
  background-color: yellow;
}
</style>
