<template>
  <div v-if="editor" class="editor-wrapper">
    <editor-menu-bar :editor="editor" v-slot="{ commands, isActive }">
      <v-toolbar dense flat color="#F7F7F7" class="toolbar">
        <v-btn
          icon
          small
          @click="commands.bold"
          :class="{ 'v-btn--active': isActive.bold() }"
        >
          <v-icon>mdi-format-bold</v-icon>
        </v-btn>
        <v-btn
          icon
          small
          @click="commands.italic"
          :class="{ 'v-btn--active': isActive.italic() }"
        >
          <v-icon>mdi-format-italic</v-icon>
        </v-btn>
        <v-btn
          icon
          small
          @click="commands.strike"
          :class="{ 'v-btn--active': isActive.strike() }"
        >
          <v-icon>mdi-format-strikethrough</v-icon>
        </v-btn>
        <v-btn
          icon
          small
          @click="commands.underline"
          :class="{ 'v-btn--active': isActive.underline() }"
        >
          <v-icon>mdi-format-underline</v-icon>
        </v-btn>
        <v-btn
          icon
          small
          @click="commands.paragraph"
          :class="{ 'v-btn--active': isActive.paragraph() }"
        >
          <v-icon>mdi-format-paragraph</v-icon>
        </v-btn>
        <v-btn
          icon
          small
          @click="commands.heading({ level: level })"
          :class="{ 'v-btn--active': isActive.heading({ level: level }) }"
          v-for="level in [1, 2, 3]"
          :key="level"
        >
          H{{ level }}
        </v-btn>
      </v-toolbar>
    </editor-menu-bar>
    <editor-content :editor="editor" class="editor-content cursor-text" />
  </div>
</template>

<script>
import { Editor, EditorContent, EditorMenuBar } from "tiptap";
import {
  Blockquote,
  CodeBlock,
  HardBreak,
  Heading,
  HorizontalRule,
  OrderedList,
  BulletList,
  ListItem,
  TodoItem,
  TodoList,
  Bold,
  Code,
  Italic,
  Link,
  Strike,
  Underline,
  History,
} from "tiptap-extensions";

export default {
  components: {
    EditorContent,
    EditorMenuBar,
  },
  props: {
    value: {
      type: [String, Object],
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      emitAfterOnUpdate: false,
      editor: null,
    };
  },
  watch: {
    value(val) {
      if (this.emitAfterOnUpdate) {
        this.emitAfterOnUpdate = false;
        return;
      }
      if (this.editor) this.editor.setContent(val, false);
    },
    disabled(val) {
      this.editor.setOptions({ editable: !val });
    },
  },
  mounted() {
    this.editor = new Editor({
      content: this.value,
      editable: !this.disabled,
      onUpdate: this.onUpdate.bind(this),
      onBlur: this.onBlur.bind(this),
      onFocus: this.onFocus.bind(this),
      disablePasteRules: true,
      disableInputRules: true,
      extensions: [
        new Blockquote(),
        new BulletList(),
        new CodeBlock(),
        new HardBreak(),
        new Heading({ levels: [1, 2, 3] }),
        new HorizontalRule(),
        new ListItem(),
        new OrderedList(),
        new TodoItem(),
        new TodoList(),
        new Link(),
        new Bold(),
        new Code(),
        new Italic(),
        new Strike(),
        new Underline(),
        new History(),
      ],
    });
  },
  methods: {
    onUpdate(info) {
      this.emitAfterOnUpdate = true;
      this.$emit("input", info.getHTML(), info);
    },
    onBlur({ event, view }) {
      this.$emit("blur", event, view);
    },
    onFocus({ event, view }) {
      this.$emit("focus", event, view);
    },
  },
  beforeDestroy() {
    if (this.editor) this.editor.destroy();
  },
};
</script>
<style scoped>
.editor-wrapper{
  padding: 8px;
  background-color: #F7F7F7;
}
.editor-content:deep(.ProseMirror) {
  border-radius: 0px;
  padding: 10px;
}
.toolbar{
  border-bottom: 1px solid #E8E8E8 !important;
}
</style>
