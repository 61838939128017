var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"organizationMenu",staticClass:"organization-menu"},[_c('v-text-field',{staticClass:"search-field",attrs:{"outlined":"","hide-details":"","color":"rgba(255,255,255,0.5)"},on:{"focus":() => {
        _vm.showDropdown = true;
        this.searchString = '';
      }},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('v-avatar',{staticClass:"ml-4",staticStyle:{"margin":"0px 12px 0 0px"},attrs:{"size":28}},[_c('img',{attrs:{"src":_vm.orgImage(_vm.getOrganization(_vm.organizationId)),"alt":_vm.getOrganization(_vm.organizationId).name}})])]},proxy:true},{key:"append",fn:function(){return [(!_vm.mini)?_c('v-icon',[_vm._v("mdi-unfold-more-horizontal")]):_vm._e()]},proxy:true}]),model:{value:(_vm.searchString),callback:function ($$v) {_vm.searchString=$$v},expression:"searchString"}}),(_vm.showDropdown)?_c('div',{staticClass:"drop-down"},[_c('PopOverTail',{staticClass:"popover-tail",attrs:{"width":"100%","height":"10px"}}),_c('div',{staticClass:"menu-items"},_vm._l((_vm.combinedOrganizations),function(item,index){return _c('div',{key:index,staticClass:"menu-item"},[(item.children)?[_c('div',{directives:[{name:"show",rawName:"v-show",value:(
              _vm.hasHighlistedText(item.name, _vm.searchString) ||
              _vm.childrenHaveHighlightedText(item.children, _vm.searchString)
            ),expression:"\n              hasHighlistedText(item.name, searchString) ||\n              childrenHaveHighlightedText(item.children, searchString)\n            "}],staticClass:"organization-group",on:{"click":function($event){_vm.organizationId = item.id}}},[_c('v-avatar',{staticClass:"mr-4",attrs:{"color":"red","size":28}},[_c('img',{attrs:{"src":_vm.orgImage(item),"alt":item.name}})]),_c('div',{domProps:{"innerHTML":_vm._s(_vm.highlightText(item.name, _vm.searchString))}}),_c('v-btn',{staticClass:"toggle-organization-children",class:_vm.showGroup === item.name ? 'mini' : '',attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();_vm.showGroup === item.name
                  ? (_vm.showGroup = '')
                  : (_vm.showGroup = item.name)}}},[_c('DrawerDismissBtnIcon')],1)],1),(_vm.showGroup === item.name)?_c('div',{staticClass:"organization-children"},_vm._l((item.children),function(child,index){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.hasHighlistedText(child.name, _vm.searchString)),expression:"hasHighlistedText(child.name, searchString)"}],key:index,staticClass:"organization-child",on:{"click":function($event){_vm.organizationId = child.id}}},[_c('SubdirArrow',{staticClass:"subdir-arrow mr-2 ml-1",attrs:{"width":"20px","height":"20px"}}),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({ref:"organizationName",refInFor:true,staticClass:"organization-name",domProps:{"innerHTML":_vm._s(_vm.highlightText(child.name, _vm.searchString))}},'div',attrs,false),child.name.length > 19 && on))]}}],null,true)},[_c('div',{staticClass:"tooltip"},[_vm._v(" "+_vm._s(child.name)+" ")])])],1)}),0):_vm._e()]:[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.hasHighlistedText(item.name, _vm.searchString)),expression:"hasHighlistedText(item.name, searchString)"}],staticClass:"organization",on:{"click":function($event){_vm.organizationId = item.id}}},[_c('v-avatar',{staticClass:"mr-4 mt-0",attrs:{"color":"red","size":28}},[_c('img',{attrs:{"src":_vm.orgImage(item),"alt":item.name}})]),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({ref:"organizationName",refInFor:true,staticClass:"organization-name",domProps:{"innerHTML":_vm._s(_vm.highlightText(item.name, _vm.searchString))}},'div',attrs,false),item.name.length > 17 && on))]}}],null,true)},[_c('div',{staticClass:"tooltip"},[_vm._v(" "+_vm._s(item.name)+" ")])])],1)]],2)}),0)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }