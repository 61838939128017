<template>
  <v-row dense>
    <v-col>
      <div class="d-flex flex-row align-center">
        <p class="text-caption grey--text text--lighten-1 my-2 flex-grow-1">
          {{ label }}
        </p>
      </div>
      <v-autocomplete
        :value="value"
        :items="items"
        :item-text="itemText"
        :item-value="itemValue"
        :menu-props="{ top: false, offsetY: true }"
        class="rounded-0 mb-4"
        item-color="#F25D3B"
        color="#F25D3B"
        background-color="#F7F7F7"
        hide-details
        hide-selected
        solo
        flat
        filled
        light
        clearable
        @input="$emit('input', $event)"
      >
        <template v-if="$scopedSlots.selectItem" #item="params">
          <slot name="selectItem" v-bind="params"></slot>
        </template>
      </v-autocomplete>
    </v-col>
  </v-row>
</template>
<script>
export default {
  props: {
    value: [String, Number, Object],
    items: Array,
    label: String,
    itemText: {
      type: String,
      default: "name",
    },
    itemValue: {
      type: String,
      default: "id",
    },
  },
};
</script>
