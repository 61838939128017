<template>
  <div>
    <div class="d-flex flex-row align-center">
      <p
        v-if="title"
        class="text-caption grey--text text--lighten-1 mt-2 mb-1 flex-grow-1"
      >
        {{ title }}
      </p>
      <ai-status v-if="aiStatus" :type="aiStatus" :collapsable="false" />
    </div>
    <slot></slot>
  </div>
</template>

<script>
import AiStatus from "../../Labels/AiStatus.vue";

export default {
  components: { AiStatus },
  props: {
    aiStatus: String,
    title: String,
  },
};
</script>

<style lang="scss" scoped></style>
