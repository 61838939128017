import i18n from "../i18n";
import { fixedAttributes, fixedFieldAttributes } from "./fixedAssetData";

export const roundedNumberFormatter = new Intl.NumberFormat("da-DK", {
  maximumFractionDigits: 2,
});

export const fullNumberFormatter = new Intl.NumberFormat("da-DK", {
  maximumFractionDigits: 20,
});

export const currencyFormatter = new Intl.NumberFormat("da-DK", {
  useGrouping: "always",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});
export const decimalFormatter = new Intl.NumberFormat("da-DK", {
  useGrouping: "always",
  minimumFractionDigits: 2,
  maximumFractionDigits: 4,
});

export const integerNumberFormatter = new Intl.NumberFormat("da-DK", {
  useGrouping: "always",
  maximumFractionDigits: 0,
});

export const emissionsFormatter = new Intl.NumberFormat("da-DK", {
  useGrouping: "always",
  maximumFractionDigits: 5,
});

export const chartNumberFormatter = new Intl.NumberFormat("da-DK", {
  useGrouping: "always",
  maximumFractionDigits: 1,
});

export function formatAttributeLink(
  attributeLink,
  defaultCurrencyCode,
  dateFormat
) {
  if (!defaultCurrencyCode) defaultCurrencyCode = "";
  if (!dateFormat) dateFormat = "";

  const guidValueAttributeIds = [
    fixedAttributes.co2SectorId,
    fixedAttributes.co2CategoryId,
    fixedAttributes.co2RegionId,
    fixedAttributes.co2NameId,
    fixedAttributes.co2GhgCategoryId,
  ];

  if (guidValueAttributeIds.includes(attributeLink?.attributeId)) {
    return attributeLink.searchString;
  }

  if (
    attributeLink?.attributeId === fixedFieldAttributes.generalVerarcaIdId ||
    attributeLink?.attributeId === fixedAttributes.infoAccountNumberId ||
    attributeLink?.attributeId === fixedAttributes.infoEntryNumberId
  ) {
    return attributeLink.searchString;
  }

  if (attributeLink?.attributeId === fixedAttributes.co2EmissionsId) {
    if (!attributeLink.decimalValue) return "";

    return emissionsFormatter.format(attributeLink.decimalValue);
  }

  switch (attributeLink?.attribute?.format) {
    case "Text":
      return attributeLink.stringValue;
    case "TextArea":
      return attributeLink.stringValue;
    case "Select":
      return attributeLink.attributeSelectOption?.value;
    case "Number":
      if (!attributeLink.decimalValue) return "";

      return fullNumberFormatter.format(attributeLink.decimalValue);
    case "Percentage":
      if (!attributeLink.decimalValue) return "";

      return fullNumberFormatter.format(attributeLink.decimalValue) + " %";
    case "DateTime":
      return attributeLink.searchString;
    case "Date":
      if (dateFormat === "YYYY-MM-DD") {
        return attributeLink.searchString;
      } else if (dateFormat === "DD-MM-YYYY") {
        const parts = attributeLink.searchString.split("-");
        const convertedDate = `${parts[2]}-${parts[1]}-${parts[0]}`;
        return convertedDate;
      }
      return attributeLink.searchString;
    case "Currency":
      return `${
        attributeLink.stringValue
          ? attributeLink.stringValue + " "
          : defaultCurrencyCode + " "
      }${currencyFormatter.format(attributeLink.decimalValue)}`;
    case "Boolean":
      if (
        attributeLink.boolValue === undefined ||
        attributeLink.boolValue === null
      )
        return "";

      return attributeLink.boolValue
        ? i18n.t("common.boolean.true")
        : i18n.t("common.boolean.false");
    default:
      return;
  }
}

export function formatEmissionFactorName(emissionFactor) {
  const lcaActivity = emissionFactor.lcaActivity ?? "unknown";

  const localSuffix = emissionFactor.organizationId ? " (Local)" : "";
  const lcaSuffix =
    lcaActivity.toLowerCase() !== "unknown" ? ` (${lcaActivity})` : "";

  return `${emissionFactor.name}${lcaSuffix}${localSuffix}`;
}

export function formatAndConvertStringToFloat(stringToConvert) {
  stringToConvert = stringToConvert.replace(/\./g, "");
  stringToConvert = stringToConvert.replace(/,/g, ".");
  let floatValue = parseFloat(stringToConvert);

  return floatValue;
}

export function formatEmissions(value) {
  let unit = "kgCO2e";
  let formattedValue = "";

  let decimals = 0;
  if (value < 1) decimals = 4;
  else if (value < 10) decimals = 3;
  else if (value < 100) decimals = 2;
  else if (value < 1000) decimals = 1;
  else if (value < 10000) decimals = 3;
  else if (value < 100000) decimals = 2;
  else if (value < 1000000) decimals = 1;

  let useGrouping = null;

  // Determine unit
  if (value >= 1000) {
    unit = "tCO2e";
    // convert to tonnes
    value /= 1000;
    // Add grouping seperator for tonnes
    useGrouping = "always";
  }

  // Format the value
  formattedValue = Intl.NumberFormat("da-DK", {
    maximumFractionDigits: decimals,
    useGrouping: useGrouping,
  }).format(value);

  return { value: formattedValue, unit: unit };
}

export function formatKgToTonEmissions(value) {
  let decimals = 0;

  // Determine number of decimal places
  if (value < 10000) decimals = 3;
  else if (value < 100000) decimals = 2;
  else if (value < 1000000) decimals = 1;

  // Determine unit
  let unit = "tCo2e";
  value /= 1000;

  // Format the value
  let formattedValue = Intl.NumberFormat("da-DK", {
    maximumFractionDigits: decimals,
  }).format(value);

  return { value: formattedValue, unit: unit };
}
