<template>
  <div class="page-container fill-height grow d-flex flex-column flex-nowrap">
    <v-card
      elevation="0"
      min-height="17.625rem"
      class="d-flex flex-column ma-4"
    >
      <v-card-text class="pa-0 flex-grow-1">
        <v-row class="d-flex align-center pa-4" dense>
          <v-col>
            <attribute-class-form
              v-model="dialog"
              :attributeClass="attributeClass"
              :attributes="attributes"
              :attributeClassRoute="attributeClassRoute"
              @clear="attributeClass = null"
            />
            <v-btn
              large
              class="align-self-stretch align-self-sm-center mt-5 mt-sm-0"
              elevation="0"
              tile
              color="#F25D3B"
              dark
              @click="dialog = true"
            >
              <v-icon class="pr-3 block" color="#fff" small>mdi-plus</v-icon>
              {{ $t("pages.attributes.form.create") }}
            </v-btn>
            <v-btn
              class="text-none font-weight-medium ml-4"
              style="color: #686868"
              outlined
              tile
              large
            >
              <v-icon color="#686868">mdi-filter-outline</v-icon>
              <span class="d-none d-sm-block">
                {{ $t("pages.attributes.index.filter") }}
              </span>
            </v-btn>
          </v-col>
          <v-col md="3">
            <v-text-field
              v-model="filters.search"
              :label="$t('common.dropdowns.search.label')"
              single-line
              elevation="0"
              hide-details
              color="#686868"
              class="search-field rounded-0 pt-0"
              height="44"
              light
              background-color="#F7F7F7"
              filled
              solo
              dense
              prepend-inner-icon="mdi-magnify"
              flat
            ></v-text-field>
          </v-col>
        </v-row>
        <v-data-table
          class="elevation-0 row-pointer"
          :headers="headers"
          :items="attributeClasses.data"
          :server-items-length="attributeClasses.totalCount"
          :options.sync="pagination"
          :footer-props="tableFooterProps"
          :must-sort="true"
          @dblclick:row="(event, { item }) => editAttributeClass(item)"
          @update:page="changePage(pagination)"
          @update:items-per-page="changePage(pagination)"
          @update:sort-by="changePage(pagination)"
          @update:sort-desc="changePage(pagination)"
        >
          <template v-slot:[`item.referenceKey`]="{ item }">
            <td :class="item.fixed ? 'disabled' : ''">
              <div style="min-width: 100px">
                <template v-if="item.referenceKey > 0">
                  {{ item.referenceKey }}
                </template>
              </div>
            </td>
          </template>

          <template v-slot:[`item.name`]="{ item }">
            <td :class="item.fixed ? 'disabled' : ''">
              <div style="min-width: 200px">
                {{ item.name }}
              </div>
            </td>
          </template>

          <template v-slot:[`item.attributes`]="{ item }">
            <td :class="item.fixed ? 'disabled' : ''">
              <div style="min-width: 350px">
                {{ handleAttributes(item) }}
              </div>
            </td>
          </template>

          <template v-slot:item.action="{ item }">
            <td :class="item.fixed ? 'disabled fixed' : 'fixed'">
              <v-row class="actionrow" justify="center">
                <v-tooltip content-class="custom-tooltip" left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      small
                      class="mr-2"
                      :disabled="item.fixed"
                      v-bind="attrs"
                      v-on="!item.fixed ? on : undefined"
                      >mdi-eye-outline</v-icon
                    >
                  </template>
                  <v-col class="px-6 black--text">
                    <v-row class="mb-8" justify="space-between">
                      <span class="mr-2">{{
                        $t("components.attributes.actionMenu.info.id")
                      }}</span>
                      <span class="font-weight-black">{{
                        item.referenceKey
                      }}</span>
                    </v-row>
                    <v-row justify="space-between">
                      <span>{{
                        $t("components.attributes.actionMenu.info.created")
                      }}</span>
                      <span class="font-weight-black">{{
                        formatDate(item.createdAt)
                      }}</span>
                    </v-row>
                    <v-row justify="space-between">
                      <span>{{
                        $t("components.attributes.actionMenu.info.createdBy")
                      }}</span>
                      <span class="font-weight-black">{{
                        item.createdBy ? item.createdBy.name : ""
                      }}</span>
                    </v-row>
                    <v-row justify="space-between">
                      <span>{{
                        $t("components.attributes.actionMenu.info.lastUpdated")
                      }}</span>
                      <span class="font-weight-black">{{
                        formatDate(item.updatedAt)
                      }}</span>
                    </v-row>
                    <v-row justify="space-between">
                      <span>{{
                        $t(
                          "components.attributes.actionMenu.info.lastUpdatedBy"
                        )
                      }}</span>
                      <span class="font-weight-black">{{
                        item.updatedBy ? item.updatedBy.name : ""
                      }}</span>
                    </v-row>
                    <v-row class="mt-8" justify="space-between">
                      <span class="mr-16">{{
                        $t(
                          "components.attributes.actionMenu.info.attributesWithData"
                        )
                      }}</span>
                      <span class="font-weight-black">
                        {{ $t("common.placeholders.data") }}
                      </span>
                    </v-row>
                  </v-col>
                </v-tooltip>

                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      small
                      class="mr-2"
                      :disabled="item.fixed"
                      v-bind="attrs"
                      v-on="!item.fixed ? on : undefined"
                      @click="copyAttributeClass(item)"
                      >mdi-content-copy</v-icon
                    >
                  </template>
                  <span>{{ $t("common.actions.copy") }}</span>
                </v-tooltip>

                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      small
                      class="mr-2"
                      :disabled="formIsDisabled(item)"
                      v-bind="attrs"
                      v-on="!formIsDisabled(item) ? on : undefined"
                      @click="editAttributeClass(item)"
                      >mdi-pencil</v-icon
                    >
                  </template>
                  <span>{{ $t("common.actions.edit") }}</span>
                </v-tooltip>

                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      small
                      class="mr-2"
                      :disabled="item.fixed"
                      v-bind="attrs"
                      v-on="!item.fixed ? on : undefined"
                      @click="destroyAttributeClass(item)"
                      >mdi-trash-can-outline</v-icon
                    >
                  </template>
                  <span>{{ $t("common.actions.delete") }}</span>
                </v-tooltip>
              </v-row>
            </td>
          </template>
          <template #footer.page-text="pageTextProps">
            {{ $t("common.dataTable.visibleRows", pageTextProps) }}
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <confirm-dialog
      v-model="showConfirmDialog"
      v-show="showConfirmDialog"
      :confirm-message="confirmMessage"
      @confirmed="handleConfirmation"
    ></confirm-dialog>
  </div>
</template>

<script>
import throttle from "lodash/throttle";
import pickBy from "lodash/pickBy";
import { appLayout } from "@/util/layout";
import AttributeClassForm from "./Form.vue";
import { footerProps } from "@/util/dataTable";
import { fixedClasses } from "../../util/fixedSolutionData";
import ConfirmDialog from "../../Components/ConfirmDialog.vue";

export default {
  layout: appLayout({ title: "pages.attributeClasses.index.title" }),
  components: {
    AttributeClassForm,
    ConfirmDialog,
  },
  props: {
    attributeClasses: Object,
    attributes: Array,
    reloadFilters: Object,
  },
  data() {
    var searchParams = new URLSearchParams(window.location.search);

    return {
      dialog: false,
      attributeClass: null,
      pagination: {
        page: this.attributeClasses.currentPage,
        itemsPerPage: 50,
        sortBy: [searchParams.get("sortBy") || "referenceKey"],
        sortDesc: [searchParams.get("sortDesc") === "true"],
      },
      tableFooterProps: footerProps,
      filters: this.reloadFilters,
      showConfirmDialog: false,
      confirmMessage: this.$t("pages.attributeClasses.index.confirmDelete"),
      itemId: String,
    };
  },
  methods: {
    handleAttributes(item) {
      var attributesText = "";
      item.attributes.forEach((x, index) => {
        if (index == 0) {
          attributesText += x.name;
        } else {
          attributesText += ", " + x.name;
        }
      });
      return attributesText;
    },
    editAttributeClass(item) {
      if (this.formIsDisabled(item)) return;

      this.attributeClass = { ...item };
      this.dialog = true;
    },
    copyAttributeClass(item) {
      if (this.formIsDisabled(item)) return;

      this.attributeClass = { ...item, id: null, attributes: [] };
      this.dialog = true;
    },
    destroyAttributeClass(item) {
      if (item.fixed) return;

      this.itemId = item.id;
      this.showConfirmDialog = true;
    },
    handleConfirmation(status) {
      this.isConfirmed = status;
      if (status) {
        this.confirmDestroyAttributeClass();
      }
    },
    confirmDestroyAttributeClass() {
      this.$inertia.delete(
        this.route(`${this.attributeClassRoute}.destroy`, this.itemId)
      );
      this.itemId = "";
    },
    formatDate(date) {
      if (!date) return "N/A";

      return new Date(date).toLocaleDateString();
    },
    resetFilter() {
      this.filters = {};
    },
    changePage(options) {
      let query = {
        filters: pickBy(this.filters),
        sortBy: options.sortBy[0],
        sortDesc: options.sortDesc[0],
        page: options.page,
        pageSize: options.itemsPerPage,
      };

      this.$inertia.get(
        this.route(`${this.attributeClassRoute}.index`, query),
        {},
        { preserveState: true }
      );
    },
    formIsDisabled(item) {
      if (!item.fixed) return false;

      return item.id !== fixedClasses.primeId;
    },
  },
  computed: {
    attributeClassRoute() {
      return this.routeRaw().current().split(".")[0];
    },
    headers() {
      return [
        { text: this.$t("common.fieldNames.id"), value: "referenceKey" },
        {
          text: this.$t("pages.attributeClasses.index.columns.name"),
          value: "name",
        },
        {
          text: this.$t("pages.attributeClasses.index.columns.attributes"),
          value: "attributes",
        },
        {
          text: this.$t("common.dataTable.action"),
          value: "action",
          width: 120,
          sortable: false,
          align: "center fixed",
          class: "fixed",
        },
      ];
    },
  },
  watch: {
    filters: {
      handler: throttle(function () {
        this.pagination = { page: 1, sortBy: [], sortDesc: [] };
        this.changePage(this.pagination);
      }, 150),
      deep: true,
    },
  },
};
</script>
<style lang="scss">
.custom-tooltip {
  opacity: 1 !important;
  background: #e2dee3 !important;
  border-radius: 0 !important;
  border: 1px solid #cfd8dc !important;
}

table tbody tr td.fixed:nth-last-child(1),
table thead tr th.fixed:nth-last-child(1) {
  position: sticky !important;
  position: -webkit-sticky !important;
  right: 0;
  z-index: 199;
  background: #e2dee3;
  -webkit-box-shadow: -1px 0px 3px -1px rgba(0, 0, 0, 0.19);
  -moz-box-shadow: -1px 0px 3px -1px rgba(0, 0, 0, 0.19);
  box-shadow: -1px 0px 3px -1px rgba(0, 0, 0, 0.19);
}

table thead tr th.fixed:nth-last-child(1) {
  z-index: 199;
  border-left: 1px black;
}

table td.disabled {
  background: lightgray !important;
}
</style>
