export const oneshotAnimation = {
  autoplay: true,
  minimizable: false,
  loop: false,
  playerControls: false,
  playerSize: "minimal",
};

export const loopingAnimation = {
  autoplay: true,
  loop: true,
  playerSize: "minimal",
  playerControls: false,
  minimizable: false,
};
