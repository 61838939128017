<template>
  <span>
    <span class="font-weight-bold">{{ assetRule.triggerPropertyName }}:</span>
    {{ assetRule.triggerValue }}
    <template v-if="secondLogicOperator">
      <span class="font-weight-bold">
        {{
          $t(
            `pages.settings.assetRuleForm.stepOne.logicOperators.${secondLogicOperator}`
          )
        }}
      </span>
      <span class="font-weight-bold">{{ secondProperty }}:</span>
      {{ secondValue }}
    </template>
    <template v-if="thirdLogicOperator">
      <span class="font-weight-bold">
        {{
          $t(
            `pages.settings.assetRuleForm.stepOne.logicOperators.${thirdLogicOperator}`
          )
        }}
      </span>
      <span class="font-weight-bold">{{ thirdProperty }}:</span>
      {{ thirdValue }}
    </template>
  </span>
</template>
<script>
export default {
  props: {
    assetRule: Object,
  },
  computed: {
    secondLogicOperator() {
      return this.assetRule.secondTriggerLogicOperator;
    },
    secondProperty() {
      return this.assetRule.secondTriggerPropertyName;
    },
    secondValue() {
      return this.assetRule.secondTriggerValue;
    },
    thirdLogicOperator() {
      return this.assetRule.thirdTriggerLogicOperator;
    },
    thirdProperty() {
      return this.assetRule.thirdTriggerPropertyName;
    },
    thirdValue() {
      return this.assetRule.thirdTriggerValue;
    },
  },
};
</script>
