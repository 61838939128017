<template>
  <dialog-form :show-dialog="show" max-width="650px">
    <div class="d-flex flex-column px-6 py-4">
      <div class="d-flex justify-space-between">
        <h1 class="text-h4 black--text mb-2">
          {{ $t("components.integration.header") }}
        </h1>
      </div>
      <div class="mx-n6 pt-2 border-line" />

      <div v-if="isLoading" class="d-flex flex-column align-center py-16">
        <div class="loading-container">
          <div class="logo-container">
            <economic-logo />
          </div>
          <div class="progress-container">
            <div class="progress-bar-container">
              <div class="progress-bar"></div>
            </div>
          </div>
          <verarca-logo />
        </div>
        <h1 class="text-h6 black--text mb-2 text-center pt-6">
          {{ $t("components.integration.uploadingData") }}
        </h1>
        <div class="px-10">
          <h1
            class="text-body-2 grey--text mb-6 font-weight-light text-center px-16"
          >
            {{ $t("components.integration.uploadingSubText") }}
          </h1>
        </div>
      </div>

      <div v-if="!isLoading" class="d-flex flex-column align-center py-10">
        <div class="icon-container">
          <check-circle />
        </div>
        <h1 class="text-h6 black--text mb-2 text-center py-6">
          {{ $t("components.integration.finished") }}
        </h1>
        <v-btn
          tile
          elevation="0"
          color="orange"
          class="font-weight-regular text-capitalize text-body-2 white--text"
          @click="close"
        >
          {{ $t("components.integration.startUsingVerarca") }}
        </v-btn>
      </div>
    </div>
  </dialog-form>
</template>
<script>
import DialogForm from "../Dialog/DialogForm.vue";
import CheckCircle from "../../assets/svg/check-circle.svg";
import EconomicLogo from "../../assets/svg/e-conomic-logo.svg";
import VerarcaLogo from "../../assets/svg/verarca-logo-small.svg";

export default {
  components: {
    DialogForm,
    CheckCircle,
    EconomicLogo,
    VerarcaLogo,
  },
  data() {
    return {
      isLoading: false,
    };
  },
  props: {
    value: Boolean,
    integrationId: String,
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    async close() {
      this.syncIntegration();
      this.$emit("finish-integration");
      this.show = false;
    },
    syncIntegration() {
      this.$inertia.post(
        this.route("settings.integrations.sync", this.integrationId)
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.logo-container {
  background-color: white;
  border-radius: 50%;
  width: 62px;
  height: 62px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  border: solid 2px #f7f7f7;
}

.icon-container {
  background-color: rgba(38, 102, 99, 0.12);
  border-radius: 50%;
  width: 62px;
  height: 62px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
}

.loading-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80%;
}

.progress-container {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 8px;
}

.progress-bar-container {
  width: 100%;
  height: 8px;
  background-color: #f7f7f7;
  overflow: hidden;
}

.progress-bar {
  width: 100%;
  height: 100%;
  background: linear-gradient(270deg, #f25d3b 0%, rgba(242, 93, 59, 0) 100%);
  animation: progressAnimation 2s infinite;
}

@keyframes progressAnimation {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

.border-line {
  border-bottom: solid 2px #f7f7f7;
}
</style>
