<template>
  <div>
    <DialogFormHeader
      :title="$t('pages.assets.import.title')"
    ></DialogFormHeader>
    <DialogFormSectionOneCol>
      <!--<dialog-form-select-input
        v-model="selectedImportOption"
        :items="importOptions"
        itemText="text"
        itemValue="id"
        :title="$t('pages.assets.import.stepOne.importFrom')"
      ></dialog-form-select-input>-->
      <dialog-form-file-upload-input
        :selectedImportFiles="selectedImportFiles"
        @errorChanged="handleStepOneError"
        @onFileUploadChanged="onFileUploadChanged"
        @filesChanged="setSelectedImportFiles($event)"
        :multiple="true"
      ></dialog-form-file-upload-input>
    </DialogFormSectionOneCol>
  </div>
</template>
<script>
import DialogFormHeader from "../../Dialog/DialogFormHeader.vue";
import DialogFormSectionOneCol from "../../Dialog/DialogFormSectionOneCol.vue";
import DialogFormFileUploadInput from "../../Dialog/inputs/DialogFormFileUploadInput.vue";

const ALLOWED_IMPORT_MIME_TYPES = [
  "text/csv",
  "application/pdf",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
];

export default {
  props: {
    selectedImportFiles: Array,
  },
  emits: ["errorChanged", "invalidFormatChanged", "filesChanged"],
  data() {
    return {
      selectedImportOption: 1,
      importOptions: [{ id: 1, text: "File (.xls, .xlsx, .csv, .pdf)" }],
      isFileHovered: false,
      allowedFileExpensions: ALLOWED_IMPORT_MIME_TYPES,
    };
  },
  methods: {
    handleStepOneError() {},
    onFileUploadChanged(event) {
      const files = [...event.target.files];
      this.$emit("errorChanged", null);
      this.$emit("invalidFormatChanged", false);
      if (files.length === 0) {
        return;
      }
      this.setSelectedImportFiles(files);
    },
    onFileContainerDrop(event) {
      event.preventDefault();
      this.isFileHovered = false;
      this.$emit("errorChanged", null);
      this.$emit("invalidFormatChanged", false);
      const files = [...event.dataTransfer.files];
      if (!files.length) return;

      this.setSelectedImportFiles(files);
    },
    setSelectedImportFiles(files) {
      if (
        !!files &&
        files.every(
          (file) => !!file && ALLOWED_IMPORT_MIME_TYPES.includes(file.type)
        )
      ) {
        this.$emit("filesChanged", files);
      } else {
        this.$emit("filesChanged", []);
        this.$emit(
          "errorChanged",
          this.$t("pages.assets.import.stepOne.filetypeNotSupported")
        );
        this.$emit("invalidFormatChanged", true);
      }
    },
    onFileContainerDragOver(event) {
      event.preventDefault();
      this.isFileHovered = true;
    },
    onFileContainerDragEnter(event) {
      event.preventDefault();
      this.isFileHovered = true;
    },
    onFileContainerDragLeave(event) {
      event.preventDefault();
      this.isFileHovered = false;
    },
  },
  components: {
    DialogFormHeader,
    DialogFormSectionOneCol,
    DialogFormFileUploadInput,
  },
};
</script>
<style scoped>
.file-input-container {
  border: 2px dashed grey;
  background-color: white;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 2rem;
}

.file-hover-border {
  border: 2px solid #00695c;
}

.file-input-container .instructions span,
.file-input-container .file-selection span {
  display: block;
  color: black;
  font-weight: bold;
  margin-top: 1rem;
  margin-bottom: 1rem;
  text-align: center;
}
</style>
